import { Listbox } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/outline";
import { compact, omit } from "lodash";
import { useCallback, useMemo } from "react";
import {
  AssessmentQuestionType,
  FullAssessmentGroupSectionInput,
} from "types/graphql-schema";

import Button, { buttonTheme } from "@components/button/button";
import Input from "@components/input/input";
import Select from "@components/select/select";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { alphabet } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertNonNull } from "@helpers/helpers";

import AssessmentGroupSidebarQuestion, {
  assessmentQuestionIcon,
} from "./assessment-group-sidebar-question";

const AssessmentGroupSidebarSection = ({
  section,
  sectionIndex,
  canUpdateAssessmentGroup,
  onRemoveSection,
  onUpdateSection,
  deleteTooltip,
  onAddQuestion,
  onAddSectionBelow,
  isEngagementSurveyTemplate,
}: {
  deleteTooltip?: string;
  section: FullAssessmentGroupSectionInput;
  sectionIndex: number;
  canUpdateAssessmentGroup: boolean;
  isEngagementSurveyTemplate: boolean;
  onRemoveSection: () => void;
  onUpdateSection: (data: FullAssessmentGroupSectionInput) => void;
  onAddQuestion: (questionType: AssessmentQuestionType) => void;
  onAddSectionBelow: () => void;
}) => {
  const addQuestionOptions = useMemo(() => {
    const RangeIcon = assessmentQuestionIcon[AssessmentQuestionType.Range];
    const MultichoiceIcon =
      assessmentQuestionIcon[AssessmentQuestionType.Multichoice];
    const TextIcon = assessmentQuestionIcon[AssessmentQuestionType.Text];
    const NpsIcon = assessmentQuestionIcon[AssessmentQuestionType.Nps];
    return compact([
      {
        value: AssessmentQuestionType.Range,
        label: (
          <div className="flex items-center gap-2">
            <RangeIcon />
            Range
          </div>
        ),
      },
      isEngagementSurveyTemplate && {
        value: AssessmentQuestionType.Nps,
        label: (
          <div className="flex items-center gap-2">
            <NpsIcon />
            eNPS
          </div>
        ),
      },
      {
        value: AssessmentQuestionType.Multichoice,
        label: (
          <div className="flex items-center gap-2">
            <MultichoiceIcon />
            Multiple choice
          </div>
        ),
      },
      {
        value: AssessmentQuestionType.Text,
        label: (
          <div className="flex items-center gap-2">
            <TextIcon />
            Short answer
          </div>
        ),
      },
    ]);
  }, [isEngagementSurveyTemplate]);

  const handleScrollQuestionIntoView = useCallback(
    (questionIndex: number) => {
      setTimeout(() => {
        const questionElement = document.getElementById(
          `question-${sectionIndex}-${questionIndex}`
        );
        if (questionElement) {
          questionElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          questionElement.classList.add("flash-assessment-question");
          setTimeout(() => {
            questionElement.classList.remove("flash-assessment-question");
          }, 2000);
        }
      }, 100);
    },
    [sectionIndex]
  );

  return (
    <div className="border-t p-6">
      <div className="flex items-center justify-between">
        <div className="text-lg font-semibold">{`Section ${alphabet[sectionIndex]}`}</div>
        <Button
          disabled={!canUpdateAssessmentGroup || !!deleteTooltip}
          tooltip={deleteTooltip}
          icon={TrashIcon}
          theme={buttonTheme.iconGray}
          onClick={onRemoveSection}
        />
      </div>

      <div className="mt-6">
        <Input
          disabled={!canUpdateAssessmentGroup}
          aria-label="Section input"
          className="w-full"
          placeholder="Add header"
          value={section.title ?? ""}
          onChange={(e) => {
            onUpdateSection({
              ...section,
              title: e.target.value,
            });
          }}
        />
        <TextareaWysiwyg
          editable={canUpdateAssessmentGroup}
          className={classNames("mt-4", canUpdateAssessmentGroup && "bg-white")}
          placeholder="Add decription (optional)"
          value={section.description}
          deps={[section.title]}
          onChangeValue={(description: string) => {
            onUpdateSection({
              ...section,
              description,
            });
          }}
        />
      </div>

      <div className="mt-6 flex flex-col gap-6">
        {section.questions.map((question, questionIndex) => {
          return (
            <AssessmentGroupSidebarQuestion
              key={questionIndex}
              sectionIndex={sectionIndex}
              questionIndex={questionIndex}
              isLastQuestion={section.questions.length - 1 === questionIndex}
              question={assertNonNull(question)}
              canUpdateAssessmentGroup={canUpdateAssessmentGroup}
              onUpdateQuestion={(newQuestion) => {
                onUpdateSection({
                  ...section,
                  questions: section.questions.map((q, i) =>
                    i === questionIndex ? newQuestion : q
                  ),
                });
              }}
              onDeleteQuestion={() => {
                onUpdateSection({
                  ...section,
                  questions: section.questions.filter(
                    (_, i) => i !== questionIndex
                  ),
                });
              }}
              onMoveQuestionUp={() => {
                const toIndex = questionIndex - 1;
                const newQuestions = [...section.questions];
                const q = newQuestions.splice(questionIndex, 1)[0];
                newQuestions.splice(toIndex, 0, q);
                onUpdateSection({
                  ...section,
                  questions: newQuestions,
                });
                handleScrollQuestionIntoView(toIndex);
              }}
              onMoveQuestionDown={() => {
                const toIndex = questionIndex + 1;
                const newQuestions = [...section.questions];
                const q = newQuestions.splice(questionIndex, 1)[0];
                newQuestions.splice(toIndex, 0, q);
                onUpdateSection({
                  ...section,
                  questions: newQuestions,
                });
                handleScrollQuestionIntoView(toIndex);
              }}
              onDuplicateQuestion={() => {
                onUpdateSection({
                  ...section,
                  questions: [
                    ...section.questions.slice(0, questionIndex),
                    question,
                    {
                      ...omit(question, "id"),
                      title: `Copy of ${question?.title}`,
                    },
                    ...section.questions.slice(questionIndex + 1),
                  ],
                });
                handleScrollQuestionIntoView(questionIndex + 1);
              }}
            />
          );
        })}
      </div>

      <div className="mt-6 flex items-center gap-4">
        <Select
          value={undefined}
          onChange={(opt) => onAddQuestion(opt.value)}
          options={addQuestionOptions}
          disabled={!canUpdateAssessmentGroup}
        >
          {({ setReferenceElement, disabled }) => (
            <Listbox.Button
              className={classNames(
                "px-2 py-1 text-left text-sm rounded flex items-center gap-1.5 bg-blue-100 text-blue-700",
                !disabled && "hover:bg-blue-200",
                disabled && "opacity-50"
              )}
              ref={setReferenceElement}
            >
              Add Question
            </Listbox.Button>
          )}
        </Select>

        <Button
          disabled={!canUpdateAssessmentGroup}
          theme={buttonTheme.lightBlue}
          text="Add section below"
          onClick={onAddSectionBelow}
        />
      </div>
    </div>
  );
};

export default AssessmentGroupSidebarSection;

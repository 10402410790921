import { gql } from "@apollo/client";

const GoalArtifactComponentFragment = gql`
  fragment GoalArtifactComponentFragment on GoalArtifactNode {
    ... on GoalArtifactNode {
      id
      artifactType
      title
      updated
      canUpdate {
        permission
      }
      canDelete {
        permission
      }
      creator {
        id
        name
        avatar
      }
      firstOwner {
        id
        name
        avatar
      }
      state
      created
      scope
      ownerCount
      dueDate
      goalVisibility
      status
      progress
      progressType
      currentValue
      startValue
      targetValue
      isStale
      owners(first: 1) {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      parentGoalId
    }
  }
`;

// This fragment is used for goals that are not visible to the user but user should be aware of them (i.e. child goals).
export const BasicGoalArtifactComponentFragment = gql`
  ${GoalArtifactComponentFragment}
  fragment BasicGoalArtifactComponentFragment on BasicGoalArtifactNode {
    id
    artifactType
    startValue
    currentValue
    targetValue
    progress
    progressType
    dueDate
    startDate
    state
    status
    scope
    goalVisibility
    created
    parentGoalId
    firstOwner {
      id
      avatar
      name
    }
    owners(first: 1) {
      edges {
        node {
          id
          name
          avatar
        }
      }
    }
    ownerCount
    contributorCount
    canDelete {
      permission
    }
    canUpdate {
      permission
    }
    canRead {
      permission
    }
    isStale
    created
    goalArtifact {
      ...GoalArtifactComponentFragment
    }
  }
`;

const RecognitionArtifactComponentFragment = gql`
  fragment RecognitionArtifactComponentFragment on RecognitionArtifactNode {
    ... on RecognitionArtifactNode {
      recognitionCoreValue {
        id
        title
        emoji
        image
      }
      recipients {
        totalCount
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
    }
  }
`;

const ArtifactComponentFragment = gql`
  ${GoalArtifactComponentFragment}
  ${RecognitionArtifactComponentFragment}
  fragment ArtifactComponentFragment on ArtifactInterface {
    id
    artifactType
    title
    updated
    canUpdate {
      permission
    }
    canDelete {
      permission
    }
    creator {
      id
      name
      avatar
    }
    created
    ... on ActionItemArtifactNode {
      isComplete
      assignee {
        id
        name
        avatar
      }
    }
    ... on RecognitionArtifactNode {
      ...RecognitionArtifactComponentFragment
    }
    ... on DecisionArtifactNode {
      decision
      decisionState
      isDraft
    }
    ... on GoalArtifactNode {
      ...GoalArtifactComponentFragment
    }
    ... on RatingArtifactNode {
      rating {
        id
        title
      }
    }
  }
`;

export default ArtifactComponentFragment;

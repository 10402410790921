import { Route, Switch, useRouteMatch } from "react-router-dom";

import { isAdminVar } from "@cache/cache";
import AppLink from "@components/link/link";
import useDocumentTitle from "@components/use-document-title/use-document-title";

import CompliancePrograms from "../programs/components/compliance-programs";
import ComplianceProgram from "./components/compliance-program";
import ComplianceProgramAdmin from "./components/compliance-program-admin";
import ComplianceProgramForm from "./components/compliance-program-form/compliance-program-form";

const Programs = () => {
  useDocumentTitle("Programs");
  const { path } = useRouteMatch();
  const isAdmin = isAdminVar();

  return (
    <Switch>
      <Route exact path={path}>
        <CompliancePrograms />
      </Route>
      <Route
        exact
        path={[
          `${path}/:complianceProgramId(\\d+)`,
          `${path}/:complianceProgramId(\\d+)/actions`,
          `${path}/:complianceProgramId(\\d+)/progress`,
          `${path}/:complianceProgramId(\\d+)/reporting`,
        ]}
      >
        <ComplianceProgram />
      </Route>
      {isAdmin && (
        <Route exact path={`${path}/:complianceProgramId(\\d+)/admin`}>
          <div aria-label="Programs" className="flex flex-col flex-1 fs-unmask">
            <div className="flex flex-wrap items-center justify-between h-14 px-4 sm:px-6 border-b bg-gray-50">
              <AppLink
                className="mb-2 sm:mb-0 text-2xl font-semibold mr-8 flex items-center"
                to="/programs"
              >
                Programs
              </AppLink>
            </div>
            <div className="p-6">
              <div className="bg-white flex rounded-lg shadow-md ">
                <ComplianceProgramAdmin />
              </div>
            </div>
          </div>
        </Route>
      )}
      {isAdmin && (
        <Route
          exact
          path={[
            `${path}/new`,
            `${path}/:complianceProgramId(\\d+)/edit`,
            `${path}/:complianceProgramId(\\d+)/duplicate`,
          ]}
        >
          <ComplianceProgramForm />
        </Route>
      )}
    </Switch>
  );
};

export default Programs;

import _pluralize from "pluralize";

export const pluralize = (word: string, count: number) => {
  if (
    ["feedback", "alignment", "coaching"].includes(String(word).toLowerCase())
  ) {
    return _pluralize(word, 1);
  }
  if (["need"].includes(String(word).toLowerCase())) {
    return `${word}${count === 1 ? "s" : ""}`;
  }
  return _pluralize(word, count);
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const smallWordsRegexp =
  /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;

export const titleCase = (str: string) => {
  return str.replace(
    /[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g,
    function (match, index, title) {
      if (
        index > 0 &&
        index + match.length !== title.length &&
        match.search(smallWordsRegexp) > -1 &&
        title.charAt(index - 2) !== ":" &&
        (title.charAt(index + match.length) !== "-" ||
          title.charAt(index - 1) === "-") &&
        title.charAt(index - 1).search(/[^\s-]/) < 0
      ) {
        return match.toLowerCase();
      }

      if (match.substr(1).search(/[A-Z]|\../) > -1) {
        return match;
      }

      return match.charAt(0).toUpperCase() + match.substr(1);
    }
  );
};

export const lowerCase = (str: string, accronyms = false) => {
  if (accronyms) {
    return str.toLowerCase();
  }
  return str
    .split(" ")
    .map((word) => {
      if (/[A-Z]/.test(str.slice(1))) {
        return word;
      }
      return word.toLowerCase();
    })
    .join(" ");
};

export const removeBreaklines = (str = "", replaceBy = " ") => {
  return str.replace(/(\r\n|\n|\r)/gm, replaceBy);
};

export const joinStringWithCommaAnd = (arr: string[]) => {
  if (arr.length === 0) return "";
  if (arr.length === 1) return arr[0];
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return firsts.join(", ") + " and " + last;
};

import { Dialog } from "@headlessui/react";
import { noop } from "lodash";
import { PropsWithChildren, RefObject } from "react";

import { classNames } from "@helpers/css";

export enum ModalWidth {
  normal,
  medium,
  full,
}

const Modal = ({
  open = true,
  onClose = noop,
  children,
  initialFocus = undefined,
  className = "",
  dialogClassName = "",
  dialogContainerClassName = "",
  alignedTop = false,
  width = ModalWidth.normal,
  ...props
}: PropsWithChildren<{
  alignedTop?: boolean;
  open?: boolean;
  onClose?: (value: boolean) => void;
  className?: string;
  dialogClassName?: string;
  dialogContainerClassName?: string;
  initialFocus?: RefObject<HTMLElement | null>;
  width?: ModalWidth;
}>) => (
  <Dialog
    as="div"
    className={classNames(
      "fixed z-modal inset-0 overflow-y-auto text-gray-800",
      className
    )}
    open={open}
    onClose={onClose}
    initialFocus={initialFocus}
    {...props}
  >
    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
    <div
      className={classNames(
        "flex min-h-full items-center justify-center p-4 text-center",
        alignedTop && "items-start",
        dialogContainerClassName
      )}
    >
      <div
        className={classNames(
          "w-full mt-4 sm:mt-20 align-top transform rounded-lg bg-white text-left shadow-xl",
          width === ModalWidth.normal && "md:max-w-2xl",
          width === ModalWidth.medium && "md:max-w-5xl",
          width === ModalWidth.full && "md:max-w-7xl",
          dialogClassName
        )}
      >
        {children}
      </div>
    </div>
  </Dialog>
);

export default Modal;

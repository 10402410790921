import { useMemo } from "react";
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from "recharts";
import {
  GetComplianceProgramForReportQuery,
  RangeAssessmentQuestionNode,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import {
  getManagerEffectivenessAnswerPercentage,
  matchesReportsToFilter,
  matchesTeamsFilter,
  matchesUserFilter,
} from "@apps/reporting/helpers";
import useLabel from "@apps/use-label/use-label";
import { assertNonNull } from "@helpers/helpers";

import { TeamPickerTeam } from "../team-picker";

type QuestionData = { question: { id: number }; avgAnswer: number };

const ManagerEffectivenessChart = ({
  complianceProgram,
  questions,
  userFilterList,
  reportsToFilterList,
  teamFilterList,
}: {
  complianceProgram: NonNullable<
    GetComplianceProgramForReportQuery["complianceProgram"]
  >;
  questions: Pick<
    RangeAssessmentQuestionNode,
    "id" | "title" | "startValue" | "endValue" | "labels"
  >[];
  userFilterList: BasicUser[];
  reportsToFilterList: BasicUser[];
  teamFilterList: TeamPickerTeam[];
}) => {
  const label = useLabel();
  const hasFilter = useMemo(
    () =>
      userFilterList.length > 0 ||
      reportsToFilterList.length > 0 ||
      teamFilterList.length > 0,
    [reportsToFilterList.length, teamFilterList.length, userFilterList.length]
  );
  const data = useMemo(() => {
    if (!complianceProgram?.managerEffectivenessReport) {
      return [];
    }
    const reduceReportFunc = (
      questionArr: QuestionData[],
      edge: NonNullable<
        NonNullable<
          GetComplianceProgramForReportQuery["complianceProgram"]
        >["managerEffectivenessReport"]
      >["edges"][0]
    ) => {
      const row = assertNonNull(edge?.node);
      const questions = row.questions.edges.map((e) => assertNonNull(e?.node));
      return questionArr.concat(questions);
    };

    const allQuestions =
      complianceProgram.managerEffectivenessReport.edges.reduce<QuestionData[]>(
        reduceReportFunc,
        []
      );

    const filteredQuestions = complianceProgram.managerEffectivenessReport.edges
      .filter((reportRow) =>
        matchesUserFilter(
          userFilterList,
          assertNonNull(reportRow?.node?.manager)
        )
      )
      .filter((reportRow) =>
        matchesReportsToFilter(
          reportsToFilterList,
          assertNonNull(reportRow?.node?.manager)
        )
      )
      .filter((reportRow) =>
        matchesTeamsFilter(
          teamFilterList,
          assertNonNull(reportRow?.node?.manager)
        )
      )
      .reduce<QuestionData[]>(reduceReportFunc, []);

    return questions.map((question) => {
      const matchingFiltered = filteredQuestions.filter(
        (q) => q.question.id === question.id
      );
      const matchingAll = allQuestions.filter(
        (q) => q.question.id === question.id
      );
      const avgOfAveragesFiltered =
        matchingFiltered.reduce((sum, answer) => {
          return sum + answer.avgAnswer;
        }, 0) / matchingFiltered.length;
      const avgOfAveragesAll =
        matchingAll.reduce((sum, answer) => {
          return sum + answer.avgAnswer;
        }, 0) / matchingAll.length;

      return {
        questionTitle: question.title,
        filteredAvg: getManagerEffectivenessAnswerPercentage(
          question,
          avgOfAveragesFiltered
        ),
        avg: getManagerEffectivenessAnswerPercentage(
          question,
          avgOfAveragesAll
        ),
      };
    });
  }, [
    complianceProgram,
    questions,
    userFilterList,
    reportsToFilterList,
    teamFilterList,
  ]);

  if (data.length === 0) {
    return (
      <div className="mx-auto mt-8 w-92 text-center text-gray-500">
        This Upward {label("review", { capitalize: true })} has no Range
        questions
      </div>
    );
  }

  return (
    <div className="p-4 rounded-lg mb-4 bg-gray-100">
      <ResponsiveContainer width="100%" height={370}>
        <RadarChart cx="50%" cy="50%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="questionTitle" />
          <PolarRadiusAxis domain={[0, 100]} />
          <Radar
            name="Avg"
            dataKey="filteredAvg"
            stroke="#818cf8"
            fill="#a5b4fc"
            fillOpacity={0.6}
          />
          {hasFilter && (
            <Radar
              name="Avg"
              dataKey="avg"
              stroke="#f59e0b"
              strokeWidth={2}
              strokeDasharray="3 3"
              fillOpacity={0.0}
            />
          )}
        </RadarChart>
      </ResponsiveContainer>
      {hasFilter && (
        <div className="mt-4 flex gap-4 items-center justify-center">
          <div className="w-8 border-b-4 border-dotted border-amber-500"></div>
          <div>Program average</div>
        </div>
      )}
    </div>
  );
};

export default ManagerEffectivenessChart;

import { gql } from "@apollo/client";

import {
  ComplianceProgramActionAssessmentDeliveryFragment,
  UserActivityComplianceProgramMeetingFragment,
} from "@apps/programs/graphql/get-compliance-program-actions-query";

import { AssessmentNominationFragment } from "./get-assessment-nominations-query";

export default gql`
  ${UserActivityComplianceProgramMeetingFragment}
  fragment ActiveReviewsProgramAssessmentTemplate on AssessmentTemplateNode {
    id
    assessmentType
  }
  fragment ActiveReviewsComplianceProgram on ComplianceProgramNode {
    id
    title
    dueDate
    canReadDetails {
      permission
      reason
    }
    usersMissingAssessment {
      edges {
        node {
          target {
            id
            name
            avatar
          }
          responder {
            id
            name
            avatar
          }
          assessmentTemplateId
          dueDate
        }
      }
    }
    matchingOneonones(participants: [$currentUserId]) {
      edges {
        node {
          ...UserActivityComplianceProgramMeeting
        }
      }
    }
    requiredTopicTemplates {
      edges {
        node {
          id
          title
        }
      }
    }
    applicableReports: applicableUsers(managerIds: [$currentUserId]) {
      edges {
        node {
          id
          name
          avatar
          email
        }
      }
    }
    applicableManagers: applicableUsers(reportIds: [$currentUserId]) {
      edges {
        node {
          id
          name
          avatar
          email
        }
      }
    }
    performanceAssessmentTemplate {
      ...ActiveReviewsProgramAssessmentTemplate
    }
    managerAssessmentTemplate {
      ...ActiveReviewsProgramAssessmentTemplate
    }
    peerAssessmentTemplate {
      ...ActiveReviewsProgramAssessmentTemplate
    }
    assessments(responderId: $currentUserId) {
      edges {
        node {
          id
          state
          target {
            id
            name
            avatar
          }
          responder {
            id
            name
            avatar
          }
          template {
            id
          }
          submittedDatetime
        }
      }
    }
  }
  ${ComplianceProgramActionAssessmentDeliveryFragment}
  ${AssessmentNominationFragment}
  query getActiveReviewsActions(
    $organizationId: Int!
    $currentUserId: Int!
    $nominationTargetId: Int
    $deliveryTargetIds: [Int]!
  ) {
    compliancePrograms(
      onlyActive: true
      assessmentTypes: [performance, peer, manager]
      organizationId: $organizationId
      orderBy: "-due_date"
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ActiveReviewsComplianceProgram
        }
      }
    }
    assessmentDeliveries(
      state: draft
      organizationId: $organizationId
      targetIds: $deliveryTargetIds
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ComplianceProgramActionAssessmentDelivery
        }
      }
    }
    assessmentsOpenForNominations(targetId: $nominationTargetId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...AssessmentNomination
        }
      }
    }
  }
`;

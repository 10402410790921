import { useQuery } from "@apollo/client";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { sortBy } from "lodash";
import { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetTopicTemplatesQuery,
  GetTopicTemplatesQueryVariables,
  TopicTemplateCategoryFragmentFragment,
  TopicTemplateFragmentFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { isAdminVar } from "@cache/cache";
import Button from "@components/button/button";
import Input from "@components/input/input";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import getTopicTemplatesQuery from "../graphql/get-templates-query";
import TemplateItem from "./template-item";

const orgGroupId = "org";
const searchGroupId = "search";
const personalGroupId = "personal";
const oneononeGroupId = "oneonone";

type TemplateCategoryType = TopicTemplateCategoryFragmentFragment & {
  templates: TopicTemplateFragmentFragment[];
  isSearch?: boolean;
};
type TemplateScopeType = {
  id: string;
  title: string;
  ordinal: number;
  isSearch: boolean;
  templates: TopicTemplateFragmentFragment[];
};

const isTemplateCategory = (
  group: TemplateCategoryType | TemplateScopeType
): group is TemplateCategoryType => {
  return group.isSearch === undefined;
};

const TemplateList = () => {
  const isAdmin = isAdminVar();
  const label = useLabel();
  const link = useLink();
  const [search, setSearch] = useState("");
  const { groupId } = useParams<{ groupId: string }>();
  const queryGroupId = groupId;
  const { data, loading } = useQuery<
    GetTopicTemplatesQuery,
    GetTopicTemplatesQueryVariables
  >(getTopicTemplatesQuery, {
    onError: onNotificationErrorHandler(),
  });

  const [filteredGroup, setFilteredGroup] = useState(
    queryGroupId || orgGroupId
  );

  const handleToggleGroup = (groupId: string | number) => () => {
    link.replace(`/templates/group/${groupId}`);
    setFilteredGroup(String(groupId));
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const templates = data?.topicTemplates
    ? assertEdgesNonNull(data.topicTemplates)
    : [];
  const templateCategories = data?.topicTemplateCategories
    ? assertEdgesNonNull(data.topicTemplateCategories)
    : [];
  const templateCategoryById = templateCategories.reduce((memo, node) => {
    memo[String(node.id)] = { ...node, templates: [] };
    return memo;
  }, {} as { [key: string]: TemplateCategoryType });
  const templatesByGroup = templates.reduce(
    (memo, template) => {
      if (
        search.trim().length === 0 ||
        template.title.toLowerCase().includes(search.toLowerCase())
      ) {
        memo.scope.search.templates =
          memo.scope.search.templates.concat(template);
      }

      if (!template.publicTemplate) {
        memo.scope.personal.templates =
          memo.scope.personal.templates.concat(template);
      }
      if (template.oneononeTemplate) {
        memo.scope.oneonone.templates = [
          ...memo.scope.oneonone.templates,
          template,
        ];
      }
      if (!template.globalTemplate && template.publicTemplate) {
        memo.scope.org.templates = memo.scope.org.templates.concat(template);
      }

      const categories = template.categoriesList;
      if (categories.length > 0) {
        categories.forEach((category) => {
          category = assertNonNull(category);
          const id = String(category.id);
          if (!memo.category[id]) {
            memo.category[category.id] = {
              ...category,
              id: category.id,
              ordinal: category.ordinal,
              title: category.title,
              templates: [],
            };
          }
          memo.category[id].templates =
            memo.category[id].templates.concat(template);
        });
      }
      return memo;
    },
    {
      scope: {
        search: {
          id: searchGroupId,
          title: "Search",
          templates: [],
          ordinal: -1,
          isSearch: true,
        } as TemplateScopeType,
        org: {
          id: orgGroupId,
          title: "Organization templates",
          templates: [],
          ordinal: -10,
          isSearch: false,
        } as TemplateScopeType,
        personal: {
          id: personalGroupId,
          title: "Personal templates",
          templates: [],
          ordinal: -9,
          isSearch: false,
        } as TemplateScopeType,
        oneonone: {
          id: oneononeGroupId,
          title: `${label("oneonone", { capitalize: true })} templates`,
          templates: [],
          isSearch: false,
          ordinal: -9,
        } as TemplateScopeType,
      },
      category: templateCategoryById || {},
    }
  );

  const templatesByScopeGroupArray = sortBy(
    Object.values(templatesByGroup.scope),
    "ordinal"
  );
  const templatesByCategoryGroupArray = sortBy(
    Object.values(templatesByGroup.category),
    "ordinal"
  );
  const defaultTemplatesByCategoryGroupArray =
    templatesByCategoryGroupArray.filter(
      (group) => !group.organization?.id && group.templates.length > 0
    );
  const customTemplatesByCategoryGroupArray =
    templatesByCategoryGroupArray.filter((group) => !!group.organization?.id);
  const templatesByGroupId = {
    ...templatesByGroup.scope,
    ...templatesByGroup.category,
  };

  const CategoryButton = ({
    groupTemplate,
  }: {
    groupTemplate: TemplateScopeType | TemplateCategoryType;
  }) => (
    <button
      key={groupTemplate.id}
      className={classNames(
        "px-2 py-1 text-gray-600 text-left text-base flex gap-1 rounded-lg hover:bg-gray-100 w-full",
        filteredGroup === groupTemplate.id &&
          "font-medium text-gray-800 bg-blue-100 hover:bg-blue-100 "
      )}
      onClick={handleToggleGroup(groupTemplate.id)}
    >
      {groupTemplate.title}{" "}
      {`${
        groupTemplate?.isSearch ? "" : `(${groupTemplate.templates.length})`
      }`}
    </button>
  );

  const templateGroup = (
    templatesByGroupId as {
      [key: string]: TemplateCategoryType | TemplateScopeType;
    }
  )[filteredGroup];

  // RENDER
  return (
    <div className="sm:flex flex-1 " aria-label="Template list">
      <div className="sm:w-84 flex flex-col text-sm gap-2 p-4 sm:p-6 sm:border-r sm:rounded-l-lg">
        {templatesByScopeGroupArray.length > 0 &&
          templatesByScopeGroupArray.map((groupTemplate) => (
            <CategoryButton
              groupTemplate={groupTemplate}
              key={groupTemplate.id}
            />
          ))}
        <div className="mx-2 mt-6 text-xs font-semibold uppercase text-gray-500 flex items-center gap-2">
          Categories
          {isAdmin && (
            <AppLink
              to="/templates/category/new"
              className="hover:text-gray-800"
            >
              <PlusCircleIcon className="h-5 w-5" />
            </AppLink>
          )}
        </div>
        {customTemplatesByCategoryGroupArray.length === 0 && (
          <div className="text-sm text-gray-500 italic mx-2">
            No template categories
          </div>
        )}
        {customTemplatesByCategoryGroupArray.length > 0 &&
          customTemplatesByCategoryGroupArray.map((groupTemplate) => (
            <CategoryButton
              groupTemplate={groupTemplate}
              key={groupTemplate.id}
            />
          ))}

        {defaultTemplatesByCategoryGroupArray.length > 0 && (
          <div className="mx-2 mt-6 text-xs font-semibold uppercase text-gray-500 flex items-center gap-2">
            Topicflow
          </div>
        )}
        {defaultTemplatesByCategoryGroupArray.length > 0 &&
          defaultTemplatesByCategoryGroupArray.map((groupTemplate) => (
            <CategoryButton
              groupTemplate={groupTemplate}
              key={groupTemplate.id}
            />
          ))}
      </div>

      {!data && loading && (
        <div className="p-12 flex-1 bg-white flex justify-center">
          <Loading>Loading templates...</Loading>
        </div>
      )}

      {data && templateGroup && (
        <div className="flex-1 p-4 sm:p-6 flex flex-col gap-6 bg-white">
          <div className="flex flex-wrap justify-between items-center gap-4">
            {templateGroup.isSearch ? (
              <div className="flex-1">
                <div className="max-w-96 relative">
                  {search.trim().length > 0 && (
                    <button className="text-gray-500 h-4 w-4 p-0.5 absolute top-1/2 -mt-3 right-2">
                      <XIcon className="h-3 w-3" />
                    </button>
                  )}
                  <Input
                    value={search}
                    onChange={handleChangeSearch}
                    placeholder="Search templates..."
                    className="pr-6"
                  />
                </div>
              </div>
            ) : (
              <div className="text-lg flex-1">
                {templateGroup.title} ({templateGroup.templates.length})
              </div>
            )}
            <div className="flex gap-2">
              {isTemplateCategory(templateGroup) &&
                templateGroup?.canUpdate?.permission && (
                  <Button
                    to={`/templates/category/${templateGroup.id}`}
                    text="Edit category"
                  />
                )}
              <Button
                to={{
                  pathname: "/templates/new",
                  state: {
                    previousPathname: `/templates/group/${filteredGroup}`,
                  },
                }}
                text="New template"
              />
            </div>
          </div>
          <div>
            <div className="@container">
              {templateGroup.templates.length > 0 ? (
                <div className="grid @md:grid-cols-2 @2xl:grid-cols-3 @5xl:grid-cols-4 @7xl:grid-cols-5 gap-4">
                  {templateGroup.templates.map((template) => (
                    <div key={template.id} className="relative group">
                      <AppLink
                        to={{
                          pathname: `/templates/${template.id}`,
                          state: {
                            previousPathname: `/templates/group/${filteredGroup}`,
                          },
                        }}
                        className="flex"
                      >
                        <TemplateItem template={template} />
                      </AppLink>
                      <div className="absolute bottom-6 left-0 right-0 px-6 hidden group-hover:flex gap-4 justify-center items-center">
                        <Button
                          to={{
                            pathname: `/templates/${template.id}`,
                            state: {
                              previousPathname: `/templates/group/${filteredGroup}`,
                            },
                          }}
                          className="w-full"
                          text="Preview"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-gray-500">No templates...</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateList;

import { useQuery } from "@apollo/client";
import { ChangeEvent, useState } from "react";
import {
  AlignmentGoalFragmentFragment,
  GetGoalSearchResultsToAlignQueryQuery,
  GetGoalSearchResultsToAlignQueryQueryVariables,
  GoalAlignmentDialogGoalFragmentFragment,
  GoalScope,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Button from "@components/button/button";
import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, isGoalArtifactNode } from "@helpers/helpers";

import getGoalSearchResultsToAlignQuery from "../graphql/get-goal-search-results-to-align-query";
import MiniGoalAlignmentTree from "./mini-goal-alignment-tree";
import GoalAlignmentpopoverSearchResult from "./search-result";

const GoalAlignmentPopoverContent = ({
  goal,
  alignmentType,
  allowedGoalScopes,
  onSelectGoal,
}: {
  goal?: GoalAlignmentDialogGoalFragmentFragment | undefined;
  alignmentType: "child" | "parent";
  allowedGoalScopes: GoalScope[];
  onSelectGoal: (
    selectedGoal:
      | GoalAlignmentDialogGoalFragmentFragment
      | AlignmentGoalFragmentFragment
  ) => void;
}) => {
  const label = useLabel();
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query.trim(), 300);
  const handleChangeQuery = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };
  const {
    data: dataSearch,
    loading: loadingSearch,
    fetchMore,
  } = useQuery<
    GetGoalSearchResultsToAlignQueryQuery,
    GetGoalSearchResultsToAlignQueryQueryVariables
  >(getGoalSearchResultsToAlignQuery, {
    variables: { search: debouncedQuery },
    skip: query.trim().length === 0,
    onError: onNotificationErrorHandler(),
  });
  const isFinalQuery = debouncedQuery === query;

  const lowerCaseQuery = query.toLowerCase();
  const artifacts = dataSearch?.artifacts
    ? assertEdgesNonNull(dataSearch.artifacts)
    : [];
  const searchResults = artifacts
    .filter(isGoalArtifactNode)
    .filter((goal) =>
      (goal.title || "").trim().toLowerCase().includes(lowerCaseQuery)
    );

  const handleClickLoadMoreSearchResults = () => {
    if (!dataSearch?.artifacts?.pageInfo.hasNextPage) return;
    fetchMore({
      variables: {
        after: dataSearch?.artifacts.pageInfo.endCursor,
        merge: true,
      },
    });
  };

  return (
    <div className="p-4 gap-6 flex flex-col">
      <div>
        <div className="flex items-center gap-2 justify-between mb-2">
          <div className="text-base font-semibold text-gray-500">
            Choose a {alignmentType} {label("goal")}
          </div>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <Input
            className={"border rounded-md shadow-none"}
            onChange={handleChangeQuery}
            value={query}
            placeholder={`Search for a ${label(alignmentType)} ${label(
              "goal"
            )}...`}
          />
        </div>
        <div>
          <label className="text-xs text-gray-500 flex items-center gap-1">
            <input type="checkbox" />
            Show closed goals
          </label>
        </div>
      </div>
      {query.length > 0 ? (
        <div>
          <div className="font-medium text-gray-500 text-xs">
            Search results
          </div>
          <div className="mt-1">
            {isFinalQuery && !loadingSearch && searchResults.length === 0 ? (
              <div className="text-sm text-gray-400">No results</div>
            ) : (
              <div className="text-sm -mx-4">
                {searchResults.map((searchResult) => (
                  <GoalAlignmentpopoverSearchResult
                    onSelectGoal={onSelectGoal}
                    goal={searchResult}
                    goalToAlignTo={goal}
                    allowedGoalScopes={allowedGoalScopes}
                    key={searchResult.id}
                    alignmentType={alignmentType}
                  />
                ))}
                {isFinalQuery && dataSearch?.artifacts?.pageInfo.hasNextPage && (
                  <div className="flex justify-center mt-1">
                    <Button
                      onClick={handleClickLoadMoreSearchResults}
                      disabled={loadingSearch}
                      text="Load more"
                    />
                  </div>
                )}
              </div>
            )}
            {(!isFinalQuery || loadingSearch) && (
              <div className="flex justify-center mt-1">
                <Loading mini size={4} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <MiniGoalAlignmentTree
          goalToAlignTo={goal}
          onSelectGoal={onSelectGoal}
          allowedGoalScopes={allowedGoalScopes}
          alignmentType={alignmentType}
        />
      )}
    </div>
  );
};

export default GoalAlignmentPopoverContent;

import { gql } from "@apollo/client";

import { ArtifactActivityFragment } from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import KeyResultFragment from "@graphql/key-result-fragment";

export default gql`
  ${KeyResultFragment}
  ${ArtifactActivityFragment}
  mutation goalCheckinMutation(
    $comment: JSONString
    $currentValue: Float
    $goalStatus: GoalStatus
    $keyResults: [GoalCheckinKeyResultInput]
    $goalId: Int!
  ) {
    createGoalCheckin(
      goalId: $goalId
      currentValue: $currentValue
      goalStatus: $goalStatus
      keyResults: $keyResults
      comment: $comment
    ) {
      goal {
        id
        ... on GoalArtifactNode {
          status
          startValue
          currentValue
          targetValue
          progress
          progressType
          keyResults {
            edges {
              node {
                ...KeyResultFragment
              }
            }
          }
          isStale
          activities(first: 1, activityType: goal_checkin) {
            edges {
              node {
                ...ArtifactActivityFragment
              }
            }
          }
        }
      }
    }
  }
`;

import { Editor } from "@tiptap/react";
import getYouTubeID from "get-youtube-id";
import { KeyboardEvent, useRef, useState } from "react";

import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import { regexp } from "@helpers/constants";
import {
  isCommandEnterEvent,
  isEnterEvent,
  isValidUrl,
} from "@helpers/helpers";

import WYSIWYGDialog from "../dialog";
import { isValidForUrlPreview } from "../url-preview";

const EmbedUrlDialog = ({
  range,
  editor,
  onClose,
}: {
  range: any;
  editor: Editor;
  onClose: () => void;
}) => {
  const focusRef = useRef<HTMLInputElement | null>(null);
  const currentUser = currentUserVar();

  const [value, setValue] = useState("");
  const isValidUrlValue = isValidUrl(value);

  const handleClickEmbedUrl = () => {
    const command = editor.chain().focus().deleteRange(range);
    // Asana
    const isValidValueRegexpAsana = !!regexp.asanaTaskUrl.exec(value);
    // media
    const isValidLoomValue = regexp.loomUrl.exec(value);
    const isValidMiroRegexpValue = regexp.miroUrl.exec(value);
    const isValidVimeoRegexpValue = regexp.vimeoUrl.exec(value);
    const youtubeId = getYouTubeID(value);

    if (isValidForUrlPreview(value)) {
      command.previewUrl({ url: value }).run();
    } else if (isValidValueRegexpAsana) {
      command
        .insertAsana({ url: value, createdByUser: { id: currentUser.id } })
        .run();
    } else if (value.startsWith(window.location.origin)) {
      command.insertContent(`<a href="${value}">${value}</a>`).run();
    } else if (youtubeId) {
      const src = `https://www.youtube.com/embed/${youtubeId}`;
      command.setIframe({ src, url: value }).run();
    } else if (regexp.figmaUrl.test(value)) {
      const src = `https://www.figma.com/embed?embed_host=share&url=${encodeURIComponent(
        value
      )}`;
      command.setIframe({ src, url: value }).run();
    } else if (isValidLoomValue) {
      const src = `https://www.loom.com/embed/${isValidLoomValue[2]}`;
      command.setIframe({ src, url: value }).run();
    } else if (isValidMiroRegexpValue) {
      const src = `https://miro.com/app/live-embed/${isValidMiroRegexpValue[1]}/`;
      command.setIframe({ src, url: value }).run();
    } else if (isValidVimeoRegexpValue) {
      const src = `//player.vimeo.com/video/${isValidVimeoRegexpValue[3]}`;
      command.setIframe({ src, url: value }).run();
    } else if (isValidUrlValue) {
      command.setIframe({ src: value, url: value }).run();
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (isCommandEnterEvent(e) || isEnterEvent(e)) {
      handleClickEmbedUrl();
    }
  };

  return (
    <WYSIWYGDialog title="Embed URL" onClose={onClose} focusRef={focusRef}>
      <div className="flex flex-col gap-6">
        <div>
          <div className="max-w-96">
            <input
              ref={focusRef}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              className="block w-full border px-4 py-2 flex-1 text-sm rounded"
              placeholder="https://"
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="mt-1 text-2xs tracking-tight text-gray-400 ml-1">
            You can also paste the url in the notes directly.
          </div>
        </div>

        <div className="flex gap-4 items-center">
          <Button
            theme={buttonTheme.primary}
            disabled={!isValidUrlValue}
            onClick={handleClickEmbedUrl}
            text="Embed URL"
          />
          <Button onClick={onClose} text="Cancel" />
        </div>

        {value && !isValidUrlValue && (
          <div className="text-sm text-red-700">The URL is invalid.</div>
        )}
      </div>
    </WYSIWYGDialog>
  );
};

export default EmbedUrlDialog;

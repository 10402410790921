import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { AssessmentType } from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import useCreateEmptyAssessment from "@apps/assessments/hooks/use-create-empty-assessment";

import { ComplianceProgramActionAssessmentItemProps } from "../helpers";
import ComplianceProgramActionItem from "./compliance-program-action-item";

const ComplianceProgramActionItemForAssessment = ({
  item,
  children,
}: {
  item: ComplianceProgramActionAssessmentItemProps;
  children?: ReactNode | null;
}) => {
  const location = useLocation<TFLocationState>();
  const { handleCreateEmptyAssessment, isLoading } = useCreateEmptyAssessment(
    location.pathname
  );

  const isEngagementSurvey =
    item.assessmentType === AssessmentType.EngagementSurvey;
  const path = isEngagementSurvey ? `/survey` : `/assessments`;

  const handleClickCreateAssessment = () => {
    if (!isEngagementSurvey && !item.user) {
      throw new Error(
        "All assessment types (except engagement survey) must have a target user."
      );
    }
    handleCreateEmptyAssessment(
      item.complianceProgram.id,
      item.assessmentTemplateId,
      !isEngagementSurvey && item.user ? item.user.id : undefined,
      path
    );
  };

  const { key, ...extendedItem } = {
    ...item,
    buttonProps: {
      ...item.buttonProps,
      to: item.id ? `${path}/assessment/${item.id}` : undefined,
      onClick: item.id ? undefined : handleClickCreateAssessment,
      text: isLoading ? "Creating..." : item.buttonProps?.text,
      disabled: isLoading,
    },
  };

  return (
    <>
      <ComplianceProgramActionItem {...extendedItem}>
        {children}
      </ComplianceProgramActionItem>
    </>
  );
};

export default ComplianceProgramActionItemForAssessment;

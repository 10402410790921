import {
  AdjustmentsIcon,
  ArrowSmRightIcon,
  DocumentTextIcon,
} from "@heroicons/react/outline";
import { TbMessage } from "react-icons/tb";
import {
  ArtifactComponentFragmentFragment,
  ArtifactType,
  BasicGoalArtifactComponentFragmentFragment,
  CommonGoalArtifactFragmentFragment,
} from "types/graphql-schema";

import { AlignedGoalType } from "@apps/goal-alignment/use-fetch-alignment-goals";
import GoalIcon from "@components/goal-icon/goal-icon";
import { classNames } from "@helpers/css";
import { isPrivateBasicGoalArtifactNode } from "@helpers/helpers";

import ArtifactActionItemCompleteToggle from "./action-item-complete-toggle";
import RecognitionCoreValueIcon from "./recognition-core-value-icon";

type Props = {
  artifact:
    | ArtifactComponentFragmentFragment
    | CommonGoalArtifactFragmentFragment
    | BasicGoalArtifactComponentFragmentFragment
    | AlignedGoalType
    | {
        __typename?: undefined;
        artifactType: ArtifactType;
      };
  className?: string;
  size?: string | number;
  isStatic?: boolean;
};

const ArtifactIcon: React.FC<Props> = ({
  artifact,
  className = "",
  size = "5",
  isStatic = false,
}) => {
  return artifact.artifactType === ArtifactType.Decision ? (
    <ArrowSmRightIcon
      className={classNames(`w-${size} h-${size} text-emerald-600`, className)}
    />
  ) : artifact.artifactType === ArtifactType.Document ? (
    <DocumentTextIcon
      className={classNames(`text-gray-500 w-${size} h-${size}`, className)}
    />
  ) : artifact.artifactType === ArtifactType.Feedback ? (
    <TbMessage
      className={classNames(`text-gray-500 w-${size} h-${size}`, className)}
    />
  ) : artifact.artifactType === ArtifactType.Recognition ||
    artifact.__typename === "RecognitionArtifactNode" ? (
    <RecognitionCoreValueIcon
      artifact={
        artifact.__typename === "RecognitionArtifactNode" ? artifact : undefined
      }
    />
  ) : artifact.artifactType === ArtifactType.Goal &&
    isPrivateBasicGoalArtifactNode(artifact) ? (
    <GoalIcon
      className={className}
      size={size}
      state={artifact.state}
      scope={artifact.scope}
      isBasicPrivateGoal={true}
    />
  ) : artifact.artifactType === ArtifactType.Goal ||
    artifact.__typename === "GoalArtifactNode" ? (
    <GoalIcon
      className={className}
      size={size}
      state={
        artifact.__typename === "GoalArtifactNode" ||
        artifact.__typename === "BasicGoalArtifactNode"
          ? artifact.state
          : undefined
      }
      scope={
        artifact.__typename === "GoalArtifactNode" ||
        artifact.__typename === "BasicGoalArtifactNode"
          ? artifact.scope
          : undefined
      }
    />
  ) : artifact.artifactType === ArtifactType.ActionItem ||
    artifact.__typename === "ActionItemArtifactNode" ? (
    <ArtifactActionItemCompleteToggle
      artifact={
        artifact.__typename === "ActionItemArtifactNode" ? artifact : undefined
      }
      isStatic={isStatic}
      className={classNames(className)}
      size={size}
    />
  ) : artifact.artifactType === ArtifactType.Rating ? (
    <AdjustmentsIcon className={`h-${size} w-${size} text-gray-500`} />
  ) : null;
};

export default ArtifactIcon;

import { gql } from "@apollo/client";

export default gql`
  fragment OrgChartUserNode on UserNode {
    id
    name
    avatar
    managersList {
      id
    }
  }
  query getOrgChartOrganizationMembersQuery($organizationId: Int!) {
    organization(pk: $organizationId) {
      id
      members(membershipStatus: active) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            user {
              ...OrgChartUserNode
            }
          }
        }
      }
    }
  }
`;

import { gql } from "@apollo/client";

import { BasicGoalArtifactComponentFragment } from "@apps/artifact/graphql/artifact-component-fragment";

export const GoalOverviewGoalFragment = gql`
  ${BasicGoalArtifactComponentFragment}
  fragment GoalOverviewGoalOwners on GoalArtifactNode {
    id
    ownerCount
    canUpdate {
      permission
    }
    firstOwner {
      id
      name
      avatar
    }
    owners(first: 1) {
      edges {
        node {
          id
          name
          avatar
        }
      }
    }
  }
  fragment GoalOverviewGoalFragment on GoalArtifactNode {
    id
    artifactType
    title
    created
    updated
    canUpdate {
      permission
    }
    canDelete {
      permission
    }
    creator {
      id
      name
      avatar
    }
    state
    scope
    dueDate
    goalVisibility
    status
    startValue
    currentValue
    targetValue
    progress
    progressType
    isStale
    ...GoalOverviewGoalOwners
    activities(first: 1, activityType: goal_checkin) {
      edges {
        node {
          created
        }
      }
    }
    keyResults {
      edges {
        node {
          id
          title
          startValue
          currentValue
          targetValue
          progressType
          assignee {
            id
            name
            avatar
          }
          kpi {
            id
            title
          }
        }
      }
    }
    basicChildGoals {
      edges {
        node {
          ...BasicGoalArtifactComponentFragment
        }
      }
    }
  }
`;

export default gql`
  ${GoalOverviewGoalFragment}
  query getOverviewGoalsQuery(
    $ownerIds: [Int]
    $contributorIds: [Int]
    $involvingUserIds: [Int]
    $teamIds: [Int]
    $goalScopes: [GoalScope]
    $goalDueBetweenDates: [Date]!
  ) {
    artifacts(
      artifactType: goal
      goalScopes: $goalScopes
      goalOwners: $ownerIds
      goalContributors: $contributorIds
      goalInvolvingUsers: $involvingUserIds
      goalTeams: $teamIds
      goalDueBetweenDates: $goalDueBetweenDates
      orderBy: "objective_alignment"
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          id
          ... on GoalArtifactNode {
            ...GoalOverviewGoalFragment
          }
        }
      }
    }
  }
`;

import "types/graphql-schema";

import { classNames } from "@helpers/css";

export enum StatusPillEnum {
  NotApplicable = "N/A",
  NotStarted = "Not Started",
  InProgress = "In Progress",
  Complete = "Complete",
}

const StatusPill = ({
  label,
  className,
  status = StatusPillEnum.NotApplicable,
}: {
  label?: string;
  className?: string;
  status: StatusPillEnum;
}) => {
  const iconClassName =
    status === StatusPillEnum.NotApplicable
      ? "border-gray-300"
      : status === StatusPillEnum.NotStarted
      ? "border-amber-400"
      : status === StatusPillEnum.InProgress
      ? "border-blue-400 "
      : "border-emerald-400 bg-emerald-400";
  const statusClassName =
    status === StatusPillEnum.NotApplicable
      ? "bg-gray-100 text-gray-600"
      : status === StatusPillEnum.NotStarted
      ? "bg-amber-100 text-gray-600"
      : status === StatusPillEnum.InProgress
      ? "bg-[#DFEDFF] text-gray-600"
      : "bg-emerald-100 text-gray-600";

  const pillClassName = classNames(
    "flex items-center gap-2 px-2 py-1 text-sm break-keep rounded-full text-gray-600 outline-none",
    statusClassName,
    className
  );

  const statusLabel = label || status;

  const pillContent = (
    <>
      <div
        className={classNames(
          "w-2 h-2 border-2 rounded-full relative",
          iconClassName
        )}
      >
        {status === StatusPillEnum.InProgress && (
          <div className="absolute top-1/2 left-0 w-full h-1/2 bg-blue-400"></div>
        )}
      </div>
      {statusLabel}
    </>
  );
  return <span className={pillClassName}>{pillContent}</span>;
};

export default StatusPill;

import { useQuery } from "@apollo/client";
import { ArrowSmRightIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { compact, uniqBy } from "lodash";
import { useCallback, useMemo } from "react";
import {
  GetActiveEngagementSurveyActionsQuery,
  GetActiveEngagementSurveyActionsQueryVariables,
} from "types/graphql-schema";

import { ComplianceProgramItemProgramLink } from "@apps/assessments/components/homepage-active-reviews";
import { ProgramActionToDoStateFilter } from "@apps/programs/components/compliance-program";
import ComplianceProgramActionItemForAssessment from "@apps/programs/components/compliance-program-action-item-for-assessment";
import { sortByCompletedItemsLast } from "@apps/programs/components/compliance-program-actions";
import {
  ComplianceProgramActionAssessmentItemProps,
  ComplianceProgramActionBasicItemProps,
  getAssessmentItemsForTemplate,
} from "@apps/programs/helpers";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import {
  currentOrganizationVar,
  currentUserVar,
  isAdminVar,
} from "@cache/cache";
import Button from "@components/button/button";
import Layout from "@components/layout/layout";
import AppLink from "@components/link/link";
import AppPopover from "@components/popover/app-popover";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
} from "@components/toggle-button-group/toggle-button-group";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import getActiveEngagementSurveyActionsQuery from "../graphql/get-active-engagement-survey-actions-query";

const EngagementSurveyHomepageActiveSurveys = () => {
  const isAdmin = isAdminVar();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const currentUser = currentUserVar();
  const currentOrganization = currentOrganizationVar();

  const handleChangeActiveReviewFilter = useCallback(
    (filter: ProgramActionToDoStateFilter) => {
      saveUiPreference({ programActionsFilter: filter });
    },
    [saveUiPreference]
  );

  const { data, loading } = useQuery<
    GetActiveEngagementSurveyActionsQuery,
    GetActiveEngagementSurveyActionsQueryVariables
  >(getActiveEngagementSurveyActionsQuery, {
    variables: {
      organizationId: currentOrganization.id,
      currentUserId: currentUser.id,
    },
    onError: onNotificationErrorHandler(),
  });

  const compliancePrograms = useMemo(
    () =>
      data?.compliancePrograms
        ? assertEdgesNonNull(data.compliancePrograms)
        : [],
    [data]
  );
  const filterToDoState = useCallback(
    (item: ComplianceProgramActionBasicItemProps) =>
      uiPreferenceCache.programActionsFilter ===
        ProgramActionToDoStateFilter.All ||
      (uiPreferenceCache.programActionsFilter ===
        ProgramActionToDoStateFilter.ToDo &&
        !item.statusCompleted),
    [uiPreferenceCache.programActionsFilter]
  );

  const engagementSurveyCompliancePrograms = useMemo(
    () =>
      compliancePrograms.filter((program) => program.engagementSurveyTemplate),
    [compliancePrograms]
  );
  const engagementSurveyItems = engagementSurveyCompliancePrograms
    .reduce((memo, program) => {
      if (!program.engagementSurveyTemplate) {
        return memo;
      }
      const items = getAssessmentItemsForTemplate(
        program,
        program.engagementSurveyTemplate,
        currentUser.id
      );
      return memo.concat(items);
    }, [] as ComplianceProgramActionAssessmentItemProps[])
    .filter(filterToDoState);

  const reportIds = useMemo(
    () =>
      assertEdgesNonNull(currentUser.directReports).map((report) => report.id),
    [currentUser.directReports]
  );
  const hasReports = reportIds.length > 0;

  const myItemsCount = engagementSurveyItems.length;

  const programs: { id: number; title: string }[] = uniqBy(
    compact([...engagementSurveyCompliancePrograms]),
    "id"
  );

  return (
    <>
      <div className="mt-8 mb-12 flex justify-between w-full">
        <ToggleButtonGroup<ProgramActionToDoStateFilter>
          theme={ToggleButtonGroupTheme.mergedLightBlueButtons}
          buttons={[
            {
              title: ProgramActionToDoStateFilter.All,
              value: ProgramActionToDoStateFilter.All,
              active:
                uiPreferenceCache.programActionsFilter ===
                ProgramActionToDoStateFilter.All,
            },
            {
              title: ProgramActionToDoStateFilter.ToDo,
              value: ProgramActionToDoStateFilter.ToDo,
              active:
                uiPreferenceCache.programActionsFilter ===
                ProgramActionToDoStateFilter.ToDo,
            },
          ]}
          onClick={(button) =>
            handleChangeActiveReviewFilter(
              button?.value || ProgramActionToDoStateFilter.All
            )
          }
        />
        {(isAdmin || hasReports) && (
          <AppPopover
            options={{
              className: classNames(
                AppPopover.className,
                "w-96 overflow-y-auto max-h-64"
              ),
            }}
            placement="bottom-end"
            content={
              <div className="p-4">
                <div className="text-sm font-medium mb-2">Programs</div>
                <div className="flex flex-col divide-y border-t border-b">
                  {programs.map((program) => (
                    <AppLink
                      key={program.id}
                      to={`/programs/${program.id}/progress`}
                      className="py-2 px-1 text-sm text-gray-500 hover:bg-gray-50 flex items-center justify-between"
                    >
                      {program.title}
                      <ArrowSmRightIcon className="w-4 h-4" />
                    </AppLink>
                  ))}
                </div>
              </div>
            }
          >
            <AppPopover.Button
              as={Button}
              className="flex items-center gap-1"
              disabled={programs.length === 0}
            >
              View program progress{" "}
              <ChevronDownIcon className="w-3 h-3 text-gray-500" />
            </AppPopover.Button>
          </AppPopover>
        )}
      </div>

      <div className="text-lg font-semibold text-gray-900">My Surveys</div>
      <Layout.MainSubSection
        title=""
        loading={loading}
        emptyPlaceholder={
          myItemsCount === 0 ? `There are no active surveys for you` : null
        }
      >
        <Layout.MainSubSectionList>
          {sortByCompletedItemsLast(engagementSurveyItems).map((item) => (
            <ComplianceProgramActionItemForAssessment
              key={item.key}
              item={item}
            >
              <ComplianceProgramItemProgramLink
                program={item.complianceProgram}
              />
            </ComplianceProgramActionItemForAssessment>
          ))}
        </Layout.MainSubSectionList>
      </Layout.MainSubSection>
    </>
  );
};

export default EngagementSurveyHomepageActiveSurveys;

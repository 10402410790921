import { gql } from "@apollo/client";

export default gql`
  mutation undoAssessmentDeliveryApproval($assessmentDeliveryId: Int!) {
    undoAssessmentDeliveryAdminApproval(
      assessmentDeliveryId: $assessmentDeliveryId
    ) {
      assessmentDelivery {
        id
        state
      }
    }
  }
`;

import { Draggable } from "@hello-pangea/dnd";
import { withErrorBoundary } from "@sentry/react";
import { MdOutlineDragIndicator } from "react-icons/md";
import { useLocation } from "react-router-dom";
import {
  MeetingViewMeetingNodeNewPageFragmentFragment,
  NotesRequirement,
  TopicNodeNewPageFragmentFragment,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import TopicAssignee from "@apps/topic-assignee/topic-assignee";
import TopicDropdownMenu from "@apps/topic-dropdown-menu/topic-dropdown-menu";
import Error from "@components/error/error";
import AppLink from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import { getUrl, toWithBackground } from "@helpers/helpers";

import { meetingPaddingClassName } from "../helpers";
import DiscussionNotes from "./discussion-notes";
import IndividualNotesContainer from "./individual-notes-container";

const TopicContent = ({
  snapshot,
  provided,
  topic,
  meeting,
  opensInSidebar,
  isInExtension,
  isInPresentation,
  currentPageMeeting = null,
  index,
}: {
  topic: TopicNodeNewPageFragmentFragment;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  index?: number;
  opensInSidebar?: boolean;
  isInExtension?: boolean;
  isInPresentation?: boolean;
  currentPageMeeting?: MeetingViewMeetingNodeNewPageFragmentFragment | null;
  snapshot?: any;
  provided?: any;
}) => {
  const location = useLocation<TFLocationState>();

  let notesHelperText = "";
  if (topic.notesRequirement === NotesRequirement.OneParticipant) {
    notesHelperText = "At least one participant must add notes.";
  } else if (topic.notesRequirement === NotesRequirement.AllParticipants) {
    notesHelperText = "All participants must add notes.";
  }

  const isInFullScreen = index === undefined;
  return (
    <li
      key={topic.id}
      id={`meeting-${meeting.id}-topic-${topic.id}`}
      className={classNames(
        "group/topic",
        "py-4",
        "-mx-6",
        topic.isMandatory && "relative js-mandatory-topic",
        snapshot?.isDragging && "bg-purple-50/90 shadow-xl rounded-xl"
      )}
      {...(provided?.draggableProps || {})}
      ref={provided?.innerRef}
      aria-label={`Topic ${topic.title}`}
      data-testid={`container-topic-${topic.id}`}
    >
      <div
        className={classNames(
          "flex w-full relative group",
          meetingPaddingClassName
        )}
      >
        <div className="flex-1 flex gap-1">
          {!isInFullScreen && (
            <span
              className={classNames(
                "cursor-grab text-gray-400 absolute py-0.5 px-0 left-[2px] top-1 bg-gray-100 hover:bg-gray-200 rounded hidden sm:group-hover:block",
                snapshot?.isDragging && "block",
                isInExtension && "hidden"
              )}
              {...(provided?.dragHandleProps || {})}
              aria-label="Topic drag handle"
            >
              <MdOutlineDragIndicator className="h-4 w-4 " />
            </span>
          )}

          {/* TEXT */}
          <div className="">
            <AppLink
              to={toWithBackground({
                pathname: getUrl({
                  topicId: topic.id,
                  meetingId: meeting.id,
                  meetingGroupId: meeting.meetingGroup?.id,
                }),
                location,
              })}
              className={classNames(
                "inline hover:underline text-lg font-medium p-1 -ml-1",
                isInPresentation && "text-xl"
              )}
              aria-label="Topic title"
            >
              <span>{topic.title}</span>
            </AppLink>
          </div>

          {!isInExtension && topic.isMandatory && (
            <div>
              <Tooltip
                text={`The creator of this template has marked the topic as mandatory and it cannot be edited. ${notesHelperText}`}
              >
                <span className="text-xs text-amber-700 bg-amber-100 px-1 py-px rounded-md ml-1">
                  Mandatory
                </span>
              </Tooltip>
            </div>
          )}
        </div>

        {/* STATE & ACTIONS */}
        {/* wrapping div is necessary to keep icon align to top */}
        {!isInExtension && (
          <div className="">
            <div className="opacity-0 group-hover/topic:opacity-100 flex items-center gap-2">
              <TopicAssignee
                topic={topic}
                assignableUsers={meeting.participants?.edges.map(
                  (edge) => edge?.node?.user
                )}
              />
              <div className="flex">
                <TopicDropdownMenu
                  meetingGroup={meeting.meetingGroup}
                  topic={topic}
                  meeting={meeting}
                  portal={opensInSidebar}
                  size="5"
                  className="py-0.5 hover:bg-black hover:bg-opacity-5"
                  currentPageMeeting={currentPageMeeting}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="pt-2 relative">
        {topic.includesSharedNotes && (
          <DiscussionNotes
            topic={topic}
            meeting={meeting}
            className={isInPresentation ? "text-lg" : ""}
          />
        )}
        <div>
          <IndividualNotesContainer
            topic={topic}
            meeting={meeting}
            className={classNames("mb-2", meetingPaddingClassName)}
          />
        </div>
      </div>
    </li>
  );
};

const Topic = ({
  topic,
  index,
  meeting,
  opensInSidebar,
  isInExtension,
  isInPresentation,
  currentPageMeeting = null,
}: {
  topic: TopicNodeNewPageFragmentFragment;
  index?: number;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  opensInSidebar?: boolean;
  isInExtension?: boolean;
  isInPresentation?: boolean;
  currentPageMeeting?: MeetingViewMeetingNodeNewPageFragmentFragment | null;
}) => {
  const isInFullScreen = index === undefined;

  return isInFullScreen ? (
    <TopicContent
      key={topic.id}
      topic={topic}
      index={index}
      meeting={meeting}
      opensInSidebar={opensInSidebar}
      isInExtension={isInExtension}
      isInPresentation={isInPresentation}
      currentPageMeeting={currentPageMeeting}
    />
  ) : (
    <Draggable draggableId={`topic-${topic.id}`} index={index}>
      {(provided, snapshot) => (
        <TopicContent
          provided={provided}
          snapshot={snapshot}
          isInPresentation={isInPresentation}
          key={topic.id}
          topic={topic}
          index={index}
          meeting={meeting}
          opensInSidebar={opensInSidebar}
          isInExtension={isInExtension}
          currentPageMeeting={currentPageMeeting}
        />
      )}
    </Draggable>
  );
};

export default withErrorBoundary(Topic, {
  fallback: <Error description={"The topic could not be rendered."} />,
});

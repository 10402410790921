import { useMutation } from "@apollo/client";
import { DotsHorizontalIcon } from "@heroicons/react/outline";
import {
  ControlledMenu,
  Menu,
  MenuDivider,
  MenuItem,
  SubMenu,
} from "@szhsin/react-menu";
import { useCallback, useState } from "react";
import {
  CancelMeetingMutationMutation,
  CancelMeetingMutationMutationVariables,
  DeleteDraftMeetingNewPageMutationMutation,
  DeleteDraftMeetingNewPageMutationMutationVariables,
  MeetingDialogFragmentFragment,
  MeetingEditTypeEnum,
  OnlineVideoConference,
  PermissionNode,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import getSidebarDataQuery from "@apps/main/graphql/get-sidebar-data-query";
import cancelMeetingMutation from "@apps/meeting-dialog/graphql/cancel-meeting-mutation";
import MeetingDialog, {
  meetingDialogAction,
} from "@apps/meeting-dialog/meeting-dialog";
import deleteMeetingTranscriptsMutation from "@apps/meeting-new/components/prep/graphql/delete-meeting-transcripts-mutation";
import MeetingTypeDropdownOptions from "@apps/meeting-new/components/prep/meeting-type-dropdown-options";
import deleteDraftMeetingMutation from "@apps/meeting-new/graphql/delete-draft-meeting-mutation";
import getMeetingQuery from "@apps/meeting-new/graphql/get-meeting-query";
import SetAgendaAsTemplateDialog from "@apps/set-agenda-as-template-dialog/set-agenda-as-template-dialog";
import Templates from "@apps/templates/dialog";
import { currentUserVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import { useLink } from "@components/link/link";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import { getUrl } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import CalendarEventMenu from "./components/calendar-event-menu";
import CopyUrlMenu from "./components/copy-url-menu";
import CreateTemplateDialog from "./components/create-template-dialog";
import IgnoreMenu from "./components/ignore-menu";
import VideoConferenceMenu from "./components/video-conference-menu";

const MeetingDropdownMenu = ({
  meeting,
  meetingGroup,
  size = "6",
  className = "",
  isContextMenu = false,
  buttonClassName = "",
  anchorPoint,
  onToggleMenu,
  onMenuChange,
  onOpenSendNotesDialog,
  onMeetingUpdated,
  ...menuProps
}: {
  meeting: {
    id: number;
    title?: string | null;
    organizer?: BasicUser | null;
    isRecurring: boolean;
    externalUrl?: string | null;
    videoConferenceUrl?: string | null;
    draft: boolean;
    videoConferenceType?: OnlineVideoConference | null;
    transcript?: {
      id: number;
    } | null;
    canDelete?: Pick<PermissionNode, "permission"> | null;
    canUpdate?: Pick<PermissionNode, "permission"> | null;
    ignored: boolean;
  };
  meetingGroup: {
    id: number;
    isFormalOneonone: boolean;
    facilitator?: BasicUser | null;
    hasTemplate?: boolean | null;
  };
  size?: number | string;
  className?: string;
  buttonClassName?: string;
  isContextMenu?: boolean;
  anchorPoint?: any;
  onToggleMenu?: (opened: boolean) => void;
  onMenuChange?: ({ open }: { open: boolean }) => void;
  onOpenSendNotesDialog?: () => void;
  onMeetingUpdated?: (data: { meeting: MeetingDialogFragmentFragment }) => void;
}) => {
  const {
    ConfirmationDialog: ConfirmationDialogThisMeeting,
    confirm: confirmThisMeeting,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this meeting?"
  );
  const {
    ConfirmationDialog: ConfirmationDialogThisAndFutureMeetings,
    confirm: confirmThisAndFutureMeetings,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this and all future meetings?"
  );
  const {
    ConfirmationDialog: ConfirmationDialogAllMeetings,
    confirm: confirmAllMeetings,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this and all future meetings?"
  );
  const link = useLink();
  const { onError } = useNotificationError();
  const [openCreateTemplateDialog, setOpenCreateTemplateDialog] =
    useState(false);
  const [openTemplateDialog, setOpenTemplatesDialog] = useState(false);
  const [openSetAgendaAsTemplateDialog, setOpenSetAgendaAsTemplateDialog] =
    useState(false);
  const [isShowingMeetingDialogAction, setIsShowingMeetingDialogAction] =
    useState<meetingDialogAction | false>(false);
  const [cancelMeeting] = useMutation<
    CancelMeetingMutationMutation,
    CancelMeetingMutationMutationVariables
  >(cancelMeetingMutation);
  const [deleteDraftMeeting] = useMutation<
    DeleteDraftMeetingNewPageMutationMutation,
    DeleteDraftMeetingNewPageMutationMutationVariables
  >(deleteDraftMeetingMutation);

  const handleOpenTemplatesDialog = () => {
    setOpenTemplatesDialog(true);
  };
  const handleCreateTemplateDialog = () => {
    setOpenCreateTemplateDialog(true);
  };

  const handleSetAgendaAsTemplate = () => {
    setOpenSetAgendaAsTemplateDialog(true);
  };

  const handleClickDeleteDraftMeeting = useCallback(() => {
    deleteDraftMeeting({
      variables: {
        meetingId: meeting.id,
      },
      update: (cache) => {
        const cacheId = cache.identify(meeting);
        cache.evict({ id: cacheId });
        cache.gc();
      },
      onCompleted: () => {
        successNotificationVar({ title: "Meeting discarded." });
        link.redirect("/");
      },
      onError,
    });
  }, [deleteDraftMeeting, meeting, onError, link]);

  const handleClickCancelMeeting = useCallback(
    (editType: MeetingEditTypeEnum) => {
      cancelMeeting({
        variables: {
          editType,
          meetingId: meeting.id,
        },
        update: (cache) => {
          const cacheId = cache.identify(meeting);
          cache.evict({ id: cacheId });
          cache.gc();
        },
        onCompleted: () => {
          successNotificationVar({ title: "Meetings cancelled." });
          link.redirect("/");
        },
        onError,
      });
    },
    [cancelMeeting, meeting, onError, link]
  );

  const handleClickCancelThisMeeting = useCallback(async () => {
    const confirmation = await confirmThisMeeting();
    if (confirmation) {
      handleClickCancelMeeting(MeetingEditTypeEnum.SingleEvent);
    }
  }, [confirmThisMeeting, handleClickCancelMeeting]);
  const handleClickCancelThisAndFutureMeetings = useCallback(async () => {
    const confirmation = await confirmThisAndFutureMeetings();
    if (confirmation) {
      handleClickCancelMeeting(MeetingEditTypeEnum.ThisEventAndFuture);
    }
  }, [confirmThisAndFutureMeetings, handleClickCancelMeeting]);
  const handleClickCancelAllMeetings = useCallback(async () => {
    const confirmation = await confirmAllMeetings();
    if (confirmation) {
      handleClickCancelMeeting(MeetingEditTypeEnum.AllEvents);
    }
  }, [confirmAllMeetings, handleClickCancelMeeting]);

  const meetingUrl = getUrl({
    meetingGroupId: meetingGroup.id,
    meetingId: meeting.id,
  });
  const handleClickEditMeetingSettings = () => {
    link.redirect(
      `${getUrl({
        meetingGroupId: meetingGroup.id,
      })}/settings?from=${meetingUrl}`
    );
  };

  const organizerId = meeting.organizer?.id;
  const currentUser = currentUserVar();
  const isCurrentUserTheOrganizer = organizerId === currentUser.id;

  const {
    ConfirmationDialog: ConfirmationDeleteTranscript,
    confirm: confirmDeleteTranscript,
  } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete the transcript?"
  );
  const [deleteMeetingTranscripts] = useMutation(
    deleteMeetingTranscriptsMutation
  );

  const handleClickDeleteTranscript = useCallback(async () => {
    const confirmation = await confirmDeleteTranscript();
    if (confirmation) {
      deleteMeetingTranscripts({
        variables: { meetingId: meeting.id },
        onError: onNotificationErrorHandler(),
      });
    }
  }, [confirmDeleteTranscript, deleteMeetingTranscripts, meeting]);

  const items = (
    <>
      <>
        {isCurrentUserTheOrganizer ? (
          <MenuItem
            className={"text-sm"}
            onClick={() =>
              setIsShowingMeetingDialogAction(meetingDialogAction.edit)
            }
            disabled={!isCurrentUserTheOrganizer}
          >
            Edit meeting
          </MenuItem>
        ) : (
          <MenuItem
            className={"text-sm"}
            onClick={() =>
              setIsShowingMeetingDialogAction(meetingDialogAction.readonly)
            }
          >
            Meeting details
          </MenuItem>
        )}
        {!meeting.draft && meeting.canDelete?.permission && (
          <>
            {meeting.isRecurring ? (
              <SubMenu label="Delete meeting" className={"text-sm"}>
                <MenuItem
                  className={"text-sm"}
                  onClick={handleClickCancelThisMeeting}
                >
                  <div>This meeting only</div>
                </MenuItem>
                <MenuItem
                  className={"text-sm"}
                  onClick={handleClickCancelThisAndFutureMeetings}
                >
                  <div>This and all future meetings</div>
                </MenuItem>
                <MenuItem
                  className={"text-sm"}
                  onClick={handleClickCancelAllMeetings}
                >
                  <div>All meetings</div>
                </MenuItem>
              </SubMenu>
            ) : (
              <MenuItem
                className={"text-sm"}
                onClick={handleClickCancelThisMeeting}
              >
                <div>Delete meeting</div>
              </MenuItem>
            )}
          </>
        )}
        {meeting.draft && meeting.canUpdate?.permission && (
          <MenuItem
            className={"text-sm"}
            onClick={handleClickDeleteDraftMeeting}
          >
            <div>Discard draft meeting</div>
          </MenuItem>
        )}
      </>

      <MenuDivider />
      <MeetingTypeDropdownOptions
        meetingGroup={meetingGroup}
        meeting={meeting}
      />

      <ConfirmationDeleteTranscript />
      <ConfirmationDialogAllMeetings />
      <ConfirmationDialogThisAndFutureMeetings />
      <ConfirmationDialogThisMeeting />
      {!isContextMenu && meeting && (
        <>
          <SubMenu className="text-sm" label="Templates">
            <MenuItem
              className="text-sm"
              onClick={handleClickEditMeetingSettings}
            >
              Edit meeting template
            </MenuItem>
            <MenuItem className="text-sm" onClick={handleOpenTemplatesDialog}>
              Apply a template...
            </MenuItem>
            <MenuItem
              className="text-sm block"
              onClick={handleCreateTemplateDialog}
            >
              Save agenda to template library
            </MenuItem>
            {meeting.isRecurring && (
              <MenuItem
                className="text-sm block"
                onClick={handleSetAgendaAsTemplate}
              >
                Reuse this agenda in upcoming meetings
              </MenuItem>
            )}
          </SubMenu>
          <MenuDivider />
        </>
      )}

      {meeting.externalUrl && (
        <CalendarEventMenu externalUrl={meeting.externalUrl} />
      )}
      {meeting.videoConferenceUrl && meeting.videoConferenceType && (
        <VideoConferenceMenu
          videoConferenceUrl={meeting.videoConferenceUrl}
          videoConferenceType={meeting.videoConferenceType}
        />
      )}
      {(meeting.externalUrl || meeting.videoConferenceUrl) && <MenuDivider />}

      {!isContextMenu && meeting && (
        <>
          <CopyUrlMenu meeting={meeting} meetingGroup={meetingGroup} />
          <MenuItem className={"text-sm"} onClick={onOpenSendNotesDialog}>
            Share notes
          </MenuItem>
          <MenuDivider />
        </>
      )}

      {meeting?.transcript?.id && (
        <>
          <SubMenu label="Transcription" className="text-sm">
            <MenuItem
              className={"text-sm"}
              onClick={handleClickDeleteTranscript}
            >
              Delete transcript
            </MenuItem>
          </SubMenu>
          <MenuDivider />
        </>
      )}

      {meeting.canUpdate?.permission && (
        <>
          <MenuItem
            className={"text-sm"}
            onClick={handleClickEditMeetingSettings}
          >
            <div>Slack notifications</div>
          </MenuItem>
          <MenuDivider />
        </>
      )}

      <SubMenu label="More..." className="text-sm">
        <IgnoreMenu meeting={meeting} meetingGroup={meetingGroup} />
        <MenuDivider />
        <MenuItem className={"text-sm"}>
          <a
            href={`${getUrl({
              meetingGroupId: meetingGroup.id,
              meetingId: meeting.id,
            })}.md`}
            target="_blank"
            rel="noreferrer"
          >
            Markdown
          </a>
        </MenuItem>
      </SubMenu>
    </>
  );

  // Render
  return (
    <>
      {meeting && meetingGroup && openTemplateDialog && (
        <Templates
          meeting={meeting}
          meetingGroup={meetingGroup}
          onClose={() => setOpenTemplatesDialog(false)}
        />
      )}
      {openCreateTemplateDialog && (
        <CreateTemplateDialog
          meeting={meeting}
          onClose={() => setOpenCreateTemplateDialog(false)}
        />
      )}
      {openSetAgendaAsTemplateDialog && (
        <SetAgendaAsTemplateDialog
          meeting={meeting}
          meetingGroup={meetingGroup}
          onClose={() => setOpenSetAgendaAsTemplateDialog(false)}
        />
      )}
      {isShowingMeetingDialogAction && (
        <MeetingDialog
          meetingId={meeting.id}
          meetingGroupId={meetingGroup.id}
          onClose={() => setIsShowingMeetingDialogAction(false)}
          defaultAction={isShowingMeetingDialogAction}
          onSaved={(data) => {
            if (onMeetingUpdated) {
              onMeetingUpdated(data);
            }
            setIsShowingMeetingDialogAction(false);
          }}
          refetchQueries={[getSidebarDataQuery, getMeetingQuery]}
        />
      )}
      {isContextMenu && onToggleMenu ? (
        <ControlledMenu
          {...menuProps}
          portal={true}
          className="z-dropdown fs-unmask"
          anchorPoint={anchorPoint}
          onClose={() => onToggleMenu(false)}
        >
          {items}
        </ControlledMenu>
      ) : (
        <Menu
          onMenuChange={onMenuChange}
          className="z-dropdown fs-unmask"
          portal
          menuButton={
            <Button
              theme={buttonTheme.iconGray}
              className={buttonClassName}
              icon={DotsHorizontalIcon}
              aria-label="Meeting dropdown button"
            />
          }
          aria-label="Meeting dropdown list"
        >
          {items}
        </Menu>
      )}
    </>
  );
};

export default MeetingDropdownMenu;

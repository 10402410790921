import { useMutation } from "@apollo/client";
import {
  AnnotationIcon,
  DotsHorizontalIcon,
  ExternalLinkIcon,
  LinkIcon,
  TrashIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline";
import {
  ClickEvent,
  Menu,
  MenuDivider,
  MenuItem,
  SubMenu,
} from "@szhsin/react-menu";
import copy from "copy-to-clipboard";
import { noop } from "lodash";
import { Fragment, ReactNode, useCallback, useState } from "react";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { MdOutlinePlaylistRemove } from "react-icons/md";
import { TbArrowBarDown, TbArrowBarUp, TbListTree } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import {
  ArtifactType,
  GoalArtifactNode,
  GoalProgressType,
  GoalScope,
  GoalState,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import CheckinDialog from "@apps/checkin-dialog/checkin-dialog";
import CommentFormDialog from "@apps/comments/components/comment-form-dialog";
import GoalDuplicationDialog from "@apps/goal-duplication-dialog/goal-duplication-dialog";
import useLabel from "@apps/use-label/use-label";
import cache, { errorNotificationVar } from "@cache/cache";
import { successNotificationVar } from "@cache/cache";
import { useLink } from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { getUrl, isGoalArtifactNode, toWithBackground } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import ArtifactDropdownAlignmentSubMenu from "./artifact-dropdown-menu-alignment-sub-menu";
import deleteArtifactMutation from "./graphql/delete-artifact-mutation";

export type ArtifactDropdownArtifactType = {
  __typename: string;
  id: number;
  artifactType: ArtifactType;
  title?: string | null;
  progressType?: GoalProgressType;
  canDelete?: {
    permission: boolean;
  } | null;
  canUpdate?: {
    permission: boolean;
  } | null;
  startDatetime?: string | null;
  endDatetime?: string | null;
  scope?: GoalScope;
  state?: GoalState;
  parentGoalId?: number | null;
};

const ArtifactDropdownMenu = ({
  artifact,
  portal = true,
  size = "6",
  className = "",
  isInWysiwyg = false,
  descriptionIsExpanded = false,
  children = null,
  onToggleDescription,
  onDelete = noop,
  onSavedComment = noop,
  onRemoveComment,
}: {
  artifact: ArtifactDropdownArtifactType;
  portal?: boolean;
  size?: string;
  className?: string;
  isInWysiwyg?: boolean;
  descriptionIsExpanded?: boolean;
  anchorPoint?: any;
  children?: ReactNode;
  onToggleDescription?: () => void;
  onToggleMenu?: (bool: boolean) => void;
  onDelete?: (obj?: { artifact: any }) => void;
  onSavedComment?: (comment: any) => void;
  onRemoveComment?: () => void;
}) => {
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null);
  const isEditableGoalArtifact =
    artifact.id &&
    artifact.artifactType === ArtifactType.Goal &&
    artifact.canUpdate?.permission;
  const label = useLabel();
  const artifactName = label(artifact.artifactType);
  const link = useLink();
  const location = useLocation<TFLocationState>();
  const artifactUrl = getUrl({
    artifactType: artifact.artifactType,
    artifactId: artifact.id,
  });
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    `Are you sure you want to delete this ${artifactName}?`
  );

  const [isOpeningCommentFormDialog, setIsOpeningCommentFormDialog] =
    useState(false);
  const [isOpeningCheckinDialog, setIsOpeningCheckinDialog] = useState(false);
  const [isOpeningDuplicateGoalDialog, setIsOpeningDuplicateGoalDialog] =
    useState(false);

  const handleOpenSidebar = () => {
    link.redirect(
      toWithBackground({
        pathname: artifactUrl,
        location,
      })
    );
  };
  const handleCopyLink = () => {
    copy(`${window.location.origin}${artifactUrl}`);
    successNotificationVar({
      title: "Url copied.",
    });
  };

  /* Mutations */
  const [deleteArtifact] = useMutation(deleteArtifactMutation, {
    onCompleted: () => {
      cache.evict({ id: cache.identify(artifact) });
      cache.gc();
      onDelete({ artifact });
    },
    onError: () => {
      errorNotificationVar({
        description: `This ${artifactName} could not be deleted.`,
      });
    },
  });

  const handleSavedComment = (comment: any) => {
    setIsOpeningCommentFormDialog(false);
    onSavedComment(comment);
  };

  const handleDuplicateGoal = () => {
    if (artifact.__typename === "GoalArtifactNode") {
      setIsOpeningDuplicateGoalDialog(true);
    }
  };
  const handleRemoveArtifactFromNotes = () => {
    onDelete();
  };

  /* Handler */
  const handleDeleteArtifact = useCallback(
    async (e: ClickEvent) => {
      const confirmation = await confirm();
      if (confirmation) {
        deleteArtifact({
          variables: { artifactId: artifact.id },
          optimisticResponse: {
            deleteArtifact: {
              artifact,
            },
          },
          update(cache) {
            const artifactCacheId = cache.identify(artifact);
            cache.evict({ id: artifactCacheId });
            const baseArtifactCacheId = cache.identify({
              __typename: "BaseArtifactNode",
              id: artifact.id,
            });
            cache.evict({ id: baseArtifactCacheId });
            cache.gc();
          },
          onError: onNotificationErrorHandler(),
        });
        e.stopPropagation = true;
      }
    },
    [artifact, confirm, deleteArtifact]
  );

  const items = (
    <>
      {artifact.id && (
        <>
          <MenuItem contentEditable={false} onClick={handleOpenSidebar}>
            <ExternalLinkIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
            Open
          </MenuItem>
          <MenuItem contentEditable={false} onClick={handleCopyLink}>
            <LinkIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
            Copy Link
          </MenuItem>
          <MenuDivider />
        </>
      )}

      {artifact.id && artifact.artifactType === ArtifactType.Goal && (
        <>
          <MenuItem
            contentEditable={false}
            onClick={() => setIsOpeningCheckinDialog(true)}
          >
            <TrendingUpIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
            Update Progress
          </MenuItem>
          <MenuDivider />
        </>
      )}

      {isEditableGoalArtifact && (
        <>
          <SubMenu
            label={
              <>
                <TbListTree className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
                Update Alignment
              </>
            }
          >
            <ArtifactDropdownAlignmentSubMenu
              buttonRef={buttonRef}
              artifact={artifact}
            />
          </SubMenu>

          <MenuDivider />
        </>
      )}

      {isInWysiwyg && artifact.id && (
        <>
          <MenuItem
            contentEditable={false}
            onClick={() => setIsOpeningCommentFormDialog(true)}
          >
            <AnnotationIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
            Add a Comment
          </MenuItem>
          {onRemoveComment && (
            <MenuItem contentEditable={false} onClick={onRemoveComment}>
              <AnnotationIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
              Remove Comment
            </MenuItem>
          )}
          <MenuDivider />
        </>
      )}
      {artifact.id && onToggleDescription && (
        <>
          <MenuItem contentEditable={false} onClick={onToggleDescription}>
            {descriptionIsExpanded ? (
              <TbArrowBarUp className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
            ) : (
              <TbArrowBarDown className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
            )}{" "}
            {descriptionIsExpanded ? "Collapse" : "Expand"} Description
          </MenuItem>
          <MenuDivider />
        </>
      )}
      {artifact.__typename === "GoalArtifactNode" &&
        artifact.id &&
        artifact.canUpdate?.permission && (
          <>
            <MenuItem
              onClick={handleDuplicateGoal}
              data-testid="artifact-dropdown-delete"
            >
              <HiOutlineDocumentDuplicate className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
              Duplicate {label("goal", { titleCase: true })}
            </MenuItem>
            <MenuDivider />
          </>
        )}
      {isInWysiwyg && (
        <MenuItem
          onClick={handleRemoveArtifactFromNotes}
          data-testid="artifact-dropdown-remove-from-notes"
        >
          <MdOutlinePlaylistRemove className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
          Remove from Notes
        </MenuItem>
      )}
      {artifact.id && artifact.canDelete?.permission && (
        <>
          <ConfirmationDialog />
          <MenuItem
            onClick={handleDeleteArtifact}
            data-testid="artifact-dropdown-delete"
          >
            <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
            Delete
          </MenuItem>
        </>
      )}
    </>
  );

  return (
    <Fragment>
      {isOpeningCommentFormDialog && (
        <CommentFormDialog
          artifactId={artifact.id}
          onClose={() => setIsOpeningCommentFormDialog(false)}
          onSavedComment={handleSavedComment}
        />
      )}
      {isOpeningCheckinDialog &&
        isGoalArtifactNode(artifact) &&
        artifact.progressType && (
          <CheckinDialog
            artifact={artifact as GoalArtifactNode}
            onClose={() => setIsOpeningCheckinDialog(false)}
          />
        )}
      {isOpeningDuplicateGoalDialog &&
        isGoalArtifactNode(artifact) &&
        artifact.title && (
          <GoalDuplicationDialog
            artifactId={artifact.id}
            onClose={() => setIsOpeningDuplicateGoalDialog(false)}
          />
        )}
      <Menu
        portal={portal}
        align="end"
        transition={false}
        aria-label="Artifact dropdown menu list"
        className="text-sm not-prose z-dropdown fs-unmask"
        menuButton={
          <button
            className={classNames("text-gray-400 rounded", className)}
            data-testid="artifact-dropdown-button"
            aria-label="Artifact dropdown menu button"
            ref={setButtonRef}
          >
            <span className="sr-only">Open options</span>
            <DotsHorizontalIcon className={`h-${size} w-${size}`} />
          </button>
        }
      >
        {children}
        {items}
      </Menu>
    </Fragment>
  );
};
export default ArtifactDropdownMenu;

import { useMutation } from "@apollo/client";
import { ChangeEvent, FormEvent, useRef, useState } from "react";

import { successNotificationVar } from "@cache/cache";
import Button from "@components/button/button";
import Input from "@components/input/input";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import createTemplateFromMeetingMutation from "../graphql/create-template-from-meeting-mutation";

const CreateTemplateDialog = ({
  meeting,
  onClose,
}: {
  meeting: {
    id: number;
    title?: string | null;
  };
  onClose: () => void;
}) => {
  const focusRef = useRef(null);
  const [templateTitle, setTemplateTitle] = useState(meeting.title || "");
  const [createTemplateFromMeeting] = useMutation(
    createTemplateFromMeetingMutation,
    {
      onCompleted: () => {
        successNotificationVar({ title: "Template created!" });
        onClose();
      },
    }
  );
  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setTemplateTitle(e.target.value);
  };

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    createTemplateFromMeeting({
      variables: {
        meetingId: meeting?.id,
        title: templateTitle,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <Modal onClose={onClose} initialFocus={focusRef}>
      <div
        className="px-4 py-5 sm:p-6 flex flex-col gap-6"
        aria-label="Create template dialog"
      >
        <ModalTitle onClose={onClose}>
          Save meeting agenda in template library
        </ModalTitle>
        <form className="mt-5" onSubmit={handleSubmitForm}>
          <div className="w-full sm:max-w-xs">
            <Input
              name="title"
              id="title"
              label="Template name"
              ref={focusRef}
              placeholder="Template title..."
              aria-label="Create template name input"
              value={templateTitle}
              onChange={handleChangeTitle}
            />
          </div>
          <div className="mt-8 flex items-center gap-4">
            <Button
              theme="primary"
              type="submit"
              aria-label="Create template submit button"
              text="Create template"
            />
            <Button type="button" onClick={onClose} text="Cancel" />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateTemplateDialog;

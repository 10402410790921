import { CalendarIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
  ComplianceProgramAppliesTo,
  ComplianceProgramRecurrence,
  SaveComplianceProgramMutationVariables,
} from "types/graphql-schema";

import RecipientForm from "@apps/artifact/components/recipient-form";
import TeamPicker from "@apps/reporting/components/team-picker";
import useLabel from "@apps/use-label/use-label";
import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric from "@components/combobox/generic-combobox";
import FormFields from "@components/form/form-fields";
import Input from "@components/input/input";
import Select from "@components/select/select";
import { classNames } from "@helpers/css";
import { assertNonNull } from "@helpers/helpers";

import { DateRangeType } from "./compliance-program-form";
import ComplianceProgramFormProgramPeriod from "./compliance-program-form-program-period";
import ComplianceProgramFormStartDueDates from "./compliance-program-form-start-due-dates";

enum ProgramStartTrigger {
  ON_START_DATE,
  EMPLOYEE_START_DATE,
}

const appliesToOptions = [
  { value: ComplianceProgramAppliesTo.Organization, label: "All employees" },
  { value: ComplianceProgramAppliesTo.Departments, label: "Departments" },
  { value: ComplianceProgramAppliesTo.Managers, label: "Managers" },
  { value: ComplianceProgramAppliesTo.Users, label: "Users" },
];

const ComplianceProgramFormTopFields = ({
  proposedComplianceProgram,
  onUpdateProposedComplianceProgram,
  canUpdate,
  excludedUsers,
  onUpdateExcludedUsers,
  isSaving,
  appliesToTeams,
  onUpdateAppliesToTeams,
  appliesToManagers,
  onUpdateAppliesToManagers,
  appliesToUsers,
  onUpdateAppliesToUsers,
  selectedProgramPeriodRange,
  setSelectedProgramPeriodRange,
}: {
  proposedComplianceProgram: SaveComplianceProgramMutationVariables;
  onUpdateProposedComplianceProgram: (
    data: SaveComplianceProgramMutationVariables
  ) => void;
  canUpdate: boolean;
  excludedUsers: { id: number; name: string }[];
  onUpdateExcludedUsers: (data: { id: number; name: string }[]) => void;
  isSaving: boolean;
  appliesToTeams: { id: number; title: string }[];
  onUpdateAppliesToTeams: (data: { id: number; title: string }[]) => void;
  appliesToManagers: { id: number; name: string }[];
  onUpdateAppliesToManagers: (data: { id: number; name: string }[]) => void;
  appliesToUsers: { id: number; name: string }[];
  onUpdateAppliesToUsers: (data: { id: number; name: string }[]) => void;
  selectedProgramPeriodRange: DateRangeType;
  setSelectedProgramPeriodRange: (range: DateRangeType) => void;
}) => {
  const label = useLabel();

  return (
    <div className="py-10 border-b">
      <FormFields
        fields={compact([
          {
            title: "Title",
            render: () => (
              <Input
                aria-label="Compliance program title input"
                value={proposedComplianceProgram.title ?? ""}
                onChange={(e) =>
                  onUpdateProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    title: e.target.value,
                  })
                }
              />
            ),
          },
          waffle.flag_is_active("ongoing-programs") && {
            title: "Program start trigger",
            render: () => (
              <Select
                containerClassName="flex-1"
                disabled={!canUpdate}
                value={
                  proposedComplianceProgram.ongoing
                    ? ProgramStartTrigger.EMPLOYEE_START_DATE
                    : ProgramStartTrigger.ON_START_DATE
                }
                options={[
                  {
                    value: ProgramStartTrigger.ON_START_DATE,
                    label: "Automatically on start date",
                  },
                  {
                    value: ProgramStartTrigger.EMPLOYEE_START_DATE,
                    label: "Number of days after employee start date",
                  },
                ]}
                onChange={(opt) => {
                  const isOngoing =
                    opt.value === ProgramStartTrigger.EMPLOYEE_START_DATE;
                  onUpdateProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    ongoing: isOngoing,
                    recurrence: isOngoing
                      ? ComplianceProgramRecurrence.None
                      : proposedComplianceProgram.recurrence,
                  });
                }}
              />
            ),
          },
          {
            title: "Start and due date",
            render: () => (
              <ComplianceProgramFormStartDueDates
                proposedComplianceProgram={proposedComplianceProgram}
                onUpdateProposedComplianceProgram={
                  onUpdateProposedComplianceProgram
                }
              />
            ),
          },
          {
            title: "Program period",
            render: () => (
              <ComplianceProgramFormProgramPeriod
                proposedComplianceProgram={proposedComplianceProgram}
                onUpdateProposedComplianceProgram={
                  onUpdateProposedComplianceProgram
                }
                selectedProgramPeriodRange={selectedProgramPeriodRange}
                setSelectedProgramPeriodRange={setSelectedProgramPeriodRange}
              />
            ),
          },
          {
            title: "Applies to",
            render: () => (
              <ComboboxGeneric
                options={appliesToOptions}
                disabled={!canUpdate}
                onChangeValue={(opt) =>
                  onUpdateProposedComplianceProgram({
                    ...proposedComplianceProgram,
                    appliesTo: opt.value,
                  })
                }
                value={assertNonNull(
                  appliesToOptions.find(
                    ({ value }) => value === proposedComplianceProgram.appliesTo
                  )
                )}
              />
            ),
          },
          proposedComplianceProgram.appliesTo ===
            ComplianceProgramAppliesTo.Departments && {
            title: `${label("team", { pluralize: true, capitalize: true })}`,
            render: () => (
              <TeamPicker
                canChange={canUpdate}
                teams={appliesToTeams}
                onAddTeam={(team) => {
                  onUpdateAppliesToTeams(appliesToTeams.concat(team));
                }}
                onRemoveTeam={(team) => {
                  onUpdateAppliesToTeams(
                    appliesToTeams.filter((t) => t.id !== team.id)
                  );
                }}
              />
            ),
          },
          proposedComplianceProgram.appliesTo ===
            ComplianceProgramAppliesTo.Managers && {
            title: "Managers",
            render: () => (
              <RecipientForm
                canChange={canUpdate}
                showCurrentUser
                recipients={appliesToManagers}
                onAddRecipient={(user) =>
                  onUpdateAppliesToManagers([...appliesToManagers, user])
                }
                onRemoveRecipient={(user) =>
                  onUpdateAppliesToManagers(
                    appliesToManagers.filter((u) => u.id !== user.id)
                  )
                }
              />
            ),
          },
          proposedComplianceProgram.appliesTo ===
            ComplianceProgramAppliesTo.Users && {
            title: "Employees",
            render: () => (
              <RecipientForm
                canChange={canUpdate}
                showCurrentUser
                recipients={appliesToUsers}
                onAddRecipient={(user) =>
                  onUpdateAppliesToUsers([...appliesToUsers, user])
                }
                onRemoveRecipient={(user) =>
                  onUpdateAppliesToUsers(
                    appliesToUsers.filter((u) => u.id !== user.id)
                  )
                }
              />
            ),
          },
          {
            title: "Excluded employees",
            render: () => (
              <RecipientForm
                canChange={canUpdate}
                showCurrentUser
                recipients={excludedUsers}
                onAddRecipient={(user) =>
                  onUpdateExcludedUsers([...excludedUsers, user])
                }
                onRemoveRecipient={(user) =>
                  onUpdateExcludedUsers(
                    excludedUsers.filter((u) => u.id !== user.id)
                  )
                }
              />
            ),
          },
          {
            title: "Exclude hired after",
            render: () => (
              <div className="flex items-center gap-4">
                <DatePicker
                  showIcon
                  icon={<CalendarIcon className="pr-4 text-gray-600" />}
                  wrapperClassName="w-full"
                  disabled={!canUpdate}
                  selected={
                    proposedComplianceProgram.excludedHireDateAfter
                      ? moment(
                          proposedComplianceProgram.excludedHireDateAfter
                        ).toDate()
                      : null
                  }
                  onChange={(date) =>
                    onUpdateProposedComplianceProgram({
                      ...proposedComplianceProgram,
                      excludedHireDateAfter: date
                        ? moment(date).format("YYYY-MM-DD")
                        : null,
                      removeExcludedHireDateAfter: undefined,
                    })
                  }
                  dateFormat="MMM d, yyyy"
                  ariaLabelledBy="Compliance program exclude hire date after"
                  className={classNames(
                    "px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500",
                    !canUpdate && "bg-gray-100"
                  )}
                />
                {proposedComplianceProgram.excludedHireDateAfter && (
                  <Button
                    className="text-sm"
                    onClick={() =>
                      onUpdateProposedComplianceProgram({
                        ...proposedComplianceProgram,
                        excludedHireDateAfter: undefined,
                        removeExcludedHireDateAfter: true,
                      })
                    }
                    theme={buttonTheme.text}
                    disabled={isSaving}
                    text="Clear"
                  />
                )}
              </div>
            ),
          },
        ])}
      />
    </div>
  );
};

export default ComplianceProgramFormTopFields;

import { PencilIcon } from "@heroicons/react/outline";
import { sortBy } from "lodash";
import { MdContentCopy } from "react-icons/md";
import {
  AssessmentGroupFragmentFragment,
  AssessmentType,
} from "types/graphql-schema";

import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import { assertNonNull } from "@helpers/helpers";

const QuestionSetSelector = ({
  assessmentType,
  availableAssessmentGroupSelectOptions,
  canUpdate,
  onUpdateAssessmentTemplate,
  onDuplicateAssessmentGroup,
  onOpenAssessmentGroupSidebar,
  selectedAssessmentGroup,
}: {
  assessmentType: AssessmentType;
  availableAssessmentGroupSelectOptions: ComboboxGenericOption<number>[];
  canUpdate: boolean;
  onUpdateAssessmentTemplate: (
    assessmentType: AssessmentType
  ) => (opt: ComboboxGenericOption<number> | null) => void;
  onDuplicateAssessmentGroup: (
    assessmentGroup: AssessmentGroupFragmentFragment
  ) => void;
  onOpenAssessmentGroupSidebar: () => void;
  selectedAssessmentGroup: AssessmentGroupFragmentFragment | null;
}) => {
  return (
    <>
      <ComboboxGeneric
        placeholder="Select a question set"
        className="flex-1"
        aria-label={`Compliance program question set selector: ${assessmentType}`}
        options={sortBy(availableAssessmentGroupSelectOptions, (opt) =>
          opt.label.toLocaleLowerCase()
        )}
        disabled={!canUpdate}
        onChangeValue={onUpdateAssessmentTemplate(assessmentType)}
        createLabel="Create New Question Set"
        onClickCreateNew={() => {
          onUpdateAssessmentTemplate(assessmentType)(null);
          onOpenAssessmentGroupSidebar();
        }}
        value={
          selectedAssessmentGroup
            ? {
                value: selectedAssessmentGroup.id,
                label: selectedAssessmentGroup.title,
              }
            : null
        }
      />
      <Button
        disabled={!selectedAssessmentGroup}
        theme={buttonTheme.iconGray}
        icon={PencilIcon}
        onClick={onOpenAssessmentGroupSidebar}
      />
      <Button
        disabled={!selectedAssessmentGroup}
        icon={MdContentCopy}
        theme={buttonTheme.iconGray}
        onClick={() => {
          onDuplicateAssessmentGroup(assertNonNull(selectedAssessmentGroup));
          onOpenAssessmentGroupSidebar();
        }}
      />
    </>
  );
};

export default QuestionSetSelector;

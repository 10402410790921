import { compact } from "lodash";
import {
  ArtifactType,
  AssessmentGroupAnonymity,
  AssessmentGroupDelivery,
  AssessmentGroupProviders,
  AssessmentQuestionType,
  AssessmentType,
  ComplianceProgramParticipantStatus,
  GoalProgressType,
  GoalState,
  OnlineConference,
  OnlineVideoConference,
  SyncCredentialsTypeEnum,
} from "types/graphql-schema";

import { UseLabelType, getLabel } from "@apps/use-label/use-label";
import { SelectOption } from "@components/select/select";

export const meetingFrequency = {
  none: "none",
  daily: "daily",
  weekly: "weekly",
  monthly: "monthly",
  yearly: "yearly",
  weekdays: "weekdays",
};

export const getPrivateGoalTitle = () =>
  `(Private ${getLabel()("goal", {
    titleCase: true,
  })})`;

export const ignoredParticipantEmails = ["fellow@gamesight.io"];

export const ProgramStatusLabel = {
  [`${ComplianceProgramParticipantStatus.NotStarted}`]: "Not started",
  [`${ComplianceProgramParticipantStatus.InProgress}`]: "In progress",
  [`${ComplianceProgramParticipantStatus.Complete}`]: "Complete",
  [`${ComplianceProgramParticipantStatus.NotApplicable}`]: "Not applicable",
};

export const goalStateLabel = {
  [`${GoalState.Draft}`]: "Draft",
  [`${GoalState.Open}`]: "In progress",
  [`${GoalState.Closed}`]: "Closed",
};

const assessmentTypeLabels: { [key in AssessmentType]: string } = {
  [AssessmentType.Performance]: "Manager",
  [AssessmentType.Manager]: "Upward",
  [AssessmentType.Peer]: "Peer",
  [AssessmentType.EngagementSurvey]: "Survey",
};
const selfAssessmentTypeLabels: { [key in AssessmentType]: string } = {
  [AssessmentType.Performance]: "Self",
  [AssessmentType.Manager]: "Upward Self",
  [AssessmentType.Peer]: "Peer",
  [AssessmentType.EngagementSurvey]: "Survey",
};
export const getAssessmentTypeLabel = (
  type: AssessmentType,
  isSelfAssessment = false
) => {
  if (isSelfAssessment) {
    return selfAssessmentTypeLabels[type];
  }
  return assessmentTypeLabels[type];
};

export const assessmentGroupDeliveryOptions = (
  label: UseLabelType,
  assessmentType: AssessmentType
): SelectOption<AssessmentGroupDelivery>[] => {
  return compact([
    {
      label: "Delivered automatically upon submission",
      value: AssessmentGroupDelivery.Full,
      description: `${label("review", {
        capitalize: true,
      })} will be delivered automatically`,
    },
    {
      label: "Manager approval",
      value: AssessmentGroupDelivery.Partial,
      description: `Manager approval required before subject receives ${label(
        "review"
      )} responses`,
    },
    assessmentType === AssessmentType.Performance && {
      label: "After admin approval",
      value: AssessmentGroupDelivery.AdminApproval,
      description: "Delivered after admin approval and manager delivers",
    },
    {
      label: "No delivery",
      value: AssessmentGroupDelivery.None,
      description: `No ${label("review")} responses will be delivered`,
    },
  ]);
};
export const assessmentGroupProviderOptions = (
  assessmentType?: AssessmentType | null
): SelectOption<AssessmentGroupProviders>[] => {
  if (
    assessmentType === AssessmentType.Performance ||
    assessmentType === AssessmentType.Manager
  ) {
    return [];
  }
  return [
    {
      label:
        assessmentType === AssessmentType.Peer
          ? "All peers that report to the same manager"
          : "Default",
      value: AssessmentGroupProviders.Default,
    },
    {
      label: "Subject's manager selects",
      value: AssessmentGroupProviders.ManagerSelect,
      description: "Subject's manager selects assessment providers",
    },
    {
      label: "Subject selects",
      value: AssessmentGroupProviders.SubjectSelect,
      description: "Subject selects providers and manager approves",
    },
  ];
};
export const assessmentGroupAnonymityOptions = (
  assessmentType?: AssessmentType | null
): SelectOption<AssessmentGroupAnonymity>[] => {
  if (assessmentType === AssessmentType.Performance) {
    return [];
  }
  return compact([
    {
      label: "Not anonymous",
      value: AssessmentGroupAnonymity.NotAnonymous,
      description:
        "Assessment feedback provider identity is visible to everyone",
    },
    assessmentType !== AssessmentType.EngagementSurvey && {
      label: "Semi-anonymous",
      value: AssessmentGroupAnonymity.SemiAnonymous,
      description:
        "Assessment providers identity is anonymous to the subject but viewable to management tree and admins",
    },
    {
      label: "Anonymous",
      value: AssessmentGroupAnonymity.Anonymous,
      description: "Assessment provider's identity is anonymous to all parties",
    },
  ]);
};

export const assessmentQuestionTypeOptions: SelectOption<AssessmentQuestionType>[] =
  [
    {
      label: "Range",
      value: AssessmentQuestionType.Range,
    },
    {
      label: "Text",
      value: AssessmentQuestionType.Text,
    },
    {
      label: "Multiple choice",
      value: AssessmentQuestionType.Multichoice,
    },
  ];

export const getKeyResultProgressTypeOptions = () => {
  return [
    {
      value: GoalProgressType.Percentage,
      label: "Percentage",
    },
    {
      value: GoalProgressType.Numeric,
      label: "Numeric",
    },
    {
      value: GoalProgressType.Currency,
      label: "Dollar",
    },
    {
      value: GoalProgressType.Boolean,
      label: "Binary",
    },
  ];
};

export const getProgressTypeOptions = () => {
  const label = getLabel();
  return [
    ...getKeyResultProgressTypeOptions(),
    {
      value: GoalProgressType.AlignedAverage,
      label: `${label("key result", {
        pluralize: true,
        titleCase: true,
      })} & Aligned ${label("goal", {
        pluralize: true,
        titleCase: true,
      })} Progress`,
    },
  ];
};

export const progressTypeBooleanStartValue = 0;
export const progressTypeBooleanTargetValue = 100;

export const recurrenceValue = {
  none: meetingFrequency.none,
  daily: meetingFrequency.daily,
  weekdays: "weekdays",
  weekly: meetingFrequency.weekly,
  monthlyMonthDay: "monthly-monthday",
  monthlyWeekDay: "monthly-weekday",
  yearly: meetingFrequency.yearly,
};

export const topicState = {
  open: "open",
  closed: "closed_to_discussion",
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const nthWeekDaysInMonth = [
  { label: "First", value: 1 },
  { label: "Second", value: 2 },
  { label: "Third", value: 3 },
  { label: "Fourth", value: 4 },
  { label: "Last", value: -1 },
];

export const graphqlNone = -1;

const oneSecond = 1000;
const minuteDelay = 60 * oneSecond;
export const delay = {
  searchDebounce: 800,
  sidebarRefresh: 5 * minuteDelay,
  userRefresh: 15 * minuteDelay,
  notificationHidesAfter: 15 * oneSecond,
  autosaveDebounce: 700,
  waitTillFocus: 100,
};

export const pricing = {
  free: "free",
  pro: "pro",
};

export const chromeExtensionUrl =
  "https://chrome.google.com/webstore/detail/topicflow-chrome-extensio/dpjdbcoajclifiaeeajajigmdppjnefn";

export const meetingStatus = {
  confirmed: "confirmed",
  cancelled: "cancelled",
};

export const dateFormat = {
  long: "dddd, MMMM D, YYYY",
  longWithTime: "dddd, MMMM D, YYYY [at] H:mma",
  longAbbreviated: "ddd, MMM D, YYYY",
  casual: "dddd, MMM Do",
  shortMonthDay: "MMM D, YYYY",
};

// Make sure value is using underscore so it is matching django model
export const artifactType = {
  actionItem: "action_item",
  decision: "decision",
  goal: "goal",
  rating: "rating",
  kpi: "kpi",
  document: "document",
  recognition: "recognition",
  feedback: "feedback",
};

export const apolloPersistCacheKey = "apollo-cache-persist";

export const artifactTypeUrl = {
  [`${ArtifactType.Recognition}`]: "recognition",
  [`${ArtifactType.ActionItem}`]: "action-item",
  [`${ArtifactType.Decision}`]: "decision",
  [`${ArtifactType.Goal}`]: "goal",
  [`${ArtifactType.Rating}`]: "rating",
  [`${ArtifactType.Document}`]: "document",
  [`${ArtifactType.Feedback}`]: "feedback",
  [`${ArtifactType.AsanaTask}`]: "asana",
  [`${ArtifactType.Kpi}`]: "kpi",
  [`${ArtifactType.GithubIssues}`]: "github-issues",
};

export const typename = {
  BaseArtifactNode: "BaseArtifactNode",
  RecognitionArtifactNode: "RecognitionArtifactNode",
  ActionItemArtifactNode: "ActionItemArtifactNode",
  RatingArtifactNode: "RatingArtifactNode",
  DecisionArtifactNode: "DecisionArtifactNode",
  GoalArtifactNode: "GoalArtifactNode",
  KPIArtifactNode: "KPIArtifactNode",
  DocumentArtifactNode: "DocumentArtifactNode",
  MeetingGroupNode: "MeetingGroupNode",
  MeetingNode: "MeetingNode",
  CommentNode: "CommentNode",
  TopicNode: "TopicNode",
  OrganizationNode: "OrganizationNode",
};

export const tempCacheIdPrefix = "temp-cache-";

export const regexp = {
  iframeHTML: /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g,
  loomUrl: /https:\/\/www\.loom\.com\/(share|embed)?\/([0-9a-z-]+)/,
  miroUrl: /https:\/\/miro\.com\/app\/board\/(\S+)\//,
  asanaTaskUrl: /https:\/\/app\.asana\.com\/(.*)\/([0-9]{16})/,
  githubIssueUrl: /https:\/\/github\.com\/(.*)\/(.*)\/(pull|issues)\/([0-9]*)/,
  jiraIssueUrl: /https:\/\/(.*)\.atlassian\.net\/(.*)\/([A-Z]+)-([0-9]*)/,
  clickupTaskUrl: /https:\/\/app.clickup.com\/t\/(.*)/,
  linearIssueUrl: /https:\/\/linear.app\/.*?\/issue\/(.*?)\//,
  linearProjectUrl: /https:\/\/linear.app\/.*?\/project\/(.*?)\//,
  salesforceOpportunityUrl: /force.com\/lightning\/r\/Opportunity\/(.*?)\/view/,
  hubspotDealUrl:
    /https:\/\/app.hubspot.com\/contacts\/(.*?)\/(deal|record\/0-3)\/(.*?)(\/|$)/,
  vimeoUrl:
    /https?:\/\/(www\.)?vimeo.com\/(channels\/[a-zA-Z0-9]*\/)?([0-9]*)(?:\/[a-zA-Z0-9]+)?(#t=(\d+)s)?/,
  figmaUrl:
    /https:\/\/([w.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/,
};

export const tiptapExtensionPriority = {
  comment: 1000,
  artifact: 1100,
  iframe: 1200,
  explorerLink: 1300,
  link: 1400,
};

export const lastSeenStr = {
  NEVER: "Never seen",
  LTE_24_HOURS: "Seen less than 24h ago",
  LTE_7_DAYS: "Seen less than a week ago",
  LTE_1_MONTH: "Seen less than a month ago",
  LTE_2_MONTH: "Seen less than two months ago",
  LTE_3_MONTH: "Seen less than three months ago",
  LTE_1_YEAR: "Seen less than a year ago",
  NOT_RECENTLY: "Not seen recently",
};

export const role = {
  administrator: "administrator",
  member: "member",
};

export const orgMembership = {
  anyoneWithValidDomain: "anyone_with_valid_domain",
  manualInvitesOnly: "manual_invites_only",
};

export const invitationStatus = {
  pending: "pending",
  accepted: "accepted",
};

export const provider = {
  googleCalendar: "google_calendar",
  outlookCalendar: "outlook_calendar",
};

export const providerUrl = {
  [SyncCredentialsTypeEnum.GoogleCalendar]: "google",
  [SyncCredentialsTypeEnum.OutlookCalendar]: "microsoft",
  [SyncCredentialsTypeEnum.Github]: "github",
  [SyncCredentialsTypeEnum.Zoom]: "zoom",
};

const onlineConferenceType = {
  none: "none",
  native: "native",
  zoom: "zoom",
};

export const onlineVideoConferenceType = {
  none: "none",
  aroundco: "aroundco",
  google: "google",
  teams: "teams",
  whereby: "whereby",
  zoom: "zoom",
};

export const validOnlineConferenceTypesByProvider = {
  google_calendar: [onlineConferenceType.native, onlineConferenceType.zoom],
  outlook_calendar: [onlineConferenceType.native, onlineConferenceType.zoom],
};

export const onlineConferenceName = {
  [OnlineVideoConference.Aroundco]: "aroundco",
  [OnlineVideoConference.Google]: "Google Meet",
  [OnlineVideoConference.Teams]: "Microsoft Teams",
  [OnlineVideoConference.Whereby]: "Whereby",
  [OnlineConference.Native]: "Native",
  [OnlineConference.None]: "None",
  [SyncCredentialsTypeEnum.GoogleCalendar]: "Google Meet",
  [SyncCredentialsTypeEnum.OutlookCalendar]: "Microsoft Teams",
  [SyncCredentialsTypeEnum.Zoom]: "Zoom",
};

export const attendeeRole = {
  required: "required",
  optional: "optional",
};

export const attendeeStatus = {
  not_responded: "not_responded",
  tentative: "tentative",
  declined: "declined",
  accepted: "accepted",
};

export const meetingEditType = {
  singleEvent: "single_event",
  allEvents: "all_events",
  thisEventAndFuture: "this_event_and_future",
};

export const maxKeyResults = 20;

export const tooltipZIndex = 10800;

export const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

import { ApolloError, FetchResult, useMutation } from "@apollo/client";
import EmojiPicker from "emoji-picker-react";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import {
  DeleteCoreValueMutationMutation,
  DeleteCoreValueMutationMutationVariables,
  OrgSettingsCreateOrUpdateCoreValueMutation,
  OrgSettingsCreateOrUpdateCoreValueMutationVariables,
} from "types/graphql-schema";

import { errorNotificationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import GraphqlError from "@components/error/graphql-error";
import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import AppPopover from "@components/popover/app-popover";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  asyncUploadImage,
  asyncUploadImageErrorMessage,
} from "@components/wysiwyg/extensions/image";
import { getUploadedImageToResizedSquareImageUrl } from "@helpers/file";

import deleteCoreValueMutation from "../graphql/delete-core-value-mutation";
import getOrganizationCoreValuesQuery from "../graphql/get-organization-core-values-query";

type ImageFileAndUrl = { file: File; url: string };

export type CoreValue = {
  id?: number;
  title: string;
  description: string;
  emoji?: string | null;
  image?: string | ImageFileAndUrl | null;
};

function determineIfIsStringOrImageBlobUrl(
  toBeDetermined: string | ImageFileAndUrl
): toBeDetermined is ImageFileAndUrl {
  if ((toBeDetermined as ImageFileAndUrl).file) {
    return true;
  }
  return false;
}

export const emptyCoreValue: CoreValue = {
  id: undefined,
  title: "",
  description: "",
  emoji: undefined,
  image: undefined,
};

type Props = {
  organizationId: number;
  coreValue?: CoreValue;
  isUpdatingCoreValue: boolean;
  updatingCoreValueError?: ApolloError;
  onSave: (
    variables: OrgSettingsCreateOrUpdateCoreValueMutationVariables
  ) => Promise<FetchResult<OrgSettingsCreateOrUpdateCoreValueMutation>>;
  onClose: () => void;
};

const CoreValueEditDialog: React.FC<Props> = ({
  organizationId,
  coreValue,
  isUpdatingCoreValue,
  updatingCoreValueError,
  onSave,
  onClose,
}) => {
  const focusRef = useRef<HTMLElement>(null);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const initialCoreValue = coreValue || emptyCoreValue;
  const [proposedCoreValue, setProposedCoreValue] =
    useState<CoreValue>(initialCoreValue);
  const [loading, setLoading] = useState(false);
  const [deleteCoreValue] = useMutation<
    DeleteCoreValueMutationMutation,
    DeleteCoreValueMutationMutationVariables
  >(deleteCoreValueMutation);

  const handleUploadAndSaveImage = ({
    isNew,
    coreValueId,
    imageFile,
  }: {
    isNew: boolean;
    coreValueId: number;
    imageFile: File;
  }): Promise<{ fileViewingUrl: string; coreValueId: number }> => {
    return new Promise((resolve, reject) => {
      return asyncUploadImage({
        recognitionCoreValueId: coreValueId,
      })(imageFile)
        .then((fileViewingUrl: string | undefined) => {
          if (fileViewingUrl) {
            resolve({ fileViewingUrl, coreValueId });
          } else {
            reject("An error occurred while uploading the image");
          }
        })
        .catch((errorMessage) => {
          // we delete the core value if it failed uploading the image.
          if (isNew && errorMessage === asyncUploadImageErrorMessage) {
            deleteCoreValue({
              variables: { coreValueId },
              onError: onNotificationErrorHandler(),
              refetchQueries: [getOrganizationCoreValuesQuery],
            }).finally(() => {
              reject("The core value could not be created.");
            });
          } else {
            reject(errorMessage);
          }
        });
    });
  };

  const handleSave = useCallback(() => {
    setLoading(true);
    const { image } = proposedCoreValue;
    const promise =
      image && determineIfIsStringOrImageBlobUrl(image)
        ? handleSaveCoreValueWithImageUpload()
            .then((coreValueId) => {
              return handleUploadAndSaveImage({
                isNew: !proposedCoreValue.id,
                imageFile: image.file,
                coreValueId: coreValueId,
              });
            })
            .then(({ fileViewingUrl, coreValueId }) => {
              return onSave({
                id: coreValueId,
                image: fileViewingUrl,
                organizationId,
              });
            })
        : handleSaveCoreValueWithoutImageUpload();

    promise
      .then(() => {
        successNotificationVar({ title: "Core value saved" });
        onClose();
      })
      .catch((errorMessage) => {
        if (typeof errorMessage === "string") {
          errorNotificationVar({ title: errorMessage });
        } else {
          errorNotificationVar({ title: String(errorMessage) });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [onClose, onSave, organizationId, proposedCoreValue]);

  const handleSaveCoreValueWithImageUpload = () => {
    const { image, ...restProposedCoreValue } = proposedCoreValue;
    return onSave({
      ...restProposedCoreValue,
      organizationId,
    }).then((response) => {
      if (response.data?.createOrUpdateCoreValue?.coreValue?.id) {
        return Promise.resolve(
          response.data.createOrUpdateCoreValue.coreValue.id
        );
      }
      return Promise.reject("The core value was not saved.");
    });
  };

  const handleSaveCoreValueWithoutImageUpload = () => {
    const { image, ...restProposedCoreValue } = proposedCoreValue;
    return onSave({
      ...restProposedCoreValue,
      image:
        image && determineIfIsStringOrImageBlobUrl(image) ? undefined : image,
      organizationId,
    });
  };

  const handleSelectImage = (e: ChangeEvent<HTMLInputElement>) => {
    const maxSize = 5 * 1024 * 1024;
    if (e.target?.files?.[0]) {
      const file = e.target?.files?.[0];
      if (file.size > maxSize) {
        return errorNotificationVar({
          title: "Image must have a size lower than 5MB",
        });
      }

      if (file.type.startsWith("image/")) {
        getUploadedImageToResizedSquareImageUrl(file, 192)
          .then((imageBlobAndUrl) => {
            setProposedCoreValue({
              ...proposedCoreValue,
              image: imageBlobAndUrl,
              emoji: "",
            });
          })
          .catch((errorMessage) => {
            return errorNotificationVar({ title: errorMessage });
          });
      } else {
        return errorNotificationVar({
          title: "Upload an image (png, jpg)",
        });
      }
    }
  };

  const handleClickUploadFileButton = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleClickClear = () => {
    setProposedCoreValue({
      ...proposedCoreValue,
      emoji: "",
      image: "",
    });
  };

  const handleSelectEmoji =
    (close: () => void) => (emojiData: { emoji: string }) => {
      if (inputFileRef.current) inputFileRef.current.value = "";
      setProposedCoreValue({
        ...proposedCoreValue,
        emoji: emojiData.emoji,
        image: "",
      });
      close();
    };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      open
      onClose={onClose}
      initialFocus={focusRef}
      aria-label="Core value dialog"
      dialogClassName="md:max-w-4xl overflow-visible"
    >
      <div className="p-6 bg-white rounded-md">
        <div className="mb-4">
          <ModalTitle onClose={handleClose}>
            {coreValue?.id
              ? `Edit Core Value: ${coreValue.title}`
              : `New Core Value`}
          </ModalTitle>
        </div>
        <div className="mt-4 flex gap-6">
          <div className="flex flex-col items-center">
            <div className="text-8xl mb-4">
              {proposedCoreValue.image &&
              typeof proposedCoreValue.image === "string" ? (
                <img
                  alt={proposedCoreValue.title}
                  src={proposedCoreValue.image}
                  className="w-[96px] h-[96px]"
                />
              ) : proposedCoreValue.image &&
                determineIfIsStringOrImageBlobUrl(proposedCoreValue.image) ? (
                <img
                  alt={proposedCoreValue.title}
                  src={proposedCoreValue.image.url}
                  className="w-[96px] h-[96px]"
                />
              ) : proposedCoreValue.emoji ? (
                proposedCoreValue.emoji
              ) : (
                <MdOutlineCancel className="fill-gray-200" />
              )}
            </div>
            <div className="flex items-center gap-2">
              <AppPopover
                content={({ close }) => (
                  <EmojiPicker
                    skinTonesDisabled
                    onEmojiClick={handleSelectEmoji(close)}
                  />
                )}
              >
                <AppPopover.Button as={Button}>Choose emoji</AppPopover.Button>
              </AppPopover>
              <Button
                type="button"
                text="Upload image"
                onClick={handleClickUploadFileButton}
              />
              <Button type="button" text="Clear" onClick={handleClickClear} />

              <input
                ref={inputFileRef}
                type="file"
                onChange={handleSelectImage}
                className="hidden"
                accept="image/*,image/svg+xml"
              />
            </div>
            <div className="flex justify-center text-sm mt-4"></div>
          </div>
          <div className="flex flex-grow flex-col gap-6">
            <div>
              <Input
                label="Title"
                aria-label="Core value title input"
                value={proposedCoreValue.title ?? ""}
                placeholder="Name"
                onChange={(e) =>
                  setProposedCoreValue({
                    ...proposedCoreValue,
                    title: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <div className="mt-1">
                  <textarea
                    placeholder="Description"
                    value={proposedCoreValue.description}
                    onChange={(evt) =>
                      setProposedCoreValue({
                        ...proposedCoreValue,
                        description: evt.target.value,
                      })
                    }
                    className="resize-none h-52 p-4 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {updatingCoreValueError && (
          <div>
            <GraphqlError error={updatingCoreValueError} />
          </div>
        )}
        <div className="flex justify-between items-center gap-2 mt-4">
          <div className="flex gap-2 items-center">
            <Button
              type="submit"
              theme={buttonTheme.primary}
              onClick={handleSave}
              disabled={
                loading ||
                isUpdatingCoreValue ||
                !proposedCoreValue?.title ||
                proposedCoreValue.title.trim().length === 0 ||
                !proposedCoreValue?.description ||
                proposedCoreValue.description.trim().length === 0
              }
              text="Save"
            />
            <Button
              onClick={handleClose}
              disabled={isUpdatingCoreValue}
              text="Cancel"
            />
            {loading && <Loading mini size={5} />}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CoreValueEditDialog;

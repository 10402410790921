import { ArrowSmRightIcon, CalendarIcon } from "@heroicons/react/outline";
import moment from "moment";
import { useCallback, useMemo } from "react";
import DatePicker from "react-datepicker";
import { SaveComplianceProgramMutationVariables } from "types/graphql-schema";
import { PastOnlyDateRangeEnum } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Input from "@components/input/input";
import Select, { SelectOption } from "@components/select/select";
import { dateRangeToDateArray } from "@helpers/helpers";

import { DateRangeType, ExtraDateRangeEnum } from "./compliance-program-form";

const ComplianceProgramFormProgramPeriod = ({
  proposedComplianceProgram,
  onUpdateProposedComplianceProgram,
  selectedProgramPeriodRange,
  setSelectedProgramPeriodRange,
}: {
  proposedComplianceProgram: SaveComplianceProgramMutationVariables;
  onUpdateProposedComplianceProgram: (
    data: SaveComplianceProgramMutationVariables
  ) => void;
  selectedProgramPeriodRange: DateRangeType;
  setSelectedProgramPeriodRange: (range: DateRangeType) => void;
}) => {
  const label = useLabel();
  const organization = currentOrganizationVar();

  const programPeriodOptions = useMemo(() => {
    const dateRangeOptions = Object.values(PastOnlyDateRangeEnum).map(
      (dateRange) => ({
        value: dateRange,
        label: label(dateRange, { capitalize: true }),
        description: dateRangeToDateArray({
          range: dateRange,
          quarterStartMonth: organization.quarterStartMonth,
        })
          .map((date) => moment(date).format("ll"))
          .join(" - "),
      })
    );
    return [
      {
        value: ExtraDateRangeEnum.none,
        label: "Not set",
      },
      ...dateRangeOptions,
      {
        value: ExtraDateRangeEnum.custom,
        label: "Custom date range",
      },
    ];
  }, [label, organization]);

  const handleChangeProgramPeriod = useCallback(
    (option: SelectOption<DateRangeType>) => {
      if (option.value === ExtraDateRangeEnum.none) {
        onUpdateProposedComplianceProgram({
          ...proposedComplianceProgram,
          periodStartDate: null,
          periodEndDate: null,
          removeProgramPeriod: true,
        });
      } else if (option.value !== ExtraDateRangeEnum.custom) {
        const dates = dateRangeToDateArray({
          range: option.value,
          quarterStartMonth: organization.quarterStartMonth,
        });
        onUpdateProposedComplianceProgram({
          ...proposedComplianceProgram,
          periodStartDate: dates[0],
          periodEndDate: dates[1],
          removeProgramPeriod: undefined,
        });
      }
      setSelectedProgramPeriodRange(option.value);
    },
    [
      onUpdateProposedComplianceProgram,
      organization,
      proposedComplianceProgram,
      setSelectedProgramPeriodRange,
    ]
  );

  return proposedComplianceProgram.ongoing ? (
    <div className="flex gap-2 items-center justify-between">
      <div className="text-sm">Starts</div>
      <Input
        type="number"
        className="w-24"
        min={0}
        step={1}
        aria-label="Compliance program due date offset"
        value={proposedComplianceProgram.startDatePeriodStartOffset ?? ""}
        onChange={(e) =>
          onUpdateProposedComplianceProgram({
            ...proposedComplianceProgram,
            startDatePeriodStartOffset: Math.trunc(Number(e.target.value)),
          })
        }
      />
      <div className="text-sm">days after start date</div>
      <ArrowSmRightIcon className="text-gray-500 w-4 h-4 shrink-0" />
      <div className="text-sm">Ends</div>
      <Input
        type="number"
        className="w-24"
        min={0}
        step={1}
        aria-label="Compliance program due date offset"
        value={proposedComplianceProgram.startDatePeriodEndOffset ?? ""}
        onChange={(e) =>
          onUpdateProposedComplianceProgram({
            ...proposedComplianceProgram,
            startDatePeriodEndOffset: Math.trunc(Number(e.target.value)),
          })
        }
      />
      <div className="text-sm">days after start date</div>
    </div>
  ) : (
    <div className="w-full">
      <Select<DateRangeType>
        onChange={handleChangeProgramPeriod}
        options={programPeriodOptions}
        value={selectedProgramPeriodRange}
      />

      {selectedProgramPeriodRange === ExtraDateRangeEnum.custom && (
        <div className="mt-2 flex gap-2 items-center">
          <DatePicker
            showIcon
            icon={<CalendarIcon className="pr-4 text-gray-600" />}
            wrapperClassName="flex-1"
            placeholderText="Start date"
            selected={
              proposedComplianceProgram.periodStartDate
                ? moment(proposedComplianceProgram.periodStartDate).toDate()
                : null
            }
            onChange={(date) =>
              onUpdateProposedComplianceProgram({
                ...proposedComplianceProgram,
                periodStartDate: date
                  ? moment(date).format("YYYY-MM-DD")
                  : null,
                removeProgramPeriod: undefined,
              })
            }
            dateFormat="MMM d, yyyy"
            ariaLabelledBy="Compliance program period start date"
            className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
          />
          <ArrowSmRightIcon className="text-gray-500 w-4 h-4" />
          <DatePicker
            showIcon
            icon={<CalendarIcon className="pr-4 text-gray-600" />}
            wrapperClassName="flex-1"
            placeholderText="End date"
            selected={
              proposedComplianceProgram.periodEndDate
                ? moment(proposedComplianceProgram.periodEndDate).toDate()
                : null
            }
            onChange={(date) =>
              onUpdateProposedComplianceProgram({
                ...proposedComplianceProgram,
                periodEndDate: date ? moment(date).format("YYYY-MM-DD") : null,
                removeProgramPeriod: undefined,
              })
            }
            dateFormat="MMM d, yyyy"
            ariaLabelledBy="Compliance program period end date"
            className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      )}
    </div>
  );
};

export default ComplianceProgramFormProgramPeriod;

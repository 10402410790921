import { useCallback, useState } from "react";
import {
  AssessmentGroupFragmentFragment,
  AssessmentTemplateComplianceRequirement,
  AssessmentType,
  SaveComplianceProgramMutationVariables,
} from "types/graphql-schema";

import Button, { buttonTheme } from "@components/button/button";
import { ComboboxGenericOption } from "@components/combobox/generic-combobox";

import {
  AssessmentTemplateExtraSettings,
  ComplianceProgramType,
} from "./compliance-program-form";
import ComplianceProgramFormAddStepModal from "./compliance-program-form-add-step-modal";
import EngagementSurveyStep from "./steps/engagement-survey-step";
import OneOnOneStep from "./steps/one-on-one-step";
import PeerAssessmentStep from "./steps/peer-assessment-step";
import SelfManagerAssessmentStep from "./steps/self-manager-assessment-step";
import UpwardAssessmentStep from "./steps/upward-assessment-step";

export enum ComplianceProgramStep {
  SELF_MANAGER_REVIEW = "self_manager_review",
  UPWARD_REVIEW = "upward_review",
  PEER_REVIEW = "peer_review",
  ONE_ON_ONE = "one_on_one",
  ENGAGEMENT_SURVEY = "engagement_survey",
}

const ComplianceProgramFormSteps = ({
  complianceProgramType,
  proposedComplianceProgram,
  onUpdateProposedComplianceProgram,
  canUpdate,
  complianceProgramSteps,
  onUpdateComplianceProgramSteps,
  onUpdateAssessmentTemplateSettings,
  onUpdateAssessmentTemplate,
  availableAssessmentGroups,
  availableTopicTemplates,
  onAssessmentGroupCreated,
}: {
  complianceProgramType: ComplianceProgramType;
  proposedComplianceProgram: SaveComplianceProgramMutationVariables;
  onUpdateProposedComplianceProgram: (
    data: SaveComplianceProgramMutationVariables
  ) => void;
  canUpdate: boolean;
  complianceProgramSteps: ComplianceProgramStep[];
  onUpdateComplianceProgramSteps: (steps: ComplianceProgramStep[]) => void;
  onUpdateAssessmentTemplateSettings: (
    assessmentType: AssessmentType
  ) => (settings: AssessmentTemplateExtraSettings) => void;
  onUpdateAssessmentTemplate: (
    assessmentType: AssessmentType
  ) => (opt: ComboboxGenericOption<number> | null) => void;
  availableAssessmentGroups: AssessmentGroupFragmentFragment[];
  availableTopicTemplates: ComboboxGenericOption<number>[];
  onAssessmentGroupCreated: (
    newAssessmentGroup: AssessmentGroupFragmentFragment
  ) => void;
}) => {
  const [isAddProgramStepModalOpen, setIsAddProgramStepModalOpen] =
    useState(false);

  const handleRemoveAssessmentTemplate = useCallback(
    (assessmentType: AssessmentType) => {
      const existingTemplates =
        proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[];
      onUpdateProposedComplianceProgram({
        ...proposedComplianceProgram,
        assessmentTemplates: existingTemplates.filter(
          (g) => g.assessmentType !== assessmentType
        ),
      });
    },
    [onUpdateProposedComplianceProgram, proposedComplianceProgram]
  );

  const allStepsAdded =
    complianceProgramSteps.length ===
    Object.values(ComplianceProgramStep).length;

  return (
    <>
      {isAddProgramStepModalOpen && (
        <ComplianceProgramFormAddStepModal
          complianceProgramSteps={complianceProgramSteps}
          onClose={() => setIsAddProgramStepModalOpen(false)}
          onUpdateComplianceProgramSteps={onUpdateComplianceProgramSteps}
        />
      )}
      <div className="mt-10 flex items-center justify-between">
        <div className="text-xl font-semibold">Program Steps</div>
        {complianceProgramType !== ComplianceProgramType.ENGAGEMENT_SURVEY && (
          <Button
            className="p-4"
            onClick={() => setIsAddProgramStepModalOpen(true)}
            disabled={!canUpdate || allStepsAdded}
            tooltip={
              allStepsAdded ? "All steps have already been added" : undefined
            }
            theme={buttonTheme.lightBlue}
            text="Add Program Step"
          />
        )}
      </div>
      {complianceProgramSteps.length === 0 ? (
        <div className="mt-6 flex justify-center p-4 rounded bg-gray-100 text-gray-700 text-sm">
          Add a step to get started
        </div>
      ) : (
        <div className="mt-6 flex flex-col gap-8">
          {complianceProgramSteps.includes(
            ComplianceProgramStep.SELF_MANAGER_REVIEW
          ) && (
            <SelfManagerAssessmentStep
              proposedComplianceProgram={proposedComplianceProgram}
              canUpdate={canUpdate}
              complianceProgramSteps={complianceProgramSteps}
              onUpdateComplianceProgramSteps={onUpdateComplianceProgramSteps}
              onUpdateAssessmentTemplateSettings={
                onUpdateAssessmentTemplateSettings
              }
              onUpdateAssessmentTemplate={onUpdateAssessmentTemplate}
              availableAssessmentGroups={availableAssessmentGroups}
              onRemoveAssessmentTemplate={handleRemoveAssessmentTemplate}
              onAssessmentGroupCreated={onAssessmentGroupCreated}
            />
          )}

          {complianceProgramSteps.includes(
            ComplianceProgramStep.UPWARD_REVIEW
          ) && (
            <UpwardAssessmentStep
              proposedComplianceProgram={proposedComplianceProgram}
              canUpdate={canUpdate}
              complianceProgramSteps={complianceProgramSteps}
              onUpdateComplianceProgramSteps={onUpdateComplianceProgramSteps}
              onUpdateAssessmentTemplateSettings={
                onUpdateAssessmentTemplateSettings
              }
              onUpdateAssessmentTemplate={onUpdateAssessmentTemplate}
              availableAssessmentGroups={availableAssessmentGroups}
              onRemoveAssessmentTemplate={handleRemoveAssessmentTemplate}
              onAssessmentGroupCreated={onAssessmentGroupCreated}
            />
          )}

          {complianceProgramSteps.includes(
            ComplianceProgramStep.PEER_REVIEW
          ) && (
            <PeerAssessmentStep
              proposedComplianceProgram={proposedComplianceProgram}
              canUpdate={canUpdate}
              complianceProgramSteps={complianceProgramSteps}
              onUpdateComplianceProgramSteps={onUpdateComplianceProgramSteps}
              onUpdateAssessmentTemplateSettings={
                onUpdateAssessmentTemplateSettings
              }
              onUpdateAssessmentTemplate={onUpdateAssessmentTemplate}
              availableAssessmentGroups={availableAssessmentGroups}
              onRemoveAssessmentTemplate={handleRemoveAssessmentTemplate}
              onAssessmentGroupCreated={onAssessmentGroupCreated}
            />
          )}

          {complianceProgramSteps.includes(
            ComplianceProgramStep.ONE_ON_ONE
          ) && (
            <OneOnOneStep
              proposedComplianceProgram={proposedComplianceProgram}
              onUpdateProposedComplianceProgram={
                onUpdateProposedComplianceProgram
              }
              canUpdate={canUpdate}
              complianceProgramSteps={complianceProgramSteps}
              onUpdateComplianceProgramSteps={onUpdateComplianceProgramSteps}
              availableTopicTemplates={availableTopicTemplates}
            />
          )}

          {complianceProgramSteps.includes(
            ComplianceProgramStep.ENGAGEMENT_SURVEY
          ) && (
            <EngagementSurveyStep
              proposedComplianceProgram={proposedComplianceProgram}
              canUpdate={canUpdate}
              complianceProgramSteps={complianceProgramSteps}
              onUpdateComplianceProgramSteps={onUpdateComplianceProgramSteps}
              onUpdateAssessmentTemplateSettings={
                onUpdateAssessmentTemplateSettings
              }
              onUpdateAssessmentTemplate={onUpdateAssessmentTemplate}
              availableAssessmentGroups={availableAssessmentGroups}
              onRemoveAssessmentTemplate={handleRemoveAssessmentTemplate}
              onAssessmentGroupCreated={onAssessmentGroupCreated}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ComplianceProgramFormSteps;

import { useMutation } from "@apollo/client";
import {
  ChevronDownIcon,
  MinusCircleIcon,
  PauseIcon,
  PlayIcon,
} from "@heroicons/react/outline";
import { ChangeEvent } from "react";
import { PiWaveformBold } from "react-icons/pi";
import {
  AddBotToMeetingNewPageMutationMutation,
  AddBotToMeetingNewPageMutationMutationVariables,
  ChangeBotRecordingStatusNewPageMutationMutation,
  ChangeBotRecordingStatusNewPageMutationMutationVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
  RemoveBotFromMeetingNewPageMutationMutation,
  RemoveBotFromMeetingNewPageMutationMutationVariables,
  UpdateMeetingGroupNewPageMutationMutation,
  UpdateMeetingGroupNewPageMutationMutationVariables,
} from "types/graphql-schema";

import addBotToMeetingMutation from "@apps/meeting-new/graphql/add-bot-to-meeting-mutation";
import changeBotRecordingStatusMutation from "@apps/meeting-new/graphql/change-bot-recording-status-mutation";
import removeBotFromMeetingMutation from "@apps/meeting-new/graphql/remove-bot-from-meeting-mutation";
import updateMeetingGroupMutation from "@apps/meeting-new/graphql/update-meeting-group-mutation";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import AppPopover from "@components/popover/app-popover";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";

export const AIAssistMeetingBotPopover = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  return (
    <AppPopover
      options={{
        className: classNames(AppPopover.className, "min-w-[400px] max-w-144"),
      }}
      content={<AIAssistMeetingBot meeting={meeting} />}
    >
      <AppPopover.Button as={Button} disabled={!meeting.canUpdate?.permission}>
        {meeting.botIsRecording ? (
          <PiWaveformBold className="h-5 w-5 text-red-600" />
        ) : (
          <PiWaveformBold className="h-5 w-5 text-gray-400" />
        )}
        {meeting.botIsRecording
          ? "Transcribing"
          : meeting.hasBotInMeeting
          ? "Notetaker Paused"
          : meeting.botIsWaitingToJoinMeeting
          ? "Notetaker Waiting to Join"
          : "Not Transcribing"}
        {!!meeting.canUpdate?.permission && (
          <ChevronDownIcon className="h-3 w-3 text-gray-600" />
        )}
      </AppPopover.Button>
    </AppPopover>
  );
};

export const AIAssistMeetingBot = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const [addBotToMeeting] = useMutation<
    AddBotToMeetingNewPageMutationMutation,
    AddBotToMeetingNewPageMutationMutationVariables
  >(addBotToMeetingMutation);
  const [removeBotFromMeeting] = useMutation<
    RemoveBotFromMeetingNewPageMutationMutation,
    RemoveBotFromMeetingNewPageMutationMutationVariables
  >(removeBotFromMeetingMutation);
  const [changeBotRecordingStatus] = useMutation<
    ChangeBotRecordingStatusNewPageMutationMutation,
    ChangeBotRecordingStatusNewPageMutationMutationVariables
  >(changeBotRecordingStatusMutation);
  const [updateMeetingGroup] = useMutation<
    UpdateMeetingGroupNewPageMutationMutation,
    UpdateMeetingGroupNewPageMutationMutationVariables
  >(updateMeetingGroupMutation);

  const meetingGroup = meeting.meetingGroup;
  if (!meetingGroup) return null;

  const handleToggleAddTranscriptionBotToMeetingsByDefault = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    updateMeetingGroup({
      variables: {
        meetingGroupId: meetingGroup.id,
        addTranscriptionBotToMeetingsByDefault: e.target.checked,
      },
      onError: onNotificationErrorHandler(),
      optimisticResponse: {
        __typename: "Mutation",
        updateMeetingGroup: {
          __typename: "UpdateMeetingGroupMutation",
          meetingGroup: {
            ...meetingGroup,
            addTranscriptionBotToMeetingsByDefault: e.target.checked,
          },
        },
      },
    });
  };

  const handleClickAddBotInMeeting = () => {
    addBotToMeeting({
      variables: {
        meetingId: meeting.id,
      },
      onError: onNotificationErrorHandler(),
      optimisticResponse: {
        __typename: "Mutation",
        addBotToMeeting: {
          __typename: "AddBotToMeetingMutation",
          meeting: {
            ...meeting,
            hasBotInMeeting: true,
            botIsWaitingToJoinMeeting: true,
          },
        },
      },
    });
  };

  const handleClickRemoveBotInMeeting = () => {
    removeBotFromMeeting({
      variables: {
        meetingId: meeting.id,
      },
      onError: onNotificationErrorHandler(),
      optimisticResponse: {
        __typename: "Mutation",
        removeBotFromMeeting: {
          __typename: "RemoveBotFromMeetingMutation",
          meeting: {
            ...meeting,
            hasBotInMeeting: true,
            botIsWaitingToJoinMeeting: false,
          },
        },
      },
    });
  };

  const handleClickPauseBotRecording = () => {
    const newValue = !meeting.botIsRecording;
    changeBotRecordingStatus({
      onError: onNotificationErrorHandler(),
      variables: {
        meetingId: meeting.id,
        isRecording: newValue,
      },
    });
  };

  return meeting.draft ? (
    <div className="flex flex-col gap-4 text-sm tracking-tight text-gray-800 p-4">
      The meeting needs to be published first before it can be transcribed.
    </div>
  ) : (
    <div className="flex flex-col text-sm tracking-tight text-gray-800">
      <div className="flex flex-col gap-4 p-4">
        <div className="flex w-full items-center gap-2 flex-wrap">
          <div className="w-full flex items-center justify-between gap-2">
            <div className="flex items-center gap-1.5">
              {meeting.hasBotInMeeting && !meeting.botIsRecording ? (
                <PauseIcon className="h-5 w-5 text-gray-400" />
              ) : meeting.hasBotInMeeting && meeting.botIsRecording ? (
                <div className="h-2 w-2 shrink-0 rounded-full bg-red-600 outline outline-offset-1 outline-1 outline-red-600 animate-pulse" />
              ) : meeting.botIsWaitingToJoinMeeting ? (
                <Loading mini size={4} />
              ) : (
                <div className="h-3 w-3 shrink-0 rounded-full bg-gray-200" />
              )}
              {meeting.hasBotInMeeting && meeting.botIsRecording
                ? "Notetaker in meeting and recording"
                : meeting.hasBotInMeeting && !meeting.botIsRecording
                ? "Notetaker in meeting, not recording"
                : meeting.botIsWaitingToJoinMeeting
                ? "Notetaker is waiting to join the meeting"
                : "Notetaker not in the meeting"}
            </div>
          </div>
        </div>

        {!meeting.hasBotInMeeting && !meeting.botIsWaitingToJoinMeeting && (
          <div>
            <Button
              theme={buttonTheme.primary}
              text="Add Notetaker to meeting"
              onClick={handleClickAddBotInMeeting}
            />
          </div>
        )}

        {meeting.hasBotInMeeting && (
          <div className="flex flex-1 flex-wrap items-center gap-2 @container/aiassistbotbuttons">
            <Button
              className="flex items-center gap-1"
              tooltip="Pause transcription"
              onClick={handleClickPauseBotRecording}
            >
              {meeting.botIsRecording ? (
                <PauseIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <PlayIcon className="h-5 w-5 text-gray-500" />
              )}
              <span className="hidden @[14rem]/aiassistbotbuttons:block">
                {meeting.botIsRecording
                  ? "Pause Transcription"
                  : "Resume Transcription"}
              </span>
            </Button>
            <Button
              className="flex items-center gap-1"
              tooltip="Remove notetaker"
              onClick={handleClickRemoveBotInMeeting}
            >
              <MinusCircleIcon className="h-5 w-5 text-gray-500" />
              <span className="hidden @[14rem]/aiassistbotbuttons:block">
                Remove Notetaker
              </span>
            </Button>
          </div>
        )}
      </div>

      <div className="bg-gray-50 rounded-b-lg px-4 py-3 border-t text-sm">
        <label className="flex items-center gap-1 cursor-pointer">
          <input
            type="checkbox"
            className="cursor-pointer"
            checked={meetingGroup.addTranscriptionBotToMeetingsByDefault}
            onChange={handleToggleAddTranscriptionBotToMeetingsByDefault}
          />
          <span className="tracking-tight">
            Automatically add notetaker to this meeting.
          </span>
        </label>
      </div>
    </div>
  );
};

export default AIAssistMeetingBot;

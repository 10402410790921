import { useMutation, useQuery } from "@apollo/client";
import { Dialog, Listbox, Popover } from "@headlessui/react";
import { SelectorIcon, SwitchHorizontalIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import moment from "moment";
import { ChangeEvent, ReactElement, useEffect, useRef, useState } from "react";
import {
  AttendeeRole,
  AttendeeStatus,
  FrequencyNode,
  GetMeetingToEditQueryQuery,
  GetMeetingToEditQueryQueryVariables,
  GetTopicTemplatesQuery,
  GetTopicTemplatesQueryVariables,
  MeetingDialogFragmentFragment,
  OnlineConference,
} from "types/graphql-schema";

import getSidebarDataQuery from "@apps/main/graphql/get-sidebar-data-query";
import getTemplatesQuery from "@apps/templates/graphql/get-templates-query";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar, errorNotificationVar } from "@cache/cache";
import ComboboxGeneric, {
  ComboboxGenericClearButton,
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import GraphqlError from "@components/error/graphql-error";
import Input from "@components/input/input";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { Select, SelectOption } from "@components/select/select";
import CustomTextareaAutosize from "@components/textarea-autosize/textarea-autosize";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  ignoredParticipantEmails,
  meetingEditType,
  validOnlineConferenceTypesByProvider,
} from "@helpers/constants";
import {
  classNames,
  inputBorderClassName,
  inputFocusClassName,
} from "@helpers/css";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
  getUrl,
  removeTypename,
} from "@helpers/helpers";

import AttendeeInput, { Attendee } from "./components/attendee-input";
import AttendeeList from "./components/attendee-list";
import MeetingDialogCalendars from "./components/calendars";
import MeetingDialogCurrentUserAttendance from "./components/current-user-attendance";
import MeetingDates from "./components/dates";
import MeetingDialogButtons from "./components/dialog-buttons";
import MeetingDialogFormButtons from "./components/form-buttons";
import { getRecurrenceValue } from "./components/recurrence";
import VideoConference from "./components/video-conference";
import createOrUpdateMeetingInExternalCalendarMutation from "./graphql/create-or-update-meeting-in-external-calendar-mutation";
import getMeetingToEditQuery from "./graphql/get-meeting-to-edit-query";

export const meetingDialogInputClassName =
  "hover:bg-gray-100 rounded text-sm px-2 py-1.5 focus:outline-0 focus:ring-0";

const defaultEventTitle = "(No title)";
export enum meetingDialogAction {
  readonly = "readonly",
  create = "create",
  publish = "publish",
  edit = "edit",
}
export type MeetingDialogFormType = {
  recurrence: null | undefined | (FrequencyNode & { value: string });
  credentialsId: number | null;
  meetingId: number | null;
  templateId: number | null;
  facilitatorId?: number | null;
  organizer?: { id: number; name: string } | null;
  editType: string;
  title: string | null | undefined;
  description: string | null | undefined;
  onlineConference: OnlineConference | null | undefined;
  startDatetime?: string;
  endDatetime?: string;
  isFormalOneonone: boolean;
  draft: boolean;
  isRecurring: boolean;
  attendees: Attendee[];
};

const MeetingDialogFieldContainer = ({
  title,
  children,
}: {
  title: string;
  children: ReactElement;
}) => (
  <div className="py-3 pl-6 pr-8 flex">
    <div className="shrink-0 pt-1.5 text-gray-500 text-sm tracking-tight w-26 font-medium">
      {title}
    </div>
    {children}
  </div>
);

const MeetingDialog = ({
  meetingGroupId = null,
  meetingId = null,
  formOptions = {},
  defaultAction = meetingDialogAction.create,
  enforceMeetingDate = false,
  refetchQueries = [],
  onClose,
  onSaved = null,
}: {
  meetingGroupId?: number | null;
  meetingId?: number | null;
  formOptions?: Partial<MeetingDialogFormType>;
  defaultAction?: meetingDialogAction;
  refetchQueries?: any[];
  enforceMeetingDate?: boolean;
  onClose: () => void;
  onSaved?:
    | null
    | (({ meeting }: { meeting: MeetingDialogFragmentFragment }) => void);
}) => {
  const label = useLabel();
  const [isShowingDescription, setIsShowingDescription] = useState(false);
  const [action, setAction] = useState(defaultAction);
  const textareaAutosizeRef = useRef(null);
  const inputTitleRef = useRef(null);
  const link = useLink();
  const currentUser = currentUserVar();
  const [availableTopicTemplates, setAvailableTopicTemplates] = useState<
    ComboboxGenericOption<number>[]
  >([]);

  const calendarOptions = (
    currentUser.syncCredentials
      ? assertEdgesNonNull(currentUser.syncCredentials)
      : []
  )
    .map((node) => ({
      ...node,
      value: node.id,
      label: node.credentialsUid,
    }))
    .filter(({ hasValidCalendarCredentials }) => hasValidCalendarCredentials);

  // use to track changes between form and initial form
  const initialData = {
    organizer: currentUser,
    facilitatorId: currentUser.id,
    editType: meetingEditType.singleEvent,
    isFormalOneonone: false,
    meetingId: null,
    templateId: null,
    draft: true,
    isRecurring: false,
    title: "",
    description: "",
    onlineConference: OnlineConference.Native,
    credentialsId: calendarOptions[0]?.id || null,
    attendees: [
      {
        ...currentUser,
        email: calendarOptions[0]?.credentialsUid || currentUser.email,
        role: AttendeeRole.Required,
        participantStatus: AttendeeStatus.NotResponded,
      },
    ],
    startDatetime: undefined,
    endDatetime: undefined,
    recurrence: null,
    ...formOptions,
  };
  const [initialForm, setInitialForm] =
    useState<MeetingDialogFormType>(initialData);
  const [form, setForm] = useState<MeetingDialogFormType>(initialData);

  const selectedCalendar =
    calendarOptions.find(({ id }) => id === form.credentialsId) ||
    calendarOptions[0];

  const [
    createOrUpdateMeetingInExternalCalendar,
    { loading: loadingSave, error },
  ] = useMutation(createOrUpdateMeetingInExternalCalendarMutation, {
    refetchQueries: [getSidebarDataQuery],
  });
  const { loading: loadingExistingMeeting } = useQuery<
    GetMeetingToEditQueryQuery,
    GetMeetingToEditQueryQueryVariables
  >(getMeetingToEditQuery, {
    skip: !meetingId,
    variables: { meetingId: meetingId || 0 },
    onCompleted: ({ meeting }) => {
      if (!meeting || !meeting.meetingGroup) {
        return;
      }
      const participants = assertEdgesNonNullWithStringId(meeting.participants);
      const attendees = compact(
        participants.map((node) =>
          node.user
            ? {
                ...node.user,
                role: node.role || AttendeeRole.Optional,
                email: node.participantEmail || node.user.email,
                participantStatus: node.status || AttendeeStatus.NotResponded,
              }
            : null
        )
      );
      const recurrence = meeting.meetingGroup.recurrenceParameters && {
        ...meeting.meetingGroup.recurrenceParameters,
        value: getRecurrenceValue(meeting.meetingGroup.recurrenceParameters),
      };
      const editType = meeting.meetingGroup.recurrenceParameters
        ? meetingEditType.thisEventAndFuture
        : meetingEditType.singleEvent;
      const updatedForm = {
        ...form,
        organizer: meeting.organizer,
        isRecurring: meeting.isRecurring,
        isFormalOneonone: meeting.meetingGroup.isFormalOneonone,
        facilitatorId: meeting.meetingGroup.facilitator?.id,
        templateId: meeting.meetingGroup.topicTemplate?.id || null,
        editType,
        draft: meeting.draft,
        title: meeting.title,
        meetingId: meeting.id,
        description: meeting.meetingGroup.description || "",
        credentialsId:
          meeting.meetingGroup.syncCredentials?.id || calendarOptions[0]?.id,
        attendees,
        recurrence,
        onlineConference: meeting.meetingGroup.onlineConference,
        startDatetime: meeting.startDatetime
          ? moment(meeting.startDatetime).format()
          : undefined,
        endDatetime: meeting.endDatetime
          ? moment(meeting.endDatetime).format()
          : undefined,
      };
      setIsShowingDescription(updatedForm.description.trim().length > 0);
      setInitialForm(updatedForm);
      setForm(updatedForm);
      if (
        meeting.draft &&
        (action === meetingDialogAction.edit ||
          action === meetingDialogAction.readonly)
      ) {
        return setAction(meetingDialogAction.publish);
      }
      // if user has no permissions to edit meeting, or if the recurrence rule isn't supported
      // then we show read only view
      if (
        (action === meetingDialogAction.edit &&
          updatedForm.organizer?.id !== currentUser.id) ||
        meeting.meetingGroup.recurrenceRule?.startsWith("EXDATE")
      ) {
        setAction(meetingDialogAction.readonly);
      }
    },
    onError: onNotificationErrorHandler(),
  });

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, title: e.currentTarget.value });
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setForm({ ...form, description: e.currentTarget.value });
  };

  const handleChangeIsFormalOneonone = (option: SelectOption) => {
    const isFormalOneonone = option.value === "true";
    const updatedForm = { ...form, isFormalOneonone };
    setForm(updatedForm);
  };

  const handleSwitchFacilitatorSubject = (facilitatorId?: number | null) => {
    const attendees = form.attendees.filter(
      ({ email }) => !ignoredParticipantEmails.includes(email)
    );
    const facilitator =
      attendees.find(({ id }) => facilitatorId !== id) ?? attendees[0];
    setForm({
      ...form,
      facilitatorId: facilitator.id,
      organizer: form.meetingId
        ? form.organizer
        : facilitator.id
        ? { id: facilitator.id, name: facilitator.name }
        : null,
    });
  };

  const handleChangeAttendees = (attendees: Attendee[]) => {
    // ensure we always have a facilitator
    const matchingFacilitatorAttendee = form.facilitatorId
      ? attendees.find(({ id }) => id === form.facilitatorId)
      : null;
    const facilitatorId =
      form.isFormalOneonone && matchingFacilitatorAttendee
        ? matchingFacilitatorAttendee.id
        : attendees[0]?.id;
    // update attendees
    setForm({ ...form, attendees, facilitatorId });
  };

  const handleChangeCurrentUserAttendance = (
    participantStatus: AttendeeStatus
  ) => {
    const updatedAttendees = form.attendees.map((attendee) => {
      if (attendee.id === currentUser.id)
        return { ...attendee, participantStatus };
      return attendee;
    });
    setForm({ ...form, attendees: updatedAttendees });
  };

  const handleChangeCalendar = (value: number) => {
    let onlineConference = form.onlineConference;
    const newCalendar = calendarOptions.find(({ id }) => id === value);
    // if the video conf is not valid with selected calendar we reset the video conference
    if (
      form.onlineConference &&
      newCalendar &&
      (newCalendar.provider === "google_calendar" ||
        newCalendar.provider === "outlook_calendar") &&
      !validOnlineConferenceTypesByProvider[newCalendar.provider].includes(
        form.onlineConference
      )
    ) {
      onlineConference = null;
    }
    // update the organizer email appearing in tooltip
    const attendees = form.attendees.map((attendee) => ({
      ...attendee,
      email:
        attendee.id === currentUser.id && newCalendar?.credentialsUid
          ? newCalendar.credentialsUid
          : attendee.email,
    }));
    setForm({
      ...form,
      credentialsId: value,
      attendees,
      onlineConference,
    });
  };

  const handleChangeOnlineConference = (onlineConference: OnlineConference) => {
    setForm({ ...form, onlineConference });
  };

  const handleSaveMeeting = (options: { publish: boolean }) => () => {
    let recurrence = null;
    if (!!form.recurrence) {
      const { value, ...restRecurrence } = form.recurrence;
      recurrence = removeTypename(restRecurrence);
    }
    const attendees = form.attendees.map(({ email, role }) => ({
      email,
      role,
    }));
    const newForm = {
      ...form,
      ...options,
      recurrence,
      attendees,
      // cannot edit the template of a meeting, can only specify it when creating it
      templateId: form.meetingId ? null : form.templateId,
      startTimezone: form.startDatetime
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : undefined,
      endTimezone: form.endDatetime
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : undefined,
    };
    const validTitle = newForm.title;

    if (
      enforceMeetingDate &&
      (!newForm.startDatetime || !newForm.endDatetime)
    ) {
      errorNotificationVar({
        title: "Enter a valid meeting date and time.",
        timeout: 5000,
      });
      return;
    }

    // get confirmation user want to create meeting without title
    if (
      options.publish &&
      !meetingId &&
      !validTitle &&
      // eslint-disable-next-line
      !confirm("Confirm you want to create a meeting without title?")
    ) {
      return;
    }
    const variables = {
      ...newForm,
      title: newForm.title || defaultEventTitle,
    };
    createOrUpdateMeetingInExternalCalendar({
      variables,
      onCompleted: ({ createOrUpdateMeetingInExternalCalendar }) => {
        const meeting = createOrUpdateMeetingInExternalCalendar.meeting;
        if (onSaved) {
          onSaved({ meeting });
        } else if (meeting?.meetingGroup) {
          link.redirect(`/meeting/${meeting.meetingGroup.id}/${meeting.id}`);
        }
        onClose();
      },
      refetchQueries: [getSidebarDataQuery, ...refetchQueries],
      onError: onNotificationErrorHandler(),
    });
  };

  const { loading: isLoadingAvailableTopicTemplates } = useQuery<
    GetTopicTemplatesQuery,
    GetTopicTemplatesQueryVariables
  >(getTemplatesQuery, {
    onCompleted: (response) => {
      const templates = response.topicTemplates
        ? assertEdgesNonNull(response.topicTemplates)
        : [];
      setAvailableTopicTemplates(
        templates.map(({ id, title }) => ({ value: id, label: title }))
      );
    },
    onError: onNotificationErrorHandler(),
  });

  const handleClickEdit = () => {
    if (form.draft) {
      setAction(meetingDialogAction.publish);
    } else {
      setAction(meetingDialogAction.edit);
    }
  };

  const handleClickView = () => {
    setAction(meetingDialogAction.readonly);
  };

  const handleSaveChangesPublishedMeeting = (value: {}) => {
    handleSaveMeeting({ publish: true, ...value })();
  };

  // set a default form when we suddenly connect a new calendar in the dialog
  useEffect(() => {
    if (calendarOptions.length > 0 && !selectedCalendar) {
      setForm({ ...form, credentialsId: calendarOptions[0].id });
    }
  }, [calendarOptions, form, selectedCalendar]);

  useEffect(() => {
    // this is to force the description field to resize
    window.dispatchEvent(new Event("resize"));
  }, []);

  const isReadOnly = action === meetingDialogAction.readonly;
  const datesHaveChanged =
    form.startDatetime !== initialForm.startDatetime ||
    form.endDatetime !== initialForm.endDatetime;
  const selectedTemplate = form.templateId
    ? availableTopicTemplates.find(({ value }) => value === form.templateId) ??
      null
    : null;

  return (
    <Dialog
      as="div"
      aria-label="Meeting dialog"
      className="fixed z-modal inset-0 overflow-y-auto"
      open
      onClose={() => {}}
      initialFocus={inputTitleRef}
    >
      <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
      <div className="flex min-h-full sm:pt-20 items-center flex-col justify-between text-center text-gray-800">
        <div className="w-full md:max-w-xl mt-4 align-top transform rounded-lg bg-white text-left shadow-xl">
          <MeetingDialogButtons
            action={action}
            isRecurring={form.isRecurring}
            organizerId={initialForm.organizer?.id}
            meetingId={form.meetingId}
            draft={form.draft}
            onClickEdit={handleClickEdit}
            onClickView={handleClickView}
            onClose={onClose}
          />
          <div className="relative">
            <div>
              {loadingExistingMeeting ? (
                <div className="py-8">
                  <Loading>loading meeting</Loading>
                </div>
              ) : (
                <form
                  className="relative flex flex-col divide-y pt-3"
                  autoComplete="off"
                >
                  <MeetingDialogFieldContainer title="Title">
                    <div className="flex-1 flex flex-col gap-2">
                      <div>
                        {isReadOnly ? (
                          <div className="py-1 font-medium">
                            {form.title || "No title"}
                          </div>
                        ) : (
                          <Input
                            className={classNames(
                              inputBorderClassName,
                              inputFocusClassName,
                              "px-3 py-1.5 text-sm font-medium"
                            )}
                            aria-label="Meeting dialog title input"
                            type="text"
                            placeholder="Add title"
                            data-lpignore="true"
                            autoComplete="off"
                            value={form.title || ""}
                            onChange={handleChangeTitle}
                            ref={inputTitleRef}
                            autoFocus
                            disabled={isReadOnly}
                          />
                        )}
                      </div>
                      {isShowingDescription ? (
                        <div>
                          {isReadOnly ? (
                            <div className="text-sm text-gray-500 whitespace-pre-line">
                              {form.description}
                            </div>
                          ) : (
                            <CustomTextareaAutosize
                              className={classNames(
                                inputBorderClassName,
                                inputFocusClassName,
                                "px-3 py-1.5 text-sm w-full",
                                isReadOnly &&
                                  "bg-white hover:bg-white resize-none"
                              )}
                              minRows={2}
                              disabled={isReadOnly}
                              ref={textareaAutosizeRef}
                              placeholder="Add meeting description"
                              aria-label="Meeting dialog description input"
                              id="meeting-modal-description"
                              defaultValue={form.description || ""}
                              onChange={handleChangeDescription}
                            />
                          )}
                        </div>
                      ) : !isReadOnly ? (
                        <button
                          type="button"
                          className="text-2xs text-left tracking-tight text-gray-400 hover:text-gray-800"
                          onClick={() => setIsShowingDescription(true)}
                        >
                          Add description
                        </button>
                      ) : null}
                    </div>
                  </MeetingDialogFieldContainer>

                  <MeetingDialogFieldContainer title="Type">
                    <Select
                      onChange={handleChangeIsFormalOneonone}
                      value={String(form.isFormalOneonone)}
                      disabled={isReadOnly}
                      options={[
                        {
                          value: "true",
                          label: label("oneonone", { capitalize: true }),
                        },
                        {
                          value: "false",
                          label: "Meeting",
                        },
                      ]}
                    >
                      {({ selected, setReferenceElement }) => (
                        <Listbox.Button
                          className={classNames(
                            "flex-1 truncate relative w-full -ml-2 pl-2 pr-7 py-1.5 ",
                            "bg-white text-left cursor-default text-gray-800 text-sm rounded",
                            !isReadOnly && "hover:bg-gray-100 "
                          )}
                          ref={setReferenceElement}
                        >
                          <span className="block truncate">
                            {selected?.label}
                          </span>
                          {!isReadOnly && (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon className="h-4 w-4 text-gray-400" />
                            </span>
                          )}
                        </Listbox.Button>
                      )}
                    </Select>
                  </MeetingDialogFieldContainer>

                  <MeetingDialogFieldContainer title="Date & Time">
                    <MeetingDates
                      isReadOnly={isReadOnly}
                      form={form}
                      onChangeForm={setForm}
                    />
                  </MeetingDialogFieldContainer>

                  <MeetingDialogFieldContainer title="Participants">
                    <div className="flex-1 min-w-0">
                      {!isReadOnly && (
                        <AttendeeInput
                          isFormalOneonone={form.isFormalOneonone}
                          attendees={form.attendees}
                          onChange={handleChangeAttendees}
                        />
                      )}
                      <AttendeeList
                        validDomains={compact(
                          selectedCalendar?.organization?.domains || []
                        )}
                        isFormalOneonone={form.isFormalOneonone}
                        facilitatorId={form.facilitatorId}
                        organizerId={initialForm.organizer?.id}
                        isReadOnly={isReadOnly}
                        attendees={form.attendees}
                        onChange={handleChangeAttendees}
                      />
                      {!isReadOnly && (
                        <div className="text-sm mt-2 mx-1 flex justify-end items-center">
                          {form.isFormalOneonone && form.attendees.length > 1 && (
                            <div className="mx-0.5">
                              <button
                                type="button"
                                className="text-xs text-gray-400 flex items-center gap-2 hover:underline"
                                onClick={() =>
                                  handleSwitchFacilitatorSubject(
                                    form.facilitatorId
                                  )
                                }
                              >
                                Switch facilitator & subject
                                <SwitchHorizontalIcon className="h-4 w-4" />
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </MeetingDialogFieldContainer>

                  <MeetingDialogFieldContainer title="Template">
                    <div className="flex-1 min-w-0 flex gap-2 items-center">
                      {/* new meeting */}
                      {!meetingId ? (
                        <ComboboxGeneric
                          placeholder="No templates selected"
                          options={availableTopicTemplates}
                          clearable={!!form.templateId}
                          onClearValue={() => {
                            setForm({ ...form, templateId: null });
                          }}
                          disabled={isReadOnly}
                          onChangeValue={(opt) =>
                            setForm({
                              ...form,
                              templateId: opt.value,
                            })
                          }
                          value={selectedTemplate}
                        >
                          {({ setReferenceElement, value }) => (
                            <div className="flex justify-between items-center">
                              <Popover.Button
                                // className="px-4 py-2 flex gap-2 w-full text-sm text-left items-center justify-between min-w-0"
                                className="flex-1 truncate bg-white relative w-full -ml-2 px-2 py-1.5 text-left cursor-default text-gray-800 text-sm hover:bg-gray-100 rounded flex items-center gap-1"
                                ref={setReferenceElement}
                                disabled={isReadOnly}
                              >
                                <div className="truncate">
                                  {value?.label ?? "No templates selected"}
                                </div>
                                {!isReadOnly && (
                                  <span>
                                    <SelectorIcon className="h-4 w-4 text-gray-400" />
                                  </span>
                                )}
                              </Popover.Button>
                              {!!form.templateId && !isReadOnly && (
                                <ComboboxGenericClearButton
                                  onClearValue={() =>
                                    setForm({ ...form, templateId: null })
                                  }
                                />
                              )}
                            </div>
                          )}
                        </ComboboxGeneric>
                      ) : (
                        <div className="text-sm pt-1.5">
                          {selectedTemplate ? (
                            <span>{selectedTemplate.label}</span>
                          ) : form.templateId ? (
                            <span>Custom template</span>
                          ) : (
                            <span className="text-gray-500">
                              No templates selected.
                            </span>
                          )}
                          {!isReadOnly && (
                            <div className="mt-1 text-xs text-gray-800">
                              Meeting template can be edited in the{" "}
                              <AppLink
                                to={`${getUrl({ meetingGroupId })}/settings`}
                                className="text-blue-link hover:underline"
                                target="_blank"
                              >
                                meeting settings
                              </AppLink>
                              .
                            </div>
                          )}
                        </div>
                      )}
                      {isLoadingAvailableTopicTemplates && (
                        <Loading mini size="5" />
                      )}
                    </div>
                  </MeetingDialogFieldContainer>

                  {!isReadOnly && selectedCalendar && (
                    <MeetingDialogFieldContainer title="Conference">
                      <VideoConference
                        selectedCalendar={selectedCalendar}
                        onlineConference={form.onlineConference}
                        onChangeOnlineConference={handleChangeOnlineConference}
                      />
                    </MeetingDialogFieldContainer>
                  )}

                  <MeetingDialogFieldContainer title="Calendar">
                    <MeetingDialogCalendars
                      action={action}
                      selectedCalendar={selectedCalendar}
                      onChangeCalendar={handleChangeCalendar}
                    />
                  </MeetingDialogFieldContainer>
                </form>
              )}
            </div>
          </div>
          <div className="py-4 px-6 flex flex-col gap-2 border-t empty:hidden">
            <MeetingDialogFormButtons
              hasDates={!!form.startDatetime && !!form.endDatetime}
              action={action}
              calendarOptions={calendarOptions}
              loadingExistingMeeting={loadingExistingMeeting}
              loadingSave={loadingSave}
              selectedCalendar={selectedCalendar}
              recurrence={initialForm.recurrence}
              showAllEventsOption={!datesHaveChanged}
              onSaveMeeting={handleSaveMeeting}
              onClose={onClose}
              onSaveChangesPublishedMeeting={handleSaveChangesPublishedMeeting}
            />
            <MeetingDialogCurrentUserAttendance
              isRecurring={form.isRecurring}
              action={action}
              meetingId={meetingId}
              meetingGroupId={meetingGroupId}
              attendees={form.attendees}
              loadingExistingMeeting={loadingExistingMeeting}
              onChangeAttendeeStatus={handleChangeCurrentUserAttendance}
            />
            {error && (
              <div className="mt-4">
                <GraphqlError
                  whatDidNotWork="The meeting could not be created."
                  error={error}
                />
              </div>
            )}
          </div>
        </div>
        <div className="h-[5vh]"></div>
      </div>
    </Dialog>
  );
};

export default MeetingDialog;

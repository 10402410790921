import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { compact } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AssessmentDeliveryFragment,
  AssessmentDeliveryState,
  AssessmentGroupDelivery,
  AssessmentType,
  GetAssessmentDeliveryQuery,
  GetAssessmentDeliveryQueryVariables,
  SaveAssessmentDeliveryMutation,
  SaveAssessmentDeliveryMutationVariables,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import AskAI from "@apps/ask-ai/ask-ai";
import {
  assessmentTypeSectionHeadings,
  getHiddenQuestionCount,
} from "@apps/assessments/helpers";
import Feedbacks from "@apps/dashboard-new/components/feedbacks";
import Recognitions from "@apps/dashboard-new/components/recognitions";
import useLabel from "@apps/use-label/use-label";
import WorkHistorySidebar from "@apps/work-history/components/work-history-sidebar";
import {
  currentOrganizationVar,
  isAdminVar,
  previousUrlVar,
  successNotificationVar,
} from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import useDebounce from "@components/use-debounce/use-debounce";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import getAssessmentDelivery from "../../graphql/get-assessment-delivery";
import updateAssessmentDeliveryMutation from "../../graphql/update-assessment-delivery-mutation";
import { AssessmentSidebarTab } from "../assessment/assessment";
import AssessmentGoalSidebar from "../assessment/assessment-goal-sidebar";
import RelatedRequirements from "../assessment/related-requirements";
import AssessmentLayout from "../layouts/assessment-layout";
import AssessmentDeliveryContent from "./assessment-delivery-content";
import AssessmentDeliveryHeader from "./assessment-delivery-header";
import CompletedAssessmentDeliveryStack from "./completed-assessment-delivery-stack";
import AssessmentDeliveryCalibrationChangelog from "./delivery-calibration-changelog";

export const AssessmentDeliveryActions = ({
  assessmentDeliveryData,
  isSaving,
  assessmentDeliveryId,
  backUrl,
}: {
  assessmentDeliveryData: NonNullable<GetAssessmentDeliveryQuery>;
  isSaving: boolean;
  assessmentDeliveryId: number;
  backUrl?: string;
}) => {
  const isAdmin = isAdminVar();
  const label = useLabel();
  const link = useLink();
  const canUpdate = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery &&
      assessmentDeliveryData.assessmentDelivery.canUpdate?.permission,
    [assessmentDeliveryData]
  );

  const [saveAssessmentDelivery] = useMutation<
    SaveAssessmentDeliveryMutation,
    SaveAssessmentDeliveryMutationVariables
  >(updateAssessmentDeliveryMutation);

  const {
    ConfirmationDialog: DeliverConfirmationDialog,
    confirm: confirmDeliver,
  } = useConfirm(
    "Are you sure?",
    `This will deliver the responses you have selected to ${assessmentDeliveryData?.assessmentDelivery?.target?.name}. No further responses will be gathered. Do you want to continue?`
  );

  const handleClickDeliver = useCallback(async () => {
    const confirmation = await confirmDeliver();
    if (confirmation) {
      saveAssessmentDelivery({
        variables: {
          assessmentDeliveryId,
          state: AssessmentDeliveryState.Delivered,
        },
        onCompleted: () => {
          successNotificationVar({
            title: `${label("review", {
              capitalize: true,
            })} package delivered`,
          });
          if (backUrl) {
            link.redirect(backUrl);
          }
        },
        refetchQueries: [getAssessmentDelivery],
        onError: onNotificationErrorHandler(),
      });
    }
  }, [
    confirmDeliver,
    saveAssessmentDelivery,
    assessmentDeliveryId,
    label,
    link,
    backUrl,
  ]);

  const {
    ConfirmationDialog: AdminApprovalConfirmationDialog,
    confirm: confirmAdminApproval,
  } = useConfirm(
    "Are you sure?",
    `Proceeding will approve this delivery package and allow the manager of ${assessmentDeliveryData?.assessmentDelivery?.target?.name} to share it whenever they choose. No further responses will be gathered. Do you want to continue?`
  );
  const handleClickApproveAdminApproval = useCallback(async () => {
    const confirmation = await confirmAdminApproval();
    if (confirmation) {
      saveAssessmentDelivery({
        variables: {
          assessmentDeliveryId,
          state: AssessmentDeliveryState.Draft,
        },
        onCompleted: () => {
          successNotificationVar({
            title: `${label("review", {
              capitalize: true,
            })} package approved`,
          });
          if (backUrl) {
            link.redirect(backUrl);
          }
        },
        onError: onNotificationErrorHandler(),
      });
    }
  }, [
    confirmAdminApproval,
    saveAssessmentDelivery,
    assessmentDeliveryId,
    label,
    link,
    backUrl,
  ]);

  const isDraft =
    assessmentDeliveryData?.assessmentDelivery?.state ===
    AssessmentDeliveryState.Draft;

  const isAwaitingAdminApproval =
    waffle.flag_is_active("calibration") &&
    assessmentDeliveryData.assessmentDelivery?.template.delivery ===
      AssessmentGroupDelivery.AdminApproval &&
    assessmentDeliveryData?.assessmentDelivery?.state ===
      AssessmentDeliveryState.AwaitingAdminApproval;

  return (
    <>
      <AdminApprovalConfirmationDialog />
      <DeliverConfirmationDialog />

      {canUpdate && isDraft && (
        <div className="mt-2 flex items-center justify-end">
          {isSaving && <Loading className="mr-4" mini size="5" />}
          <Button
            theme={buttonTheme.primary}
            disabled={isSaving}
            onClick={handleClickDeliver}
            text={`Deliver to ${assessmentDeliveryData?.assessmentDelivery?.target?.name}`}
          />
        </div>
      )}
      {canUpdate && isAwaitingAdminApproval && isAdmin && (
        <div className="mt-2 flex items-center justify-end">
          {isSaving && <Loading className="mr-4" mini size="5" />}
          <Button
            theme={buttonTheme.primary}
            disabled={isSaving}
            onClick={handleClickApproveAdminApproval}
            text="Approve"
          />
        </div>
      )}
    </>
  );
};

type DeliveryData = {
  excludedAnswerIds: number[];
  summary: string | null;
};

const AssessmentDelivery = ({
  assessmentDeliveryId,
  isInSidebar = false,
  onCloseSidebar,
}: {
  assessmentDeliveryId: number;
  isInSidebar?: boolean;
  onCloseSidebar?: () => void;
}) => {
  if ((isInSidebar && !onCloseSidebar) || (!isInSidebar && !!onCloseSidebar)) {
    throw new Error(
      "onCloseSidebar and isInSidebar are required to be used together"
    );
  }

  const location = useLocation<TFLocationState>();
  const prevUrl = previousUrlVar();
  const link = useLink();
  const backUrl = location.state?.previousPathname || prevUrl || "/assessments";
  const { onError } = useNotificationError();
  const label = useLabel();
  const organization = currentOrganizationVar();

  const [activeTab, setActiveTab] = useState(AssessmentSidebarTab.artifacts);
  const [chatSessionId, setChatSessionId] = useState<null | number>(null);
  const [proposedDeliveryData, setProposedDeliveryData] =
    useState<DeliveryData | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isShowingHiddenQuestions, setIsShowingHiddenQuestions] =
    useState(false);
  const [relatedAssessmentDeliveries, setRelatedAssessmentDeliveries] =
    useState<AssessmentDeliveryFragment[]>([]);
  const debouncedDeliveryData = useDebounce(proposedDeliveryData, 500);

  const { data: assessmentDeliveryData, loading: isLoadingAssessmentDelivery } =
    useQuery<GetAssessmentDeliveryQuery, GetAssessmentDeliveryQueryVariables>(
      getAssessmentDelivery,
      {
        fetchPolicy: "no-cache",
        variables: {
          assessmentDeliveryId,
          organizationId: organization.id,
        },
        onCompleted: (response) => {
          if (response.assessmentDelivery) {
            const excludedAnswerIds = response.assessmentDelivery
              .excludedAnswerIds as number[];
            setProposedDeliveryData({
              summary: response.assessmentDelivery.summary,
              excludedAnswerIds,
            });
          }
        },
        onError: onNotificationErrorHandler(),
      }
    );

  const [
    getRelatedDelivery,
    { loading: isLoadingRelatedAssessmentDeliveries },
  ] = useLazyQuery<
    GetAssessmentDeliveryQuery,
    GetAssessmentDeliveryQueryVariables
  >(getAssessmentDelivery, {
    fetchPolicy: "no-cache",
    onError: onNotificationErrorHandler(),
  });

  const getMoreDelivery = useCallback(
    (deliveryId: string | number) => {
      getRelatedDelivery({
        variables: {
          assessmentDeliveryId: deliveryId,
          organizationId: organization.id,
        },
      }).then((response) => {
        if (!!response.data?.assessmentDelivery) {
          const delivery = response.data.assessmentDelivery;
          setRelatedAssessmentDeliveries((prev) => [...prev, delivery]);
        }
      });
    },
    [getRelatedDelivery, organization]
  );

  useEffect(() => {
    if (assessmentDeliveryData) {
      const relatedDeliveryIds: (string | number)[] = compact(
        assessmentDeliveryData.assessmentDelivery?.relatedAssessmentDeliveries.edges.map(
          (edge) => edge?.node?.id
        )
      );
      if (
        assessmentDeliveryData.assessmentDelivery
          ?.undeliveredPeerAssessmentDeliveryId
      ) {
        relatedDeliveryIds.push(
          assessmentDeliveryData.assessmentDelivery
            ?.undeliveredPeerAssessmentDeliveryId
        );
      }
      relatedDeliveryIds.forEach(getMoreDelivery);
    }
    // only run when first delivery query is completed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentDeliveryData]);

  const [saveAssessmentDelivery] = useMutation<
    SaveAssessmentDeliveryMutation,
    SaveAssessmentDeliveryMutationVariables
  >(updateAssessmentDeliveryMutation);

  const canUpdate = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery &&
      assessmentDeliveryData.assessmentDelivery.canUpdate?.permission,
    [assessmentDeliveryData]
  );

  useEffect(() => {
    if (
      !assessmentDeliveryData?.assessmentDelivery ||
      !canUpdate ||
      debouncedDeliveryData === null
    ) {
      return;
    }
    saveAssessmentDelivery({
      variables: {
        assessmentDeliveryId,
        ...debouncedDeliveryData,
      },
      onCompleted: () => setIsSaving(false),
      onError,
    });
    // autosave, only fire to backend when debounced data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDeliveryData]);

  const program = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery?.complianceProgram
        : null,
    [assessmentDeliveryData]
  );
  const complianceProgramPeriod = useMemo(
    () =>
      program?.periodStartDate && program?.periodEndDate
        ? [program.periodStartDate, program.periodEndDate]
        : undefined,
    [program]
  );
  const target = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery?.target
        : null,
    [assessmentDeliveryData]
  );
  const relatedMeetings = assessmentDeliveryData?.assessmentDelivery
    ?.relatedMeetings
    ? assertEdgesNonNull(
        assessmentDeliveryData?.assessmentDelivery?.relatedMeetings
      )
    : [];

  const handleUpdateDeliveryData = useCallback(
    (data: Partial<DeliveryData>) => {
      if (proposedDeliveryData === null) {
        return;
      }
      setProposedDeliveryData({ ...proposedDeliveryData, ...data });
      setIsSaving(true);
    },
    [proposedDeliveryData]
  );

  const {
    ConfirmationDialog: ReopenConfirmationDialog,
    confirm: confirmReopen,
  } = useConfirm(
    "Are you sure?",
    `Reopening the ${label(
      "review"
    )} delivery package will remove the subject's access until it is redelivered.`
  );
  const handleReopen = useCallback(
    async (deliveryId: number) => {
      const confirmation = await confirmReopen();
      if (confirmation) {
        saveAssessmentDelivery({
          variables: {
            assessmentDeliveryId: deliveryId,
            state: AssessmentDeliveryState.Draft,
          },
          onCompleted: () => {
            successNotificationVar({
              title: `${label("review", {
                capitalize: true,
              })} package reverted to in progress`,
            });
            link.redirect(backUrl);
          },
          refetchQueries: [getAssessmentDelivery],
          onError,
        });
      }
    },
    [confirmReopen, onError, saveAssessmentDelivery, label, link, backUrl]
  );

  const performanceAssessmentDelivery: AssessmentDeliveryFragment | undefined =
    useMemo(() => {
      if (
        assessmentDeliveryData?.assessmentDelivery?.state ===
        AssessmentDeliveryState.Delivered
      ) {
        if (
          assessmentDeliveryData?.assessmentDelivery?.template
            .assessmentType === AssessmentType.Performance
        ) {
          return assessmentDeliveryData?.assessmentDelivery;
        }
        return relatedAssessmentDeliveries.find(
          (delivery) =>
            delivery.template.assessmentType === AssessmentType.Performance
        );
      }
      return undefined;
    }, [assessmentDeliveryData, relatedAssessmentDeliveries]);
  const shouldHavePerformanceAssessmentData = useMemo(() => {
    if (!assessmentDeliveryData?.assessmentDelivery) {
      return false;
    }
    return (
      !!assessmentDeliveryData.assessmentDelivery.complianceProgram
        ?.performanceAssessmentTemplate?.id &&
      assessmentDeliveryData.assessmentDelivery.complianceProgram
        .performanceAssessmentTemplate.delivery !== AssessmentGroupDelivery.None
    );
  }, [assessmentDeliveryData]);
  const managerAssessmentDelivery: AssessmentDeliveryFragment | undefined =
    useMemo(() => {
      if (
        assessmentDeliveryData?.assessmentDelivery?.state ===
        AssessmentDeliveryState.Delivered
      ) {
        if (
          assessmentDeliveryData?.assessmentDelivery?.template
            .assessmentType === AssessmentType.Manager
        ) {
          return assessmentDeliveryData?.assessmentDelivery;
        }
        return relatedAssessmentDeliveries.find(
          (delivery) =>
            delivery.template.assessmentType === AssessmentType.Manager
        );
      }
      return undefined;
    }, [assessmentDeliveryData, relatedAssessmentDeliveries]);
  const shouldHaveManagerAssessmentData = useMemo(() => {
    if (!assessmentDeliveryData?.assessmentDelivery) {
      return false;
    }
    return (
      !!assessmentDeliveryData.assessmentDelivery.complianceProgram
        ?.managerAssessmentTemplate?.id &&
      assessmentDeliveryData.assessmentDelivery.complianceProgram
        .managerAssessmentTemplate.delivery !== AssessmentGroupDelivery.None
    );
  }, [assessmentDeliveryData]);
  const peerAssessmentDelivery: AssessmentDeliveryFragment | undefined =
    useMemo(() => {
      if (
        assessmentDeliveryData?.assessmentDelivery?.state ===
          AssessmentDeliveryState.Delivered &&
        (assessmentDeliveryData?.assessmentDelivery?.template.delivery !==
          AssessmentGroupDelivery.None ||
          isShowingHiddenQuestions)
      ) {
        if (
          assessmentDeliveryData?.assessmentDelivery?.template
            .assessmentType === AssessmentType.Peer
        ) {
          return assessmentDeliveryData?.assessmentDelivery;
        }
        return relatedAssessmentDeliveries.find(
          (delivery) =>
            delivery.template.assessmentType === AssessmentType.Peer &&
            (delivery.template.delivery !== AssessmentGroupDelivery.None ||
              isShowingHiddenQuestions)
        );
      }
      return undefined;
    }, [
      assessmentDeliveryData,
      relatedAssessmentDeliveries,
      isShowingHiddenQuestions,
    ]);
  const shouldHavePeerAssessmentData = useMemo(() => {
    if (!assessmentDeliveryData?.assessmentDelivery) {
      return false;
    }
    return (
      !!assessmentDeliveryData.assessmentDelivery.complianceProgram
        ?.peerAssessmentTemplate?.id &&
      assessmentDeliveryData.assessmentDelivery.complianceProgram
        .peerAssessmentTemplate.delivery !== AssessmentGroupDelivery.None
    );
  }, [assessmentDeliveryData]);

  const isDelivered =
    assessmentDeliveryData?.assessmentDelivery?.state ===
    AssessmentDeliveryState.Delivered;

  const hiddenQuestionCount = useMemo(() => {
    if (!isDelivered && assessmentDeliveryData?.assessmentDelivery) {
      return getHiddenQuestionCount(assessmentDeliveryData.assessmentDelivery);
    }
    let count = 0;
    if (performanceAssessmentDelivery) {
      count += getHiddenQuestionCount(performanceAssessmentDelivery);
    }
    if (managerAssessmentDelivery) {
      count += getHiddenQuestionCount(managerAssessmentDelivery);
    }
    if (peerAssessmentDelivery) {
      count += getHiddenQuestionCount(peerAssessmentDelivery);
    }
    return count;
  }, [
    performanceAssessmentDelivery,
    managerAssessmentDelivery,
    peerAssessmentDelivery,
    isDelivered,
    assessmentDeliveryData,
  ]);

  const isDraft =
    assessmentDeliveryData?.assessmentDelivery?.state ===
    AssessmentDeliveryState.Draft;

  return (
    <Layout className="bg-white">
      {!isInSidebar && (
        <Layout.Header
          breadcrumbs={compact([
            {
              title: label("review", { capitalize: true, pluralize: true }),
              url: "/assessments",
            },
            assessmentDeliveryData && {
              title: `${
                assessmentDeliveryData.assessmentDelivery?.target?.name
              } ${label("review", { capitalize: true })}: ${
                assessmentDeliveryData.assessmentDelivery?.complianceProgram
                  ?.title || "Untitled"
              }`,
              url: `/assessments/assessment/delivery/${assessmentDeliveryId}`,
            },
          ])}
          sidebarExpandedUiPreferenceKey="assessmentDeliverySidebarExpanded"
          className="bg-white"
        />
      )}
      <Layout.Container
        loading={
          isLoadingAssessmentDelivery || isLoadingRelatedAssessmentDeliveries
        }
        noHeader={isInSidebar}
      >
        <Layout.Main
          fullWidth
          sidebarExpandedUiPreferenceKey={
            isInSidebar ? undefined : "assessmentDeliverySidebarExpanded"
          }
        >
          <ReopenConfirmationDialog />
          <div
            className={isInSidebar ? "mt-0" : "mt-8"}
            aria-label="Assessments > performance assessment delivery"
          >
            <div className="flex flex-col">
              {isDelivered && assessmentDeliveryData && proposedDeliveryData && (
                <>
                  <AssessmentDeliveryHeader
                    assessmentDeliveryData={assessmentDeliveryData}
                    onToggleShowHiddenQuestions={setIsShowingHiddenQuestions}
                    isShowingHiddenQuestions={isShowingHiddenQuestions}
                    hiddenQuestionCount={hiddenQuestionCount}
                    isDelivered={isDelivered}
                    onClose={onCloseSidebar}
                  />

                  <CompletedAssessmentDeliveryStack
                    title={
                      assessmentTypeSectionHeadings(label)[
                        AssessmentType.Performance
                      ]
                    }
                    assessmentType={AssessmentType.Performance}
                    assessmentDeliveryData={performanceAssessmentDelivery}
                    isShowingHiddenQuestions={isShowingHiddenQuestions}
                    shouldHaveData={shouldHavePerformanceAssessmentData}
                    onReopen={() =>
                      handleReopen(
                        assertNonNull(performanceAssessmentDelivery?.id)
                      )
                    }
                    isLoading={
                      shouldHavePerformanceAssessmentData &&
                      !performanceAssessmentDelivery &&
                      isLoadingRelatedAssessmentDeliveries
                    }
                  />

                  <CompletedAssessmentDeliveryStack
                    title={
                      assessmentTypeSectionHeadings(label)[
                        AssessmentType.Manager
                      ]
                    }
                    assessmentType={AssessmentType.Manager}
                    assessmentDeliveryData={managerAssessmentDelivery}
                    isShowingHiddenQuestions={isShowingHiddenQuestions}
                    shouldHaveData={shouldHaveManagerAssessmentData}
                    onReopen={() =>
                      handleReopen(assertNonNull(managerAssessmentDelivery?.id))
                    }
                    isLoading={
                      shouldHaveManagerAssessmentData &&
                      !managerAssessmentDelivery &&
                      isLoadingRelatedAssessmentDeliveries
                    }
                  />
                  <CompletedAssessmentDeliveryStack
                    title={
                      assessmentTypeSectionHeadings(label)[AssessmentType.Peer]
                    }
                    assessmentType={AssessmentType.Peer}
                    assessmentDeliveryData={peerAssessmentDelivery}
                    isShowingHiddenQuestions={isShowingHiddenQuestions}
                    shouldHaveData={shouldHavePeerAssessmentData}
                    onReopen={() =>
                      handleReopen(assertNonNull(peerAssessmentDelivery?.id))
                    }
                    isLoading={
                      shouldHavePeerAssessmentData &&
                      !peerAssessmentDelivery &&
                      isLoadingRelatedAssessmentDeliveries
                    }
                  />
                </>
              )}
              {!isDelivered &&
                assessmentDeliveryData &&
                assessmentDeliveryData.assessmentDelivery &&
                proposedDeliveryData && (
                  <>
                    <AssessmentDeliveryHeader
                      assessmentDeliveryData={assessmentDeliveryData}
                      onToggleShowHiddenQuestions={setIsShowingHiddenQuestions}
                      isShowingHiddenQuestions={isShowingHiddenQuestions}
                      hiddenQuestionCount={hiddenQuestionCount}
                    />
                    <AssessmentLayout.AssessmentTypeSection>
                      <AssessmentLayout.AssessmentTypeHeader>
                        {
                          assessmentTypeSectionHeadings(label)[
                            assessmentDeliveryData.assessmentDelivery.template
                              .assessmentType
                          ]
                        }
                      </AssessmentLayout.AssessmentTypeHeader>
                      {isShowingHiddenQuestions && (
                        <AssessmentDeliveryCalibrationChangelog
                          assessmentDeliveryData={
                            assessmentDeliveryData.assessmentDelivery
                          }
                        />
                      )}
                      <div className="mt-12">
                        <AssessmentDeliveryContent
                          assessmentDeliveryData={
                            assessmentDeliveryData.assessmentDelivery
                          }
                          canUpdate={!!canUpdate}
                          isDraft={isDraft}
                          excludedAnswerIds={
                            proposedDeliveryData.excludedAnswerIds
                          }
                          summary={proposedDeliveryData.summary}
                          onUpdateDeliveryData={handleUpdateDeliveryData}
                          showHiddenQuestions={isShowingHiddenQuestions}
                        />
                      </div>

                      <AssessmentDeliveryActions
                        assessmentDeliveryData={assessmentDeliveryData}
                        isSaving={isSaving}
                        backUrl={backUrl}
                        assessmentDeliveryId={assessmentDeliveryId}
                      />
                    </AssessmentLayout.AssessmentTypeSection>
                  </>
                )}
            </div>
          </div>
        </Layout.Main>
        {!isInSidebar && (
          <Layout.Sidebar<AssessmentSidebarTab>
            sidebarExpandedUiPreferenceKey="assessmentDeliverySidebarExpanded"
            className="bg-white"
            onChangeTab={(tab) => {
              if (tab.value) {
                setActiveTab(tab.value);
              }
            }}
            tabs={compact([
              {
                title: AssessmentSidebarTab.artifacts,
                value: AssessmentSidebarTab.artifacts,
                active: activeTab === AssessmentSidebarTab.artifacts,
              },
              organization.featureFlags.workHistory && {
                title: AssessmentSidebarTab.workHistory,
                value: AssessmentSidebarTab.workHistory,
                active: activeTab === AssessmentSidebarTab.workHistory,
              },
              {
                title: AssessmentSidebarTab.askAI,
                value: AssessmentSidebarTab.askAI,
                active: activeTab === AssessmentSidebarTab.askAI,
              },
            ])}
            fullHeight={activeTab === AssessmentSidebarTab.askAI}
          >
            {activeTab === AssessmentSidebarTab.askAI ? (
              <AskAI
                chatSessionId={chatSessionId}
                onChangeChatSessionId={setChatSessionId}
              />
            ) : activeTab === AssessmentSidebarTab.workHistory ? (
              <WorkHistorySidebar
                userId={assertNonNull(
                  assessmentDeliveryData?.assessmentDelivery?.target?.id
                )}
                startDate={
                  program?.periodStartDate ||
                  moment().subtract(7, "days").format("YYYY-MM-DD")
                }
                endDate={
                  program?.periodEndDate || moment().format("YYYY-MM-DD")
                }
              />
            ) : activeTab === AssessmentSidebarTab.artifacts ? (
              <>
                {relatedMeetings.length === 0 &&
                  !organization.featureFlags.goals &&
                  !organization.featureFlags.recognitions &&
                  !organization.featureFlags.feedbacks && (
                    <Layout.SidebarSection>
                      <Layout.SidebarSubSectionEmpty>
                        No related data.
                      </Layout.SidebarSubSectionEmpty>
                    </Layout.SidebarSection>
                  )}
                <RelatedRequirements relatedMeetings={relatedMeetings} />

                {organization.featureFlags.goals && target && (
                  <AssessmentGoalSidebar
                    selectedUser={target}
                    goalDueBetweenDates={complianceProgramPeriod}
                  />
                )}

                {organization.featureFlags.recognitions && target && (
                  <Recognitions
                    selectedUser={target}
                    createdBetweenDates={complianceProgramPeriod}
                  />
                )}
                {organization.featureFlags.feedbacks && target && (
                  <Feedbacks
                    selectedUser={target}
                    createdBetweenDates={complianceProgramPeriod}
                  />
                )}
              </>
            ) : null}
          </Layout.Sidebar>
        )}
      </Layout.Container>
    </Layout>
  );
};

export default AssessmentDelivery;

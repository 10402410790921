import Avatars from "@components/avatar/avatars";
import { classNames } from "@helpers/css";

import { AssessmentAnswerWithResponderAndId } from "./questions/types";

const AssessmentQuestionMultiSelectOptionsCombined = ({
  answers,
  options,
  optionDescriptions,
  onClickAnswer,
}: {
  answers: AssessmentAnswerWithResponderAndId[];
  options: ReadonlyArray<string | null>;
  optionDescriptions: ReadonlyArray<string | null>;
  onClickAnswer?: (index: number) => void;
}) => {
  return (
    <div className="flex flex-col gap-1">
      {options.map((value, index) => {
        const itemAnswers = answers.filter(
          (answer) => answer.choices && answer.choices.includes(index)
        );
        return (
          <button
            key={index}
            className={classNames(
              "flex gap-2 items-start cursor-default rounded p-3 text-sm",
              itemAnswers.length === 0 && "border border-gray-100 bg-gray-50",
              itemAnswers.length > 0 && "bg-blue-100"
            )}
            onClick={(evt) => {
              evt.preventDefault();
              if (onClickAnswer) {
                onClickAnswer(index);
              }
            }}
          >
            <div
              className={classNames(
                "w-64 text-left font-semibold",
                itemAnswers.length === 0 && "text-gray-500",
                itemAnswers.length > 0 && "text-gray-700"
              )}
            >
              {value}
            </div>
            <div className="flex-1 whitespace-pre-line text-left text-gray-500">
              {optionDescriptions[index]}
            </div>
            {itemAnswers.length > 0 && (
              <Avatars
                showNullAsAnonymous
                users={itemAnswers.map((answer) => answer.responder)}
                size={5}
                max={3}
                modalTitle="Responders"
              />
            )}
          </button>
        );
      })}
    </div>
  );
};

export default AssessmentQuestionMultiSelectOptionsCombined;

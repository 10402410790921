import { useMutation } from "@apollo/client";
import { omit } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  AssessmentGroupFragmentFragment,
  AssessmentGroupQuestionInput,
  AssessmentQuestionResponseVisibility,
  AssessmentQuestionResponses,
  AssessmentQuestionType,
  FullAssessmentGroupSectionInput,
  LoggedInUserOrgFragment,
  SaveFullAssessmentGroupMutation,
  SaveFullAssessmentGroupMutationVariables,
} from "types/graphql-schema";

import createOrUpdateFullAssessmentGroupMutation from "@apps/programs/graphql/create-or-update-full-assessment-group-mutation";
import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import FormFields from "@components/form/form-fields";
import Input from "@components/input/input";
import Sidebar from "@components/sidebar/sidebar";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import AssessmentGroupSidebarSection from "./assessment-group-sidebar-section";

const emptyQuestion = (
  questionType: AssessmentQuestionType
): AssessmentGroupQuestionInput => ({
  questionType: AssessmentQuestionType.Range,
  responseVisibility: AssessmentQuestionResponseVisibility.All,
  responses: AssessmentQuestionResponses.Both,
  isCommentMandatory:
    questionType === AssessmentQuestionType.Text ? false : true,
  title: "",
  startValue: questionType === AssessmentQuestionType.Range ? 1 : undefined,
  endValue: questionType === AssessmentQuestionType.Range ? 3 : undefined,
  labels:
    questionType === AssessmentQuestionType.Range ? ["", "", ""] : undefined,
  labelDescriptions:
    questionType === AssessmentQuestionType.Range ? ["", "", ""] : undefined,
  options:
    questionType === AssessmentQuestionType.Multichoice ? ["", ""] : undefined,
  optionDescriptions:
    questionType === AssessmentQuestionType.Multichoice ? ["", ""] : undefined,
});

const parseExistingAssessmentGroup = (
  organization: LoggedInUserOrgFragment,
  assessmentGroup: Omit<AssessmentGroupFragmentFragment, "id"> & {
    id?: number | null;
  }
) => ({
  title: assessmentGroup.title,
  organizationId: organization.id,
  assessmentGroupId: assessmentGroup.id,
  sections: assertEdgesNonNull(assessmentGroup.sections).map((section) => ({
    title: section.title,
    description: section.description,
    questions: assertEdgesNonNull(section.questions).map(({ question }) => ({
      id: question.id,
      title: question.title,
      description: question.description,
      startValue:
        question.__typename === "RangeAssessmentQuestionNode"
          ? question.startValue
          : undefined,
      endValue:
        question.__typename === "RangeAssessmentQuestionNode"
          ? question.endValue
          : undefined,
      isCommentMandatory: question.isCommentMandatory,
      labels:
        question.__typename === "RangeAssessmentQuestionNode"
          ? question.labels
          : undefined,
      labelDescriptions:
        question.__typename === "RangeAssessmentQuestionNode"
          ? question.labelDescriptions
          : undefined,
      options:
        question.__typename === "MultiChoiceAssessmentQuestionNode"
          ? question.options
          : undefined,
      optionDescriptions:
        question.__typename === "MultiChoiceAssessmentQuestionNode"
          ? question.optionDescriptions
          : undefined,
      responses: question.responses,
      responseVisibility: question.responseVisibility,
      questionType: question.questionType,
    })),
  })),
});

const AssessmentGroupSidebar = ({
  onClose,
  assessmentGroup,
  assessmentGroupToDuplicate,
  onAssessmentGroupCreated,
  isEngagementSurveyTemplate,
}: {
  assessmentGroup?: AssessmentGroupFragmentFragment | null;
  assessmentGroupToDuplicate?: AssessmentGroupFragmentFragment | null;
  onClose: () => void;
  onAssessmentGroupCreated: (
    newAssessmentGroup: AssessmentGroupFragmentFragment
  ) => void;
  isEngagementSurveyTemplate?: boolean;
}) => {
  const organization = currentOrganizationVar();
  const focusRef = useRef<HTMLElement>(null);

  const canUpdateAssessmentGroup =
    !assessmentGroup ||
    !!assessmentGroupToDuplicate ||
    !!assessmentGroup?.canUpdate?.permission;

  const [proposedAssessmentGroup, setProposedAssessmentGroup] =
    useState<SaveFullAssessmentGroupMutationVariables>(
      assessmentGroupToDuplicate
        ? parseExistingAssessmentGroup(organization, {
            ...omit(assessmentGroupToDuplicate, ["id"]),
            title: `Copy of ${assessmentGroupToDuplicate.title}`,
          })
        : assessmentGroup
        ? parseExistingAssessmentGroup(organization, assessmentGroup)
        : {
            title: "",
            organizationId: organization.id,
            sections: [{ questions: [] }],
          }
    );

  const [saveAssessmentGroup, { loading: isSaving }] = useMutation<
    SaveFullAssessmentGroupMutation,
    SaveFullAssessmentGroupMutationVariables
  >(createOrUpdateFullAssessmentGroupMutation);

  const handleSave = useCallback(() => {
    saveAssessmentGroup({
      variables: proposedAssessmentGroup,
      onCompleted: (response) => {
        successNotificationVar({
          title: "Question set saved successfully",
          timeout: 2000,
        });
        onAssessmentGroupCreated(
          assertNonNull(
            response.createOrUpdateFullAssessmentGroup?.assessmentGroup
          )
        );
        onClose();
      },
      onError: onNotificationErrorHandler(),
    });
  }, [
    onAssessmentGroupCreated,
    proposedAssessmentGroup,
    saveAssessmentGroup,
    onClose,
  ]);

  const sections = useMemo(() => {
    return proposedAssessmentGroup.sections as FullAssessmentGroupSectionInput[];
  }, [proposedAssessmentGroup]);

  const handleAddSection = useCallback(
    (sectionIndex: number) => {
      const newSection = { questions: [] };
      const newSectionList = [
        ...sections.slice(0, sectionIndex + 1),
        newSection,
        ...sections.slice(sectionIndex + 1),
      ];
      setProposedAssessmentGroup((prev) => ({
        ...prev,
        sections: newSectionList,
      }));
    },
    [sections]
  );

  const handleRemoveSection = useCallback(
    (sectionIndex: number) => {
      setProposedAssessmentGroup((prev) => {
        const newSections = [...sections];
        newSections.splice(sectionIndex, 1);
        return { ...prev, sections: newSections };
      });
    },
    [sections]
  );

  const handleAddQuestion = useCallback(
    (sectionIndex: number, questionType: AssessmentQuestionType) => {
      const newSection = {
        ...sections[sectionIndex],
        questions: [
          ...sections[sectionIndex].questions,
          { ...emptyQuestion(questionType), questionType },
        ],
      };
      setProposedAssessmentGroup((prev) => ({
        ...prev,
        sections: [
          ...sections.slice(0, sectionIndex),
          newSection,
          ...sections.slice(sectionIndex + 1),
        ],
      }));
    },
    [sections]
  );

  const sectionSaveTooltip = sections.reduce((warning, section) => {
    if (warning) {
      return warning;
    }
    return section.questions.some((question) => question?.title.trim() === "")
      ? "Please fill in all question labels"
      : section.questions.length === 0
      ? "Please add at least 1 question to each section"
      : section.questions
          .filter(
            (question) =>
              question?.questionType === AssessmentQuestionType.Multichoice
          )
          .some((question) =>
            question?.options?.some((opt) => opt?.trim() === "")
          )
      ? "All multiple choice options need a label"
      : null;
  }, null as string | null);

  const saveTooltip =
    proposedAssessmentGroup.title?.trim() === ""
      ? "Please enter a title"
      : sectionSaveTooltip
      ? sectionSaveTooltip
      : undefined;

  return (
    <Sidebar
      show
      wide
      title={`${
        proposedAssessmentGroup.assessmentGroupId ? "Edit" : "New"
      } Question Set`}
      className="bg-gray-50"
      onClose={onClose}
      focusRef={focusRef}
      additionalActions={
        <>
          <Button
            disabled={isSaving}
            theme={buttonTheme.text}
            text="Discard"
            onClick={onClose}
          />
          <Button
            disabled={isSaving || !!saveTooltip}
            tooltip={saveTooltip}
            theme="primary"
            text="Save"
            onClick={handleSave}
          />
        </>
      }
    >
      {!canUpdateAssessmentGroup && (
        <div className="m-6 p-4 border rounded border-yellow-300 bg-yellow-50 mt-2 text-gray-600 text-sm">
          <div>
            This question set has already been used in a program so some fields
            cannot be changed. Please take care when modifying question labels
            as it could affect the historical trend analysis.
          </div>
        </div>
      )}
      <div className="p-6">
        <FormFields
          fields={[
            {
              title: "Title",
              render: () => (
                <Input
                  placeholder="Enter a title..."
                  aria-label="Assessment template title input"
                  value={proposedAssessmentGroup.title ?? ""}
                  onChange={(e) =>
                    setProposedAssessmentGroup({
                      ...proposedAssessmentGroup,
                      title: e.target.value,
                    })
                  }
                />
              ),
            },
          ]}
        />
      </div>

      {sections.map((section, sectionIndex) => {
        return (
          <AssessmentGroupSidebarSection
            key={sectionIndex}
            canUpdateAssessmentGroup={canUpdateAssessmentGroup}
            section={section}
            sectionIndex={sectionIndex}
            isEngagementSurveyTemplate={!!isEngagementSurveyTemplate}
            deleteTooltip={
              sections.length <= 1 ? "Need at least 1 section" : undefined
            }
            onAddQuestion={(questionType) =>
              handleAddQuestion(sectionIndex, questionType)
            }
            onAddSectionBelow={() => handleAddSection(sectionIndex)}
            onRemoveSection={() => handleRemoveSection(sectionIndex)}
            onUpdateSection={(newSection) => {
              setProposedAssessmentGroup({
                ...proposedAssessmentGroup,
                sections: [
                  ...sections.slice(0, sectionIndex),
                  newSection,
                  ...sections.slice(sectionIndex + 1),
                ],
              });
            }}
          />
        );
      })}
    </Sidebar>
  );
};

export default AssessmentGroupSidebar;

import { useMutation } from "@apollo/client";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { useCallback } from "react";

import getComplianceProgramProgressQuery from "@apps/programs/graphql/get-compliance-program-progress-query";
import getComplianceProgramQuery from "@apps/programs/graphql/get-compliance-program-query";
import syncComplianceProgramUsersMutation from "@apps/programs/graphql/sync-compliance-program-users-mutation";
import { successNotificationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertNonNull } from "@helpers/helpers";

import {
  GetComplianceProgramQuery,
  SyncComplianceProgramUsersMutationMutation,
  SyncComplianceProgramUsersMutationMutationVariables,
} from "../../../types/graphql-schema";

const ComplianceProgramUserChangesDialog = ({
  complianceProgram,
  onClose,
}: {
  complianceProgram: NonNullable<
    GetComplianceProgramQuery["complianceProgram"]
  >;
  onClose: () => void;
}) => {
  const availableAppliesToUpdates = assertNonNull(
    complianceProgram.availableAppliesToUpdates
  );

  const [syncProgramUsers, { loading }] = useMutation<
    SyncComplianceProgramUsersMutationMutation,
    SyncComplianceProgramUsersMutationMutationVariables
  >(syncComplianceProgramUsersMutation);

  const handleClickConfirm = useCallback(() => {
    syncProgramUsers({
      variables: {
        complianceProgramId: complianceProgram.id,
      },
      onError: onNotificationErrorHandler(),
      onCompleted: () => {
        successNotificationVar({ title: "Program updated" });
        onClose();
      },
      refetchQueries: [
        getComplianceProgramQuery,
        getComplianceProgramProgressQuery,
      ],
    });
  }, [complianceProgram.id, syncProgramUsers, onClose]);

  return (
    <Modal onClose={onClose} className="">
      <div
        className="flex flex-col divide-y text-sm max-h-[90vh] overflow-y-auto"
        aria-label="Program user changes dialog"
      >
        <div className="p-6">
          <ModalTitle onClose={onClose}>
            <div className="flex-col justify-center items-start gap-2 inline-flex">
              <div className="text-gray-500 text-sm leading-tight">
                Program Changes
              </div>
              <div className="justify-start items-center gap-2 inline-flex">
                <div className="text-gray-800 text-lg leading-7">
                  {complianceProgram.title}
                </div>
              </div>
            </div>
          </ModalTitle>
        </div>
        <div className="border-t border-gray-200 flex flex-col gap-6 p-6 justify-start items-start">
          {availableAppliesToUpdates.usersToAdd.length > 0 && (
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch justify-start items-center gap-3">
                <div className="text-gray-600 text-sm font-semibold leading-tight">
                  To be added
                </div>
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-3 flex">
                {availableAppliesToUpdates.usersToAdd.map((user) => (
                  <div
                    className="self-stretch h-7 rounded justify-between items-center inline-flex"
                    key={user.id}
                  >
                    <div className="justify-start items-center gap-2 flex min-w-48">
                      <div className="w-[18px] h-[18px] justify-center items-center flex">
                        <Avatar user={user} tooltipPrefix="" size="5" />
                      </div>
                      <div className="text-gray-600 text-sm font-normal leading-tight">
                        {user.name}
                      </div>
                    </div>
                    <div className="text-gray-400 text-sm font-normal leading-tight text-right">
                      {user.roles.map((role) => role.title).join(", ") ||
                        "No Role"}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {availableAppliesToUpdates.usersToRemove.length > 0 && (
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch justify-start items-center gap-3">
                <div className="text-gray-600 text-sm font-semibold leading-tight">
                  To be removed
                </div>
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-3 flex">
                {availableAppliesToUpdates.usersToRemove.map((user) => (
                  <div
                    className="self-stretch h-7 rounded justify-between items-center inline-flex"
                    key={user.id}
                  >
                    <div className="justify-start items-center gap-2 flex min-w-48">
                      <div className="w-[18px] h-[18px] justify-center items-center flex">
                        <Avatar user={user} tooltipPrefix="" size="5" />
                      </div>
                      <div className="text-gray-600 text-sm font-normal leading-tight">
                        {user.name}
                      </div>
                    </div>
                    <div className="text-gray-400 text-sm font-normal leading-tight text-right">
                      {user.roles.map((role) => role.title).join(", ") ||
                        "No Role"}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {availableAppliesToUpdates.rolesToChange.length > 0 && (
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch justify-start items-center gap-3">
                <div className="text-gray-600 font-semibold text-sm leading-tight">
                  Role Change
                </div>
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-3 flex">
                {availableAppliesToUpdates.rolesToChange.map(
                  (userRoleChange) => (
                    <div
                      className="self-stretch h-7 rounded justify-between items-center inline-flex"
                      key={userRoleChange.user.id}
                    >
                      <div className="justify-start items-center gap-2 flex min-w-48">
                        <div className="w-[18px] h-[18px] justify-center items-center flex">
                          <Avatar
                            user={userRoleChange.user}
                            tooltipPrefix=""
                            size="5"
                          />
                        </div>
                        <div className="text-gray-600 text-sm font-normal leading-tight">
                          {userRoleChange.user.name}
                        </div>
                      </div>
                      <div className="text-gray-400 text-sm font-normal leading-tight text-right">
                        {userRoleChange.oldRoles.length > 0
                          ? userRoleChange.oldRoles
                              .map((role) => role.title)
                              .join(", ")
                          : "No Role"}
                        <ArrowSmRightIcon className="inline h-5 w-5" />
                        {userRoleChange.user.roles.length > 0
                          ? userRoleChange.user.roles
                              .map((role) => role.title)
                              .join(", ")
                          : "No Role"}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
        <div className="p-6 border-t border-gray-200 flex-col justify-start items-start gap-6 inline-flex">
          <div className="self-stretch justify-end items-start gap-3 inline-flex">
            <Button
              theme={buttonTheme.primary}
              onClick={handleClickConfirm}
              disabled={loading}
              text="Confirm & Update Program"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ComplianceProgramUserChangesDialog;

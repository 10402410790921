import { useMutation } from "@apollo/client";
import {
  ChevronDownIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  ExclamationIcon,
  InformationCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import { CheckIcon, SwitchHorizontalIcon } from "@heroicons/react/outline";
import { withErrorBoundary } from "@sentry/react";
import { compact } from "lodash";
import moment from "moment";
import { useState } from "react";
import {
  FinalizePermissions,
  SubmitOneononeNewPageMutation,
  SubmitOneononeNewPageMutationVariables,
  UpdateMeetingFinalizationStatusNewPageMutationMutation,
  UpdateMeetingFinalizationStatusNewPageMutationMutationVariables,
  UserStatus,
} from "types/graphql-schema";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import AssessmentDeliverySidebar from "@apps/assessments/components/delivery/assessment-delivery-sidebar";
import MeetingDropdownMenu from "@apps/meeting-dropdown-menu/meeting-dropdown-menu";
import MeetingContentAccessDropdown from "@apps/meeting-settings/components/content-access-dropdown";
import useLabel, { getLabel } from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Avatars from "@components/avatar/avatars";
import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import DraftLabel from "@components/draft-label/draft-label";
import Error from "@components/error/error";
import AppLink from "@components/link/link";
import AppPopover from "@components/popover/app-popover";
import ProgramIcon from "@components/program-icon/program-icon";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import InfoTooltip from "@components/tooltip/info-tooltip";
import Tooltip from "@components/tooltip/tooltip";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import { ignoredParticipantEmails } from "@helpers/constants";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
  assertNonNull,
  formatTime,
  getUrl,
  removeCurrentYear,
} from "@helpers/helpers";
import { pluralize, titleCase } from "@helpers/string";

import getMeetingQuery from "../graphql/get-meeting-query";
import updateMeetingGroupMutation from "../graphql/update-meeting-group-mutation";
import { getMeetingAllowVisibilityLabel } from "../helpers";
import { MeetingViewEnum } from "./header";
import {
  MeetingVideoConferenceImage,
  getMeetingVideoConferenceName,
} from "./meeting/video-conference-button";
import submitOneononeMutation from "./prep/graphql/submit-oneonone-mutation";
import updateMeetingFinalizationStatusMutation from "./prep/graphql/update-meeting-finalization-status-mutation";
import PublishMeetingButton from "./publish-meeting-button";
import ConvertToReview1On1Banner from "./review/convert-to-review-1-on-1-banner";
import ShareNotesDialog from "./share-meeting/share-meeting-notes-dialog";

export const getFinalizePermissionLabel = (
  finalizePermission: FinalizePermissions
) => {
  const label = getLabel();
  const finalizePermissionLabel =
    finalizePermission === FinalizePermissions.ManagersAndAdmins
      ? "managers and admins"
      : finalizePermission === FinalizePermissions.AdminsOnly
      ? "admins only"
      : finalizePermission === FinalizePermissions.FacilitatorsAndAdmins
      ? "facilitator and admins"
      : finalizePermission === FinalizePermissions.AllParticipants
      ? "all participants"
      : "";
  return `${
    finalizePermissionLabel
      ? `This ${label(
          "oneonone"
        )} can be finalized by ${finalizePermissionLabel}.`
      : ""
  }`;
};

const MeetingDetails = ({
  meeting,
  isInSidebar = false,
  isInExtension = false, // true when this view appears in an extension
  onShowShareNotesDialog,
  onChangeMeetingView,
  meetingView,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  isInSidebar?: boolean; // true when appearing on calendar view or meeting search sidebar
  isInExtension?: boolean; // true when this view appears in an extension
  onShowShareNotesDialog: () => void;
  meetingView: MeetingViewEnum;
  onChangeMeetingView: (option: ToggleButtonGroupType<MeetingViewEnum>) => void;
}) => {
  const [isShowingShareNotesDialog, setIsShowingShareNotesDialog] =
    useState(false);

  const handleShareNotesDialog = () => {
    setIsShowingShareNotesDialog(true);
  };
  const label = useLabel();
  const currentUser = currentUserVar();
  const [isShowingDeliverySidebar, setIsShowingDeliverySidebar] =
    useState(false);

  const participants = assertEdgesNonNullWithStringId(meeting.participants);
  const inactiveUserEmails = participants
    .filter((node) => node.user?.status === UserStatus.Inactive)
    .map((node) => node.participantEmail);
  const avatars = compact(
    participants.map((node) =>
      node.user
        ? {
            ...node.user,
            disabled: node.status === "declined",
            tooltipSuffix: node.status === "declined" ? " (declined)" : "",
          }
        : null
    )
  );

  const participantUsers = compact(participants.map(({ user }) => user));
  const facilitator = meeting?.meetingGroup?.facilitator || participantUsers[0];
  const subject = participantUsers.find(
    ({ id, email }) =>
      id !== facilitator?.id && !ignoredParticipantEmails.includes(email)
  );
  const labelClassName = "w-28 font-medium text-gray-500";
  const contentClassName =
    "flex-1 flex gap-4 items-center flex-wrap gap-y-1 min-h-[28px]";
  const subContentClassName = "flex gap-1.5 items-center";

  const topics = assertEdgesNonNull(meeting.topics);
  const mandatoryTopics = topics.filter((topic) => topic.isMandatory);
  const mandatoryTopicsWithHiddenNotes = mandatoryTopics.filter((node) => {
    const hiddenIndividualNotes =
      node.individualNotes?.edges.filter(
        (edge) => edge?.node?.creator.id === currentUser.id && edge.node.hidden
      ) || [];
    return hiddenIndividualNotes.length > 0;
  });
  const hasMandatoryTopicsWithHiddenNotes =
    mandatoryTopicsWithHiddenNotes.length > 0;

  const finalizePermission =
    meeting.meetingGroup?.topicTemplate?.finalizePermissions ||
    FinalizePermissions.FacilitatorsAndAdmins;
  const [submitOneonone, { loading: loadingSubmit }] = useMutation<
    SubmitOneononeNewPageMutation,
    SubmitOneononeNewPageMutationVariables
  >(submitOneononeMutation);

  const { onError } = useNotificationError({
    errorMatches: [
      {
        match: "Cannot finalize a meeting with incomplete mandatory topics",
        title: "In order to be finalized, all topics must contains notes.",
      },
    ],
  });

  const [toggleFinalizeMeeting, { loading: loadingFinalize }] = useMutation<
    UpdateMeetingFinalizationStatusNewPageMutationMutation,
    UpdateMeetingFinalizationStatusNewPageMutationMutationVariables
  >(updateMeetingFinalizationStatusMutation);

  const [updateMeetingGroup, { loading: loadingSwitch }] = useMutation(
    updateMeetingGroupMutation,
    {
      refetchQueries: [getMeetingQuery],
    }
  );
  if (!meeting.meetingGroup) return null;

  const handleSaveFinalizeMeeting = (newIsFinalized: boolean) => {
    const nonNullTopics = assertNonNull(meeting.topics);
    toggleFinalizeMeeting({
      variables: {
        meetingId: meeting.id,
        isFinalized: newIsFinalized,
      },
      onError: onError,
      optimisticResponse: {
        __typename: "Mutation",
        updateMeetingFinalizationStatus: {
          meeting: {
            ...meeting,
            __typename: "MeetingNode",
            isFinalized: newIsFinalized,
            finalizedAt: newIsFinalized ? moment().format() : null,
            finalizedBy: newIsFinalized ? currentUser : null,
            topics: {
              ...nonNullTopics,
              __typename: "TopicNodeConnection",
              edges: nonNullTopics.edges.map((edge) => {
                const topicEdge = assertNonNull(edge);
                const topicEdgeNode = assertNonNull(topicEdge.node);
                return {
                  ...topicEdge,
                  node: {
                    ...topicEdgeNode,
                    canUpdate: {
                      ...topicEdgeNode.canUpdate,
                      permission: !newIsFinalized,
                    },
                    canDelete: {
                      ...topicEdgeNode.canDelete,
                      permission: !newIsFinalized,
                    },
                  },
                };
              }),
            },
          },
          __typename: "UpdateMeetingFinalizationStatus",
        },
      },
    });
  };

  const handleClickSwitch = () => {
    if (!subject) return;
    if (meeting?.meetingGroup?.isFormalOneonone) {
      updateMeetingGroup({
        variables: {
          meetingGroupId: meeting.meetingGroup.id,
          facilitatorId: subject.id,
        },
        onError: onNotificationErrorHandler(),
        optimisticResponse: {
          updateMeetingGroup: {
            meetingGroup: {
              ...meeting.meetingGroup,
              facilitator: subject,
            },
          },
        },
      });
    }
  };

  const handleClickFinalizeMeeting = () => handleSaveFinalizeMeeting(true);
  const handleClickUndoFinalization = () => handleSaveFinalizeMeeting(false);

  const handleClickSubmit = () => {
    submitOneonone({
      variables: {
        meetingId: meeting.id,
        unhideIndividualNotes: true,
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const assessmentDeliveriesMatching1on1Template =
    meeting?.assessmentDeliveriesMatching1on1Template
      ? assertEdgesNonNull(meeting.assessmentDeliveriesMatching1on1Template)
      : [];
  const assessmentDeliveryMatching1on1Template =
    assessmentDeliveriesMatching1on1Template[0];
  const topicTemplateOfAssessmentDelivery =
    assessmentDeliveryMatching1on1Template?.complianceProgram
      ?.requiredTopicTemplates.edges[0]?.node;
  const meetingGroup = meeting.meetingGroup;

  return (
    <>
      {isShowingShareNotesDialog && (
        <ShareNotesDialog
          meeting={meeting}
          onClose={() => setIsShowingShareNotesDialog(false)}
          isInviteDialog={false}
        />
      )}
      <div
        className={classNames(
          "border-b empty:hidden flex flex-col gap-2 pb-8 text-sm text-gray-900",
          isInExtension && "gap-1 pb-6"
        )}
      >
        <div className="mb-2 flex items-start justify-between gap-2">
          <div className="">
            <AppLink
              target={isInExtension ? "_blank" : undefined}
              to={getUrl({
                meetingGroupId: meeting.meetingGroup.id,
                meetingId: meeting.id,
              })}
              className="hover:underline text-2xl font-semibold align-middle "
            >
              {meeting.title}
            </AppLink>
            {meeting.draft && (
              <div className="align-middle ml-3 inline-flex items-center">
                <DraftLabel className="mt-1" />
              </div>
            )}
          </div>
          {!isInSidebar && (
            <div className="mt-1 flex items-center gap-2 empty:hidden">
              {isInExtension ? null : meeting.draft ? (
                <PublishMeetingButton meeting={meeting}>
                  Publish
                  <span className="hidden @2xl/layout-main:inline">
                    {" "}
                    Meeting
                  </span>
                </PublishMeetingButton>
              ) : meeting.videoConferenceUrl ? (
                <Button
                  className={classNames("gap-2")}
                  tooltip={`Join ${getMeetingVideoConferenceName({
                    meeting,
                    compact: false,
                  })}`}
                  url={meeting.videoConferenceUrl}
                  target="_blank"
                >
                  <MeetingVideoConferenceImage
                    meeting={meeting}
                    className="h-5 w-5"
                  />
                  <span>
                    <span className="hidden @2xl/layout-main:inline">
                      Join{" "}
                    </span>
                    {getMeetingVideoConferenceName({
                      meeting,
                      compact: false,
                    })}
                  </span>
                </Button>
              ) : null}

              <MeetingDropdownMenu
                meeting={meeting}
                meetingGroup={meeting.meetingGroup}
                size="5"
                onOpenSendNotesDialog={handleShareNotesDialog}
              />
            </div>
          )}
        </div>
        <div className="flex gap-2 items-center">
          <div className={labelClassName}>Date</div>
          <div className={contentClassName}>
            {meeting.startDatetime && meeting.endDatetime ? (
              <span className={subContentClassName}>
                {removeCurrentYear(moment(meeting.startDatetime).format("ll"))},{" "}
                {formatTime(moment(meeting.startDatetime).format("h:mma"))}-
                {formatTime(moment(meeting.endDatetime).format("h:mma"))}
              </span>
            ) : (
              <span className={subContentClassName}>Unscheduled</span>
            )}
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className={labelClassName}>Details</div>
          <div className={contentClassName}>
            <span className={subContentClassName}>
              {meeting.meetingGroup.isFormalOneonone ? (
                <UsersIcon className="h-4 w-4 text-gray-400" />
              ) : (
                <UserGroupIcon className="h-4 w-4 text-gray-400" />
              )}
              {meeting.meetingGroup.isFormalOneonone
                ? label("1-on-1", { capitalize: true })
                : "Meeting"}

              {meeting.meetingGroup.isFormalOneonone && (
                <InfoTooltip
                  text={`This ${label(
                    "1-on-1"
                  )} can be finalized by ${getFinalizePermissionLabel(
                    finalizePermission
                  )} Once finalized, the ${label(
                    "1-on-1"
                  )} won't be editable anymore.`}
                />
              )}
            </span>
            {/* Show only when there is a template and it's not the same as the assessment delivery template, otherwise it can create confusion. */}
            {meeting.meetingGroup?.topicTemplate &&
              !topicTemplateOfAssessmentDelivery && (
                <AppLink
                  className={classNames(subContentClassName, "hover:underline")}
                  to={`${getUrl({
                    meetingGroupId: meeting.meetingGroup?.id,
                  })}/settings`}
                >
                  <DocumentDuplicateIcon className="h-4 w-4 text-gray-400" />
                  {meeting.meetingGroup.topicTemplate.publicTemplate
                    ? meeting.meetingGroup?.topicTemplate.title
                    : "Custom template"}
                </AppLink>
              )}
          </div>
        </div>
        {!meeting.meetingGroup.isFormalOneonone && (
          <div className="flex gap-2 items-center">
            <div className={labelClassName}>Participants</div>
            <div className={contentClassName}>
              <Avatars
                users={avatars}
                size={5}
                max={10}
                modalTitle="Participants"
              />
              {!isInExtension && inactiveUserEmails.length > 0 && (
                <Tooltip
                  text={`${inactiveUserEmails.length} ${pluralize(
                    "guest",
                    inactiveUserEmails.length
                  )} in this meeting ${pluralize(
                    "is",
                    inactiveUserEmails.length
                  )}n't ${
                    inactiveUserEmails.length === 1 ? "a" : ""
                  } Topicflow  ${pluralize("user", inactiveUserEmails.length)}`}
                >
                  <button
                    aria-label="Invite non registered users to meeting button"
                    className={classNames(
                      "hidden sm:flex items-center gap-0.5 text-sm text-yellow-600"
                    )}
                    onClick={onShowShareNotesDialog}
                  >
                    <ExclamationIcon className="h-5 w-5" />
                    {inactiveUserEmails.length}
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
        )}

        {waffle.flag_is_active("meeting-controls") && (
          <div className="flex gap-2 items-center">
            <div className={labelClassName}>Permissions</div>
            <div className={classNames(contentClassName, "flex-1 min-w-0")}>
              <MeetingContentAccessDropdown meetingGroup={meetingGroup}>
                <AppPopover.Button
                  as={Button}
                  theme={buttonTheme.text}
                  leftNegativeMargin
                  aria-label="Meeting permission dropdown menu button"
                  disabled={!meetingGroup?.canUpdate?.permission}
                  className="min-w-0" // necessary for truncating text if too long
                >
                  <Tooltip
                    text={titleCase(
                      getMeetingAllowVisibilityLabel({
                        allowManagementTreeVisibility:
                          meetingGroup.allowManagementTreeVisibility,
                        allowAdminVisibility: meetingGroup.allowAdminVisibility,
                        allowOrgVisibility: meetingGroup.allowOrgVisibility,
                      })
                    )}
                  >
                    <span className="flex items-center gap-1 min-w-0 flex-1">
                      {meetingGroup.allowAdminVisibility ||
                      meetingGroup.allowOrgVisibility ? (
                        <LockOpenIcon className="h-4 w-4 text-gray-400 shrink-0" />
                      ) : (
                        <LockClosedIcon className="h-4 w-4 text-gray-400 shrink-0" />
                      )}
                      <span className="truncate flex-1 min-w-0">
                        {titleCase(
                          getMeetingAllowVisibilityLabel(
                            {
                              allowManagementTreeVisibility:
                                meetingGroup.allowManagementTreeVisibility,
                              allowAdminVisibility:
                                meetingGroup.allowAdminVisibility,
                              allowOrgVisibility:
                                meetingGroup.allowOrgVisibility,
                            },
                            true
                          )
                        )}
                      </span>
                      {meetingGroup?.canUpdate?.permission && (
                        <ChevronDownIcon className="h-3 w-3 text-gray-400 shrink-0" />
                      )}
                    </span>
                  </Tooltip>
                </AppPopover.Button>
              </MeetingContentAccessDropdown>
            </div>
          </div>
        )}

        {assessmentDeliveryMatching1on1Template && (
          <>
            {assessmentDeliveryMatching1on1Template.complianceProgram && (
              <div className="flex gap-2 items-center">
                <div className={labelClassName}>Program</div>
                <div className={contentClassName}>
                  <div className={subContentClassName}>
                    <Button
                      url={`/programs/${assessmentDeliveryMatching1on1Template.complianceProgram.id}`}
                      theme={buttonTheme.text}
                      leftNegativeMargin
                    >
                      <ProgramIcon className="h-4 w-4 text-gray-400" />
                      {`Review: ${assessmentDeliveryMatching1on1Template.complianceProgram.title}`}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <div className="flex gap-2 items-center">
              <div className={labelClassName}>
                {label("review", { capitalize: true })}
              </div>
              <div className={contentClassName}>
                <div className={subContentClassName}>
                  <Button
                    to={`/assessments/assessment/delivery/${assessmentDeliveryMatching1on1Template.id}`}
                    theme={buttonTheme.text}
                    leftNegativeMargin
                    onClick={(e) => {
                      if (!e.metaKey) {
                        e.preventDefault();
                        setIsShowingDeliverySidebar(true);
                      }
                    }}
                  >
                    <DocumentTextIcon className="h-4 w-4 text-gray-400" />
                    {`Review: ${assessmentDeliveryMatching1on1Template.target?.name}`}
                  </Button>
                  {isShowingDeliverySidebar && (
                    <AssessmentDeliverySidebar
                      assessmentDeliveryId={
                        assessmentDeliveryMatching1on1Template.id
                      }
                      onClose={() => setIsShowingDeliverySidebar(false)}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {meeting.meetingGroup.isFormalOneonone && (
          <div className="flex gap-2 items-center">
            <div className={labelClassName}>Facilitator</div>
            <div className={contentClassName}>
              <div className={subContentClassName}>
                <Avatar user={facilitator} size={4} />
                {facilitator.name}
                {meeting.canUpdate?.permission && !meeting.isFinalized && (
                  <Tooltip text="Switch facilitator & subject">
                    <button
                      className={classNames(
                        "text-gray-300 p-1 rounded-md",
                        loadingSwitch && "hover:text-gray-800 hover:bg-gray-100"
                      )}
                      onClick={handleClickSwitch}
                      disabled={loadingSwitch}
                    >
                      <SwitchHorizontalIcon className="h-4 w-4" />
                    </button>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        )}
        {meeting.meetingGroup.isFormalOneonone && (
          <div className="flex gap-2 items-center">
            <div className={labelClassName}>Subject</div>
            <div className={contentClassName}>
              <div className={subContentClassName}>
                <Avatar user={subject} size={4} />
                {subject?.name}
              </div>
            </div>
          </div>
        )}
        {meeting.meetingGroup.isFormalOneonone && meeting.isFinalized && (
          <div className="flex gap-2 items-center">
            <div className={labelClassName}>Finalized</div>
            <div className={contentClassName}>
              <div className={subContentClassName}>
                <CheckIcon className="h-4 w-4 text-emerald-700 inline-block" />
                {label("oneonone", { capitalize: true })} finalized{" "}
                {meeting.finalizedAt ? (
                  <span>
                    <time
                      dateTime={meeting.finalizedAt}
                      className="underline decoration-dotted text-gray-700"
                    >
                      {moment(meeting.finalizedAt).format("lll")}
                    </time>
                  </span>
                ) : null}
                {meeting.finalizedBy ? ` by ${meeting.finalizedBy.name}` : ""}.
                <Button
                  theme={buttonTheme.lightBlue}
                  size={ButtonSize.small}
                  text="Undo"
                  onClick={handleClickUndoFinalization}
                  disabled={loadingFinalize}
                />
              </div>
            </div>
          </div>
        )}
        {meeting.hasExternalParticipants && (
          <div className="flex gap-2 items-center bg-amber-100 text-amber-700 px-3 py-2 rounded-md mt-4">
            <InformationCircleIcon className="h-4 w-4 text-amber-700" />
            External participants can see this meeting's notes and summary.
          </div>
        )}

        {meeting.meetingGroup.isFormalOneonone &&
          meeting.canUpdate?.permission &&
          meeting.assessmentDeliveriesMatching1on1Template.edges.length ===
            0 && <ConvertToReview1On1Banner meeting={meeting} />}
      </div>

      <div className="flex flex-col gap-4 pt-6 pb-2 ">
        <div className="@container/toggle-button-group">
          <div className="flex gap-4 items-center justify-between">
            <div
              className={classNames(
                "flex items-center text-sm text-gray-600",
                meeting.canUpdate?.permission ? "gap-2" : "gap-4"
              )}
            >
              <ToggleButtonGroup<MeetingViewEnum>
                theme={ToggleButtonGroupTheme.separatedWhiteButtons}
                buttons={compact([
                  meeting.hasPreviousMeetings &&
                    meeting.previousMeeting?.id && {
                      value: MeetingViewEnum.previousMeetingSummary,
                      title: MeetingViewEnum.previousMeetingSummary,
                      active:
                        MeetingViewEnum.previousMeetingSummary === meetingView,
                    },
                  {
                    value: MeetingViewEnum.notes,
                    title: (
                      <span>
                        Notes
                        <span className="hidden @lg/toggle-button-group:inline">
                          {" "}
                          & Agenda
                        </span>
                      </span>
                    ),
                    active: MeetingViewEnum.notes === meetingView,
                  },
                  {
                    value: MeetingViewEnum.summary,
                    title: MeetingViewEnum.summary,
                    active: MeetingViewEnum.summary === meetingView,
                  },
                ])}
                onClick={onChangeMeetingView}
              />
            </div>

            {meeting.meetingGroup.isFormalOneonone && (
              <div className="flex items-center gap-3">
                {!meeting.isFinalized &&
                meeting.hasIncompleteMandatoryTopics &&
                meeting.canFinalize ? (
                  <div className="relative">
                    <AppPopover
                      options={{
                        className: classNames(AppPopover.className, "w-144"),
                      }}
                      content={
                        <div className="divide-y flex flex-col w-144">
                          <div className="text-gray-700 font-semibold p-5">
                            Mandatory Topics are Incomplete
                          </div>
                          <div className="p-5 text-gray-500 flex flex-col gap-4">
                            <div>
                              You must add notes to all the topics with a{" "}
                              <span className="text-xs bg-amber-100 text-amber-700 px-1 py-0.5">
                                Mandatory
                              </span>{" "}
                              tag, and make sure that they are not set to "Make
                              private".
                            </div>
                            {hasMandatoryTopicsWithHiddenNotes && (
                              <div>
                                <Button
                                  text="Reveal my notes to all participants"
                                  onClick={handleClickSubmit}
                                  theme={buttonTheme.lightBlue}
                                  disabled={loadingSubmit}
                                />
                              </div>
                            )}
                          </div>
                          <div className="text-gray-700 font-semibold p-5">
                            Mandatory Topics are Incomplete
                          </div>
                        </div>
                      }
                    >
                      <AppPopover.Button
                        as={Button}
                        theme={buttonTheme.lightBlue}
                        text="Finalize"
                      />
                    </AppPopover>
                  </div>
                ) : !meeting.isFinalized && meeting.canFinalize ? (
                  <Button
                    theme={buttonTheme.lightBlue}
                    text="Finalize"
                    disabled={
                      loadingFinalize || hasMandatoryTopicsWithHiddenNotes
                    }
                    onClick={handleClickFinalizeMeeting}
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withErrorBoundary(MeetingDetails, {
  fallback: <Error description={"The meeting could not be rendered."} />,
});

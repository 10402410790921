import { useQuery } from "@apollo/client";
import { ChevronDoubleDownIcon } from "@heroicons/react/outline";
import { compact, uniq, uniqBy } from "lodash";
import {
  AssessmentDeliveryState,
  AssessmentGroupAnonymity,
  AssessmentGroupDelivery,
  GetReviewSubmissionsQuery,
  GetReviewSubmissionsQueryVariables,
  RelatedAssessmentDeliveryFragment,
  RelatedAssessmentFragment,
  RelatedAssessmentTemplateFragment,
  RelatedAssessmentUserMissingAssessmentFragment,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import getReviewSubmissionsQuery from "@apps/assessments/graphql/get-review-submissions-query";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar, isAdminVar } from "@cache/cache";
import Avatars from "@components/avatar/avatars";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import StatusPill, {
  StatusPillEnum,
} from "@components/status-pill/status-pill";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getAssessmentTypeLabel } from "@helpers/constants";
import { assertEdgesNonNull } from "@helpers/helpers";

const getAsssessmentStatusAndLabel = (
  isManagerOfTargetOrAdmin: boolean,
  submittedAssessmentsCount: number,
  missingAssessmentsCount: number,
  delivery?: RelatedAssessmentDeliveryFragment
): {
  status: StatusPillEnum;
  statusLabel: string;
} => {
  // If one has a delivery, then we consider it delivered
  // If not a manager and not admin, then we considered not delivered
  // If a manager or admin, then we can try to infer progress
  const totalAssessmentsCount =
    submittedAssessmentsCount + missingAssessmentsCount;
  let status = StatusPillEnum.NotApplicable;
  let statusLabel = "Not Delivered";

  if (delivery?.state === AssessmentDeliveryState.Delivered) {
    status = StatusPillEnum.Complete;
    statusLabel = "Delivered";
  } else if (isManagerOfTargetOrAdmin && totalAssessmentsCount > 0) {
    statusLabel = `${submittedAssessmentsCount}/${totalAssessmentsCount} Submitted`;
    if (submittedAssessmentsCount > 0) {
      status = StatusPillEnum.InProgress;
    }
  } else if (totalAssessmentsCount === 0) {
    statusLabel = "Not Applicable";
  }
  return {
    status,
    statusLabel,
  };
};

const RelatedSubmissionTypeItem = ({
  target,
  template,
  deliveries,
  usersMissingAssessment,
  relatedAssessments,
  showButtonToScroll,
}: {
  target: BasicUser;
  template: RelatedAssessmentTemplateFragment;
  deliveries: RelatedAssessmentDeliveryFragment[];
  usersMissingAssessment: RelatedAssessmentUserMissingAssessmentFragment[];
  relatedAssessments: RelatedAssessmentFragment[];
  showButtonToScroll?: boolean;
}) => {
  const label = useLabel();
  const currentUser = currentUserVar();
  const isAdmin = isAdminVar();
  const targetIsDirectReport = currentUser?.directReports?.edges?.some(
    (edge) => edge?.node?.id === target.id
  );
  const isManagerOfTargetOrAdmin = isAdmin || targetIsDirectReport;

  const usersMissingAssessmentForTemplate = usersMissingAssessment.filter(
    (a) => a.assessmentTemplate.assessmentType === template.assessmentType
  );
  const relatedAssessmentsForTemplate = relatedAssessments.filter(
    (a) => a.template.assessmentType === template.assessmentType
  );
  const delivery = deliveries.find(
    (d) => d.template.assessmentType === template.assessmentType
  );
  const deliveryVisibleResponders = delivery?.visibleResponders
    ? assertEdgesNonNull(delivery.visibleResponders)
    : [];

  // concatenate different sources of responders
  const responders = uniqBy(
    compact([
      ...deliveryVisibleResponders,
      ...relatedAssessmentsForTemplate.map((a) => a.responder),
      ...usersMissingAssessmentForTemplate.map((a) => a?.responder),
    ]),
    "id"
  );

  const { status, statusLabel } = getAsssessmentStatusAndLabel(
    isManagerOfTargetOrAdmin,
    relatedAssessmentsForTemplate.length,
    usersMissingAssessmentForTemplate.length,
    delivery
  );

  const hasSelfAssessment = responders.some((r) => r.id === target.id);
  const title = compact(
    uniq([
      hasSelfAssessment &&
        getAssessmentTypeLabel(template.assessmentType, true),
      getAssessmentTypeLabel(template.assessmentType, false),
    ])
  ).join(" & ");

  const handleClickScroll = () => {
    document
      .getElementById(`container-assessment-type-${template.assessmentType}`)
      ?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Layout.MainSubSectionListItem>
      <div className="flex items-center gap-3">
        <div className="w-48 text-gray-500">{title}</div>
        <div className="flex items-center gap-2">
          <StatusPill label={statusLabel} status={status} />

          {isManagerOfTargetOrAdmin &&
            template.delivery === AssessmentGroupDelivery.AdminApproval &&
            delivery && (
              <>
                <StatusPill
                  label={
                    delivery.state ===
                    AssessmentDeliveryState.AwaitingAdminApproval
                      ? "Awaiting Approval"
                      : "Approved"
                  }
                  status={
                    delivery.state ===
                    AssessmentDeliveryState.AwaitingAdminApproval
                      ? StatusPillEnum.NotApplicable
                      : StatusPillEnum.Complete
                  }
                />
              </>
            )}
        </div>
        <div className="flex-1 flex justify-end gap-3 items-center">
          {(template.anonymity === AssessmentGroupAnonymity.Anonymous ||
            (!isManagerOfTargetOrAdmin &&
              template.anonymity ===
                AssessmentGroupAnonymity.SemiAnonymous)) && (
            <Tooltip
              text={`Responders are anonymous for this ${label("assessment")}.`}
            >
              <div className="text-gray-400 text-sm">
                {template.anonymity === AssessmentGroupAnonymity.Anonymous
                  ? "Anonymous"
                  : "Semi-Anonymous"}
              </div>
            </Tooltip>
          )}
          {responders.length === 0 ? null : (
            <Avatars
              users={responders}
              max={5}
              size={6}
              modalTitle="Responders"
            />
          )}
        </div>
        {showButtonToScroll && (
          <Button
            theme={buttonTheme.iconGray}
            icon={ChevronDoubleDownIcon}
            onClick={handleClickScroll}
          />
        )}
      </div>
    </Layout.MainSubSectionListItem>
  );
};

const RelatedSubmissions = ({
  target,
  assessmentId,
  assessmentDeliveryId,
  showButtonToScroll,
}: {
  target: BasicUser;
  assessmentId?: number;
  assessmentDeliveryId?: number;
  showButtonToScroll?: boolean;
}) => {
  const label = useLabel();
  const currentUser = currentUserVar();
  const isAdmin = isAdminVar();
  const isCurrentUserTarget = currentUser?.id === target.id;
  const targetIsDirectReport = currentUser?.directReports?.edges?.some(
    (edge) => edge?.node?.id === target.id
  );
  const canViewRelatedAssessments =
    (isAdmin || targetIsDirectReport || isCurrentUserTarget) &&
    waffle.flag_is_active("review-submissions");

  const { data, loading } = useQuery<
    GetReviewSubmissionsQuery,
    GetReviewSubmissionsQueryVariables
  >(getReviewSubmissionsQuery, {
    skip: !canViewRelatedAssessments,
    variables: {
      targetId: target.id,
      assessmentId: assessmentId || -1,
      fetchAssessment: assessmentId !== undefined,
      assessmentDeliveryId: assessmentDeliveryId || -1,
      fetchAssessmentDelivery: assessmentDeliveryId !== undefined,
    },
    onError: onNotificationErrorHandler(),
  });

  if (!canViewRelatedAssessments) {
    return null;
  }

  const assessment = data?.assessment;
  const assessmentDelivery = data?.assessmentDelivery;
  const complianceProgram = assessmentDeliveryId
    ? assessmentDelivery?.complianceProgram
    : assessment?.complianceProgram;

  // templates
  const performanceTemplate = complianceProgram?.performanceAssessmentTemplate;
  const peerTemplate = complianceProgram?.peerAssessmentTemplate;
  const managerTemplate = complianceProgram?.managerAssessmentTemplate;

  const assessmentDeliveries = complianceProgram?.assessmentDeliveries
    ? assertEdgesNonNull(complianceProgram.assessmentDeliveries)
    : [];
  const usersMissingAssessment = complianceProgram?.usersMissingAssessment
    ? compact(
        complianceProgram?.usersMissingAssessment?.edges.map(
          (edge) => edge?.node
        )
      )
    : [];
  const relatedAssessments = complianceProgram?.assessments
    ? assertEdgesNonNull(complianceProgram.assessments)
    : [];

  return (
    <Layout.MainSubSection
      className="mt-6"
      title={`${label("review", {
        capitalize: true,
        pluralize: true,
      })} Submissions`}
      collapsible
      defaultIsExpanded
      loading={loading}
    >
      {!loading ? (
        <Layout.MainSubSectionList>
          {performanceTemplate && (
            <RelatedSubmissionTypeItem
              target={target}
              template={performanceTemplate}
              usersMissingAssessment={usersMissingAssessment}
              relatedAssessments={relatedAssessments}
              deliveries={assessmentDeliveries}
              showButtonToScroll={showButtonToScroll}
            />
          )}
          {managerTemplate && (
            <RelatedSubmissionTypeItem
              target={target}
              template={managerTemplate}
              usersMissingAssessment={usersMissingAssessment}
              relatedAssessments={relatedAssessments}
              deliveries={assessmentDeliveries}
              showButtonToScroll={showButtonToScroll}
            />
          )}
          {peerTemplate && (
            <RelatedSubmissionTypeItem
              target={target}
              usersMissingAssessment={usersMissingAssessment}
              relatedAssessments={relatedAssessments}
              template={peerTemplate}
              deliveries={assessmentDeliveries}
              showButtonToScroll={showButtonToScroll}
            />
          )}
        </Layout.MainSubSectionList>
      ) : null}
    </Layout.MainSubSection>
  );
};

export default RelatedSubmissions;

import { useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import {
  GetMeetingNewPageQueryQuery,
  GetMeetingNewPageQueryQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import Container from "@components/container/container";
import GraphqlError from "@components/error/graphql-error";
import Layout from "@components/layout/layout";
import Loading from "@components/loading/loading";
import { ToggleButtonGroupType } from "@components/toggle-button-group/toggle-button-group";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import usePageVisible from "@helpers/hooks/use-page-visible";

import getMeetingQuery from "../graphql/get-meeting-query";
import MeetingDetails from "./details";
import MeetingPageHeader, { MeetingViewEnum } from "./header";
import MeetingBreadcrumb from "./meeting-breadcrumb";
import MeetingViewSidebar from "./meeting-sidebar/meeting-view-sidebar";
import MeetingTopics from "./meeting-topics";
import PreviousMeetingSummary from "./prep/previous-meeting-summary";
import MeetingSummary from "./prep/summary";
import ShareNotesDialog from "./share-meeting/share-meeting-notes-dialog";

export const MeetingPageContent = ({
  meeting,
  meetingGroupId,
  isInSidebar = false,
  isInExtension = false,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  meetingGroupId: number;
  isInSidebar?: boolean;
  isInExtension?: boolean;
}) => {
  const [meetingView, setMeetingView] = useState(MeetingViewEnum.notes);

  const [isShowingShareNotesDialog, setIsShowingShareNotesDialog] =
    useState(false);

  const handleShareNotesDialog = () => {
    setIsShowingShareNotesDialog(true);
  };

  const handleCloseInvitationDialog = () => {
    setIsShowingShareNotesDialog(false);
  };

  const handleChangeMeetingView = (
    option: ToggleButtonGroupType<MeetingViewEnum>
  ) => {
    if (option?.value) setMeetingView(option.value);
  };

  return (
    <>
      {isShowingShareNotesDialog && (
        <ShareNotesDialog
          meeting={meeting}
          onClose={handleCloseInvitationDialog}
          isInviteDialog={false}
        />
      )}
      <MeetingDetails
        isInSidebar={isInSidebar}
        isInExtension={isInExtension}
        meeting={meeting}
        onShowShareNotesDialog={handleShareNotesDialog}
        meetingView={meetingView}
        onChangeMeetingView={handleChangeMeetingView}
      />
      {meetingView === MeetingViewEnum.notes ? (
        <MeetingTopics
          meeting={meeting}
          opensTopicsInSidebar={false}
          currentPageMeeting={meeting}
        />
      ) : meetingView === MeetingViewEnum.summary ? (
        <MeetingSummary meeting={meeting} meetingGroupId={meetingGroupId} />
      ) : meetingView === MeetingViewEnum.previousMeetingSummary ? (
        <PreviousMeetingSummary
          meeting={meeting}
          meetingGroupId={meetingGroupId}
        />
      ) : null}
    </>
  );
};

const MeetingPage = ({
  meetingId,
  hideSidebar = false,
}: {
  meetingId: number;
  hideSidebar?: boolean;
}) => {
  const lastTimeVisible = useRef<number>(Date.now());
  const isPageVisible = usePageVisible();

  const { loading, error, data, refetch } = useQuery<
    GetMeetingNewPageQueryQuery,
    GetMeetingNewPageQueryQueryVariables
  >(getMeetingQuery, {
    variables: { meetingId },
    onError: onNotificationErrorHandler(),
  });

  useEffect(() => {
    if (isPageVisible) {
      if (lastTimeVisible.current + 5 * 60 * 1000 < Date.now()) {
        // if page was not visible for more than 5 minutes we force a refresh
        refetch();
      }
      lastTimeVisible.current = Date.now();
    }
  }, [refetch, isPageVisible]);

  const meeting = data?.meeting;
  const meetingGroup = meeting?.meetingGroup;
  const title = meeting?.title || meetingGroup?.title || "";
  useDocumentTitle(title);

  // Renders
  if (!data && loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  if (error || !data || !meeting || !meetingGroup) {
    return (
      <Container>
        <GraphqlError
          error={error}
          whatDidNotWork="The meeting could not be loaded."
        />
      </Container>
    );
  }

  return (
    <>
      <Layout.Header
        className="bg-white"
        sidebarExpandedUiPreferenceKey={
          hideSidebar ? undefined : "meetingSidebarExpanded"
        }
        title={<MeetingBreadcrumb meeting={meeting} />}
      >
        <MeetingPageHeader meetingGroup={meetingGroup} meeting={meeting} />
      </Layout.Header>
      <Layout.Container>
        <Layout.Main
          sidebarExpandedUiPreferenceKey={
            hideSidebar ? undefined : "meetingSidebarExpanded"
          }
        >
          <div className="px-4 @xl/layout-main:px-8 @3xl/layout-main:px-16">
            <div className="mx-auto max-w-screen-lg pt-12">
              <MeetingPageContent
                meeting={meeting}
                meetingGroupId={meetingGroup.id}
              />
            </div>
          </div>
        </Layout.Main>
        {!hideSidebar && <MeetingViewSidebar meeting={meeting} />}
      </Layout.Container>
    </>
  );
};

export default MeetingPage;

import { XIcon } from "@heroicons/react/solid";
import { PropsWithChildren } from "react";

import { classNames } from "@helpers/css";

const ModalTitle = ({
  onClose,
  children,
  className,
}: PropsWithChildren<{
  onClose?: () => void;
  className?: string;
}>) => (
  <div
    className={classNames("flex items-center justify-between gap-2", className)}
  >
    <h3 className="flex-1 text-lg leading-6 font-medium text-gray-900">
      {children}
    </h3>
    {onClose && (
      <button
        className="text-gray-500 hover:text-gray-800 hover:bg-gray-100 rounded-full p-1"
        onClick={onClose}
        aria-label="Close modal button"
      >
        <span className="sr-only">Close topic sidebar</span>
        <XIcon className="h-6 w-6" />
      </button>
    )}
  </div>
);

export default ModalTitle;

import { gql } from "@apollo/client";

export default gql`
  fragment ComplianceProgramListItem on ComplianceProgramNode {
    id
    title
    created
    updated
    dueDate
    startDate
    ongoing
    periodStartDate
    periodEndDate
    state
    recurrence
    requiredTopicTemplates {
      edges {
        node {
          id
          title
        }
      }
    }
    canUpdate {
      permission
    }
    canDelete {
      permission
    }
    performanceAssessmentTemplate {
      id
    }
    managerAssessmentTemplate {
      id
    }
    peerAssessmentTemplate {
      id
    }
  }
  query getCompliancePrograms(
    $applicableUser: Int
    $organizationId: Int!
    $state: ComplianceProgramState
    $state_In: [ComplianceProgramState]
    $assessmentType: AssessmentType
    $search: String
    $after: String
    $dueDateGte: Date
    $dueDateLte: Date
  ) {
    detailedCompliancePrograms(
      applicableUser: $applicableUser
      organizationId: $organizationId
      state: $state
      state_In: $state_In
      search: $search
      assessmentType: $assessmentType
      orderBy: "state,-due_date"
      first: 10
      after: $after
      dueDate_Gte: $dueDateGte
      dueDate_Lte: $dueDateLte
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...ComplianceProgramListItem
        }
      }
    }
  }
`;

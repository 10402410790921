import { useMutation, useQuery } from "@apollo/client";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { withErrorBoundary } from "@sentry/react";
import a from "indefinite";
import { compact } from "lodash";
import { useCallback, useState } from "react";
import {
  GetActiveComplianceProgramWithUnmetTopicTemplatesQuery,
  GetActiveComplianceProgramWithUnmetTopicTemplatesQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import getActiveComplianceProgramWithUnmetTopicTemplatesQuery from "@apps/meeting-new/graphql/get-active-compliance-program-with-unmet-topic-templates-query";
import getMeetingQuery from "@apps/meeting-new/graphql/get-meeting-query";
import useLabel from "@apps/use-label/use-label";
import { errorNotificationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Error from "@components/error/error";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import Select from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { ignoredParticipantEmails } from "@helpers/constants";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
} from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import convertToReviewMeetingMutation from "./graphql/convert-to-review-meeting-mutation";

const MeetingConvertToReview1on1Banner = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const [selectedProgramId, setSelectedProgramId] = useState<number | null>(
    null
  );
  const [
    isShowingConvertToReviewMeetingDialog,
    setIsShowingConvertToReviewMeetingDialog,
  ] = useState(false);
  const label = useLabel();
  const { confirm, ConfirmationDialog } = useConfirm(
    "Are you sure you want to remove all existing topics?",
    "This will remove all existing topics and replace them with the topics from the program required template."
  );

  const topics = assertEdgesNonNull(meeting.topics);
  const participants = assertEdgesNonNullWithStringId(meeting.participants);

  const participantUsers = compact(participants.map(({ user }) => user));
  const facilitator = meeting?.meetingGroup?.facilitator || participantUsers[0];
  const subject = participantUsers.find(
    ({ id, email }) =>
      id !== facilitator?.id && !ignoredParticipantEmails.includes(email)
  );

  const assessmentDeliveriesMatching1on1Template =
    meeting?.assessmentDeliveriesMatching1on1Template
      ? assertEdgesNonNull(meeting.assessmentDeliveriesMatching1on1Template)
      : [];
  const assessmentDeliveryMatching1on1Template =
    assessmentDeliveriesMatching1on1Template.at(0);

  const checkActiveComplianceProgramsWithUnmetTopicTemplates =
    meeting.canUpdate?.permission &&
    meeting.meetingGroup &&
    meeting.meetingGroup.isFormalOneonone &&
    !assessmentDeliveryMatching1on1Template;

  const { data } = useQuery<
    GetActiveComplianceProgramWithUnmetTopicTemplatesQuery,
    GetActiveComplianceProgramWithUnmetTopicTemplatesQueryVariables
  >(getActiveComplianceProgramWithUnmetTopicTemplatesQuery, {
    skip: !checkActiveComplianceProgramsWithUnmetTopicTemplates,
    variables: { meetingId: meeting.id },
    onError: onNotificationErrorHandler(),
  });
  const activeComplianceProgramsWithUnmetTopicTemplates = data?.meeting
    ?.activeComplianceProgramsWithUnmetTopicTemplates
    ? assertEdgesNonNull(
        data.meeting.activeComplianceProgramsWithUnmetTopicTemplates
      )
    : [];
  const selectedProgram = activeComplianceProgramsWithUnmetTopicTemplates.find(
    (program) => program.id === selectedProgramId
  );
  const requiredTopicTemplateId =
    selectedProgram?.requiredTopicTemplates.edges[0]?.node?.id;

  const [convertToReviewMeeting, { loading: convertToReviewMeetingLoading }] =
    useMutation(convertToReviewMeetingMutation);

  const handleAddTemplateToMeeting = useCallback(
    (clearExistingTopics: boolean) => {
      if (!requiredTopicTemplateId) {
        return;
      }
      convertToReviewMeeting({
        variables: {
          meetingId: meeting.id,
          topicTemplateId: requiredTopicTemplateId,
          clearExistingTopics,
        },
        onError: onNotificationErrorHandler(),
        onCompleted: () => {
          setIsShowingConvertToReviewMeetingDialog(false);
          successNotificationVar({
            title: `Converted to ${label("review", {
              capitalize: true,
            })} Meeting`,
            timeout: 10000,
          });
        },
        refetchQueries: [getMeetingQuery],
      });
    },
    [convertToReviewMeeting, label, meeting.id, requiredTopicTemplateId]
  );

  const handleClickClearAllTopicsAndConvertToReviewMeeting =
    useCallback(async () => {
      const confirmation = await confirm();
      if (confirmation) {
        handleAddTemplateToMeeting(true);
      }
    }, [confirm, handleAddTemplateToMeeting]);

  const handleClickAddReviewTopicsToMeeting = () => {
    handleAddTemplateToMeeting(false);
  };

  const handleClickConvertToReviewMeeting = () => {
    if (meeting.draft && (!meeting.startDatetime || !meeting.endDatetime)) {
      errorNotificationVar({
        title: `Please schedule this ${label(
          "1-on-1"
        )} meeting before converting to a ${label("review")} meeting.`,
      });
      return;
    }
    setIsShowingConvertToReviewMeetingDialog(true);
  };

  if (!checkActiveComplianceProgramsWithUnmetTopicTemplates) {
    return null;
  }
  // no matching programs
  if (activeComplianceProgramsWithUnmetTopicTemplates.length === 0) {
    return null;
  }

  return (
    <div className="mt-4 flex items-center gap-6 justify-between pl-4 pr-6 py-4 bg-blue-50 rounded-lg">
      <div className="flex items-start gap-2">
        <InformationCircleIcon className="w-5 h-5 text-blue-400 shrink-0" />
        <div>
          <div className="text-sm font-medium text-gray-800">
            Looks like you haven't scheduled{" "}
            {a(label("review", { capitalize: true }))} Meeting.
          </div>
          <div className="mt-1 text-sm text-gray-800">
            Turn this {label("1-on-1", { capitalize: true })} Meeting into{" "}
            {a(label("review", { capitalize: true }))} Meeting to go over{" "}
            {subject?.name}'s performance review.
          </div>
        </div>
      </div>
      <div>
        <Button
          className="shrink-0 whitespace-nowrap"
          theme={buttonTheme.lightBlue}
          text={`Convert to ${label("review", { titleCase: true })} Meeting`}
          onClick={handleClickConvertToReviewMeeting}
        />
      </div>
      {isShowingConvertToReviewMeetingDialog && (
        <Modal
          alignedTop
          onClose={() => setIsShowingConvertToReviewMeetingDialog(false)}
        >
          <div className="flex flex-col divide-y text-sm">
            <div className="p-6">
              <ModalTitle
                onClose={() => setIsShowingConvertToReviewMeetingDialog(false)}
              >
                Convert to {label("review", { titleCase: true })} Meeting
              </ModalTitle>
              <div className="text-sm text-gray-800 mt-3">
                This {label("1-on-1", { capitalize: true })} will be converted
                to {a(label("review", { capitalize: true }))} Meeting.
                {topics.length > 0
                  ? ` You can overwrite or add to the existing ${label(
                      "1-on-1"
                    )} topics.`
                  : ""}
              </div>
            </div>
            <div className="p-6 flex gap-4 items-center">
              <div className="w-24 text-gray-500 font-medium">Program</div>
              <div className="flex-1">
                <Select
                  placeholder="Select a program..."
                  options={activeComplianceProgramsWithUnmetTopicTemplates.map(
                    (program) => ({
                      value: program.id,
                      label: program.title,
                    })
                  )}
                  value={selectedProgramId}
                  onChange={(option) => {
                    setSelectedProgramId(option.value);
                  }}
                />
              </div>
            </div>
            <div className="p-6 flex justify-end gap-4">
              {convertToReviewMeetingLoading && <Loading mini size={5} />}
              {topics.length > 0 ? (
                <>
                  <ConfirmationDialog />
                  <Button
                    theme={buttonTheme.redDanger}
                    text="Overwrite Existing Topics"
                    onClick={handleClickClearAllTopicsAndConvertToReviewMeeting}
                    disabled={!selectedProgramId}
                  />
                  <Button
                    theme={buttonTheme.primary}
                    text={`Add to Existing Topics`}
                    disabled={!selectedProgramId}
                    onClick={handleClickAddReviewTopicsToMeeting}
                  />
                </>
              ) : (
                <Button
                  theme={buttonTheme.primary}
                  text={`Convert to ${label("review", {
                    capitalize: true,
                  })} Meeting`}
                  disabled={!selectedProgramId}
                  onClick={handleClickAddReviewTopicsToMeeting}
                />
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default withErrorBoundary(MeetingConvertToReview1on1Banner, {
  fallback: <Error description={"The meeting could not be rendered."} />,
});

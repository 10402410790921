import { useMutation, useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import googleLogo from "@static/img/google-24@2x.png";
import {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  ReactElement,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import store from "store2";
import {
  GetKpiQueryQuery,
  GetKpiQueryQueryVariables,
  GithubIntegrationMetricType,
  GithubIntegrationTimeBucket,
  HubspotIntegrationMetricType,
  HubspotIntegrationTimeBucket,
  KpiIntegrationType,
  KpiSummaryMethod,
  KpiSummaryPeriod,
  KpiTargetCondition,
  KpiTargetType,
  KpiVisibility,
  TopicflowIntegrationMetricType,
  TopicflowIntegrationTimeBucket,
  TopicflowIntegrationValue,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import useHubspotComboboxQuery from "@components/combobox/use-hubspot-combobox-query";
import GraphqlError from "@components/error/graphql-error";
import Input, { InputLabel } from "@components/input/input";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import Select, { SelectOption } from "@components/select/select";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { graphqlNone } from "@helpers/constants";
import {
  assertEdgesNonNull,
  getAssetUrl,
  parseStringToJSON,
} from "@helpers/helpers";

import createOrUpdateKPIMutation from "../graphql/create-or-update-kpi-mutation";
import getKpiQuery from "../graphql/get-kpi-query";
import { kpiSummaryMethodOptions, kpiSummaryPeriodOptions } from "../helpers";

const defaultNoneIntegration = {
  integrationId: graphqlNone,
  integrationType: null,
};

const defaultTopicflowIntegration = {
  integrationId: null,
  integrationType: KpiIntegrationType.Topicflow,
  topicflow: {
    metricType: TopicflowIntegrationMetricType.NumberOfMeetings,
    timeBucket: TopicflowIntegrationTimeBucket.Weekly,
    value: TopicflowIntegrationValue.LastTimePeriod,
    userId: graphqlNone,
  },
};

const defaultGoogleSheetIntegration = {
  integrationId: null,
  integrationType: KpiIntegrationType.Googlesheets,
  googleSheets: {
    spreadsheetId: "",
    cellId: "A1",
    rightmostColumnFromRow: null,
    bottomRowFromColumn: null,
  },
};

const defaultSalesforceIntegration = {
  integrationId: null,
  integrationType: KpiIntegrationType.Salesforce,
  organizationIntegrationConnectionId: null,
  salesforce: {
    soqlQuery: "SELECT SUM(amount) kpi_value FROM Opportunity",
    kpiFieldName: "kpi_value",
  },
};

const defaultGithubIntegration = {
  integrationId: null,
  integrationType: KpiIntegrationType.Github,
  github: {
    metricType: GithubIntegrationMetricType.OpenIssues,
    timeBucket: GithubIntegrationTimeBucket.Last_7Days,
    owner: "",
    repository: "",
  },
};

const defaultHubspotIntegration = {
  integrationId: null,
  integrationType: KpiIntegrationType.Hubspot,
  hubspot: {
    metricType: HubspotIntegrationMetricType.DealCount,
    timeBucket: HubspotIntegrationTimeBucket.ThisQuarter,
    dealStageId: null,
  },
};

const getIntegrationFromKPI = (kpi: NonNullable<GetKpiQueryQuery["kpi"]>) => {
  if (kpi.integration) {
    if (kpi.integration.__typename === "TopicflowKPIIntegrationNode") {
      return {
        integrationId: kpi.integration.id,
        integrationType: kpi.integration.integrationType,
        topicflow: {
          metricType: kpi.integration.metricType,
          timeBucket: kpi.integration.timeBucket,
          value: kpi.integration.value,
          userId: kpi.integration.user?.id || graphqlNone,
        },
      };
    }
    if (kpi.integration.__typename === "GoogleSheetsKPIIntegrationNode") {
      return {
        integrationId: kpi.integration.id,
        integrationType: kpi.integration.integrationType,
        googleSheets: {
          spreadsheetId: kpi.integration.spreadsheetId,
          cellId: kpi.integration.cellId,
          rightmostColumnFromRow: kpi.integration.rightmostColumnFromRow,
          bottomRowFromColumn: kpi.integration.bottomRowFromColumn,
        },
      };
    }
    if (kpi.integration.__typename === "SalesforceKPIIntegrationNode") {
      return {
        integrationId: kpi.integration.id,
        integrationType: kpi.integration.integrationType,
        organizationIntegrationConnectionId:
          kpi.integration.organizationIntegrationConnection?.id,
        salesforce: {
          soqlQuery: kpi.integration.soqlQuery,
          kpiFieldName: kpi.integration.kpiFieldName,
        },
      };
    }
    if (kpi.integration.__typename === "GithubKPIIntegrationNode") {
      return {
        integrationId: kpi.integration.id,
        integrationType: kpi.integration.integrationType,
        github: {
          metricType: kpi.integration.ghMetricType, // this is aliased in getKpiQuery
          timeBucket: kpi.integration.ghTimeBucket, // this is aliased in getKpiQuery
          owner: kpi.integration.owner,
          repository: kpi.integration.repository,
        },
      };
    }
    if (kpi.integration.__typename === "HubspotKPIIntegrationNode") {
      return {
        integrationId: kpi.integration.id,
        integrationType: kpi.integration.integrationType,
        hubspot: {
          metricType: kpi.integration.hubspotMetricType, // this is aliased in getKpiQuery
          timeBucket: kpi.integration.hubspotTimeBucket, // this is aliased in getKpiQuery
          dealStageId: kpi.integration.dealStageId,
        },
      };
    }
  }
  return defaultNoneIntegration;
};

const KpiContainer = ({
  title,
  children,
}: {
  title: string;
  children: ReactElement;
}) => (
  <div className="flex flex-col gap-6 p-4 pb-6 bg-gray-100 rounded-lg">
    <div className="text-black font-medium">{title}</div>
    {children}
  </div>
);

enum googleSheetsFieldType {
  cellId = "cellId",
  rightmostColumnFromRow = "rightmostColumnFromRow",
  bottomRowFromColumn = "bottomRowFromColumn",
}

type KPIFormType = {
  title: string;
  description: string;
  visibility: KpiVisibility;
  integration?: any;
  defaultSummaryPeriod: KpiSummaryPeriod;
  defaultSummaryMethod: KpiSummaryMethod;
  targetValue: number;
  targetCondition: KpiTargetCondition;
  targetType: KpiTargetType;
  groupIds: number[];
  teams: {
    id: number;
    title: string;
  }[];
};

const KPIEdit = () => {
  const label = useLabel();
  const kpiLocalStorageKey = "kpi-form";
  const kpiLocalStorageValue = store(kpiLocalStorageKey) || {};
  const link = useLink();
  const params = useParams<{ kpiId: string }>();
  const { search } = useLocation();
  const urlQueryParams = new URLSearchParams(search);
  const currentUser = currentUserVar();
  const kpiId = parseInt(params.kpiId) || null;
  const currentOrganization = currentOrganizationVar();

  const [form, _setForm] = useState<KPIFormType>({
    title: "",
    description: "",
    visibility: KpiVisibility.Org,
    integration: defaultNoneIntegration,
    defaultSummaryPeriod: KpiSummaryPeriod.Monthly,
    defaultSummaryMethod: KpiSummaryMethod.LastValue,
    targetValue: 0,
    targetCondition: KpiTargetCondition.GreaterThanOrEqualTo,
    targetType: KpiTargetType.Growth,
    groupIds: [],
    teams: [],
    ...(urlQueryParams.get("restore") === "1" && kpiLocalStorageValue), // apply localstorage value only when new
  });

  // kpiId is a required query variable below, so if this is a new KPI (with no
  // id), pass -1, because it won't be used anyways (hasKpi being false will
  // mean that the part of the query using kpiId doesn't get run)
  const { data, loading, error } = useQuery<
    GetKpiQueryQuery,
    GetKpiQueryQueryVariables
  >(getKpiQuery, {
    variables: {
      kpiId: Number(kpiId) || -1,
      hasKpi: !!kpiId,
      organizationId: currentOrganization.id,
      hasOrganizationId: true,
    },
    onCompleted: (response) => {
      const kpi = response.kpi;
      if (kpi) {
        const integration = getIntegrationFromKPI(kpi);
        const newGroupIds =
          assertEdgesNonNull(kpi.groups).map(({ id }) => id) || [];
        setForm({
          ...form,
          ...kpi,
          targetValue: kpi?.targetValue || 0,
          description: parseStringToJSON(kpi.description),
          integration,
          groupIds: newGroupIds,
          teams: kpi.teams ? assertEdgesNonNull(kpi.teams) : [],
        });
      }
    },
    onError: onNotificationErrorHandler(),
  });

  const kpiGroups = data?.kpiGroups ? assertEdgesNonNull(data?.kpiGroups) : [];

  const salesforceOrgIntegrations = data?.organization?.integrations
    ? assertEdgesNonNull(data.organization.integrations)
    : [];
  const salesforceIntegrationOptions = salesforceOrgIntegrations.map(
    (integration) => ({
      value: integration.id,
      label: JSON.parse(integration.socialAuth.extraData).instance_url,
    })
  );
  const selectedSalesforceIntegrationOption =
    salesforceIntegrationOptions?.find(
      ({ value }) =>
        value === form.integration?.organizationIntegrationConnectionId
    );

  const { options: hubspotOptions, selectedOption: selectedHubspotOption } =
    useHubspotComboboxQuery({
      skip: form.integration?.integrationType !== KpiIntegrationType.Hubspot,
      stageId: form.integration?.hubspot?.dealStageId
        ? Number(form.integration.hubspot.dealStageId)
        : null,
    });
  const selectedTeamIds = form.teams.map(({ id }) => id);
  const teams = assertEdgesNonNull(currentUser.teams);
  const teamOptions = [
    { id: null, title: `Select a ${label("team")}` },
    ...teams,
  ]
    .map((team) => ({ label: team.title, value: team.id }))
    .filter(({ value }) => value === null || !selectedTeamIds.includes(value));

  const submitError =
    form.title.trim().length === 0
      ? "Add a title"
      : form.visibility === KpiVisibility.Teams && form.teams.length === 0
      ? `Select the ${label("team", {
          pluralize: true,
        })} that can view this KPI`
      : null;

  const googleSheetOptions = [
    {
      value: googleSheetsFieldType.cellId,
      label: "Cell ID",
    },
    {
      value: googleSheetsFieldType.rightmostColumnFromRow,
      label: "Right most column from row",
    },
    {
      value: googleSheetsFieldType.bottomRowFromColumn,
      label: "Bottom row from column",
    },
  ];
  const googleSheetsField = googleSheetOptions.find(
    ({ value }: { value: googleSheetsFieldType }) => {
      return form.integration?.googleSheets?.[`${value}`] !== null;
    }
  );

  const [createOrUpdateKPI, { loading: loadingSave, error: errorSave }] =
    useMutation(createOrUpdateKPIMutation);

  const setForm = (form: KPIFormType) => {
    _setForm(form);
    if (!kpiId) {
      store(kpiLocalStorageKey, form);
    }
  };

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    const variables = {
      ...form,
      teamIds: form.teams.map(({ id }) => id),
      organizationId: currentOrganization?.id,
      kpiId,
    };
    createOrUpdateKPI({
      variables,
      onCompleted: (response) => {
        store.remove(kpiLocalStorageKey);
        link.redirect(`/kpis/${response.createOrUpdateKpi.kpi.id}`);
      },
      onError: () => {
        store.remove(kpiLocalStorageKey);
      },
    });
  };

  const handleChangeDescription = (description: string) => {
    setForm({ ...form, description });
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, title: e.target.value });
  };

  const handleChangeTargetValue = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, targetValue: Number(e.target.value) || 0 });
  };

  const handleChangeTargetType = ({
    value: targetType,
  }: SelectOption<KpiTargetType>) => {
    setForm({ ...form, targetType });
  };

  const handleChangeTargetCondition = ({
    value: targetCondition,
  }: SelectOption<KpiTargetCondition>) => {
    setForm({ ...form, targetCondition });
  };

  const handleChangeVisibility = ({
    value: visibility,
  }: SelectOption<KpiVisibility>) => {
    setForm({ ...form, visibility });
  };

  const handleChangeSummaryPeriod = ({
    value: defaultSummaryPeriod,
  }: SelectOption<KpiSummaryPeriod>) => {
    setForm({ ...form, defaultSummaryPeriod });
  };

  const handleChangeSummaryMethod = ({
    value: defaultSummaryMethod,
  }: SelectOption<KpiSummaryMethod>) => {
    setForm({ ...form, defaultSummaryMethod });
  };

  const handleChangeIntegrationType = ({
    value: integrationType,
  }: SelectOption<KpiIntegrationType | null>) => {
    setForm({
      ...form,
      integration:
        integrationType === KpiIntegrationType.Topicflow
          ? defaultTopicflowIntegration
          : integrationType === KpiIntegrationType.Googlesheets
          ? defaultGoogleSheetIntegration
          : integrationType === KpiIntegrationType.Salesforce
          ? defaultSalesforceIntegration
          : integrationType === KpiIntegrationType.Github
          ? defaultGithubIntegration
          : integrationType === KpiIntegrationType.Hubspot
          ? defaultHubspotIntegration
          : defaultNoneIntegration,
    });
  };

  const handleChangeTopicflowMetricType = ({
    value: metricType,
  }: SelectOption<TopicflowIntegrationMetricType>) => {
    if (form.integration) {
      const topicflow = { ...form.integration.topicflow, metricType };
      const integration = { ...form.integration, topicflow };
      setForm({ ...form, integration });
    }
  };

  const handleChangeTopicflowTimeBucket = ({
    value: timeBucket,
  }: SelectOption<TopicflowIntegrationTimeBucket>) => {
    if (form.integration) {
      const topicflow = { ...form.integration.topicflow, timeBucket };
      const integration = { ...form.integration, topicflow };
      setForm({ ...form, integration });
    }
  };

  const handleChangeSpreadsheetId = (e: ChangeEvent<HTMLInputElement>) => {
    if (form.integration) {
      const googleSheets = {
        ...form.integration.googleSheets,
        spreadsheetId: e.target.value,
      };
      const integration = { ...form.integration, googleSheets };
      setForm({ ...form, integration });
    }
  };

  const handleChangeGoogleSheetValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (form.integration?.googleSheets && googleSheetsField) {
      const googleSheets = {
        ...form.integration.googleSheets,
        [`${googleSheetsField.value}`]: e.target.value,
      };
      const integration = { ...form.integration, googleSheets };
      setForm({ ...form, integration });
    }
  };

  const handleChangeGoogleSheetsField = ({ value }: SelectOption<string>) => {
    if (form.integration?.googleSheets && googleSheetsField) {
      const currentValue =
        form.integration.googleSheets[`${googleSheetsField.value}`];
      const googleSheets = {
        ...form.integration.googleSheets,
        [`${googleSheetsFieldType.cellId}`]: null,
        [`${googleSheetsFieldType.rightmostColumnFromRow}`]: null,
        [`${googleSheetsFieldType.bottomRowFromColumn}`]: null,
        [`${value}`]: currentValue,
      };
      const integration = { ...form.integration, googleSheets };
      setForm({ ...form, integration });
    }
  };

  const handleChangeSOQLQuery = (e: ChangeEvent<HTMLInputElement>) => {
    if (form.integration?.salesforce) {
      const salesforce = {
        ...form.integration.salesforce,
        soqlQuery: e.target.value,
      };
      const integration = { ...form.integration, salesforce };
      setForm({ ...form, integration });
    }
  };

  const handleChangeSalesforceKPIFieldName = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (form.integration?.salesforce) {
      const salesforce = {
        ...form.integration.salesforce,
        kpiFieldName: e.target.value,
      };
      const integration = { ...form.integration, salesforce };
      setForm({ ...form, integration });
    }
  };

  const handleChangeSalesforceIntegrationOption = (
    option?: ComboboxGenericOption
  ) => {
    const integration = {
      ...form.integration,
      organizationIntegrationConnectionId: option?.value || graphqlNone,
    };
    setForm({ ...form, integration });
  };

  const handleChangeGithubMetricType = ({
    value: metricType,
  }: SelectOption<GithubIntegrationMetricType>) => {
    if (form.integration) {
      const github = { ...form.integration.github, metricType };
      const integration = { ...form.integration, github };
      setForm({ ...form, integration });
    }
  };

  const handleChangeGithubTimeBucket = ({
    value: timeBucket,
  }: SelectOption<GithubIntegrationTimeBucket>) => {
    if (form.integration) {
      const github = { ...form.integration.github, timeBucket };
      const integration = { ...form.integration, github };
      setForm({ ...form, integration });
    }
  };

  const handleChangeGithubOwner = ({
    target: { value: owner },
  }: ChangeEvent<HTMLInputElement>) => {
    if (form.integration) {
      const github = { ...form.integration.github, owner };
      const integration = { ...form.integration, github };
      setForm({ ...form, integration });
    }
  };

  const handleChangeGithubRepository = ({
    target: { value: repository },
  }: ChangeEvent<HTMLInputElement>) => {
    if (form.integration) {
      const github = { ...form.integration.github, repository };
      const integration = { ...form.integration, github };
      setForm({ ...form, integration });
    }
  };

  const handleChangeHubspotMetricType = ({
    value: metricType,
  }: SelectOption<HubspotIntegrationMetricType>) => {
    if (form.integration) {
      const hubspot = { ...form.integration.hubspot, metricType };
      const integration = { ...form.integration, hubspot };
      setForm({ ...form, integration });
    }
  };

  const handleChangeHubspotTimeBucket = ({
    value: timeBucket,
  }: SelectOption<HubspotIntegrationTimeBucket>) => {
    if (form.integration) {
      const hubspot = { ...form.integration.hubspot, timeBucket };
      const integration = { ...form.integration, hubspot };
      setForm({ ...form, integration });
    }
  };

  const handleChangeHubspotDealStageId = (option?: ComboboxGenericOption) => {
    if (form.integration) {
      const hubspot = {
        ...form.integration.hubspot,
        dealStageId: option?.value || null,
      };
      const integration = { ...form.integration, hubspot };
      setForm({ ...form, integration });
    }
  };

  const handleClickCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    store.remove(kpiLocalStorageKey);
    link.redirect("/kpis");
  };

  const handleToggleGroup = (id: number, checked: boolean) => () => {
    const groupIds = checked
      ? form.groupIds.filter((groupId) => groupId !== id)
      : form.groupIds.concat(id);
    setForm({ ...form, groupIds });
  };

  const handleAddTeam = (option: SelectOption<number | null>) => {
    if (option.value) {
      const newTeams = [
        ...form.teams,
        {
          id: option.value,
          title: String(option.label),
        },
      ];
      setForm({ ...form, teams: newTeams });
    }
  };

  const handleRemoveTeamId = (id: number) => () => {
    const newTeams = form.teams.filter((team) => team.id !== id);
    setForm({ ...form, teams: newTeams });
  };

  const googleSheetsFieldValue = googleSheetsField
    ? form.integration?.googleSheets?.[`${googleSheetsField.value}`]
    : null;

  // RENDER
  return (
    <div className="bg-white flex rounded-lg shadow overflow-hidden p-6">
      <form
        className="w-full flex flex-col gap-6"
        aria-label="KPIs form"
        onSubmit={handleSubmitForm}
      >
        <div className="text-xl font-medium">
          {kpiId ? "Edit" : "Create"} KPI
        </div>
        {loading ? (
          <Loading>Loading KPI</Loading>
        ) : (
          <div className="w-full flex flex-col gap-6 max-w-144">
            <div className="w-full">
              <Input
                label="Title"
                aria-label="KPI title input"
                className="w-full"
                value={form.title}
                placeholder="KPI title..."
                onChange={handleChangeTitle}
              />
            </div>
            <div className="w-full">
              <InputLabel label="Description" />
              <TextareaWysiwyg
                editable
                className="mt-1"
                value={form.description}
                deps={[form.title]}
                onChangeValue={handleChangeDescription}
              />
            </div>
            <div className="w-full">
              <InputLabel label="Visibility" />
              <Select<KpiVisibility>
                onChange={handleChangeVisibility}
                className="mt-1"
                value={form.visibility}
                options={[
                  {
                    value: KpiVisibility.Personal,
                    label: "Only you",
                  },
                  {
                    value: KpiVisibility.Teams,
                    label: label("team", { pluralize: true, capitalize: true }),
                  },
                  {
                    value: KpiVisibility.Org,
                    label: "Organization",
                  },
                ]}
              />
            </div>
            {form.visibility === KpiVisibility.Teams && (
              <div className="w-full">
                <InputLabel
                  label={`Visible to members of these ${label("team", {
                    pluralize: true,
                  })}`}
                />
                <div className="mt-1 flex items-center gap-4 flex-wrap">
                  {form.teams.map((team) => (
                    <div
                      key={team.id}
                      className="text-sm relative px-3 py-1 border rounded-full"
                    >
                      <span>{team.title}</span>
                      <Tooltip text="Remove">
                        <button
                          className="rounded-full bg-gray-100 p-1 text-gray-600 absolute -right-2 -top-2 border hover:bg-gray-200 hover:border-gray-400"
                          onClick={handleRemoveTeamId(team.id)}
                        >
                          <XIcon className="h-3 w-3 " />
                        </button>
                      </Tooltip>
                    </div>
                  ))}
                  <Select<number | null>
                    options={teamOptions}
                    value={null}
                    onChange={handleAddTeam}
                  />
                </div>
              </div>
            )}
            <div className="w-full">
              <InputLabel label="Default summarization" />
              <div className="grid grid-cols-2 gap-2">
                <Select<KpiSummaryPeriod>
                  onChange={handleChangeSummaryPeriod}
                  className="mt-1"
                  value={form.defaultSummaryPeriod}
                  options={kpiSummaryPeriodOptions}
                />

                <Select<KpiSummaryMethod>
                  onChange={handleChangeSummaryMethod}
                  className="mt-1"
                  value={form.defaultSummaryMethod}
                  options={kpiSummaryMethodOptions}
                />
              </div>
            </div>
            <div className="w-full">
              <InputLabel label="Target" />
              <div className="flex items-center gap-2 mt-1">
                <Select<KpiTargetType>
                  onChange={handleChangeTargetType}
                  value={form.targetType}
                  width="28"
                  options={[
                    {
                      value: KpiTargetType.Growth,
                      label: "Growth",
                    },
                    {
                      value: KpiTargetType.Value,
                      label: "Value",
                    },
                  ]}
                />
                <Select<KpiTargetCondition>
                  onChange={handleChangeTargetCondition}
                  width="20"
                  value={form.targetCondition}
                  options={[
                    {
                      value: KpiTargetCondition.GreaterThan,
                      label: ">",
                    },
                    {
                      value: KpiTargetCondition.GreaterThanOrEqualTo,
                      label: ">=",
                    },
                    {
                      value: KpiTargetCondition.LessThan,
                      label: "<",
                    },
                    {
                      value: KpiTargetCondition.LessThanOrEqualTo,
                      label: "<=",
                    },
                  ]}
                />
                <Input
                  aria-label="KPI target value input"
                  className="flex-1"
                  value={form.targetValue || ""}
                  placeholder="Target value"
                  onChange={handleChangeTargetValue}
                />
              </div>
            </div>

            <KpiContainer title="Integration">
              <>
                <div className="w-full">
                  <InputLabel label="Type" />
                  <Select<KpiIntegrationType | null>
                    onChange={handleChangeIntegrationType}
                    className="mt-1"
                    value={form.integration?.integrationType || null}
                    options={[
                      {
                        value: null,
                        label: "None",
                      },
                      {
                        value: KpiIntegrationType.Topicflow,
                        label: "Topicflow",
                      },
                      {
                        value: KpiIntegrationType.Googlesheets,
                        label: "Google Sheet",
                      },
                      {
                        value: KpiIntegrationType.Salesforce,
                        label: "Salesforce",
                      },
                      {
                        value: KpiIntegrationType.Github,
                        label: "GitHub",
                      },
                      {
                        value: KpiIntegrationType.Hubspot,
                        label: "Hubspot",
                      },
                    ]}
                  />
                </div>

                {/* TOPICFLOW INTEGRATION */}
                {form.integration?.integrationType ===
                  KpiIntegrationType.Topicflow && (
                  <>
                    <div className="w-full">
                      <InputLabel label="Metric" />
                      <Select
                        onChange={handleChangeTopicflowMetricType}
                        className="mt-1"
                        value={form.integration?.topicflow?.metricType || null}
                        options={[
                          {
                            value:
                              TopicflowIntegrationMetricType.NumberOfMeetings,
                            label: "Number of meetings",
                          },
                          {
                            value:
                              TopicflowIntegrationMetricType.HoursOfMeetings,
                            label: "Hours of meetings",
                          },
                          {
                            value:
                              TopicflowIntegrationMetricType.ActionItemsCreated,
                            label: "Action items created",
                          },
                          {
                            value: TopicflowIntegrationMetricType.GoalsCreated,
                            label: `${label("goal", {
                              titleCase: true,
                              pluralize: true,
                            })} created`,
                          },
                          {
                            value:
                              TopicflowIntegrationMetricType.DecisionsCreated,
                            label: "Decisions created",
                          },
                        ]}
                      />
                    </div>
                    <div className="w-full">
                      <InputLabel label="Time bucket" />
                      <Select
                        onChange={handleChangeTopicflowTimeBucket}
                        className="mt-1"
                        value={form.integration.topicflow?.timeBucket || null}
                        options={[
                          {
                            value: TopicflowIntegrationTimeBucket.Daily,
                            label: "Daily",
                          },
                          {
                            value: TopicflowIntegrationTimeBucket.Weekly,
                            label: "Weekly",
                          },
                          {
                            value: TopicflowIntegrationTimeBucket.Monthly,
                            label: "Monthly",
                          },
                        ]}
                      />
                    </div>
                  </>
                )}

                {/* GOOGLE SHEETS INTEGRATION */}
                {form.integration?.integrationType ===
                  KpiIntegrationType.Googlesheets && (
                  <>
                    {currentUser.hasGoogleSheetsAuth &&
                    form.integration.googleSheets ? (
                      <>
                        <div className="w-full">
                          <Input
                            value={
                              form.integration.googleSheets.spreadsheetId || ""
                            }
                            onChange={handleChangeSpreadsheetId}
                            label="Spreadsheet URL or id"
                          />
                        </div>

                        <div className="w-full flex items-start gap-2">
                          <Select<string>
                            onChange={handleChangeGoogleSheetsField}
                            value={googleSheetsField?.value}
                            options={[
                              {
                                value: "cellId",
                                label: "Cell ID",
                              },
                              {
                                value: "rightmostColumnFromRow",
                                label: "Right most column from row",
                              },
                              {
                                value: "bottomRowFromColumn",
                                label: "Bottom row from column",
                              },
                            ]}
                          />
                          <Input
                            value={googleSheetsFieldValue || ""}
                            onChange={handleChangeGoogleSheetValue}
                          />
                        </div>
                        {googleSheetsFieldValue &&
                          googleSheetsField?.value ===
                            googleSheetsFieldType.bottomRowFromColumn &&
                          /\d/.test(googleSheetsFieldValue) && (
                            <div className="text-red-700 text-sm mt-1">
                              Column ID should not have any numbers
                            </div>
                          )}
                        {googleSheetsFieldValue &&
                          googleSheetsField?.value ===
                            googleSheetsFieldType.rightmostColumnFromRow &&
                          !/\d+/.test(googleSheetsFieldValue) && (
                            <div className="text-red-700 text-sm mt-1">
                              Row ID should be a numbers
                            </div>
                          )}
                        {((googleSheetsFieldValue &&
                          googleSheetsField?.value ===
                            googleSheetsFieldType.rightmostColumnFromRow &&
                          /\s/.test(googleSheetsFieldValue)) ||
                          (googleSheetsFieldValue &&
                            googleSheetsField?.value ===
                              googleSheetsFieldType.bottomRowFromColumn &&
                            /\s/.test(googleSheetsFieldValue))) && (
                          <div className="text-red-700 text-sm mt-1">
                            Value should not have any spaces.
                          </div>
                        )}
                      </>
                    ) : (
                      <div>
                        <img
                          src={getAssetUrl(googleLogo)}
                          alt="Hubspot"
                          className="w-8 inline-block mr-2"
                        />
                        <a
                          className="text-sm hover:underline text-blue-link"
                          href={`/sso/google?request_sheets_scope=true&request_calendar_scope=true&request_write_scope=true&next=${document.location.pathname}?restore=1`}
                        >
                          Please allow access to Google Sheets
                        </a>
                      </div>
                    )}
                  </>
                )}

                {/* SALESFORCE INTEGRATION */}
                {form.integration?.integrationType ===
                  KpiIntegrationType.Salesforce &&
                  form.integration.salesforce && (
                    <>
                      {currentUser.hasSalesforceAuth ? (
                        <>
                          <div className="w-full">
                            <Input
                              value={
                                form.integration.salesforce.soqlQuery || ""
                              }
                              onChange={handleChangeSOQLQuery}
                              label="SOQL Query"
                            />
                          </div>
                          <div className="w-full">
                            <Input
                              value={
                                form.integration.salesforce.kpiFieldName || ""
                              }
                              onChange={handleChangeSalesforceKPIFieldName}
                              label="KPI Result Field Name"
                            />
                          </div>
                          <div className="w-full">
                            <InputLabel label="Salesforce Connection" />
                            <ComboboxGeneric<number>
                              className="mt-1"
                              options={salesforceIntegrationOptions}
                              value={
                                selectedSalesforceIntegrationOption || null
                              }
                              placeholder="Salesforce Connection"
                              onChangeValue={
                                handleChangeSalesforceIntegrationOption
                              }
                              clearable={!!selectedSalesforceIntegrationOption}
                              onClearValue={
                                handleChangeSalesforceIntegrationOption
                              }
                            />
                          </div>
                        </>
                      ) : waffle.flag_is_active("salesforce") ? (
                        <div>
                          <a
                            className="text-sm hover:underline text-blue-link"
                            href={`/sso/salesforce`}
                          >
                            Please allow access to Salesforce
                          </a>
                        </div>
                      ) : (
                        <div>
                          Please{" "}
                          <a
                            href="https://www.topicflow.com/contact-us/"
                            className="text-blue-500 hover:underline"
                          >
                            contact us
                          </a>{" "}
                          for access to the Salesforce integration
                        </div>
                      )}
                    </>
                  )}

                {/* GITHUB INTEGRATION */}
                {form.integration?.integrationType ===
                  KpiIntegrationType.Github &&
                  form.integration.github && (
                    <>
                      <div className="w-full">
                        <InputLabel label="Metric" />
                        <Select<GithubIntegrationMetricType>
                          onChange={handleChangeGithubMetricType}
                          className="mt-1"
                          value={form.integration.github.metricType}
                          options={[
                            {
                              value: GithubIntegrationMetricType.OpenIssues,
                              label: "Number of open issues",
                            },
                            {
                              value: GithubIntegrationMetricType.OpenPrs,
                              label: "Number of open pull requests",
                            },
                            {
                              value: GithubIntegrationMetricType.CreatedIssues,
                              label: "Number of created issues",
                            },
                            {
                              value: GithubIntegrationMetricType.ClosedIssues,
                              label: "Number of closed issues",
                            },
                            {
                              value: GithubIntegrationMetricType.CreatedPrs,
                              label: "Number of created pull requests",
                            },
                            {
                              value: GithubIntegrationMetricType.ClosedPrs,
                              label: "Number of closed pull requests",
                            },
                          ]}
                        />
                      </div>
                      {form.integration.github.metricType !==
                        GithubIntegrationMetricType.OpenIssues &&
                        form.integration.github.metricType !==
                          GithubIntegrationMetricType.OpenPrs && (
                          <div className="w-full">
                            <InputLabel label="Time bucket" />
                            <Select
                              onChange={handleChangeGithubTimeBucket}
                              className="mt-1"
                              value={form.integration.github.timeBucket}
                              options={[
                                {
                                  value: GithubIntegrationTimeBucket.Last_7Days,
                                  label: "Last 7 days",
                                },
                                {
                                  value:
                                    GithubIntegrationTimeBucket.Last_31Days,
                                  label: "Last 31 days",
                                },
                              ]}
                            />
                          </div>
                        )}
                      <div className="w-full">
                        <div className="grid grid-cols-2 gap-2">
                          <Input
                            label="Repository owner"
                            aria-label="Repository owner input"
                            value={form.integration.github.owner || ""}
                            onChange={handleChangeGithubOwner}
                          />
                          <Input
                            label="Repository name"
                            aria-label="Repository name input"
                            value={form.integration.github.repository || ""}
                            onChange={handleChangeGithubRepository}
                          />
                        </div>
                      </div>
                    </>
                  )}

                {/* Hubspot INTEGRATION */}
                {form.integration?.integrationType ===
                  KpiIntegrationType.Hubspot &&
                  form.integration.hubspot && (
                    <>
                      <div className="w-full">
                        <InputLabel label="Metric" />
                        <Select
                          onChange={handleChangeHubspotMetricType}
                          className="mt-1"
                          value={form.integration.hubspot.metricType}
                          options={[
                            {
                              value: HubspotIntegrationMetricType.DealCount,
                              label: "Number of deals",
                            },
                            {
                              value: HubspotIntegrationMetricType.DealAmountSum,
                              label: "Total value of deals",
                            },
                          ]}
                        />
                      </div>

                      <div className="w-full">
                        <InputLabel label="Time bucket" />
                        <Select
                          onChange={handleChangeHubspotTimeBucket}
                          className="mt-1"
                          value={form.integration.hubspot.timeBucket}
                          options={[
                            {
                              value: HubspotIntegrationTimeBucket.Last_7Days,
                              label: "Last 7 days",
                            },
                            {
                              value: HubspotIntegrationTimeBucket.Last_31Days,
                              label: "Last 31 days",
                            },
                            {
                              value: HubspotIntegrationTimeBucket.ThisQuarter,
                              label: "This Quarter",
                            },
                            {
                              value: HubspotIntegrationTimeBucket.ThisYear,
                              label: "This Year",
                            },
                            {
                              value: HubspotIntegrationTimeBucket.AllTime,
                              label: "All Time",
                            },
                          ]}
                        />
                      </div>

                      <div className="w-full">
                        <InputLabel label="Stage" />
                        <ComboboxGeneric<number>
                          className="mt-1"
                          options={hubspotOptions}
                          value={selectedHubspotOption}
                          placeholder="All stages"
                          onChangeValue={handleChangeHubspotDealStageId}
                          clearable={!!selectedHubspotOption}
                          onClearValue={handleChangeHubspotDealStageId}
                        />
                      </div>
                    </>
                  )}
              </>
            </KpiContainer>

            <KpiContainer title="KPI Groups">
              <div className="w-full flex flex-col gap-1 text-sm -mt-4">
                {kpiGroups.map((node) => (
                  <label
                    key={node.id}
                    className="flex items-center gap-2 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      checked={form.groupIds.includes(node.id)}
                      value={node.id}
                      onChange={handleToggleGroup(
                        node.id,
                        form.groupIds.includes(node.id)
                      )}
                    />
                    {node.title}
                  </label>
                ))}
              </div>
            </KpiContainer>

            <GraphqlError
              error={errorSave || error}
              whatDidNotWork="The KPI could not be saved."
              parser={[
                {
                  match: "The caller does not have permission",
                  message: (
                    <a
                      className="underline"
                      href="/sso/google?request_sheets_scope=true&request_calendar_scope=true&request_write_scope=true&next={document.location.pathname}?restore=1"
                    >
                      Please provide access to Google Sheets
                    </a>
                  ),
                },
              ]}
            />
            <div className="flex gap-4 items-center">
              <Button
                text="Save KPI"
                type="submit"
                theme={buttonTheme.primary}
                disabled={loadingSave || !!submitError}
                tooltip={submitError}
              />
              <Button type="button" onClick={handleClickCancel} text="Cancel" />
              {loadingSave && <Loading mini size="4" />}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default KPIEdit;

import { ReactNode, useState } from "react";

import AssessmenNominationtDialog from "@apps/assessments/components/my-assessments-tabs/nomination-dialog";
import { getWatchedQuery } from "@graphql/client";

import getComplianceProgramActionsQuery from "../graphql/get-compliance-program-actions-query";
import { ComplianceProgramActionNominationItemProps } from "../helpers";
import ComplianceProgramActionItem from "./compliance-program-action-item";

const ComplianceProgramActionItemForNomination = ({
  item,
  children,
}: {
  item: ComplianceProgramActionNominationItemProps;
  children?: ReactNode | null;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseNominationDialog = () => {
    setIsOpen(false);
    const query = getWatchedQuery(getComplianceProgramActionsQuery);
    if (query) {
      query.refetch();
    }
  };

  const { key, ...extendedItem } = {
    ...item,
    buttonProps: {
      ...item.buttonProps,
      onClick: () => setIsOpen(true),
      text:
        item.assessmentOpenForNomination.nominations.totalCount > 0
          ? "Edit"
          : "Nominate",
    },
  };

  return (
    <>
      {isOpen && (
        <AssessmenNominationtDialog
          assessmentNomination={item.assessmentOpenForNomination}
          onClose={handleCloseNominationDialog}
        />
      )}
      <ComplianceProgramActionItem {...extendedItem}>
        {children}
      </ComplianceProgramActionItem>
    </>
  );
};

export default ComplianceProgramActionItemForNomination;

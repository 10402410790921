import { PencilIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import { useMemo, useState } from "react";
import {
  AssessmentGroupDelivery,
  AssessmentGroupFragmentFragment,
  AssessmentTemplateComplianceRequirement,
  AssessmentType,
  Maybe,
  SaveComplianceProgramMutationVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Button, { buttonTheme } from "@components/button/button";
import { ComboboxGenericOption } from "@components/combobox/generic-combobox";
import FormFields from "@components/form/form-fields";
import Select from "@components/select/select";
import ToggleSwitch from "@components/toggle-switch/toggle-switch";
import InfoTooltip from "@components/tooltip/info-tooltip";
import { assessmentGroupDeliveryOptions } from "@helpers/constants";
import { assertNonNull } from "@helpers/helpers";

import AssessmentGroupSidebar from "../assessment-group-sidebar/assessment-group-sidebar";
import { AssessmentTemplateExtraSettings } from "../compliance-program-form";
import { ComplianceProgramStep } from "../compliance-program-form-steps";
import ComplianceProgramFormStep from "./compliance-program-form-step";
import QuestionSetSelector from "./question-set-selector";
import RoleBasedAssessmentSettingsModal from "./role-based-assessment-settings-modal";

const SelfManagerAssessmentStep = ({
  proposedComplianceProgram,
  canUpdate,
  complianceProgramSteps,
  onUpdateComplianceProgramSteps,
  onUpdateAssessmentTemplateSettings,
  onUpdateAssessmentTemplate,
  availableAssessmentGroups,
  onRemoveAssessmentTemplate,
  onAssessmentGroupCreated,
}: {
  proposedComplianceProgram: SaveComplianceProgramMutationVariables;
  canUpdate: boolean;
  complianceProgramSteps: ComplianceProgramStep[];
  onUpdateComplianceProgramSteps: (steps: ComplianceProgramStep[]) => void;
  onUpdateAssessmentTemplateSettings: (
    assessmentType: AssessmentType
  ) => (settings: AssessmentTemplateExtraSettings) => void;
  onUpdateAssessmentTemplate: (
    assessmentType: AssessmentType
  ) => (opt: ComboboxGenericOption<number> | null) => void;
  availableAssessmentGroups: AssessmentGroupFragmentFragment[];
  onRemoveAssessmentTemplate: (assessmentType: AssessmentType) => void;
  onAssessmentGroupCreated: (
    newAssessmentGroup: AssessmentGroupFragmentFragment
  ) => void;
}) => {
  const label = useLabel();

  const [isRoleBasedAssessmentModalOpen, setIsRoleBasedAssessmentModalOpen] =
    useState(false);
  const [isAssessmentGroupSidebarOpen, setIsAssessmentGroupSidebarOpen] =
    useState(false);
  const [assessmentGroupToDuplicate, setAssessmentGroupToDuplicate] =
    useState<AssessmentGroupFragmentFragment | null>(null);

  const availableAssessmentGroupSelectOptions = useMemo(() => {
    return availableAssessmentGroups
      .filter((assessmentGroup) => !assessmentGroup.hasNpsQuestions)
      .map(({ id, title }) => ({
        value: id,
        label: title,
      }));
  }, [availableAssessmentGroups]);

  const selectedAssessmentTemplates = useMemo(
    () =>
      proposedComplianceProgram.assessmentTemplates
        ? compact(
            proposedComplianceProgram.assessmentTemplates as Maybe<AssessmentTemplateComplianceRequirement>[]
          )
        : [],
    [proposedComplianceProgram.assessmentTemplates]
  );

  const selectedPerformanceAssessmentTemplate = useMemo(() => {
    return selectedAssessmentTemplates.find(
      (template) => template.assessmentType === AssessmentType.Performance
    );
  }, [selectedAssessmentTemplates]);

  const selectedAssessmentGroup = useMemo(() => {
    return selectedPerformanceAssessmentTemplate &&
      selectedPerformanceAssessmentTemplate.questionSetIds.length > 0
      ? availableAssessmentGroups.find(
          ({ id }) =>
            id === selectedPerformanceAssessmentTemplate.questionSetIds[0]
        ) ?? null
      : null;
  }, [availableAssessmentGroups, selectedPerformanceAssessmentTemplate]);

  const performanceDeliveryOptions = useMemo(
    () => assessmentGroupDeliveryOptions(label, AssessmentType.Performance),
    [label]
  );

  return (
    <>
      {isAssessmentGroupSidebarOpen && (
        <AssessmentGroupSidebar
          assessmentGroup={selectedAssessmentGroup}
          assessmentGroupToDuplicate={assessmentGroupToDuplicate}
          onClose={() => {
            setAssessmentGroupToDuplicate(null);
            setIsAssessmentGroupSidebarOpen(false);
          }}
          onAssessmentGroupCreated={(assessmentGroup) => {
            onAssessmentGroupCreated(assessmentGroup);
            onUpdateAssessmentTemplate(AssessmentType.Performance)({
              value: assessmentGroup.id,
              label: assessmentGroup.title,
            });
          }}
        />
      )}
      {isRoleBasedAssessmentModalOpen &&
        selectedPerformanceAssessmentTemplate && (
          <RoleBasedAssessmentSettingsModal
            onClose={() => setIsRoleBasedAssessmentModalOpen(false)}
            canUpdate={canUpdate}
            selectedPerformanceAssessmentGroup={
              selectedPerformanceAssessmentTemplate
            }
            onChangeAssessmentTemplateSettings={onUpdateAssessmentTemplateSettings(
              AssessmentType.Performance
            )}
          />
        )}
      <ComplianceProgramFormStep
        canDelete={canUpdate}
        title={`Self and Manager ${label("review", {
          capitalize: true,
        })}`}
        onDelete={() => {
          onRemoveAssessmentTemplate(AssessmentType.Performance);
          onUpdateComplianceProgramSteps(
            complianceProgramSteps.filter(
              (s) => s !== ComplianceProgramStep.SELF_MANAGER_REVIEW
            )
          );
        }}
      >
        <div className="p-6 flex flex-col gap-3">
          <div className="text-sm text-gray-600 font-medium mb-4">
            Sections (For Form Input)
          </div>
          <div className="flex items-center gap-3 p-4 bg-white border rounded">
            <QuestionSetSelector
              assessmentType={AssessmentType.Performance}
              availableAssessmentGroupSelectOptions={
                availableAssessmentGroupSelectOptions
              }
              canUpdate={canUpdate}
              onUpdateAssessmentTemplate={onUpdateAssessmentTemplate}
              onDuplicateAssessmentGroup={setAssessmentGroupToDuplicate}
              onOpenAssessmentGroupSidebar={() =>
                setIsAssessmentGroupSidebarOpen(true)
              }
              selectedAssessmentGroup={selectedAssessmentGroup}
            />
          </div>

          {waffle.flag_is_active("goal-based-assessment") && (
            <div className="flex items-center justify-between p-4 bg-white border rounded">
              <div className="flex items-center gap-2">
                <div className="text-sm">{`${label("goal", {
                  capitalize: true,
                })}-based ${label("review", {
                  pluralize: true,
                  capitalize: true,
                })}`}</div>
                <InfoTooltip
                  text={`Use this if you would like to assess an individuals progress towards their ${label(
                    "goal",
                    {
                      pluralize: true,
                    }
                  )}.`}
                />
              </div>

              <ToggleSwitch
                disabled={!canUpdate || !selectedPerformanceAssessmentTemplate}
                checked={
                  !!selectedPerformanceAssessmentTemplate?.enableGoalAssessments
                }
                onChange={(enabled) =>
                  onUpdateAssessmentTemplateSettings(
                    AssessmentType.Performance
                  )({
                    enableGoalAssessments: enabled,
                  })
                }
              />
            </div>
          )}

          {waffle.flag_is_active("career-competencies") && (
            <div className="flex items-center justify-between p-4 bg-white border rounded">
              <div className="flex items-center gap-2">
                <div className="text-sm">{`Role-based ${label("review", {
                  pluralize: true,
                  capitalize: true,
                })}`}</div>
                <InfoTooltip text="Use this if you would like to assess an individual based on their job responsibilities and competencies." />
              </div>

              <div className="flex items-center gap-4">
                <Button
                  disabled={!selectedPerformanceAssessmentTemplate}
                  icon={PencilIcon}
                  theme={buttonTheme.iconGray}
                  onClick={() => setIsRoleBasedAssessmentModalOpen(true)}
                />
                <ToggleSwitch
                  disabled={
                    !canUpdate || !selectedPerformanceAssessmentTemplate
                  }
                  checked={
                    !!selectedPerformanceAssessmentTemplate?.enableRoleBasedAssessments
                  }
                  onChange={(enabled) =>
                    onUpdateAssessmentTemplateSettings(
                      AssessmentType.Performance
                    )({
                      enableRoleBasedAssessments: enabled,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div className="p-6 border-t">
          <div className="text-sm text-gray-600 font-medium mb-4">Settings</div>
          <FormFields
            fields={[
              {
                title: "Anonymity",
                render: () => (
                  <div className="text-sm h-10 flex items-center">
                    Not anonymous
                  </div>
                ),
              },
              {
                title: "Delivery",
                render: () =>
                  selectedPerformanceAssessmentTemplate ? (
                    <Select<AssessmentGroupDelivery>
                      disabled={!canUpdate}
                      value={assertNonNull(
                        selectedPerformanceAssessmentTemplate.delivery
                      )}
                      options={performanceDeliveryOptions}
                      onChange={(opt) =>
                        onUpdateAssessmentTemplateSettings(
                          AssessmentType.Performance
                        )({
                          delivery: opt.value,
                        })
                      }
                    />
                  ) : (
                    <div className="text-sm h-10 flex items-center">-</div>
                  ),
              },
            ]}
          />
        </div>
      </ComplianceProgramFormStep>
    </>
  );
};
export default SelfManagerAssessmentStep;

import { RangeAssessmentQuestionNode } from "types/graphql-schema";

import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";

import AssessmentQuestionRangeOptions from "../assessment-question-range-options";
import { AssessmentAnswer } from "./types";

const RangeAssessmentQuestionContent = ({
  answer,
  question,
  disabled,
  forceHideComment = false,
  onChangeAnswer,
}: {
  answer?: { integerAnswer?: number | null; comment?: string | null } | null;
  forceHideComment?: boolean;
  question: Pick<
    RangeAssessmentQuestionNode,
    | "labelDescriptions"
    | "labels"
    | "startValue"
    | "endValue"
    | "isCommentMandatory"
  >;
  disabled?: boolean;
  onChangeAnswer?: (newAnswer: Partial<AssessmentAnswer>) => void;
}) => {
  const comment = answer?.comment ?? "{}";
  return (
    <>
      <AssessmentQuestionRangeOptions
        answer={answer?.integerAnswer}
        disabled={disabled}
        labels={question.labels}
        labelDescriptions={question.labelDescriptions}
        startValue={question.startValue}
        endValue={question.endValue}
        onClickAnswer={(answer) => {
          if (!onChangeAnswer) {
            return;
          }
          onChangeAnswer({
            integerAnswer: answer,
          });
        }}
      />
      {!forceHideComment &&
        answer &&
        (!disabled || !isEmptyValue(answer.comment)) && (
          <div className="mt-4 flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Comment {question.isCommentMandatory ? "*" : ""}
              </div>
            </div>
            <TextareaWysiwyg
              editable={!disabled}
              className="bg-white"
              value={comment}
              onChangeValue={(comment) => {
                if (!onChangeAnswer) {
                  return;
                }
                onChangeAnswer({
                  comment,
                });
              }}
            />
          </div>
        )}
    </>
  );
};

export default RangeAssessmentQuestionContent;

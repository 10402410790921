import { useParams } from "react-router-dom";

import AssessmentDelivery from "./assessment-delivery";

const AssessmentDeliveryPage = () => {
  const { assessmentDeliveryId: assessmentDeliveryIdParam } = useParams<{
    assessmentDeliveryId: string;
  }>();
  const assessmentDeliveryId = parseInt(assessmentDeliveryIdParam);

  return <AssessmentDelivery assessmentDeliveryId={assessmentDeliveryId} />;
};

export default AssessmentDeliveryPage;

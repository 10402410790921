import { gql } from "@apollo/client";

import { MeetingParticipantsNewPageFragment } from "./meeting-participants-fragment";
import MeetingViewMeetingGroupNewPageFragment from "./meeting-view-meeting-group-new-page-fragment";
import TopicNodeFragment from "./topic-node-fragment";

const MeetingViewMeetingNodeFragment = gql`
  ${MeetingViewMeetingGroupNewPageFragment}
  ${MeetingParticipantsNewPageFragment}
  ${TopicNodeFragment}
  fragment MeetingViewMeetingNodeNewPageFragment on MeetingNode {
    id
    title
    startDatetime
    startDate
    endDatetime
    externalUrl
    videoConferenceType
    videoConferenceUrl
    allowExternalViewers
    writableSharingUrl
    websocketToken
    hasPreviousMeetings
    hasPreviousMeetingsWithTopics
    previousMeeting {
      id
      startDate
    }
    ignored
    hasBotInMeeting
    botIsRecording
    botIsWaitingToJoinMeeting
    canFinalize {
      permission
    }
    canDelete {
      permission
    }
    canUpdate {
      permission
    }
    finalizedAt
    isFinalized
    meetingGroupId
    finalizedBy {
      id
      name
      avatar
    }
    isSubmitted
    hasIncompleteMandatoryTopics
    transcriptionStatus
    id
    created
    draft
    isRecurring
    organization {
      id
      name
      effectivePricingTier
      enableMeetingSummarization
      domains
    }
    hasExternalParticipants
    organizer {
      id
      name
    }
    subject {
      id
    }
    creator {
      id
      name
      firstName
      lastName
      avatar
    }
    transcript {
      id
    }
    ...MeetingParticipantsNewPageFragment
    meetingGroup {
      ...MeetingViewMeetingGroupNewPageFragment
    }
    topics {
      edges {
        node {
          ...TopicNodeNewPageFragment
        }
      }
    }
    assessmentDeliveriesMatching1on1Template(first: 1) {
      edges {
        node {
          id
          target {
            id
            name
          }
          complianceProgram {
            id
            title
            requiredTopicTemplates {
              edges {
                node {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MeetingViewMeetingNodeFragment;

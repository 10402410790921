import { RecognitionArtifactComponentFragmentFragment } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import Avatars from "@components/avatar/avatars";
import { assertEdgesNonNull } from "@helpers/helpers";

const ArtifactRecognitionRecipients = ({
  artifact,
}: {
  artifact: RecognitionArtifactComponentFragmentFragment;
}) => {
  const label = useLabel();

  const recipients = artifact.recipients
    ? assertEdgesNonNull(artifact.recipients)
    : [];
  return artifact.recipients?.totalCount === 1 ? (
    <Avatar
      user={recipients[0]}
      size="5"
      tooltipSuffix={` received a ${label("recognition")}`}
    />
  ) : (artifact.recipients?.totalCount || 0) > 1 ? (
    <Avatars max={4} users={recipients} size={5} modalTitle="Recipients" />
  ) : null;
};

export default ArtifactRecognitionRecipients;

import { withErrorBoundary } from "@sentry/react";
import { compact } from "lodash";
import { PiStarFourFill } from "react-icons/pi";
import {
  ArtifactType,
  GetMeetingTranscriptQueryQuery,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import NoTranscriptCta from "@apps/meeting-new/components/no-transcript-cta";
import TranscriptionParticipation from "@apps/meeting-transcription-dialog/participation";
import TranscriptionText from "@apps/meeting-transcription-dialog/transcription-text";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  MeetingSummaryLengthButtonToggle,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Button from "@components/button/button";
import Error from "@components/error/error";
import Loading from "@components/loading/loading";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
  getUrl,
} from "@helpers/helpers";

import PrepGroup from "./prep-group";
import MeetingSummaryGroupTranscriptionSuggestedArtifact from "./summary-group-transcription-suggested-artifact";

const ArtifactSummaryGroup = ({
  artifactType,
  meeting,
  meetingGroupId,
}: {
  artifactType: ArtifactType;
  meeting: NonNullable<GetMeetingTranscriptQueryQuery["meeting"]>;
  meetingGroupId: number;
}) => {
  const label = useLabel();
  const suggestedArtifacts = meeting.transcript?.suggestedArtifacts
    ? assertEdgesNonNull(meeting.transcript.suggestedArtifacts)
    : [];
  const filteredSuggestedArtifacts = suggestedArtifacts.filter(
    (suggestedArtifact) => suggestedArtifact.artifactType === artifactType
  );
  const createdArtifacts = assertEdgesNonNull(meeting.artifacts).filter(
    (node) => node.id && node.artifactType === artifactType
  );
  const total = filteredSuggestedArtifacts.length + createdArtifacts.length;

  if (total === 0) return null;

  return (
    <PrepGroup
      title={
        <div className="flex items-center gap-2">
          {label(artifactType, { pluralize: true, titleCase: true })}
          <div className="flex items-center">
            <span className="bg-gray-100 rounded-md px-1.5 py-0.5 font-medium text-xs">
              {total}
            </span>
          </div>
        </div>
      }
      collapsible={true}
      isCollapsedByDefault={false}
    >
      <div className="flex flex-col px-6 py-4 gap-4">
        {createdArtifacts.map((artifact) => (
          <div className="" key={artifact.id}>
            <Artifact
              urlPrefix={getUrl({
                meetingGroupId: meetingGroupId,
                meetingId: meeting.id,
              })}
              artifact={artifact}
            />
          </div>
        ))}
        {filteredSuggestedArtifacts.map((suggestedArtifact) => (
          <MeetingSummaryGroupTranscriptionSuggestedArtifact
            key={suggestedArtifact.id}
            meeting={meeting}
            suggestedArtifact={suggestedArtifact}
          />
        ))}
      </div>
    </PrepGroup>
  );
};

const MeetingSummaryGroupTranscription = ({
  meeting,
  meetingGroupId,
  refetch,
  loading,
}: {
  meeting: NonNullable<GetMeetingTranscriptQueryQuery["meeting"]>;
  meetingGroupId: number;
  refetch: () => void;
  loading: boolean;
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const handleChangeSummaryLength = (
    option: ToggleButtonGroupType<MeetingSummaryLengthButtonToggle>
  ) => {
    saveUiPreference({ meetingSummaryLength: option.value });
  };
  const hasSummary =
    meeting.transcript?.summary ||
    meeting.transcript?.mediumSummary ||
    meeting.transcript?.longSummary;
  const orderedTranscriptData = meeting.transcript?.orderedTranscriptData;
  const highlightList = meeting.transcript?.highlightList
    ? assertEdgesNonNullWithStringId(meeting.transcript.highlightList)
    : [];

  if (!meeting.organization)
    return (
      <PrepGroup title="Summary">
        <div className="mt-1 fs-mask text-gray-500">
          The meeting is not associated to an existing organization. The notes
          and/or transcript cannot be summarized.
        </div>
      </PrepGroup>
    );

  const summaryOptions = compact([
    meeting.transcript?.summary && {
      active:
        uiPreferenceCache.meetingSummaryLength ===
        MeetingSummaryLengthButtonToggle.short,
      title: MeetingSummaryLengthButtonToggle.short,
      value: MeetingSummaryLengthButtonToggle.short,
    },
    meeting.transcript?.mediumSummary && {
      active:
        uiPreferenceCache.meetingSummaryLength ===
        MeetingSummaryLengthButtonToggle.medium,
      title: MeetingSummaryLengthButtonToggle.medium,
      value: MeetingSummaryLengthButtonToggle.medium,
    },
    meeting.transcript?.longSummary && {
      active:
        uiPreferenceCache.meetingSummaryLength ===
        MeetingSummaryLengthButtonToggle.long,
      title: MeetingSummaryLengthButtonToggle.long,
      value: MeetingSummaryLengthButtonToggle.long,
    },
  ]);

  return (
    <>
      {hasSummary && (
        <PrepGroup
          title={
            <div className="w-full flex items-center gap-4">
              Summary
              <ToggleButtonGroup<MeetingSummaryLengthButtonToggle>
                theme={ToggleButtonGroupTheme.roundedCompact}
                buttons={summaryOptions}
                onClick={handleChangeSummaryLength}
              />
            </div>
          }
        >
          <div className="px-6 py-4 text-gray-500">
            <div
              className="text-gray-500 leading-6"
              dangerouslySetInnerHTML={{
                __html:
                  (uiPreferenceCache.meetingSummaryLength ===
                  MeetingSummaryLengthButtonToggle.short
                    ? meeting.transcript?.summary
                    : uiPreferenceCache.meetingSummaryLength ===
                      MeetingSummaryLengthButtonToggle.medium
                    ? meeting.transcript?.mediumSummary
                    : meeting.transcript?.longSummary) || "No summary",
              }}
            />
          </div>
        </PrepGroup>
      )}

      {[
        ArtifactType.ActionItem,
        ArtifactType.Goal,
        ArtifactType.Recognition,
        ArtifactType.Feedback,
      ].map((artifactType) => (
        <ArtifactSummaryGroup
          key={artifactType}
          artifactType={artifactType}
          meeting={meeting}
          meetingGroupId={meetingGroupId}
        />
      ))}

      <PrepGroup
        title={
          <div className="flex items-center gap-2">
            Highlights
            <div className="flex items-center">
              <span className="bg-gray-100 rounded-md p-1 font-medium text-xs">
                <PiStarFourFill className="w-3 h-3" />
              </span>
            </div>
          </div>
        }
        collapsible={true}
        isCollapsedByDefault={false}
      >
        <div className="px-6 py-4">
          <div className="flex justify-center mt-2 mb-6 bg-gray-50 rounded-xl p-4">
            <TranscriptionParticipation meeting={meeting} />
          </div>
          <div className="text-gray-600 whitespace-pre-line fs-mask">
            {highlightList.length > 0 ? (
              <ul className="list-disc ml-4 mt-2 space-y-3">
                {highlightList.map((node) => (
                  <li key={node.id}>{node.highlight}</li>
                ))}
              </ul>
            ) : (
              <span>No highlights.</span>
            )}
          </div>
        </div>
      </PrepGroup>

      {/* Transcript */}
      {meeting.hasTranscript &&
      orderedTranscriptData &&
      orderedTranscriptData.length > 0 ? (
        <>
          <PrepGroup
            title="Transcript"
            collapsible={true}
            isCollapsedByDefault={true}
          >
            <div className="px-6 py-1">
              <div className="mt-4 fs-mask">
                <TranscriptionText meeting={meeting} />
              </div>
              <div className="mt-4 flex justify-center gap-2">
                <Button
                  onClick={() => refetch()}
                  disabled={loading}
                  text="Refresh"
                />
                <Loading
                  mini
                  size={5}
                  className={loading ? "opacity-100" : "opacity-0"}
                />
              </div>
            </div>
          </PrepGroup>
        </>
      ) : (
        <PrepGroup title="Transcript">
          <div className="mt-1 fs-mask text-gray-500 py-4 px-6">
            <NoTranscriptCta
              videoConferenceType={meeting.videoConferenceType}
            />
          </div>
        </PrepGroup>
      )}
    </>
  );
};

export default withErrorBoundary(MeetingSummaryGroupTranscription, {
  fallback: (
    <Error description={"The meeting summary could not be rendered."} />
  ),
});

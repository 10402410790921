import {
  LockClosedIcon,
  LockOpenIcon,
  MicrophoneIcon,
  PlayIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import { RiExpandDiagonalLine } from "react-icons/ri";
import { RiCollapseDiagonalLine } from "react-icons/ri";
import {
  GetMeetingNewPageQueryQuery,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import MeetingDropdownMenu from "@apps/meeting-dropdown-menu/meeting-dropdown-menu";
import MeetingVideoConferenceButton from "@apps/meeting-new/components/meeting/video-conference-button";
import MeetingPresentationDialog from "@apps/meeting-presentation-dialog/meeting-presentation-dialog";
import MeetingContentAccessDropdown from "@apps/meeting-settings/components/content-access-dropdown";
import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Button from "@components/button/button";
import { buttonTheme } from "@components/button/button";
import AppPopover from "@components/popover/app-popover";
import { classNames } from "@helpers/css";

import { getMeetingAllowVisibilityLabel } from "../helpers";
import AIAssistMeetingBot, {
  AIAssistMeetingBotPopover,
} from "./meeting-sidebar/ai-assist-bot";
import ShareNotesDialog from "./share-meeting/share-meeting-notes-dialog";

export enum MeetingViewEnum {
  notes = "Agenda & Notes",
  summary = "Meeting Recap",
  previousMeetingSummary = "Last Meeting Recap",
}

const MeetingPageHeader = ({
  meetingGroup,
  meeting,
}: {
  meetingGroup: NonNullable<
    NonNullable<GetMeetingNewPageQueryQuery["meeting"]>["meetingGroup"]
  >;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const [isShowingPresentationMode, setIsShowingPresentationMode] =
    useState(false);
  const [isShowingShareNotesDialog, setIsShowingShareNotesDialog] =
    useState(false);

  const handleShareNotesDialog = () => {
    setIsShowingShareNotesDialog(true);
  };

  const handleCloseInvitationDialog = () => {
    setIsShowingShareNotesDialog(false);
  };

  const isMeetingPageFullyExpanded =
    !uiPreferenceCache.leftNavigationSidebarExpanded &&
    !uiPreferenceCache.meetingSidebarExpanded;

  const handleToggleMeetingPageFullyExpanded = () => {
    saveUiPreference({
      leftNavigationSidebarExpanded: isMeetingPageFullyExpanded,
      meetingSidebarExpanded: isMeetingPageFullyExpanded,
    });
  };

  return waffle.flag_is_active("meeting-controls") ? (
    <div className="flex items-center gap-4 mr-4">
      <div className="relative flex items-center">
        <AIAssistMeetingBotPopover meeting={meeting} />
      </div>
      <Button
        tooltip={isMeetingPageFullyExpanded ? "Collapse View" : "Expand View"}
        onClick={handleToggleMeetingPageFullyExpanded}
      >
        {isMeetingPageFullyExpanded ? (
          <RiCollapseDiagonalLine className="h-5 w-5" />
        ) : (
          <RiExpandDiagonalLine className="h-5 w-5" />
        )}
        {isMeetingPageFullyExpanded ? "Exit Fullscreen" : "Enter Fullscreen"}
      </Button>
    </div>
  ) : (
    <>
      {isShowingShareNotesDialog && (
        <ShareNotesDialog
          meeting={meeting}
          onClose={handleCloseInvitationDialog}
          isInviteDialog={false}
        />
      )}
      {isShowingPresentationMode && (
        <MeetingPresentationDialog
          meeting={meeting}
          onClose={() => setIsShowingPresentationMode(false)}
        />
      )}
      <>
        <Button
          theme={buttonTheme.iconGray}
          className={classNames("hidden sm:flex")}
          tooltip="Present meeting notes"
          onClick={() => setIsShowingPresentationMode(true)}
          icon={PlayIcon}
        />

        <MeetingVideoConferenceButton
          meeting={meeting}
          className={classNames("hidden sm:flex")}
          textClassName="hidden"
          iconClassName="h-5 w-5"
          compact
        />

        {/* Privacy */}
        <div className="relative hidden sm:block">
          <MeetingContentAccessDropdown meetingGroup={meetingGroup}>
            <AppPopover.Button
              as={Button}
              theme={buttonTheme.iconGray}
              icon={
                meetingGroup.allowAdminVisibility ||
                meetingGroup.allowOrgVisibility
                  ? LockOpenIcon
                  : LockClosedIcon
              }
              aria-label="Meeting permission dropdown menu button"
              disabled={!meetingGroup?.canUpdate?.permission}
              tooltip={`These people can access the notes of this meeting: ${getMeetingAllowVisibilityLabel(
                {
                  allowManagementTreeVisibility:
                    meetingGroup.allowManagementTreeVisibility,
                  allowAdminVisibility: meetingGroup.allowAdminVisibility,
                  allowOrgVisibility: meetingGroup.allowOrgVisibility,
                }
              )}`}
            />
          </MeetingContentAccessDropdown>
        </div>

        {/* Microphone */}
        <div className="relative hidden sm:block">
          <AppPopover
            options={{
              className: classNames(AppPopover.className, "w-144"),
            }}
            content={<AIAssistMeetingBot meeting={meeting} />}
          >
            <AppPopover.Button
              as={Button}
              theme={buttonTheme.iconGray}
              className={classNames(
                meeting.hasBotInMeeting &&
                  meeting.botIsRecording &&
                  "text-red-600"
              )}
              icon={MicrophoneIcon}
              tooltip={`Open recording options (${
                meeting.hasBotInMeeting && meeting.botIsRecording
                  ? `recording`
                  : "not recording"
              })`}
            />
          </AppPopover>
        </div>

        <MeetingDropdownMenu
          meeting={meeting}
          meetingGroup={meetingGroup}
          size="5"
          onOpenSendNotesDialog={handleShareNotesDialog}
        />
      </>
    </>
  );
};

export default MeetingPageHeader;

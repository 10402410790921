import { useMemo } from "react";
import {
  Maybe,
  SaveComplianceProgramMutationVariables,
  TopicTemplateComplianceRequirement,
} from "types/graphql-schema";

import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import FormFields from "@components/form/form-fields";
import { assertNonNull } from "@helpers/helpers";

import { ComplianceProgramStep } from "../compliance-program-form-steps";
import ComplianceProgramFormStep from "./compliance-program-form-step";

const OneOnOneStep = ({
  proposedComplianceProgram,
  onUpdateProposedComplianceProgram,
  canUpdate,
  complianceProgramSteps,
  onUpdateComplianceProgramSteps,
  availableTopicTemplates,
}: {
  proposedComplianceProgram: SaveComplianceProgramMutationVariables;
  onUpdateProposedComplianceProgram: (
    data: SaveComplianceProgramMutationVariables
  ) => void;
  canUpdate: boolean;
  complianceProgramSteps: ComplianceProgramStep[];
  onUpdateComplianceProgramSteps: (steps: ComplianceProgramStep[]) => void;
  availableTopicTemplates: ComboboxGenericOption<number>[];
}) => {
  const selectedTopicTemplateId = useMemo(
    () =>
      proposedComplianceProgram.topicTemplates
        ? (proposedComplianceProgram.topicTemplates as Maybe<TopicTemplateComplianceRequirement>[])
        : [],
    [proposedComplianceProgram.topicTemplates]
  );

  return (
    <ComplianceProgramFormStep
      canDelete={canUpdate}
      title="1:1 Meeting"
      onDelete={() => {
        onUpdateProposedComplianceProgram({
          ...proposedComplianceProgram,
          topicTemplates: [],
        });
        onUpdateComplianceProgramSteps(
          complianceProgramSteps.filter(
            (s) => s !== ComplianceProgramStep.ONE_ON_ONE
          )
        );
      }}
    >
      <div className="p-6">
        <FormFields
          fields={[
            {
              title: "Meeting template",
              render: () => (
                <ComboboxGeneric
                  options={availableTopicTemplates}
                  disabled={!canUpdate}
                  onChangeValue={(opt) =>
                    onUpdateProposedComplianceProgram({
                      ...proposedComplianceProgram,
                      topicTemplates: [{ id: opt.value }],
                    })
                  }
                  value={
                    selectedTopicTemplateId.length
                      ? availableTopicTemplates.find(
                          ({ value }) =>
                            value ===
                            assertNonNull(selectedTopicTemplateId[0]).id
                        ) ?? null
                      : null
                  }
                />
              ),
            },
          ]}
        />
      </div>
    </ComplianceProgramFormStep>
  );
};
export default OneOnOneStep;

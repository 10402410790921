import { useMutation } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import { uniqBy } from "lodash";
import {
  GetArtifactSidebarQueryQuery,
  RecognitionArtifactNode,
  UserNode,
} from "types/graphql-schema";

import Avatars from "@components/avatar/avatars";
import CoreValueIcon from "@components/core-value-icon/core-value-icon";
import RecipientDropdown from "@components/people-dropdown/recipient-dropdown";
import TimeAgoCustom from "@components/time-ago/time-ago";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { UserComboboxUserOption } from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import getArtifactActivitiesQuery from "../graphql/get-artifact-activities-query";
import updateSidebarArtifactMutation from "../graphql/update-sidebar-artifact-mutation";
import ArtifactSidebarHeader from "./header";

const maxRecipients = 4;

const ArtifactSidebarRecognition = ({
  artifact,
}: {
  artifact: GetArtifactSidebarQueryQuery["artifact"];
}) => {
  const recognition = artifact as NonNullable<RecognitionArtifactNode>;
  const recipients = recognition.recipients
    ? assertEdgesNonNull(recognition.recipients)
    : [];

  const [updateArtifact] = useMutation(updateSidebarArtifactMutation);

  const updateArtifactRecipients = (newRecipientEdges: any) => {
    const recognitionRecipients = newRecipientEdges.map(
      (edge: any) => edge?.node.id
    );
    const optimisticResponse = {
      createOrUpdateArtifact: {
        artifact: {
          ...artifact,
          recipients: {
            ...recognition.recipients,
            totalCount: recognitionRecipients.length,
            edges: newRecipientEdges,
          },
        },
      },
    };
    updateArtifact({
      variables: {
        artifactId: recognition.id,
        additionalFields: { recognitionRecipients },
      },
      optimisticResponse,
      refetchQueries: [getArtifactActivitiesQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  const handleRemoveRecognitionRecipient =
    (recipientToRemove: Partial<UserNode>) => () => {
      if (
        window.confirm(
          `Are you sure you want to remove ${recipientToRemove.name}?`
        )
      ) {
        const newRecipientEdges = recognition.recipients?.edges.filter(
          (edge) => recipientToRemove.id !== edge?.node?.id
        );
        updateArtifactRecipients(newRecipientEdges);
      }
    };

  const handleAddRecognitionRecipient = (
    recipientToAdd: UserComboboxUserOption
  ) => {
    const originalEdges = recognition.recipients?.edges || [];
    const newRecipientEdges = uniqBy(
      [
        ...originalEdges,
        {
          __typename: "UserNodeEdge",
          node: recipientToAdd,
        },
      ],
      (edge) => edge?.node?.id
    );
    updateArtifactRecipients(newRecipientEdges);
  };

  return (
    <>
      <div className="pt-2 px-4 sm:px-6 pb-8 bg-white flex flex-col gap-4 text-center tracking-tight">
        <div className="font-medium text-lg justify-center flex gap-2 flex-wrap">
          {recipients.map((recipient, i) => (
            <span key={recipient.id} className="relative group">
              {recipients.length > 1 && artifact.canUpdate.permission && (
                <button
                  className="p-0.5 border rounded-full bg-gray-100 hover:bg-gray-200 absolute -top-1 -right-1 hidden group-hover:block"
                  onClick={handleRemoveRecognitionRecipient(recipient)}
                >
                  <XIcon className="h-3 w-3 text-gray-500" />
                </button>
              )}
              {recipient.name}
              {i < recipients.length - 1 ? "," : ""}
            </span>
          ))}
          {artifact.canUpdate.permission && (
            <div className="flex items-center">
              <RecipientDropdown
                recipients={recipients}
                onChangeRecipient={handleAddRecognitionRecipient}
              >
                {({ setReferenceElement, onClickButton }) => (
                  <Popover.Button
                    className="flex ml-1"
                    onClick={onClickButton}
                    ref={setReferenceElement}
                  >
                    <PlusCircleIcon className="h-5 w-5 text-gray-500" />
                  </Popover.Button>
                )}
              </RecipientDropdown>
            </div>
          )}
        </div>
        <div className="flex justify-center items-start gap-4">
          <div
            className={classNames(
              "h-16 w-16 flex items-center justify-center flex-none",
              !recognition.recognitionCoreValue?.image &&
                "text-4xl rounded-full ring-4 ring-gray-200"
            )}
          >
            <CoreValueIcon
              emoji={recognition.recognitionCoreValue?.emoji}
              image={recognition.recognitionCoreValue?.image}
              imagePxSize={64}
            />
          </div>
          <div>
            <Avatars
              users={recipients}
              max={maxRecipients}
              size={16}
              extraClassName="text-sm"
              modalTitle="Recipients"
            />
          </div>
        </div>
        <div className="flex flex-col gap-1.5">
          {recognition.recognitionCoreValue && (
            <div className="font-medium text-sm uppercase tracking-tight">
              {recognition.recognitionCoreValue.title}
            </div>
          )}
          <div className="text-xs text-gray-500">
            <TimeAgoCustom date={recognition.created} /> by{" "}
            {recognition.creator!.name}
          </div>
        </div>

        <div className="flex justify-center -mr-4">
          <ArtifactSidebarHeader artifact={artifact} className="text-lg" />
        </div>
      </div>
    </>
  );
};

export default ArtifactSidebarRecognition;

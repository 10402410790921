import { useQuery } from "@apollo/client";
import { compact } from "lodash";
import {
  GetDeliveredEngagementSurveyQuery,
  GetDeliveredEngagementSurveyQueryVariables,
} from "types/graphql-schema";

import AssessmentLayout from "@apps/assessments/components/layouts/assessment-layout";
import getDeliveredEngagementSurveyQuery from "@apps/engagement-survey/graphql/get-delivered-engagement-survey-query";
import Layout from "@components/layout/layout";
import { useNotificationError } from "@components/use-error/use-error";
import usePkParams from "@helpers/hooks/use-pk-params";

import EngagementSurveyDeliveryContent from "./engagement-survey-delivery-content";
import EngagementSurveyDeliveryHeader from "./engagement-survey-delivery-header";

const EngagementSurveyDelivery = () => {
  const assessmentDeliveryId = usePkParams("assessmentDeliveryId");
  const { onError } = useNotificationError();

  const { data: assessmentDeliveryData, loading: isLoadingAssessmentDelivery } =
    useQuery<
      GetDeliveredEngagementSurveyQuery,
      GetDeliveredEngagementSurveyQueryVariables
    >(getDeliveredEngagementSurveyQuery, {
      fetchPolicy: "no-cache",
      variables: {
        assessmentDeliveryId,
      },
      onError,
    });

  return (
    <Layout className="bg-white">
      <Layout.Header
        breadcrumbs={compact([
          {
            title: "Surveys",
            url: "/survey",
          },
          assessmentDeliveryData && {
            title: `Survey: ${
              assessmentDeliveryData.assessmentDelivery?.complianceProgram
                ?.title || "Untitled"
            }`,
            url: `/survey/delivery/${assessmentDeliveryId}`,
          },
        ])}
        className="bg-white"
      />
      <Layout.Container loading={isLoadingAssessmentDelivery}>
        <Layout.Main>
          <div className="mt-8" aria-label="Surveys > delivery">
            <div className="flex flex-col">
              <EngagementSurveyDeliveryHeader
                assessmentDeliveryData={assessmentDeliveryData}
              />

              <AssessmentLayout.AssessmentTypeSection>
                <div className="mt-12">
                  <EngagementSurveyDeliveryContent
                    assessmentDeliveryData={
                      assessmentDeliveryData?.assessmentDelivery
                    }
                  />
                </div>
              </AssessmentLayout.AssessmentTypeSection>
            </div>
          </div>
        </Layout.Main>
      </Layout.Container>
    </Layout>
  );
};

export default EngagementSurveyDelivery;

import { useMutation, useQuery } from "@apollo/client";
import { compact, omit } from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { AiOutlineTeam } from "react-icons/ai";
import { FaRegThumbsUp } from "react-icons/fa6";
import { useLocation, useParams } from "react-router-dom";
import {
  AssessmentGroupAnonymity,
  AssessmentGroupDelivery,
  AssessmentGroupFragmentFragment,
  AssessmentGroupProviders,
  AssessmentTemplateComplianceRequirement,
  AssessmentType,
  CompetencyAssessmentType,
  ComplianceProgramAppliesTo,
  ComplianceProgramRecurrence,
  ComplianceProgramState,
  GetAssessmentGroupsQuery,
  GetAssessmentGroupsQueryVariables,
  GetComplianceProgramAdminQuery,
  GetComplianceProgramAdminQueryVariables,
  GetTopicTemplatesQuery,
  GetTopicTemplatesQueryVariables,
  SaveComplianceProgramMutation,
  SaveComplianceProgramMutationVariables,
} from "types/graphql-schema";
import { PastOnlyDateRangeEnum } from "types/topicflow";

import getAssessmentGroupsQuery from "@apps/assessments/graphql/get-assessment-groups-query";
import createOrUpdateComplianceProgramMutation from "@apps/programs/graphql/create-or-update-compliance-program-mutation";
import getComplianceProgramAdminQuery from "@apps/programs/graphql/get-compliance-program-admin-query";
import getComplianceProgramsQuery from "@apps/programs/graphql/get-compliance-programs-query";
import getTemplatesQuery from "@apps/templates/graphql/get-templates-query";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import { ComboboxGenericOption } from "@components/combobox/generic-combobox";
import HeroButtons from "@components/hero-buttons/hero-buttons";
import Layout from "@components/layout/layout";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import {
  assertEdgesNonNull,
  assertNonNull,
  dateRangeToDateArray,
} from "@helpers/helpers";

import ComplianceProgramFormSteps, {
  ComplianceProgramStep,
} from "./compliance-program-form-steps";
import ComplianceProgramFormTopFields from "./compliance-program-form-top-fields";

const complianceProgramUrl = "/programs";

export enum ExtraDateRangeEnum {
  none = "none",
  custom = "custom",
}
export type DateRangeType = ExtraDateRangeEnum | PastOnlyDateRangeEnum;

export type AssessmentTemplateExtraSettings = Partial<
  Pick<
    AssessmentTemplateComplianceRequirement,
    | "anonymity"
    | "providers"
    | "delivery"
    | "enableRoleBasedAssessments"
    | "enableGoalAssessments"
    | "roleBasedAssessmentsAssessNextRole"
    | "roleBasedAssessmentsSelfAssessNextRole"
    | "competencyAssessmentType"
    | "nextRoleCompetencyAssessmentType"
    | "roleBasedAssessmentScale"
    | "roleBasedAssessmentScaleLabels"
    | "roleBasedAssessmentScaleStartsAt"
  >
>;

export enum ComplianceProgramType {
  REVIEW = "review",
  ENGAGEMENT_SURVEY = "engagement_survey",
}

const ComplianceProgramForm = () => {
  const link = useLink();
  const location = useLocation();
  const label = useLabel();
  const organization = currentOrganizationVar();
  const { onError } = useNotificationError();
  const { complianceProgramId: complianceProgramIdParam } = useParams<{
    complianceProgramId: string;
  }>();
  const complianceProgramId = parseInt(complianceProgramIdParam);
  const isNew = isNaN(complianceProgramId);
  const isDuplicate = !isNew && location.pathname.includes("/duplicate");

  const [complianceProgramSteps, setComplianceProgramSteps] = useState<
    ComplianceProgramStep[]
  >([]);
  const [complianceProgramType, setComplianceProgramType] =
    useState<ComplianceProgramType>(ComplianceProgramType.REVIEW);
  const [proposedComplianceProgram, setProposedComplianceProgram] =
    useState<SaveComplianceProgramMutationVariables>({
      appliesTo: ComplianceProgramAppliesTo.Organization,
      appliesToTeams: [],
      recurrence: ComplianceProgramRecurrence.None,
    });
  const [selectedProgramPeriodRange, setSelectedProgramPeriodRange] =
    useState<DateRangeType>(ExtraDateRangeEnum.none);
  const [availableAssessmentGroups, setAvailableAssessmentGroups] = useState<
    AssessmentGroupFragmentFragment[]
  >([]);
  const [availableTopicTemplates, setAvailableTopicTemplates] = useState<
    ComboboxGenericOption<number>[]
  >([]);
  const [appliesToTeams, setAppliesToTeams] = useState<
    { id: number; title: string }[]
  >([]);
  const [appliesToManagers, setAppliesToManagers] = useState<
    { id: number; name: string }[]
  >([]);
  const [appliesToUsers, setAppliesToUsers] = useState<
    { id: number; name: string }[]
  >([]);
  const [excludedUsers, setExcludedUsers] = useState<
    { id: number; name: string }[]
  >([]);
  const [canUpdateTemplates, setCanUpdateTemplates] = useState(isNew);

  const homeUrl = useMemo(
    () =>
      isNew || isDuplicate
        ? complianceProgramUrl
        : `${complianceProgramUrl}/${complianceProgramId}`,
    [complianceProgramId, isNew, isDuplicate]
  );

  const [
    createOrUpdateComplianceProgram,
    { loading: isSavingComplianceProgram },
  ] = useMutation<
    SaveComplianceProgramMutation,
    SaveComplianceProgramMutationVariables
  >(createOrUpdateComplianceProgramMutation);

  const { loading: isLoadingComplianceProgram } = useQuery<
    GetComplianceProgramAdminQuery,
    GetComplianceProgramAdminQueryVariables
  >(getComplianceProgramAdminQuery, {
    variables: { complianceProgramId },
    skip: isNew,
    onError: onNotificationErrorHandler(),
    onCompleted: (response) => {
      if (!response.complianceProgram) {
        link.redirect(homeUrl);
      } else {
        const topicTemplates = assertEdgesNonNull(
          response.complianceProgram.requiredTopicTemplates
        );
        const assessmentTemplates = compact([
          response.complianceProgram.performanceAssessmentTemplate && {
            id: response.complianceProgram.performanceAssessmentTemplate.id,
            assessmentType: AssessmentType.Performance,
            anonymity:
              response.complianceProgram.performanceAssessmentTemplate
                .anonymity,
            providers:
              response.complianceProgram.performanceAssessmentTemplate
                .providers,
            delivery:
              response.complianceProgram.performanceAssessmentTemplate.delivery,
            enableRoleBasedAssessments:
              response.complianceProgram.performanceAssessmentTemplate
                .enableRoleBasedAssessments,
            enableGoalAssessments:
              response.complianceProgram.performanceAssessmentTemplate
                .enableGoalAssessments,
            roleBasedAssessmentsAssessNextRole:
              response.complianceProgram.performanceAssessmentTemplate
                .roleBasedAssessmentsAssessNextRole,
            roleBasedAssessmentsSelfAssessNextRole:
              response.complianceProgram.performanceAssessmentTemplate
                .roleBasedAssessmentsSelfAssessNextRole,
            competencyAssessmentType:
              response.complianceProgram.performanceAssessmentTemplate
                .competencyAssessmentType,
            nextRoleCompetencyAssessmentType:
              response.complianceProgram.performanceAssessmentTemplate
                .nextRoleCompetencyAssessmentType,
            roleBasedAssessmentScale:
              response.complianceProgram.performanceAssessmentTemplate
                .roleBasedAssessmentScale,
            roleBasedAssessmentScaleLabels:
              response.complianceProgram.performanceAssessmentTemplate
                .roleBasedAssessmentScaleLabels,
            roleBasedAssessmentScaleStartsAt:
              response.complianceProgram.performanceAssessmentTemplate
                .roleBasedAssessmentScaleStartsAt,
            questionSetIds:
              response.complianceProgram.performanceAssessmentTemplate.questionSets.edges.map(
                (edge) => assertNonNull(edge?.node?.id)
              ),
          },
          response.complianceProgram.managerAssessmentTemplate && {
            id: response.complianceProgram.managerAssessmentTemplate.id,
            assessmentType: AssessmentType.Manager,
            anonymity:
              response.complianceProgram.managerAssessmentTemplate.anonymity,
            providers:
              response.complianceProgram.managerAssessmentTemplate.providers,
            delivery:
              response.complianceProgram.managerAssessmentTemplate.delivery,
            questionSetIds:
              response.complianceProgram.managerAssessmentTemplate.questionSets.edges.map(
                (edge) => assertNonNull(edge?.node?.id)
              ),
          },
          response.complianceProgram.peerAssessmentTemplate && {
            id: response.complianceProgram.peerAssessmentTemplate.id,
            assessmentType: AssessmentType.Peer,
            anonymity:
              response.complianceProgram.peerAssessmentTemplate.anonymity,
            providers:
              response.complianceProgram.peerAssessmentTemplate.providers,
            delivery:
              response.complianceProgram.peerAssessmentTemplate.delivery,
            questionSetIds:
              response.complianceProgram.peerAssessmentTemplate.questionSets.edges.map(
                (edge) => assertNonNull(edge?.node?.id)
              ),
          },
          response.complianceProgram.engagementSurveyTemplate && {
            id: response.complianceProgram.engagementSurveyTemplate.id,
            assessmentType: AssessmentType.EngagementSurvey,
            anonymity:
              response.complianceProgram.engagementSurveyTemplate.anonymity,
            providers:
              response.complianceProgram.engagementSurveyTemplate.providers,
            delivery:
              response.complianceProgram.engagementSurveyTemplate.delivery,
            questionSetIds:
              response.complianceProgram.engagementSurveyTemplate.questionSets.edges.map(
                (edge) => assertNonNull(edge?.node?.id)
              ),
          },
        ]);
        setAppliesToTeams(
          assertEdgesNonNull(response.complianceProgram.appliesToTeams)
        );
        setAppliesToManagers(
          assertEdgesNonNull(response.complianceProgram.appliesToManagers)
        );
        setAppliesToUsers(
          assertEdgesNonNull(response.complianceProgram.appliesToUsers)
        );
        setExcludedUsers(
          assertEdgesNonNull(response.complianceProgram.excludedUsers)
        );

        const dateRangeOptions = Object.values(PastOnlyDateRangeEnum).map(
          (dateRange) => {
            const dates = dateRangeToDateArray({
              range: dateRange,
              quarterStartMonth: organization.quarterStartMonth,
            });
            return {
              value: dateRange,
              startDate: dates[0],
              endDate: dates[1],
            };
          }
        );
        const programHasPeriod =
          response.complianceProgram.periodStartDate !== null &&
          response.complianceProgram.periodEndDate !== null;
        const selectedPeriodEnum = programHasPeriod
          ? dateRangeOptions.find(({ startDate, endDate }) => {
              return (
                startDate === response.complianceProgram?.periodStartDate &&
                endDate === response.complianceProgram?.periodEndDate
              );
            })
          : null;

        setProposedComplianceProgram({
          ...response.complianceProgram,
          state: isDuplicate
            ? ComplianceProgramState.Draft
            : response.complianceProgram.state,
          complianceProgramId: response.complianceProgram.id,
          topicTemplates: topicTemplates.map(({ id }) => ({ id })),
          assessmentTemplates: isDuplicate
            ? assessmentTemplates.map((template) => omit(template, "id"))
            : assessmentTemplates,
          appliesTo: response.complianceProgram.appliesTo,
          appliesToTeams: appliesToTeams.map(({ id }) => id),
          appliesToManagers: appliesToManagers.map(({ id }) => id),
          appliesToUsers: appliesToUsers.map(({ id }) => id),
          excludedUsers: excludedUsers.map(({ id }) => id),
          periodStartDate: response.complianceProgram.periodStartDate,
          periodEndDate: response.complianceProgram.periodEndDate,
        });
        setSelectedProgramPeriodRange(
          selectedPeriodEnum
            ? selectedPeriodEnum.value
            : programHasPeriod
            ? ExtraDateRangeEnum.custom
            : ExtraDateRangeEnum.none
        );
        setComplianceProgramSteps(
          response.complianceProgram.engagementSurveyTemplate
            ? [ComplianceProgramStep.ENGAGEMENT_SURVEY]
            : compact([
                response.complianceProgram.performanceAssessmentTemplate &&
                  ComplianceProgramStep.SELF_MANAGER_REVIEW,
                response.complianceProgram.managerAssessmentTemplate &&
                  ComplianceProgramStep.UPWARD_REVIEW,
                response.complianceProgram.peerAssessmentTemplate &&
                  ComplianceProgramStep.PEER_REVIEW,
                topicTemplates.length > 0 && ComplianceProgramStep.ONE_ON_ONE,
              ])
        );
        setComplianceProgramType(
          response.complianceProgram.engagementSurveyTemplate
            ? ComplianceProgramType.ENGAGEMENT_SURVEY
            : ComplianceProgramType.REVIEW
        );

        setCanUpdateTemplates(
          isDuplicate ||
            response.complianceProgram.state === ComplianceProgramState.Draft
        );
      }
    },
  });

  const { loading: isLoadingAvailableAssessmentGroups } = useQuery<
    GetAssessmentGroupsQuery,
    GetAssessmentGroupsQueryVariables
  >(getAssessmentGroupsQuery, {
    variables: {
      organizationId: organization.id,
      archived: canUpdateTemplates ? false : null,
    },
    onCompleted: (response) => {
      const groups = response.assessmentGroups
        ? assertEdgesNonNull(response.assessmentGroups)
        : [];
      setAvailableAssessmentGroups(groups);
    },
    onError: onNotificationErrorHandler(),
  });

  const handleAssessmentGroupCreated = useCallback(
    (assessmentGroup: AssessmentGroupFragmentFragment) => {
      setAvailableAssessmentGroups((prev) => [
        ...prev.filter(({ id }) => id !== assessmentGroup.id),
        assessmentGroup,
      ]);
    },
    []
  );

  const { loading: isLoadingAvailableTopicTemplates } = useQuery<
    GetTopicTemplatesQuery,
    GetTopicTemplatesQueryVariables
  >(getTemplatesQuery, {
    onCompleted: (response) => {
      const templates = response.topicTemplates
        ? assertEdgesNonNull(response.topicTemplates)
        : [];
      setAvailableTopicTemplates(
        templates.map(({ id, title }) => ({ value: id, label: title }))
      );
    },
    onError: onNotificationErrorHandler(),
  });

  const handleSaveProgram = useCallback(
    (state: ComplianceProgramState) => {
      createOrUpdateComplianceProgram({
        variables: {
          ...proposedComplianceProgram,
          topicTemplates: canUpdateTemplates
            ? proposedComplianceProgram.topicTemplates
            : undefined,
          assessmentTemplates: canUpdateTemplates
            ? proposedComplianceProgram.assessmentTemplates
            : undefined,
          complianceProgramId:
            isNew || isDuplicate
              ? undefined
              : proposedComplianceProgram.complianceProgramId,
          organizationId: organization.id,
          state: state,
          appliesToTeams: appliesToTeams.map(({ id }) => id),
          appliesToManagers: appliesToManagers.map(({ id }) => id),
          appliesToUsers: appliesToUsers.map(({ id }) => id),
          excludedUsers: excludedUsers.map(({ id }) => id),
        },
        onError,
        refetchQueries: [getComplianceProgramsQuery],
        onCompleted: () => {
          successNotificationVar({
            title:
              state === ComplianceProgramState.Draft
                ? "Program saved for later"
                : "Program published",
          });
          link.redirect(homeUrl);
        },
      });
    },
    [
      appliesToManagers,
      appliesToTeams,
      appliesToUsers,
      excludedUsers,
      createOrUpdateComplianceProgram,
      homeUrl,
      link,
      onError,
      organization,
      proposedComplianceProgram,
      isNew,
      isDuplicate,
      canUpdateTemplates,
    ]
  );

  const handleChangeAssessmentTemplate = useCallback(
    (assessmentType: AssessmentType) =>
      (opt: ComboboxGenericOption<number> | null) => {
        const existingTemplate = proposedComplianceProgram.assessmentTemplates
          ? (
              proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[]
            ).find((template) => template.assessmentType === assessmentType)
          : undefined;

        const groupSettings = {
          anonymity: AssessmentGroupAnonymity.NotAnonymous,
          providers: AssessmentGroupProviders.Default,
          delivery: AssessmentGroupDelivery.Full,
          enableRoleBasedAssessments: false,
          enableGoalAssessments: false,
          roleBasedAssessmentsAssessNextRole: false,
          roleBasedAssessmentsSelfAssessNextRole: false,
          competencyAssessmentType: CompetencyAssessmentType.CriteriaCombined,
          nextRoleCompetencyAssessmentType:
            CompetencyAssessmentType.CriteriaCombined,
          roleBasedAssessmentScale: 3,
          roleBasedAssessmentScaleStartsAt: 1,
          roleBasedAssessmentScaleLabels: ["", "", ""],
        };
        const newAssessmentTemplates = opt
          ? existingTemplate
            ? (
                proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[]
              ).map((template) => {
                if (template.assessmentType === assessmentType) {
                  return {
                    ...template,
                    ...groupSettings,
                    questionSetIds: [opt.value],
                  };
                }
                return template;
              })
            : [
                ...((proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[]) ??
                  []),
                {
                  assessmentType: assessmentType,
                  ...groupSettings,
                  questionSetIds: [opt.value],
                },
              ]
          : (
              (proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[]) ??
              []
            ).filter((template) => template.assessmentType !== assessmentType);
        setProposedComplianceProgram({
          ...proposedComplianceProgram,
          assessmentTemplates: compact(newAssessmentTemplates),
        });
      },
    [proposedComplianceProgram]
  );

  const handleChangeAssessmentTemplateSettings = useCallback(
    (assessmentType: AssessmentType) =>
      (settings: AssessmentTemplateExtraSettings) => {
        const existingTemplate = proposedComplianceProgram.assessmentTemplates
          ? (
              proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[]
            ).find((template) => template.assessmentType === assessmentType)
          : undefined;
        if (!existingTemplate) {
          return;
        }
        const newAssessmentTemplates = (
          proposedComplianceProgram.assessmentTemplates as AssessmentTemplateComplianceRequirement[]
        ).map((template) => {
          if (template.assessmentType === assessmentType) {
            const newTemplate = {
              ...template,
              ...settings,
            };

            // keep the scale and number of labels in sync
            if (newTemplate.enableRoleBasedAssessments) {
              if (
                !newTemplate.roleBasedAssessmentScaleLabels ||
                !newTemplate.roleBasedAssessmentScale
              ) {
                throw new Error(
                  "Expecting Role based assessment scale and labels to be set"
                );
              }
              if (
                newTemplate.roleBasedAssessmentScaleLabels.length >
                newTemplate.roleBasedAssessmentScale
              ) {
                newTemplate.roleBasedAssessmentScaleLabels =
                  newTemplate.roleBasedAssessmentScaleLabels.slice(
                    0,
                    newTemplate.roleBasedAssessmentScale
                  );
              } else if (
                newTemplate.roleBasedAssessmentScaleLabels.length <
                newTemplate.roleBasedAssessmentScale
              ) {
                newTemplate.roleBasedAssessmentScaleLabels =
                  newTemplate.roleBasedAssessmentScaleLabels.concat(
                    Array(
                      newTemplate.roleBasedAssessmentScale -
                        newTemplate.roleBasedAssessmentScaleLabels.length
                    ).fill("")
                  );
              }
            }

            return newTemplate;
          }
          return template;
        });
        setProposedComplianceProgram({
          ...proposedComplianceProgram,
          assessmentTemplates: newAssessmentTemplates,
        });
      },
    [proposedComplianceProgram]
  );

  const handleChangeComplianceProgramType = useCallback(
    (type: ComplianceProgramType) => {
      setComplianceProgramType(type);
      // reset the assessment templates
      if (type === ComplianceProgramType.ENGAGEMENT_SURVEY) {
        setComplianceProgramSteps([ComplianceProgramStep.ENGAGEMENT_SURVEY]);
      } else {
        setComplianceProgramSteps([]);
      }
      setProposedComplianceProgram({
        ...proposedComplianceProgram,
        assessmentTemplates: [],
      });
    },
    [proposedComplianceProgram]
  );

  const isLoading =
    isLoadingComplianceProgram ||
    isLoadingAvailableAssessmentGroups ||
    isLoadingAvailableTopicTemplates;

  const canSaveTooltip =
    !proposedComplianceProgram.title ||
    proposedComplianceProgram.title.trim().length === 0
      ? "Please enter a title"
      : !proposedComplianceProgram.startDate &&
        !proposedComplianceProgram.startDateOffset
      ? "Please enter a start date"
      : !proposedComplianceProgram.dueDate &&
        !proposedComplianceProgram.startDateDueDateOffset
      ? "Please enter a due date"
      : selectedProgramPeriodRange === ExtraDateRangeEnum.custom &&
        (!proposedComplianceProgram.periodStartDate ||
          !proposedComplianceProgram.periodEndDate)
      ? "Please enter custom period dates"
      : complianceProgramSteps.length === 0
      ? "Please add at least one step"
      : moment(proposedComplianceProgram.startDate).isAfter(
          proposedComplianceProgram.dueDate
        )
      ? "Start date must be before due date"
      : selectedProgramPeriodRange !== ExtraDateRangeEnum.none &&
        moment(proposedComplianceProgram.periodStartDate).isAfter(
          proposedComplianceProgram.periodEndDate
        )
      ? "Program period start date must be before end date"
      : null;

  return (
    <Layout>
      <Layout.Header breadcrumbs={[{ title: "Programs", url: "/programs" }]}>
        {!isLoading && (
          <div className="flex justify-end items-center gap-2 sm:gap-4">
            <Button
              to={homeUrl}
              disabled={isSavingComplianceProgram}
              text="Discard changes"
            />
            {proposedComplianceProgram.state !==
              ComplianceProgramState.Published && (
              <Button
                type="button"
                onClick={() => handleSaveProgram(ComplianceProgramState.Draft)}
                tooltip={canSaveTooltip}
                disabled={!!canSaveTooltip || isSavingComplianceProgram}
                theme={buttonTheme.lightBlue}
                text={`${
                  isSavingComplianceProgram ? "Saving" : "Save"
                } for later`}
              />
            )}
            <Button
              type="button"
              onClick={() =>
                handleSaveProgram(ComplianceProgramState.Published)
              }
              tooltip={canSaveTooltip}
              disabled={!!canSaveTooltip || isSavingComplianceProgram}
              theme={buttonTheme.primary}
              text={
                proposedComplianceProgram.state !==
                ComplianceProgramState.Published
                  ? "Save and publish"
                  : "Save changes"
              }
            />
          </div>
        )}
      </Layout.Header>
      <Layout.Container>
        <Layout.Main>
          <div className="p-8">
            <div className="text-2xl font-semibold">{`${
              isNew ? "New" : isDuplicate ? "Duplicate" : "Edit"
            } Program`}</div>
            {isLoading ? (
              <div className="flex items-center justify-center h-full w-full">
                <Loading>Loading program...</Loading>
              </div>
            ) : (
              <>
                <div className="mt-8 mb-4 text-gray-600 text-sm">
                  What type of program will this be?
                </div>
                <HeroButtons
                  buttons={[
                    {
                      title: label("review", { capitalize: true }),
                      subtitle: "Self/manager, upward, and peer",
                      icon: AiOutlineTeam,
                      iconColor: "text-amber-800",
                      selected:
                        complianceProgramType === ComplianceProgramType.REVIEW,
                      disabled: !canUpdateTemplates,
                      onClick: () =>
                        handleChangeComplianceProgramType(
                          ComplianceProgramType.REVIEW
                        ),
                    },
                    {
                      title: "Survey",
                      subtitle: "Employee engagement",
                      icon: FaRegThumbsUp,
                      iconColor: "text-green-600",
                      selected:
                        complianceProgramType ===
                        ComplianceProgramType.ENGAGEMENT_SURVEY,
                      disabled:
                        !waffle.flag_is_active("engagement-survey") ||
                        !canUpdateTemplates,
                      tooltip: waffle.flag_is_active("engagement-survey")
                        ? null
                        : "Surveys coming soon",
                      onClick: () =>
                        handleChangeComplianceProgramType(
                          ComplianceProgramType.ENGAGEMENT_SURVEY
                        ),
                    },
                  ]}
                />
                <ComplianceProgramFormTopFields
                  proposedComplianceProgram={proposedComplianceProgram}
                  onUpdateProposedComplianceProgram={
                    setProposedComplianceProgram
                  }
                  canUpdate={canUpdateTemplates}
                  excludedUsers={excludedUsers}
                  onUpdateExcludedUsers={setExcludedUsers}
                  isSaving={isSavingComplianceProgram}
                  appliesToTeams={appliesToTeams}
                  onUpdateAppliesToTeams={setAppliesToTeams}
                  appliesToManagers={appliesToManagers}
                  onUpdateAppliesToManagers={setAppliesToManagers}
                  appliesToUsers={appliesToUsers}
                  onUpdateAppliesToUsers={setAppliesToUsers}
                  selectedProgramPeriodRange={selectedProgramPeriodRange}
                  setSelectedProgramPeriodRange={setSelectedProgramPeriodRange}
                />
                <ComplianceProgramFormSteps
                  complianceProgramType={complianceProgramType}
                  proposedComplianceProgram={proposedComplianceProgram}
                  onUpdateProposedComplianceProgram={
                    setProposedComplianceProgram
                  }
                  canUpdate={canUpdateTemplates}
                  complianceProgramSteps={complianceProgramSteps}
                  onUpdateComplianceProgramSteps={setComplianceProgramSteps}
                  onUpdateAssessmentTemplateSettings={
                    handleChangeAssessmentTemplateSettings
                  }
                  onUpdateAssessmentTemplate={handleChangeAssessmentTemplate}
                  availableAssessmentGroups={availableAssessmentGroups}
                  availableTopicTemplates={availableTopicTemplates}
                  onAssessmentGroupCreated={handleAssessmentGroupCreated}
                />
              </>
            )}
          </div>
        </Layout.Main>
      </Layout.Container>
    </Layout>
  );
};

export default ComplianceProgramForm;

import { gql } from "@apollo/client";

export default gql`
  fragment RelatedAssessmentTemplate on AssessmentTemplateNode {
    id
    hasSelfAssessment
    isOnlySelfAssessment
    assessmentType
    anonymity
    delivery
    assessments(targetId: $targetId) {
      edges {
        node {
          id
        }
      }
    }
  }
  fragment RelatedAssessmentUserMissingAssessment on MissingAssessmentNode {
    assessmentTemplate {
      id
      assessmentType
    }
    target {
      id
      name
    }
    responder {
      id
      name
    }
  }
  fragment RelatedAssessmentDelivery on AssessmentDeliveryNode {
    id
    state
    visibleResponders {
      edges {
        node {
          id
          name
          avatar
        }
      }
    }
    template {
      id
      assessmentType
    }
  }
  fragment RelatedAssessment on AssessmentNode {
    id
    state
    submittedDatetime
    responder {
      id
      name
      avatar
    }
    target {
      id
      name
      avatar
    }
    template {
      id
      assessmentType
    }
  }
  fragment RelatedComplianceProgram on ComplianceProgramNode {
    id
    performanceAssessmentTemplate {
      ...RelatedAssessmentTemplate
    }
    peerAssessmentTemplate {
      ...RelatedAssessmentTemplate
    }
    managerAssessmentTemplate {
      ...RelatedAssessmentTemplate
    }
    usersMissingAssessment(targetId: $targetId) {
      edges {
        node {
          ...RelatedAssessmentUserMissingAssessment
        }
      }
    }
    assessments(targetId: $targetId, state: submitted) {
      edges {
        node {
          ...RelatedAssessment
        }
      }
    }
    assessmentDeliveries(targetId: $targetId) {
      edges {
        node {
          ...RelatedAssessmentDelivery
        }
      }
    }
  }
  query getReviewSubmissions(
    $assessmentId: Int!
    $fetchAssessment: Boolean!
    $assessmentDeliveryId: IntOrString!
    $fetchAssessmentDelivery: Boolean!
    $targetId: Int!
  ) {
    assessmentDelivery(id: $assessmentDeliveryId)
      @include(if: $fetchAssessmentDelivery) {
      id
      complianceProgram {
        ...RelatedComplianceProgram
      }
    }
    assessment(assessmentId: $assessmentId) @include(if: $fetchAssessment) {
      id
      complianceProgram {
        ...RelatedComplianceProgram
      }
    }
  }
`;

import { useRouteMatch } from "react-router-dom";

import { isAdminVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import { useLink } from "@components/link/link";
import Tabs from "@components/tabs/tabs";

import EngagementSurveyHomepageActiveSurveys from "./homepage-active-surveys";
import EngagementSurveyHomepageDeliveries from "./homepage-deliveries";

enum EngagementSurveyHomepageTabs {
  Active = "Active",
  Delivered = "Delivered",
}

const EngagementSurveyHomepage = () => {
  const { path } = useRouteMatch();
  const isAdmin = isAdminVar();
  const link = useLink();

  const selectedTab =
    path === "/survey/delivered"
      ? EngagementSurveyHomepageTabs.Delivered
      : EngagementSurveyHomepageTabs.Active;

  return (
    <Layout>
      <Layout.Header
        breadcrumbs={[
          {
            title: "Surveys",
            url: "/survey",
          },
        ]}
      >
        {isAdmin && (
          <div className="flex justify-end gap-4 items-center">
            <Button
              text={`Create Survey program`}
              to="/programs/new"
              theme={buttonTheme.primary}
            />
          </div>
        )}
      </Layout.Header>
      <Layout.Container>
        <Layout.Main>
          <Layout.MainSection>
            <Tabs
              options={[
                {
                  value: EngagementSurveyHomepageTabs.Active,
                  label: "Active Surveys",
                },
                {
                  value: EngagementSurveyHomepageTabs.Delivered,
                  label: "Completed Surveys",
                },
              ]}
              selectedValue={selectedTab}
              onClickOptionValue={(value) => {
                link.redirect(
                  value === EngagementSurveyHomepageTabs.Active
                    ? "/survey"
                    : "/survey/delivered"
                );
              }}
            />

            {selectedTab === EngagementSurveyHomepageTabs.Active && (
              <EngagementSurveyHomepageActiveSurveys />
            )}

            {selectedTab === EngagementSurveyHomepageTabs.Delivered && (
              <EngagementSurveyHomepageDeliveries />
            )}
          </Layout.MainSection>
        </Layout.Main>
      </Layout.Container>
    </Layout>
  );
};

export default EngagementSurveyHomepage;

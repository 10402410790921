import { gql } from "@apollo/client";

const convertToReviewMeetingMutation = gql`
  mutation convertToReviewMeeting(
    $meetingId: Int!
    $topicTemplateId: Int!
    $clearExistingTopics: Boolean
  ) {
    addTemplateToMeeting(
      meetingId: $meetingId
      topicTemplateId: $topicTemplateId
      clearExistingTopics: $clearExistingTopics
      setAsSeriesTemplate: false
    ) {
      meeting {
        id
      }
    }
  }
`;

export default convertToReviewMeetingMutation;

import { useCallback, useEffect, useMemo, useState } from "react";

import { isConfirmationOpenVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";

const useConfirm = (title: string, message: string) => {
  const [promise, setPromise] = useState<{
    resolve: (val: boolean | PromiseLike<boolean>) => void;
  } | null>(null);

  const confirm = () =>
    new Promise<boolean>((resolve) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      promise?.resolve(true);
      handleClose();
    },
    [promise]
  );

  const handleCancel = useCallback(() => {
    promise?.resolve(false);
    handleClose();
  }, [promise]);

  useEffect(() => {
    isConfirmationOpenVar(promise !== null);
  }, [promise]);

  const ConfirmationDialog = useMemo(
    () => () =>
      (
        <Modal
          open={promise !== null}
          onClose={handleCancel}
          alignedTop
          className="z-tooltip"
        >
          <div className="p-6 flex flex-col gap-4">
            <ModalTitle onClose={handleCancel}>{title}</ModalTitle>
            <div>{message}</div>
            <div className="mt-6 flex items-center gap-2 justify-end">
              <Button
                theme={buttonTheme.text}
                onClick={handleCancel}
                text="Cancel"
              />
              <Button
                theme={buttonTheme.primary}
                onClick={handleConfirm}
                aria-label="Confirm modal confirm button"
                text="Confirm"
              />
            </div>
          </div>
        </Modal>
      ),
    [handleCancel, handleConfirm, message, promise, title]
  );
  return { ConfirmationDialog, confirm };
};

export default useConfirm;

import { useCallback, useState } from "react";
import { createPortal } from "react-dom";

import Button, { buttonTheme } from "@components/button/button";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { classNames } from "@helpers/css";

import { ComplianceProgramStep } from "./compliance-program-form-steps";

const Step = ({
  checked,
  title,
  description,
  onClick,
}: {
  checked: boolean;
  title: string;
  description: string;
  onClick: () => void;
}) => {
  return (
    <div
      className={classNames(
        "p-3 border flex items-start gap-3 text-sm rounded",
        checked && "bg-blue-50 border-blue-200"
      )}
      role="button"
      onClick={onClick}
    >
      <input
        className="cursor-pointer mt-0.5"
        type="checkbox"
        readOnly
        checked={checked}
      />
      <div className="flex flex-col gap-1">
        <div className="font-bold">{title}</div>
        <div className="text-gray-500">{description}</div>
      </div>
    </div>
  );
};

const ComplianceProgramFormAddStepModal = ({
  complianceProgramSteps,
  onClose,
  onUpdateComplianceProgramSteps,
}: {
  complianceProgramSteps: ComplianceProgramStep[];
  onClose: () => void;
  onUpdateComplianceProgramSteps: (steps: ComplianceProgramStep[]) => void;
}) => {
  const [proposedSteps, setProposedSteps] = useState<ComplianceProgramStep[]>(
    []
  );

  const handleToggleStep = useCallback(
    (step: ComplianceProgramStep) => {
      if (proposedSteps.includes(step)) {
        setProposedSteps((prev) => prev.filter((s) => s !== step));
      } else {
        setProposedSteps((prev) => [...prev, step]);
      }
    },
    [proposedSteps]
  );

  return createPortal(
    <Modal
      open
      onClose={onClose}
      aria-label="Compliance program add step modal"
    >
      <div className="p-6 flex flex-col">
        <ModalTitle>Add Steps to the Program</ModalTitle>

        <div className="mt-4 flex flex-col gap-3">
          {!complianceProgramSteps.includes(
            ComplianceProgramStep.SELF_MANAGER_REVIEW
          ) && (
            <Step
              checked={proposedSteps.includes(
                ComplianceProgramStep.SELF_MANAGER_REVIEW
              )}
              title="Self and Manager Review"
              description="Questions referring to the employee, answered by the employee and/or the manager"
              onClick={() =>
                handleToggleStep(ComplianceProgramStep.SELF_MANAGER_REVIEW)
              }
            />
          )}
          {!complianceProgramSteps.includes(
            ComplianceProgramStep.PEER_REVIEW
          ) && (
            <Step
              checked={proposedSteps.includes(
                ComplianceProgramStep.PEER_REVIEW
              )}
              title="Peer Review"
              description="Questions referring to the employee, answered by the employee’s peers"
              onClick={() =>
                handleToggleStep(ComplianceProgramStep.PEER_REVIEW)
              }
            />
          )}
          {!complianceProgramSteps.includes(
            ComplianceProgramStep.UPWARD_REVIEW
          ) && (
            <Step
              checked={proposedSteps.includes(
                ComplianceProgramStep.UPWARD_REVIEW
              )}
              title="Upward Review"
              description="Questions referring to an employee that is a manager, answered by the manager’s direct reports"
              onClick={() =>
                handleToggleStep(ComplianceProgramStep.UPWARD_REVIEW)
              }
            />
          )}
          {!complianceProgramSteps.includes(
            ComplianceProgramStep.ONE_ON_ONE
          ) && (
            <Step
              checked={proposedSteps.includes(ComplianceProgramStep.ONE_ON_ONE)}
              title="1:1 Meeting"
              description="Meetings between individuals with an optionally defined template"
              onClick={() => handleToggleStep(ComplianceProgramStep.ONE_ON_ONE)}
            />
          )}
        </div>

        <div className="mt-6 flex items-center justify-end gap-2">
          <Button onClick={onClose} theme={buttonTheme.text} text="Cancel" />
          <Button
            theme={buttonTheme.primary}
            onClick={() => {
              onUpdateComplianceProgramSteps([
                ...complianceProgramSteps,
                ...proposedSteps,
              ]);
              onClose();
            }}
            text="Add"
          />
        </div>
      </div>
    </Modal>,
    document.body
  );
};

export default ComplianceProgramFormAddStepModal;

import { useMutation, useQuery } from "@apollo/client";
import { defer } from "lodash";
import {
  EventHandler,
  FormEventHandler,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import { Prompt, Redirect } from "react-router-dom";
import {
  GetTopicTemplateCategoryQuery,
  GetTopicTemplateCategoryQueryVariables,
} from "types/graphql-schema";

import { currentOrganizationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Input from "@components/input/input";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";
import usePkParams from "@helpers/hooks/use-pk-params";

import createOrUpdateTemplateCategoryMutation from "../graphql/create-or-update-template-category-mutation";
import deleteTemplateCategoryMutation from "../graphql/delete-template-category-mutation";
import getTemplateCategoryQuery from "../graphql/get-template-category-query";

const TemplateCategory = () => {
  const link = useLink();
  const organization = currentOrganizationVar();
  const emptyForm = { title: "" };
  const [refForm, setRefForm] = useState(emptyForm);
  const [form, setForm] = useState(emptyForm);
  const categoryId = usePkParams("categoryId");
  const isNew = isNaN(categoryId);
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this template category?"
  );

  const { data, loading: loadingQueryCategory } = useQuery<
    GetTopicTemplateCategoryQuery,
    GetTopicTemplateCategoryQueryVariables
  >(getTemplateCategoryQuery, {
    skip: isNew,
    variables: {
      categoryId,
    },
    onCompleted: (data) => {
      const newForm = {
        ...form,
        title: assertNonNull(data.topicTemplateCategory?.title),
      };
      setForm(newForm);
      setRefForm(newForm);
    },
    onError: onNotificationErrorHandler(),
  });
  const category = data?.topicTemplateCategory;

  const [createOrUpdateTemplateCategory, { loading: loadingUpdateCategory }] =
    useMutation(createOrUpdateTemplateCategoryMutation);
  const [deleteTemplateCategory, { loading: loadingDeleteCategory }] =
    useMutation(deleteTemplateCategoryMutation);

  const handleSaveCategory: EventHandler<SyntheticEvent> = (e) => {
    e.preventDefault();
    createOrUpdateTemplateCategory({
      variables: {
        organizationId: organization.id,
        title: form.title,
        topicTemplateCategoryId: categoryId,
      },
      onError: onNotificationErrorHandler(),
      onCompleted: () => {
        setRefForm(form);
        defer(() => {
          link.redirect("/templates");
        });
      },
    });
  };

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleSaveCategory(e);
  };

  const handleClickDiscard = () => {
    setRefForm(form);
    defer(() => {
      link.redirect("/templates");
    });
  };

  const handleClickDelete = useCallback(async () => {
    const confirmation = await confirm();
    if (confirmation) {
      deleteTemplateCategory({
        variables: {
          topicTemplateCategoryId: categoryId,
        },
        onCompleted: () => {
          link.redirect("/templates");
        },
        onError: onNotificationErrorHandler(),
      });
    }
  }, [categoryId, confirm, deleteTemplateCategory, link]);

  // RENDER
  const formValid = form.title.trim().length > 0;
  const formHasChanged = form.title !== refForm.title;
  return (
    <form
      className="flex-1 px-4 sm:px-6 py-6 flex flex-col gap-6"
      aria-label="Template category form"
      onSubmit={handleFormSubmit}
    >
      <ConfirmationDialog />
      {category && !category?.canUpdate?.permission && (
        <Redirect to="/templates" />
      )}
      <Prompt
        when={formHasChanged}
        message="Are you sure you want to leave? The changes you made will be lost."
      />

      <div className="flex items-center justify-between">
        <div className="text-xl font-medium">
          {isNew ? "New" : "Edit"} template category
        </div>
      </div>
      <div className="max-w-96">
        <Input
          label="Title"
          aria-label="Template category name input"
          placeholder="Type a title..."
          value={form.title}
          onChange={(e) => setForm({ ...form, title: e.target.value })}
        />
      </div>
      <div className="flex items-center justify-between gap-2 sm:gap-4">
        <div className="flex items-center gap-2 sm:gap-4">
          <Button
            type="button"
            onClick={handleSaveCategory}
            disabled={
              !formValid ||
              !formHasChanged ||
              loadingQueryCategory ||
              loadingDeleteCategory
            }
            theme={buttonTheme.primary}
            text={loadingUpdateCategory ? "Saving" : "Save"}
          />
          <Button text="Cancel" onClick={handleClickDiscard} />
          {(loadingUpdateCategory ||
            loadingQueryCategory ||
            loadingDeleteCategory) && <Loading size="5" mini />}
        </div>
        {!isNew && category?.canDelete?.permission && (
          <Button
            text="Delete"
            disabled={loadingDeleteCategory || loadingQueryCategory}
            theme={buttonTheme.redDanger}
            onClick={handleClickDelete}
          />
        )}
      </div>
    </form>
  );
};

export default TemplateCategory;

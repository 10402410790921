import { UserGroupIcon } from "@heroicons/react/outline";
import { compact, truncate, uniqBy } from "lodash";
import pluralize from "pluralize";
import { useState } from "react";
import {
  BasicGoalArtifactComponentFragmentFragment,
  GoalArtifactComponentFragmentFragment,
  GoalArtifactNode,
  GoalOverviewGoalOwnersFragment,
} from "types/graphql-schema";

import { AlignedGoalType } from "@apps/goal-alignment/use-fetch-alignment-goals";
import Avatar from "@components/avatar/avatar";
import Tooltip from "@components/tooltip/tooltip";
import {
  assertEdgesNonNull,
  isPrivateBasicGoalArtifactNode,
} from "@helpers/helpers";

import GoalParticipantsDialog from "./goal-participants-dialog";

const GoalParticipants = ({
  artifact,
}: {
  artifact:
    | {
        firstOwner: GoalArtifactNode["firstOwner"];
        owners: GoalArtifactNode["owners"];
        ownerCount: GoalArtifactNode["ownerCount"];
        __typename: "GoalArtifactNode";
        canUpdate?: GoalArtifactNode["canUpdate"];
      }
    | AlignedGoalType
    | GoalArtifactComponentFragmentFragment
    | BasicGoalArtifactComponentFragmentFragment
    | GoalOverviewGoalOwnersFragment;
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const ownerNodes = artifact?.owners
    ? assertEdgesNonNull(artifact?.owners)
    : [];
  const owners = compact(uniqBy([artifact.firstOwner, ...ownerNodes], "id"));
  const ownerTotalCount = artifact?.ownerCount || owners.length;
  const isPrivateBasicGoal = isPrivateBasicGoalArtifactNode(artifact);
  const ownersTooltipText = `${ownerTotalCount} ${pluralize(
    "owner",
    ownerTotalCount
  )}: ${truncate(owners.map((owner) => owner.name).join(", "), {
    length: 100,
    separator: ", ",
  })}`;
  return (
    <>
      {!isPrivateBasicGoal && showDialog && (
        <GoalParticipantsDialog
          artifact={artifact}
          onClose={() => setShowDialog(false)}
        />
      )}
      <button
        className="flex items-center hover:bg-gray-100 rounded-full"
        onClick={() => setShowDialog(true)}
        disabled={isPrivateBasicGoal}
      >
        {ownerTotalCount === 0 ? (
          <Avatar user={null} size="5" />
        ) : ownerTotalCount === 1 ? (
          <Avatar user={owners[0]} size="5" />
        ) : (
          <Tooltip text={ownersTooltipText}>
            <span>
              <UserGroupIcon className="h-5 w-5 text-gray-500 rounded-full bg-gray-100" />
            </span>
          </Tooltip>
        )}
      </button>
    </>
  );
};

export default GoalParticipants;

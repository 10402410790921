import Sidebar from "@components/sidebar/sidebar";

import AssessmentDelivery from "./assessment-delivery";

const AssessmentDeliverySidebar = ({
  assessmentDeliveryId,
  onClose,
}: {
  assessmentDeliveryId: number;
  onClose: () => void;
}) => {
  return (
    <Sidebar onClose={onClose} show>
      <AssessmentDelivery
        assessmentDeliveryId={assessmentDeliveryId}
        isInSidebar
        onCloseSidebar={onClose}
      />
    </Sidebar>
  );
};

export default AssessmentDeliverySidebar;

import {
  AssessmentDeliveryFragment,
  AssessmentGroupDelivery,
  AssessmentType,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Layout from "@components/layout/layout";

import CalibrationChangelog from "../calibration-changelog/calibration-changelog";

const AssessmentDeliveryCalibrationChangelog = ({
  assessmentDeliveryData,
}: {
  assessmentDeliveryData?: AssessmentDeliveryFragment;
}) => {
  const label = useLabel();
  return (
    assessmentDeliveryData &&
    assessmentDeliveryData.template.assessmentType ===
      AssessmentType.Performance &&
    assessmentDeliveryData.template.delivery ===
      AssessmentGroupDelivery.AdminApproval &&
    assessmentDeliveryData.calibrationAssessmentId && (
      <div className="mt-6">
        <Layout.MainSubSection
          title={`${label("review", {
            capitalize: true,
          })} Change Log`}
          collapsible
          defaultIsExpanded={true}
          className="mb-6"
        >
          <div className="p-6 border rounded-lg bg-gray-50">
            <CalibrationChangelog
              assessmentId={assessmentDeliveryData.calibrationAssessmentId}
            />
          </div>
        </Layout.MainSubSection>
      </div>
    )
  );
};

export default AssessmentDeliveryCalibrationChangelog;

import { useMutation } from "@apollo/client";
import { useState } from "react";

import Button from "@components/button/button";
import Heading from "@components/heading/heading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import requestExportMutation from "../graphql/request-export-mutation";

const Export = () => {
  const [exportTriggered, setExportTriggered] = useState(false);
  const [requestExport] = useMutation(requestExportMutation);

  const handleExport = () => {
    requestExport({
      onError: onNotificationErrorHandler(),
    });
    setExportTriggered(true);
  };

  return (
    <div className="sm:w-1/2">
      <Heading small title="Export" />
      <p className="mb-4">Export all of your meeting agendas and comments:</p>
      {exportTriggered ? (
        <em>You will receive an email shortly with a link to your export</em>
      ) : (
        <Button onClick={handleExport} text="Start export" />
      )}
    </div>
  );
};

export default Export;

import { useQuery } from "@apollo/client";
import { defer, uniq } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Canvas, CanvasRef, Edge, EdgeProps, Node, PortSide } from "reaflow";
import {
  GetOrgChartOrganizationMembersQueryQuery,
  GetOrgChartOrganizationMembersQueryQueryVariables,
  OrgChartUserNodeFragment,
} from "types/graphql-schema";

import { currentOrganizationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Layout from "@components/layout/layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";
import { pluralize } from "@helpers/string";

import getOrgChartOrganizationMembersQuery from "./graphql/get-org-chart-organization-members-query";

type OrgChartEdgeType = {
  id: string;
  from: string;
  to: string;
};

type OrgChartUserNodeFragmentWithManagerIdsAndReportIds =
  OrgChartUserNodeFragment & {
    managerIds: number[];
    reportIds: number[];
  };

const getAllChildIds = (
  usersById: Record<number, OrgChartUserNodeFragmentWithManagerIdsAndReportIds>,
  userId: number
): number[] => {
  const user = usersById[userId];
  if (!user) {
    return [];
  }
  return uniq([
    ...user.reportIds,
    ...user.reportIds.flatMap((id) => getAllChildIds(usersById, id)),
  ]);
};

const OrgChart = () => {
  const currentOrganization = currentOrganizationVar();
  const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>(
    {}
  );

  const handleToggleNode = (
    usersById: Record<
      number,
      OrgChartUserNodeFragmentWithManagerIdsAndReportIds
    >,
    nodeId: string
  ) => {
    const expanded = !expandedNodes[nodeId];
    const allChildIds = expanded
      ? []
      : getAllChildIds(usersById, Number(nodeId));
    setExpandedNodes((prev) => ({
      ...prev,
      [nodeId]: expanded,
      // Collapse all child nodes if parent node is collapse
      ...allChildIds.reduce((memo, id) => {
        return {
          ...memo,
          [id]: false,
        };
      }, {}),
    }));
  };

  const { data, loading } = useQuery<
    GetOrgChartOrganizationMembersQueryQuery,
    GetOrgChartOrganizationMembersQueryQueryVariables
  >(getOrgChartOrganizationMembersQuery, {
    variables: { organizationId: currentOrganization.id },
    onError: onNotificationErrorHandler(),
  });

  const members = useMemo(() => {
    return data?.organization?.members
      ? assertEdgesNonNull(data.organization.members)
      : [];
  }, [data]);

  const users = useMemo(() => {
    const tempUsers = members
      .map((member) => member.user)
      .filter((user) => user !== null && user !== undefined)
      .map((user) => ({
        ...user,
        managerIds: user.managersList.map(({ id }) => id),
      }));

    return tempUsers.map((user) => {
      return {
        ...user,
        reportIds: tempUsers
          .filter((u) => u.managerIds.includes(user.id))
          .map((u) => u.id),
      };
    });
  }, [members]);

  const usersById = useMemo(() => {
    return users.reduce((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {} as Record<number, OrgChartUserNodeFragmentWithManagerIdsAndReportIds>);
  }, [users]);

  const nodes = users
    .filter((user) => {
      // show node if it is a root node or if it is expanded or if any of its parent nodes are expanded
      return (
        user.managerIds.length === 0 ||
        !!expandedNodes[user.id] ||
        user.managerIds.some((id) => !!expandedNodes[id])
      );
    })
    .map((user) => ({
      id: String(user.id),
      ports: [
        {
          id: `${user.id}-from`,
          width: 10,
          height: 10,
          side: "SOUTH" as PortSide,
          hidden: true,
        },
        {
          id: `${user.id}-to`,
          width: 10,
          height: 10,
          side: "NORTH" as PortSide,
          hidden: true,
        },
      ],
      user,
      height: 94,
      width: 140,
    }));

  const edges = nodes.reduce((memo, node) => {
    const newEdges: OrgChartEdgeType[] = node.user.managersList
      // remove relationship if manager is collapsed
      .filter((manager) => !!expandedNodes[manager.id])
      .map((manager) => ({
        id: `${node.user.id}-${manager.id}`,
        to: String(node.user.id),
        from: String(manager.id),
        fromPort: `${manager.id}-from`,
        toPort: `${node.user.id}-to`,
      }));
    return [...memo, ...newEdges];
  }, [] as OrgChartEdgeType[]);

  useEffect(() => {
    setExpandedNodes(
      users.reduce(
        (memo, user) => ({
          ...memo,
          [user.id]: user.managersList.length === 0,
        }),
        {}
      )
    );
  }, [users]);

  const canvasRef = useRef<CanvasRef | null>(null);
  const [paneWidth, setPaneWidth] = useState(2000);
  const [paneHeight, setPaneHeight] = useState(2000);

  const calculatePaneWidthAndHeight = useCallback(() => {
    let newHeight = paneHeight;
    let newWidth = paneWidth;
    defer(() => {
      canvasRef?.current?.layout?.children?.forEach((node) => {
        if (node.y + node.height + 300 > newHeight)
          newHeight = node.y + node.height + 300;
        if (node.x + node.width > newWidth) newWidth = node.x + node.width;
      });
      if (newWidth && newHeight) {
        setPaneHeight(newHeight);
        setPaneWidth(newWidth);
      }
    });
  }, [users]);

  return (
    <Layout>
      <Layout.Header
        breadcrumbs={[
          {
            title: "Org Chart",
            url: "/org-chart",
          },
        ]}
      />
      <Layout.Container loading={loading}>
        <Canvas
          ref={canvasRef}
          className="overscroll-contain"
          pannable={true}
          maxWidth={paneWidth}
          maxHeight={paneHeight}
          onLayoutChange={() => {
            calculatePaneWidthAndHeight();
          }}
          layoutOptions={{
            "elk.algorithm": "layered",
            "elk.direction": "DOWN",
            "elk.edgeRouting": "ORTHOGONAL",
          }}
          animated={false}
          nodes={nodes}
          edges={edges}
          edge={(edge: EdgeProps) => (
            <Edge
              {...edge}
              interpolation="linear"
              style={{
                stroke: "#374151",
                strokeWidth: 2, // so line overlaps look better on retina display
                strokeLinecap: "square",
              }}
            />
          )}
          node={
            <Node>
              {(event: any) => (
                <foreignObject
                  height={event.height}
                  width={event.width}
                  x={0}
                  y={0}
                >
                  <button
                    className={classNames(
                      "fixed flex w-[140px] h-[94px] justify-center rounded-sm",
                      event.node.user.reportIds.length > 0 &&
                        "hover:bg-white/10"
                    )}
                    onClick={() => handleToggleNode(usersById, event.node.id)}
                    disabled={event.node.user.reportIds.length === 0}
                  >
                    <div className="fixed flex flex-col w-full p-2">
                      <div className="flex justify-center">
                        <Avatar user={event.node.user} size="7" />
                      </div>
                      <div className="mt-2 text-sm text-center text-white truncate tracking-tight">
                        {event.node.user.name}
                      </div>
                      {event.node.user.reportIds.length > 0 && (
                        <div className="mt-1 text-xs text-center text-gray-400 tracking-tight">
                          {event.node.user.reportIds.length}{" "}
                          {pluralize(
                            "report",
                            event.node.user.reportIds.length
                          )}
                        </div>
                      )}
                    </div>
                  </button>
                </foreignObject>
              )}
            </Node>
          }
        />
      </Layout.Container>
    </Layout>
  );
};

export default OrgChart;

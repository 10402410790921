import { useReactiveVar } from "@apollo/client";
import { Editor } from "@tiptap/core";
import { useEffect, useMemo } from "react";

import { currentTiptapJWTVar } from "@cache/cache";
import { parseStringToJSON } from "@helpers/helpers";

import { getYDocAndWebsocketProvider } from "./helpers";
import WYSIWYG from "./wysiwyg";

export default function ArtifactWYSIWYG({
  artifact,
  className = "",
  placeholder,
  editable = false,
  showFixedMenu = true,
  showPlusButton = false,
  organizationId,
  onChange = undefined,
  fixedMenuShowFullScreen = true,
  isInSidebar = false,
}: {
  artifact: any;
  className?: string;
  placeholder?: string;
  editable?: boolean;
  showFixedMenu?: boolean;
  showPlusButton?: boolean;
  organizationId?: number;
  onChange?: ({ editor }: { editor: Editor }) => void;
  fixedMenuShowFullScreen?: boolean;
  isInSidebar?: boolean;
}) {
  const description = artifact?.description
    ? parseStringToJSON(artifact.description)
    : "";

  const websocketId = `artifact-${artifact.id}`;
  const { ydoc, providerWebsocket } = useMemo(
    () => getYDocAndWebsocketProvider(websocketId, artifact.websocketToken),
    [websocketId, artifact.websocketToken]
  );

  useEffect(() => {
    return function cleanup() {
      ydoc?.destroy();
      providerWebsocket?.destroy();
    };
  }, [ydoc, providerWebsocket]);

  const extraContext = useMemo(() => {
    return {
      organizationId: organizationId,
      relatedArtifactId: artifact.id,
      relatedArtifactType: artifact.artifactType,
    };
  }, [organizationId, artifact.id, artifact.artifactType]);

  const uploadVariable = useMemo(() => {
    return { artifactId: artifact.id };
  }, [artifact.id]);

  const mentionsConfig = useMemo(() => {
    return { artifactId: artifact.id };
  }, [artifact.id]);

  const tiptapAiJwt = useReactiveVar(currentTiptapJWTVar);

  return (
    <WYSIWYG
      ydoc={ydoc}
      providerWebsocket={providerWebsocket}
      key={artifact.id}
      className={className}
      placeholder={placeholder}
      value={description}
      editable={editable}
      enableComment={true}
      showPlusButton={showPlusButton}
      showFixedMenu={showFixedMenu}
      isInSidebar={isInSidebar}
      fixedMenuShowFullScreen={fixedMenuShowFullScreen}
      uploadVariable={uploadVariable}
      webSocketDocumentId={websocketId}
      mentionsConfig={mentionsConfig}
      onUpdateContent={onChange}
      extraContext={extraContext}
      tiptapAiJwt={tiptapAiJwt}
    />
  );
}

import { Route, Switch, useRouteMatch } from "react-router-dom";

import Assessment from "@apps/assessments/components/assessment/assessment";
import useDocumentTitle from "@components/use-document-title/use-document-title";

import EngagementSurveyDelivery from "./components/delivery/engagement-survey-delivery";
import EngagementSurveyHomepage from "./components/homepage";

const EngagementSurvey = () => {
  useDocumentTitle("Surveys");
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={[`${path}/delivered`, `${path}`]}>
        <EngagementSurveyHomepage />
      </Route>
      <Route exact path={`${path}/delivery/:assessmentDeliveryId(\\d+)`}>
        <EngagementSurveyDelivery />
      </Route>
      <Route exact path={`${path}/assessment/:assessmentId(\\d+)`}>
        <Assessment />
      </Route>
    </Switch>
  );
};

export default EngagementSurvey;

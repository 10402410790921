import { gql } from "@apollo/client";

import KeyResultFragment from "@graphql/key-result-fragment";

export const AlignmentGoalFragment = gql`
  ${KeyResultFragment}
  fragment AlignmentGoalFragment on GoalArtifactNode {
    id
    artifactType
    title
    updated
    canUpdate {
      permission
    }
    canDelete {
      permission
    }
    canRead {
      permission
    }
    firstOwner {
      id
      name
      avatar
    }
    state
    scope
    ownerCount
    dueDate
    goalVisibility
    status
    progress
    progressType
    currentValue
    startValue
    targetValue
    isStale
    owners(first: 1) {
      edges {
        node {
          id
          name
          avatar
        }
      }
    }
    created
    canRead {
      permission
    }
    keyResults {
      edges {
        node {
          id
          ...KeyResultFragment
        }
      }
    }
    parentGoalId
  }
`;

export const BasicAlignmentGoalFragment = gql`
  ${AlignmentGoalFragment}
  fragment BasicAlignmentGoalFragment on BasicGoalArtifactNode {
    id
    artifactType
    updated
    canUpdate {
      permission
    }
    canDelete {
      permission
    }
    canRead {
      permission
    }
    firstOwner {
      id
      name
      avatar
    }
    state
    scope
    ownerCount
    dueDate
    goalVisibility
    status
    progress
    progressType
    currentValue
    startValue
    targetValue
    isStale
    owners(first: 1) {
      edges {
        node {
          id
          name
          avatar
        }
      }
    }
    created
    parentGoalId
    goalArtifact {
      ...AlignmentGoalFragment
    }
  }
`;

export default gql`
  ${BasicAlignmentGoalFragment}
  query getAlignmentGoalsQuery(
    $first: Int!
    $after: String
    $goalScopes: [GoalScope]
    $goalStates: [GoalState]
    $goalDueBetweenDates: [Date]
    $idsToMergeInApolloCache: [Int]
    $searchGoalOwners: [Int]
    $searchGoalTeams: [Int]
    $inPathOfGoalId: Int
    $hasSearch: Boolean!
  ) {
    basicGoals(
      idsToMergeInApolloCache: $idsToMergeInApolloCache
      orderBy: "objective_alignment"
      goalScopes: $goalScopes
      goalStates: $goalStates
      inPathOfGoalId: $inPathOfGoalId
      goalDueBetweenDates: $goalDueBetweenDates
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          ...BasicAlignmentGoalFragment
        }
      }
    }
    searchResults: artifacts(
      # idsToMergeInApolloCache: $idsToMergeInApolloCache
      artifactType: goal
      goalScopes: $goalScopes
      goalStates: $goalStates
      goalDueBetweenDates: $goalDueBetweenDates
      goalOwners: $searchGoalOwners
      goalTeams: $searchGoalTeams
    ) @include(if: $hasSearch) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

import { ArrowSmRightIcon, TrashIcon } from "@heroicons/react/outline";
import { compact, range } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { HiDotsHorizontal } from "react-icons/hi";
import { IoMdHappy } from "react-icons/io";
import { MdContentCopy, MdOutlineCheckBox } from "react-icons/md";
import { PiTextAlignLeftBold } from "react-icons/pi";
import {
  AssessmentGroupQuestionInput,
  AssessmentQuestionResponseVisibility,
  AssessmentQuestionResponses,
  AssessmentQuestionType,
} from "types/graphql-schema";

import {
  ASSESSMENT_QUESTION_SCALE_MAX,
  ASSESSMENT_QUESTION_SCALE_MIN,
  getAssessmentQuestionScale,
} from "@apps/assessments/helpers";
import useLabel from "@apps/use-label/use-label";
import Button, { buttonTheme } from "@components/button/button";
import FormFields from "@components/form/form-fields";
import Input from "@components/input/input";
import ToggleChevronIcon from "@components/layout/toggle-chevron-icon";
import Select from "@components/select/select";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { alphabet } from "@helpers/constants";

export const assessmentQuestionIcon = {
  [AssessmentQuestionType.Range]: HiDotsHorizontal,
  [AssessmentQuestionType.Text]: PiTextAlignLeftBold,
  [AssessmentQuestionType.Multichoice]: MdOutlineCheckBox,
  [AssessmentQuestionType.Nps]: IoMdHappy,
};

const inputClassName =
  "px-4 py-2 block sm:text-sm bg-white shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 w-full disabled:bg-gray-100";
const textareaClassName =
  "resize-none px-4 py-2 h-12 border border-gray-300 shadow-inner rounded-md text-sm w-full focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100";

const AssessmentGroupSidebarQuestion = ({
  question,
  canUpdateAssessmentGroup,
  sectionIndex,
  questionIndex,
  isLastQuestion,
  onUpdateQuestion,
  onDeleteQuestion,
  onMoveQuestionUp,
  onMoveQuestionDown,
  onDuplicateQuestion,
}: {
  question: AssessmentGroupQuestionInput;
  canUpdateAssessmentGroup: boolean;
  sectionIndex: number;
  questionIndex: number;
  isLastQuestion: boolean;
  onUpdateQuestion: (data: AssessmentGroupQuestionInput) => void;
  onDeleteQuestion: () => void;
  onMoveQuestionUp: () => void;
  onMoveQuestionDown: () => void;
  onDuplicateQuestion: () => void;
}) => {
  const label = useLabel();
  const [isShowingSettings, setIsShowingSettings] = useState(false);
  const Icon =
    assessmentQuestionIcon[
      question.questionType as keyof typeof assessmentQuestionIcon
    ];

  const questionLabel = useMemo(() => {
    if (question.questionType === AssessmentQuestionType.Range) {
      return `Range (${question.startValue} - ${question.endValue})`;
    }
    if (question.questionType === AssessmentQuestionType.Text) {
      return "Short answer";
    }
    if (question.questionType === AssessmentQuestionType.Multichoice) {
      return `Multiple Choice (${question.options?.length})`;
    }
    if (question.questionType === AssessmentQuestionType.Nps) {
      return "eNPS";
    }
    return "Unknown question type";
  }, [
    question.questionType,
    question.options,
    question.startValue,
    question.endValue,
  ]);

  const handleChangeRangeValues = useCallback(
    ({ startValue, endValue }: { startValue?: number; endValue?: number }) => {
      const newQuestion = {
        ...question,
        startValue: startValue ?? question.startValue,
        endValue: endValue ?? question.endValue,
      };
      const scale = getAssessmentQuestionScale(newQuestion);
      const labels = range(0, scale.scaleDiff).map((x) => {
        return newQuestion.labels?.[x] || "";
      });
      const labelDescriptions = range(0, scale.scaleDiff).map((x) => {
        return newQuestion.labelDescriptions?.[x] || "";
      });
      onUpdateQuestion({
        ...newQuestion,
        labels,
        labelDescriptions,
      });
    },
    [onUpdateQuestion, question]
  );

  const handleChangeOptionCount = useCallback(
    (count: number) => {
      const options = range(0, count).map((x) => {
        return question.options?.[x] || "";
      });
      const optionDescriptions = range(0, count).map((x) => {
        return question.optionDescriptions?.[x] || "";
      });
      onUpdateQuestion({
        ...question,
        options,
        optionDescriptions,
      });
    },
    [onUpdateQuestion, question]
  );

  const typeSpecificSettings = useMemo(() => {
    if (question.questionType === AssessmentQuestionType.Text) {
      return null;
    }

    if (question.questionType === AssessmentQuestionType.Range) {
      const scale = getAssessmentQuestionScale(question);
      return (
        <div className="p-6 border-t">
          <FormFields
            fields={[
              {
                title: "Scale",
                render: () => (
                  <div className="flex gap-4 items-center">
                    <Input
                      disabled={!canUpdateAssessmentGroup}
                      aria-label="Question start value input"
                      className="w-18"
                      type="number"
                      min={ASSESSMENT_QUESTION_SCALE_MIN}
                      max={Math.min(
                        ASSESSMENT_QUESTION_SCALE_MAX,
                        scale.scaleEndValue - 1
                      )}
                      step={1}
                      pattern="\d*"
                      value={question.startValue ?? ""}
                      onChange={(evt) => {
                        handleChangeRangeValues({
                          startValue: Number(evt.target.value),
                        });
                      }}
                    />
                    <ArrowSmRightIcon className="text-gray-500 w-4 h-4 shrink-0" />
                    <Input
                      disabled={!canUpdateAssessmentGroup}
                      aria-label="Question end value input"
                      className="w-18"
                      type="number"
                      min={Math.max(
                        ASSESSMENT_QUESTION_SCALE_MIN,
                        scale.scaleStartValue + 1
                      )}
                      max={ASSESSMENT_QUESTION_SCALE_MAX}
                      step={1}
                      pattern="\d*"
                      value={question.endValue ?? ""}
                      placeholder="End value"
                      onChange={(evt) => {
                        handleChangeRangeValues({
                          endValue: Number(evt.target.value),
                        });
                      }}
                    />
                  </div>
                ),
              },
              ...range(0, scale.scaleDiff).map((index) => ({
                title: `Label ${scale.scaleStartValue + index}`,
                render: () => (
                  <div className="flex flex-col gap-2">
                    <input
                      disabled={!canUpdateAssessmentGroup}
                      type="text"
                      value={question.labels?.[index] ?? ""}
                      onChange={(evt) => {
                        onUpdateQuestion({
                          ...question,
                          labels: question.labels?.map((label, i) =>
                            i === index ? evt.target.value : label
                          ),
                        });
                      }}
                      placeholder="Label (optional)"
                      className={inputClassName}
                    />
                    <textarea
                      disabled={!canUpdateAssessmentGroup}
                      value={question.labelDescriptions?.[index] || ""}
                      onChange={(evt) => {
                        onUpdateQuestion({
                          ...question,
                          labelDescriptions: question.labelDescriptions?.map(
                            (label, i) =>
                              i === index ? evt.target.value : label
                          ),
                        });
                      }}
                      placeholder="Tooltip (optional)"
                      className={textareaClassName}
                    />
                  </div>
                ),
              })),
            ]}
          />
        </div>
      );
    }

    if (question.questionType === AssessmentQuestionType.Multichoice) {
      return (
        <div className="p-6 border-t">
          <FormFields
            fields={[
              {
                title: "Options",
                render: () => (
                  <Input
                    disabled={!canUpdateAssessmentGroup}
                    aria-label="Question option count"
                    className="w-18"
                    type="number"
                    min={1}
                    step={1}
                    pattern="\d*"
                    value={question.options?.length ?? ""}
                    onChange={(evt) => {
                      handleChangeOptionCount(Number(evt.target.value));
                    }}
                  />
                ),
              },
              ...range(0, question.options?.length).map((index) => ({
                title: `Option ${index + 1}`,
                render: () => (
                  <div className="flex flex-col gap-2">
                    <input
                      disabled={!canUpdateAssessmentGroup}
                      type="text"
                      value={question.options?.[index] ?? ""}
                      onChange={(evt) => {
                        onUpdateQuestion({
                          ...question,
                          options: question.options?.map((label, i) =>
                            i === index ? evt.target.value : label
                          ),
                        });
                      }}
                      placeholder="Label"
                      className={inputClassName}
                    />
                    <textarea
                      disabled={!canUpdateAssessmentGroup}
                      value={question.optionDescriptions?.[index] || ""}
                      onChange={(evt) => {
                        onUpdateQuestion({
                          ...question,
                          optionDescriptions: question.optionDescriptions?.map(
                            (label, i) =>
                              i === index ? evt.target.value : label
                          ),
                        });
                      }}
                      placeholder="Description (optional)"
                      className={textareaClassName}
                    />
                  </div>
                ),
              })),
            ]}
          />
        </div>
      );
    }
  }, [
    question,
    canUpdateAssessmentGroup,
    handleChangeRangeValues,
    onUpdateQuestion,
    handleChangeOptionCount,
  ]);

  return (
    <div
      className="bg-white border rounded"
      id={`question-${sectionIndex}-${questionIndex}`}
    >
      <div className="p-6">
        <div className="flex items-center justify-between text-sm">
          <div className="flex items-center gap-3">
            <div className="py-0.5 px-2 rounded bg-green-100 font-bold">{`${
              alphabet[sectionIndex]
            }${questionIndex + 1}`}</div>
            <Icon />
            <div className="font-medium">{questionLabel}</div>
          </div>
          <div className="flex items-center gap-2">
            <Button
              disabled={!canUpdateAssessmentGroup || questionIndex === 0}
              icon={FaArrowUp}
              theme={buttonTheme.iconGray}
              onClick={onMoveQuestionUp}
            />
            <Button
              disabled={!canUpdateAssessmentGroup || isLastQuestion}
              icon={FaArrowDown}
              theme={buttonTheme.iconGray}
              onClick={onMoveQuestionDown}
            />
            <Button
              disabled={!canUpdateAssessmentGroup}
              icon={MdContentCopy}
              theme={buttonTheme.iconGray}
              onClick={onDuplicateQuestion}
            />
            <Button
              disabled={!canUpdateAssessmentGroup}
              icon={TrashIcon}
              theme={buttonTheme.iconGray}
              onClick={onDeleteQuestion}
            />
          </div>
        </div>
        <div className="mt-4">
          <FormFields
            fields={[
              {
                title: "Label",
                render: () => (
                  <Input
                    className="w-full"
                    placeholder="Add label"
                    value={question.title ?? ""}
                    onChange={(e) => {
                      onUpdateQuestion({
                        ...question,
                        title: e.target.value,
                      });
                    }}
                  />
                ),
              },
              {
                title: "Description",
                render: () => (
                  <TextareaWysiwyg
                    editable
                    className="mt-4 bg-white"
                    placeholder="Add decription (optional)"
                    value={question.description}
                    deps={[question.title]}
                    onChangeValue={(description: string) => {
                      onUpdateQuestion({
                        ...question,
                        description,
                      });
                    }}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>

      {typeSpecificSettings}

      <div className="p-6 border-t">
        <Button
          theme={buttonTheme.text}
          className="flex items-center gap-2 font-semibold -ml-[10px]"
          onClick={() => setIsShowingSettings(!isShowingSettings)}
        >
          <div>Settings</div>
          <ToggleChevronIcon expanded={isShowingSettings} />
        </Button>

        {isShowingSettings && (
          <div className="mt-4">
            <FormFields
              fields={compact([
                {
                  title: "Responders",
                  render: () => (
                    <Select
                      width="full"
                      disabled={!canUpdateAssessmentGroup}
                      value={question.responses}
                      options={compact([
                        {
                          label: "Everyone",
                          description: "Everyone answers this question",
                          value: AssessmentQuestionResponses.Both,
                        },
                        question.responseVisibility !==
                          AssessmentQuestionResponseVisibility.HiddenFromSubject && {
                          label: "Self assessment only",
                          description: `Only the subject of the ${label(
                            "review"
                          )} answers this question`,
                          value: AssessmentQuestionResponses.SelfAssessmentOnly,
                        },
                        {
                          label: "Exclude self assessment",
                          description: `The ${label(
                            "review"
                          )} subject does not answer this question`,
                          value:
                            AssessmentQuestionResponses.ExcludeSelfAssessment,
                        },
                      ])}
                      onChange={(opt) => {
                        onUpdateQuestion({
                          ...question,
                          responses: opt.value,
                        });
                      }}
                      aria-label="Resposnes type select"
                    />
                  ),
                },
                {
                  title: "Response visibility",
                  render: () => (
                    <Select
                      width="full"
                      disabled={
                        !canUpdateAssessmentGroup ||
                        question.responses ===
                          AssessmentQuestionResponses.SelfAssessmentOnly
                      }
                      value={question.responseVisibility}
                      options={compact([
                        {
                          label: "Everyone",
                          description: `Responses to this question are visible by anyone who can access the ${label(
                            "review"
                          )}`,
                          value: AssessmentQuestionResponseVisibility.All,
                        },
                        question.responses !==
                          AssessmentQuestionResponses.SelfAssessmentOnly && {
                          label: "Hide from subject",
                          description: `Responses to this question, except for their own, are hidden from the subject of the ${label(
                            "review"
                          )}`,
                          value:
                            AssessmentQuestionResponseVisibility.HiddenFromSubject,
                        },
                      ])}
                      onChange={(opt) => {
                        onUpdateQuestion({
                          ...question,
                          responseVisibility: opt.value,
                        });
                      }}
                      aria-label="Response visibility type select"
                    />
                  ),
                },
                question.questionType !== AssessmentQuestionType.Text && {
                  title: "Comments",
                  render: () => (
                    <Select
                      width="full"
                      disabled={!canUpdateAssessmentGroup}
                      value={question.isCommentMandatory}
                      options={[
                        {
                          label: "Required",
                          value: true,
                        },
                        {
                          label: "Not required",
                          value: false,
                        },
                      ]}
                      onChange={(opt) => {
                        onUpdateQuestion({
                          ...question,
                          isCommentMandatory: opt.value,
                        });
                      }}
                      aria-label="Mandatory comment select"
                    />
                  ),
                },
              ])}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentGroupSidebarQuestion;

import { LockClosedIcon } from "@heroicons/react/outline";
import {
  GoalAlignmentDialogGoalFragmentFragment,
  GoalVisibility,
} from "types/graphql-schema";

import ArtifactIcon from "@apps/artifact/components/artifact-icon";
import { AlignedGoalType } from "@apps/goal-alignment/use-fetch-alignment-goals";
import Tooltip from "@components/tooltip/tooltip";
import { getPrivateGoalTitle } from "@helpers/constants";
import { classNames } from "@helpers/css";

const AlignmentPopoverGoalItem = ({
  goal,
  disabledText,
}: {
  disabledText?: string;
  goal: AlignedGoalType | GoalAlignmentDialogGoalFragmentFragment;
}) => {
  const title =
    goal.__typename === "GoalArtifactNode"
      ? goal.title
      : goal.__typename === "BasicGoalArtifactNode"
      ? goal.goalArtifact?.title
      : getPrivateGoalTitle();
  return (
    <span className="flex-1 text-left flex items-start gap-[3px] min-w-0">
      <ArtifactIcon artifact={goal} size={4} className="mt-[2px] shrink-0 " />
      <span
        className={classNames(
          "flex-1 truncate",
          !!disabledText && "text-gray-400"
        )}
      >
        {title}{" "}
        {goal.goalVisibility === GoalVisibility.Private && (
          <div className="inline-flex items-center">
            <LockClosedIcon className="h-3 w-3 text-gray-400" />
          </div>
        )}
      </span>
      {disabledText && (
        <Tooltip text={disabledText}>
          <span className="rounded-md shrink-0 text-xs font-medium px-1 bg-red-100 text-gray-600">
            N/A
          </span>
        </Tooltip>
      )}
    </span>
  );
};

export default AlignmentPopoverGoalItem;

import { ComponentType, LegacyRef, PropsWithChildren, forwardRef } from "react";

import AppLink, { toObjectProps } from "@components/link/link";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import { titleCase } from "@helpers/string";

export const buttonTheme: {
  default: string;
  primary: string;
  lightBlue: string;
  lightGray: string;
  green: string;
  text: string;
  iconGray: string;
  redDanger: string;
} = {
  default: "default",
  primary: "primary",
  lightBlue: "lightBlue",
  lightGray: "lightGray",
  green: "green",
  text: "text",
  iconGray: "iconGray",
  redDanger: "redDanger",
};

type ButtonTheme = keyof typeof buttonTheme;
export enum ButtonSize {
  small,
  large,
}

export interface ButtonProps {
  type?: "button" | "submit" | "reset";
  text?: string;
  testId?: string;
  theme?: ButtonTheme | Omit<string, ButtonTheme>;
  disabled?: boolean;
  className?: string;
  leftNegativeMargin?: boolean;
  size?: ButtonSize;
  rounded?: boolean;
  icon?: ComponentType<{ className?: string }>;
  url?: string | null;
  to?: string | toObjectProps;
  tooltip?: string | null | false | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  "aria-label"?: string;
  target?: string;
}

const Button = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      type = "button",
      text,
      testId = "",
      theme = "default",
      disabled = false,
      className = "",
      size,
      icon,
      leftNegativeMargin = false,
      rounded = false,
      children,
      onClick,
      url = null,
      to = null,
      tooltip = "",
      "aria-label": ariaLabel,
      target,
      ...props
    },
    ref
  ) => {
    if (!!icon && (!!children || !!text)) {
      if (import.meta.env.DEV || !!window.Cypress) {
        throw new Error(
          "Button cannot have both an icon and (text or children) at the same time."
        );
      }
    }
    if (!!icon && !theme.startsWith("icon")) {
      if (import.meta.env.DEV || !!window.Cypress) {
        throw new Error(
          "When using icon prop, use theme starting with 'icon*'"
        );
      }
    }

    let themeClassName = "";
    const defaultThemeClassName = classNames(
      "border-gray-300",
      "bg-white text-gray-700 focus:ring-0 focus:outline-none",
      !disabled && "hover:bg-gray-50"
    );
    if (theme === buttonTheme.primary) {
      themeClassName = classNames(
        "border-transparent",
        "text-white bg-blue-700 focus:ring-blue-500",
        !disabled && "hover:bg-blue-600"
      );
    } else if (theme === buttonTheme.redDanger) {
      themeClassName = classNames(
        "border-red-700 text-red-700 bg-white focus:ring-red-500",
        !disabled && "hover:bg-red-700 hover:text-white",
        disabled && "opacity-70"
      );
    } else if (theme === buttonTheme.lightBlue) {
      themeClassName = classNames(
        "border-transparent",
        "bg-blue-100 text-blue-700 font-normal focus:ring-blue-100",
        !disabled && "hover:bg-blue-200"
      );
    } else if (theme === buttonTheme.green) {
      themeClassName = classNames(
        "border border-green-700",
        "text-green-700 font-normal focus:ring-green-50 bg-green-50",
        !disabled && "hover:bg-green-100"
      );
    } else if (theme === buttonTheme.text) {
      themeClassName = classNames(
        "border-transparent text-gray-700 font-normal",
        !disabled && "hover:bg-gray-100"
      );
    } else if (theme === buttonTheme.iconGray) {
      themeClassName = classNames(
        "border-transparent text-gray-400",
        !disabled && "hover:bg-black/5 hover:text-gray-700"
      );
    } else {
      themeClassName = defaultThemeClassName;
    }

    const buttonClassName = classNames(
      "inline-flex justify-center items-center font-medium rounded-md border focus:outline-none ",
      "fs-unmask",
      themeClassName,
      disabled ? "opacity-50 cursor-default" : "",
      size === ButtonSize.small ? "text-xs tracking-tight" : "text-sm",
      size === ButtonSize.small
        ? "h-[22px]"
        : size === ButtonSize.large
        ? "h-[34px]"
        : "h-[28px]",
      !icon
        ? null
        : size === ButtonSize.small
        ? "w-[22px]"
        : size === ButtonSize.large
        ? "w-[34px]"
        : "w-[28px]",
      icon
        ? null // handled by width
        : size === ButtonSize.small
        ? "px-2"
        : "px-[10px]",
      !leftNegativeMargin
        ? null
        : icon
        ? null
        : size === ButtonSize.small
        ? "-ml-2"
        : "-ml-[10px]",
      size === ButtonSize.small
        ? "gap-[4px]"
        : size === ButtonSize.large
        ? "gap-[8px]"
        : "gap-[5px]",
      rounded && "rounded-full",
      className
    );

    const handleClick = (
      e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
    ) => {
      onClick?.(e as React.MouseEvent<HTMLButtonElement>);
    };

    const Icon = icon && typeof icon !== "boolean" ? icon : null;
    const titleCaseText = titleCase(text || "");
    const buttonContent = Icon ? (
      <Icon
        className={classNames(
          "shrink-0",
          size === ButtonSize.small ? "h-4 w-4" : "h-5 w-5"
        )}
      />
    ) : (
      children || titleCaseText
    );
    const button = to ? (
      <AppLink
        disabled={disabled}
        to={to}
        data-testid={testId}
        className={buttonClassName}
        onClick={handleClick}
        target={target}
      >
        {buttonContent}
      </AppLink>
    ) : url ? (
      <a
        href={url}
        data-testid={testId}
        className={buttonClassName}
        ref={ref as unknown as LegacyRef<HTMLAnchorElement>}
        onClick={handleClick}
        aria-label={ariaLabel}
        target={target}
      >
        {buttonContent}
      </a>
    ) : (
      <button
        ref={ref as unknown as LegacyRef<HTMLButtonElement>}
        type={type}
        disabled={disabled}
        data-testid={testId}
        className={buttonClassName}
        onClick={handleClick}
        aria-label={ariaLabel}
        {...props}
      >
        {buttonContent}
      </button>
    );
    if (tooltip) {
      return (
        <Tooltip text={tooltip}>
          <span className="flex items-center min-w-0">{button}</span>
        </Tooltip>
      );
    }
    return button;
  }
);

export default Button;

import { compact, map } from "lodash";
import { useMemo } from "react";
import {
  AssessmentDeliveryFragment,
  AssessmentGroupDelivery,
  AssessmentQuestionResponseVisibility,
  AssessmentQuestionType,
} from "types/graphql-schema";

import { getAssessmentDeliverySections } from "@apps/assessments/helpers";
import useLabel from "@apps/use-label/use-label";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import AssessmentLayout from "../layouts/assessment-layout";
import RoleBasedAssessmentQuestionLayout from "../layouts/role-based-assessment-question-layout";
import {
  getQuestionsByRole,
  isGoalBasedQuestionType,
  isRoleBasedQuestionType,
} from "../questions/helpers";
import RoleBasedAssessmentQuestionGroup from "../questions/role-based-assessment-question-group";
import AssessmentDeliveryQuestion from "./assessment-delivery-question";

const AssessmentDeliveryContent = ({
  assessmentDeliveryData,
  showHiddenQuestions,
  excludedAnswerIds,
  summary,
  canUpdate,
  isDraft,
  onUpdateDeliveryData,
}: {
  showHiddenQuestions: boolean;
  assessmentDeliveryData?: AssessmentDeliveryFragment | null;
  summary: JSON | string | null;
  excludedAnswerIds: number[];
  canUpdate: boolean;
  isDraft: boolean;
  onUpdateDeliveryData?: (attrs: any) => void;
}) => {
  const label = useLabel();

  const target = useMemo(
    () => (assessmentDeliveryData ? assessmentDeliveryData.target : null),
    [assessmentDeliveryData]
  );
  const targetCurrentRoleIds = useMemo(
    () => compact(assessmentDeliveryData?.targetCurrentRoleIds),
    [assessmentDeliveryData]
  );

  const sectionNodes = useMemo(() => {
    return getAssessmentDeliverySections(assessmentDeliveryData);
  }, [assessmentDeliveryData]);

  const allAnswers = useMemo(() => {
    if (!assessmentDeliveryData) {
      return [];
    }
    const allAnswers = assertEdgesNonNull(assessmentDeliveryData.answers);
    return allAnswers;
  }, [assessmentDeliveryData]);

  return (
    <div>
      {sectionNodes.map((section) => {
        const sectionQuestions = section.questions;
        const hasVisibleQuestions = sectionQuestions.some((question) => {
          const answers = allAnswers.filter(
            (answer) => answer.questionId === question.question.id
          );
          return answers.length > 0;
        });
        if (!hasVisibleQuestions) {
          return null;
        }
        const heading =
          String(section.id).endsWith("-goals") ||
          String(section.id).endsWith("-role") ? null : section.title ? (
            <AssessmentLayout.SectionHeading>
              <AssessmentLayout.SectionHeadingTitle>
                {section.title}
              </AssessmentLayout.SectionHeadingTitle>
              {section.description && !isEmptyValue(section.description) && (
                <AssessmentLayout.SectionHeadingDescription>
                  <TextareaWysiwyg
                    editable={false}
                    className="bg-white"
                    value={section.description}
                  />
                </AssessmentLayout.SectionHeadingDescription>
              )}
            </AssessmentLayout.SectionHeading>
          ) : null;

        const regularQuestions = sectionQuestions
          .filter(
            ({ question }) =>
              !isRoleBasedQuestionType(question.questionType) &&
              !isGoalBasedQuestionType(question.questionType)
          )
          .filter(
            (node) =>
              showHiddenQuestions ||
              (node.question.responseVisibility !==
                AssessmentQuestionResponseVisibility.HiddenFromSubject &&
                assessmentDeliveryData?.template.delivery !==
                  AssessmentGroupDelivery.None)
          );
        const roleBasedQuestions = sectionQuestions.filter(({ question }) =>
          isRoleBasedQuestionType(question.questionType)
        );
        const goalBasedQuestions = sectionQuestions.filter(({ question }) =>
          isGoalBasedQuestionType(question.questionType)
        );
        if (
          regularQuestions.length === 0 &&
          roleBasedQuestions.length === 0 &&
          goalBasedQuestions.length === 0
        ) {
          return null;
        }
        const overallGoalBasedQuestion = goalBasedQuestions.find(
          ({ question }) =>
            question.questionType === AssessmentQuestionType.OverallGoal
        );
        const individualGoalBasedQuestions = goalBasedQuestions.filter(
          ({ question }) =>
            question.questionType === AssessmentQuestionType.IndividualGoal
        );
        const overallGoalBasedQuestionAnswers = allAnswers.filter(
          (answer) =>
            overallGoalBasedQuestion &&
            answer.questionId === overallGoalBasedQuestion.question.id
        );

        const {
          roles: currentRoles,
          roleBasedQuestionsByRoleId: currentRoleQuestionsByRoleId,
        } = getQuestionsByRole(roleBasedQuestions, (role) => {
          return targetCurrentRoleIds.includes(role.id);
        });
        const {
          roles: nextRoles,
          roleBasedQuestionsByRoleId: nextRoleQuestionsByRoleId,
        } = getQuestionsByRole(roleBasedQuestions, (role) => {
          return !targetCurrentRoleIds.includes(role.id);
        });

        return (
          <AssessmentLayout.SectionContainer key={section.id}>
            {heading}
            {regularQuestions.length !== 0 && (
              <AssessmentLayout.SectionRegularQuestions>
                {regularQuestions.map((node) => {
                  const { question } = node;
                  const answers = allAnswers.filter(
                    (answer) => answer.questionId === question.id
                  );
                  return (
                    <AssessmentDeliveryQuestion
                      key={question.id}
                      canUpdate={!!canUpdate && isDraft}
                      excludedAnswerIds={excludedAnswerIds}
                      onUpdateExcludedAnswers={(newExcludedAnswerIds) => {
                        if (onUpdateDeliveryData) {
                          onUpdateDeliveryData({
                            excludedAnswerIds: newExcludedAnswerIds,
                          });
                        }
                      }}
                      answers={answers}
                      question={question}
                      target={target}
                    />
                  );
                })}
              </AssessmentLayout.SectionRegularQuestions>
            )}
            {map(currentRoleQuestionsByRoleId, (questions, roleIdStr) => {
              const role = assertNonNull(
                currentRoles.find((role) => role.id === parseInt(roleIdStr))
              );

              return (
                <RoleBasedAssessmentQuestionLayout.Container key={roleIdStr}>
                  <RoleBasedAssessmentQuestionGroup
                    allAnswers={allAnswers.map((ans) => ({
                      ...ans,
                      questionId: ans.questionId,
                      textAnswer:
                        ans.__typename === "TextAssessmentAnswerNode"
                          ? ans.textAnswer
                          : null,
                      comment: ans.comment ?? null,
                    }))}
                    role={role}
                    questions={questions}
                    formDisabled
                    isCurrentRole
                  />
                </RoleBasedAssessmentQuestionLayout.Container>
              );
            })}
            {map(nextRoleQuestionsByRoleId, (questions, roleIdStr) => {
              const role = assertNonNull(
                nextRoles.find((role) => role.id === parseInt(roleIdStr))
              );
              const hasAnswers = allAnswers.some((answer) =>
                questions.map((q) => q.id).includes(answer.questionId)
              );
              if (!hasAnswers) {
                return null;
              }

              return (
                <RoleBasedAssessmentQuestionLayout.Container key={roleIdStr}>
                  <RoleBasedAssessmentQuestionGroup
                    allAnswers={allAnswers.map((ans) => ({
                      ...ans,
                      questionId: ans.questionId,
                      textAnswer:
                        ans.__typename === "TextAssessmentAnswerNode"
                          ? ans.textAnswer
                          : null,
                      comment: ans.comment ?? null,
                    }))}
                    role={role}
                    questions={questions}
                    formDisabled
                  />
                </RoleBasedAssessmentQuestionLayout.Container>
              );
            })}

            {String(section.id).endsWith("-goals") && (
              <>
                <AssessmentLayout.SectionHeading>
                  <AssessmentLayout.SectionHeadingTitle>
                    {label("goal", { pluralize: true, capitalize: true })}
                  </AssessmentLayout.SectionHeadingTitle>
                  <AssessmentLayout.SectionHeadingDescription>
                    Assess the {label("goal", { pluralize: true })} due in this{" "}
                    {label("review")} cycle
                  </AssessmentLayout.SectionHeadingDescription>
                </AssessmentLayout.SectionHeading>

                <AssessmentLayout.SectionRegularQuestions>
                  {individualGoalBasedQuestions.length === 0 && (
                    <div className="p-6">
                      <div className="text-sm text-gray-500">
                        No {label("goal", { pluralize: true })} to assess.
                      </div>
                    </div>
                  )}
                  {individualGoalBasedQuestions
                    .filter((node) => {
                      if (
                        node.question.responseVisibility ===
                          AssessmentQuestionResponseVisibility.HiddenFromSubject &&
                        !showHiddenQuestions
                      )
                        return false;
                      return true;
                    })
                    .map((node) => {
                      const { question } = node;
                      const answers = allAnswers.filter(
                        (answer) => answer.questionId === question.id
                      );
                      return (
                        <AssessmentDeliveryQuestion
                          key={question.id}
                          canUpdate={!!canUpdate && isDraft}
                          excludedAnswerIds={excludedAnswerIds}
                          onUpdateExcludedAnswers={(newExcludedAnswerIds) => {
                            if (onUpdateDeliveryData) {
                              onUpdateDeliveryData({
                                excludedAnswerIds: newExcludedAnswerIds,
                              });
                            }
                          }}
                          answers={answers}
                          question={question}
                          target={target}
                        />
                      );
                    })}
                  {individualGoalBasedQuestions.length > 0 &&
                    overallGoalBasedQuestion && (
                      <div>
                        <AssessmentDeliveryQuestion
                          key={overallGoalBasedQuestion.id}
                          canUpdate={!!canUpdate && isDraft}
                          excludedAnswerIds={excludedAnswerIds}
                          onUpdateExcludedAnswers={(newExcludedAnswerIds) => {
                            if (onUpdateDeliveryData) {
                              onUpdateDeliveryData({
                                excludedAnswerIds: newExcludedAnswerIds,
                              });
                            }
                          }}
                          answers={overallGoalBasedQuestionAnswers}
                          question={overallGoalBasedQuestion.question}
                          target={target}
                        />
                      </div>
                    )}
                </AssessmentLayout.SectionRegularQuestions>
              </>
            )}
          </AssessmentLayout.SectionContainer>
        );
      })}

      {((canUpdate && isDraft) || !isEmptyValue(summary)) && (
        <AssessmentLayout.SectionContainer aria-label="Assessment delivery summary">
          <div className="text-gray-500 text-xs uppercase font-semibold">
            Summary
          </div>
          <TextareaWysiwyg
            editable={!!canUpdate && isDraft}
            className="mt-1 bg-white"
            value={summary}
            onChangeValue={(newSummary) => {
              if (onUpdateDeliveryData)
                onUpdateDeliveryData({
                  summary: newSummary,
                });
            }}
          />
        </AssessmentLayout.SectionContainer>
      )}
    </div>
  );
};

export default AssessmentDeliveryContent;

import { useMutation, useQuery } from "@apollo/client";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { compact, uniqBy } from "lodash";
import moment from "moment";
import { useState } from "react";
import {
  AttendeeRole,
  AttendeeStatus,
  ConvertToReviewMeetingMutation,
  ConvertToReviewMeetingMutationVariables,
  GetUpcoming1on1sQuery,
  GetUpcoming1on1sQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
  Upcoming1on1MeetingFragment,
  UserActivityComplianceProgramMeetingFragment,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import getActiveReviewsActionsQuery from "@apps/assessments/graphql/get-active-reviews-actions-query";
import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import convertToReviewMeetingMutation from "@apps/meeting-new/components/review/graphql/convert-to-review-meeting-mutation";
import getComplianceProgramActionsQuery from "@apps/programs/graphql/get-compliance-program-actions-query";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Button, {
  ButtonProps,
  ButtonSize,
  buttonTheme,
} from "@components/button/button";
import DraftLabel from "@components/draft-label/draft-label";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  formatMeetingTimes,
  getUrl,
} from "@helpers/helpers";

import getUpcoming1on1sQuery from "./graphql/get-upcoming-1-on-1s-query";

const Upcoming1on1MeetingItem = ({
  meeting,
  onSelectMeeting,
  selectedMeetingId,
}: {
  meeting: Upcoming1on1MeetingFragment;
  onSelectMeeting: (meeting: Upcoming1on1MeetingFragment | null) => void;
  selectedMeetingId?: number;
}) => {
  const isSelected = selectedMeetingId === meeting.id;
  return (
    <div
      key={meeting.id}
      className="flex justify-between items-center text-sm text-gray-500 px-3 py-2 hover:bg-gray-50 rounded"
    >
      <div className="flex items-center gap-2">
        {meeting.startDatetime
          ? `${moment(meeting.startDatetime).format(
              "MMM Do, YYYY,"
            )} ${formatMeetingTimes(meeting)}`
          : meeting.title}
        {meeting.draft && <DraftLabel />}
        <Button
          icon={ExternalLinkIcon}
          size={ButtonSize.small}
          theme={buttonTheme.iconGray}
          to={getUrl({
            meetingId: meeting.id,
            meetingGroupId: meeting.meetingGroupId,
          })}
          target="_blank"
        />
      </div>
      <div>
        <button
          className={classNames(
            "text-blue-link hover:underline focus:outline-none",
            isSelected && "opacity-60"
          )}
          type="button"
          onClick={() =>
            isSelected ? onSelectMeeting(null) : onSelectMeeting(meeting)
          }
        >
          {isSelected ? "Selected" : "Select"}
        </button>
      </div>
    </div>
  );
};

const SelectOrCreate1on1Button = ({
  matchingOneonone,
  buttonProps,
  target,
  requiredTopicTemplateId,
  facilitator,
}: {
  matchingOneonone?:
    | UserActivityComplianceProgramMeetingFragment
    | MeetingViewMeetingNodeNewPageFragmentFragment;
  buttonProps: ButtonProps;
  target: BasicUser;
  requiredTopicTemplateId: number;
  facilitator: BasicUser;
}) => {
  const label = useLabel();
  const currentUser = currentUserVar();
  const [isMeetingCreationDialogOpen, setIsMeetingCreationDialogOpen] =
    useState(false);
  const [isMeetingSelectorDialogOpen, setIsMeetingSelectorDialogOpen] =
    useState(false);
  const [selectedMeeting, setSelectedMeeting] =
    useState<Upcoming1on1MeetingFragment | null>(null);

  const handleCloseDialog = () => {
    setIsMeetingCreationDialogOpen(false);
  };

  const [convertToReviewMeeting, { loading: loadingConversion }] = useMutation<
    ConvertToReviewMeetingMutation,
    ConvertToReviewMeetingMutationVariables
  >(convertToReviewMeetingMutation);

  const { data, loading } = useQuery<
    GetUpcoming1on1sQuery,
    GetUpcoming1on1sQueryVariables
  >(getUpcoming1on1sQuery, {
    variables: {
      startDatetime: moment().startOf("hour").format(),
      participants: compact([currentUser.id, target?.id]),
    },
    skip: !isMeetingSelectorDialogOpen,
    onError: onNotificationErrorHandler(),
  });
  const meetings = data?.scheduledMeetings
    ? assertEdgesNonNull(data?.scheduledMeetings)
    : [];

  const selectedMeetingCanBeOverwritten =
    selectedMeeting && selectedMeeting.isLocked;

  const handleClickCreateMeeting = () => {
    setIsMeetingCreationDialogOpen(true);
    setTimeout(() => {
      setIsMeetingSelectorDialogOpen(false);
    }, 1);
  };

  const handleAddProgramRequiredTemplateToMeeting = (
    clearExistingTopics: boolean
  ) => {
    if (!selectedMeeting) {
      return;
    }
    convertToReviewMeeting({
      variables: {
        meetingId: selectedMeeting?.id,
        topicTemplateId: requiredTopicTemplateId,
        clearExistingTopics,
      },
      refetchQueries: [
        getActiveReviewsActionsQuery,
        getComplianceProgramActionsQuery,
      ],
      onError: onNotificationErrorHandler(),
      onCompleted: () => {
        setIsMeetingSelectorDialogOpen(false);
      },
    });
  };
  const handleClickConvertToReviewMeeting = () => {
    handleAddProgramRequiredTemplateToMeeting(false);
  };
  const handleClickOverwriteAndConvertToReviewMeeting = () => {
    handleAddProgramRequiredTemplateToMeeting(true);
  };

  return (
    <>
      {isMeetingSelectorDialogOpen && (
        <Modal onClose={() => setIsMeetingSelectorDialogOpen(false)}>
          <div className="flex flex-col divide-y">
            <div className="p-6">
              <ModalTitle onClose={() => setIsMeetingSelectorDialogOpen(false)}>
                <div>
                  Schedule {label("review", { titleCase: true })} Meeting
                </div>
              </ModalTitle>
              <div className="text-sm mt-1 font-normal">
                Convert an upcoming {label("1-on-1")} or create a new{" "}
                {label("1-on-1", { titleCase: true })}. You can overwrite or add
                to the existing {label("1-on-1")} topics.
              </div>
            </div>
            <div className="px-3 py-4 flex flex-col gap-2">
              {meetings.map((meeting) => (
                <Upcoming1on1MeetingItem
                  key={meeting.id}
                  meeting={meeting}
                  selectedMeetingId={selectedMeeting?.id}
                  onSelectMeeting={setSelectedMeeting}
                />
              ))}
              {loading && (
                <Loading>Loading upcoming {label("1-on-1")}...</Loading>
              )}
              {!loading && meetings.length === 0 && (
                <div className="flex flex-col gap-8 text-center py-8 text-gray-400 text-sm">
                  <div className="text-center">
                    No upcoming {label("1-on-1", { pluralize: true })}.
                  </div>
                  <div className="flex justify-center">
                    <Button
                      theme={buttonTheme.primary}
                      onClick={handleClickCreateMeeting}
                      text="Create a new meeting"
                    />
                  </div>
                </div>
              )}
            </div>
            {!loading && meetings.length > 0 && (
              <div className="flex justify-end gap-4 p-6">
                {loadingConversion && <Loading size="5" mini />}
                <Button
                  onClick={handleClickCreateMeeting}
                  text="Create a new meeting"
                  disabled={loadingConversion}
                />

                {/* If has topics, is locked, or has a topic template, show the overwrite existing topics button */}
                {selectedMeetingCanBeOverwritten && (
                  <Button
                    onClick={handleClickOverwriteAndConvertToReviewMeeting}
                    text="Overwrite Existing Topics"
                    theme={buttonTheme.redDanger}
                    disabled={loadingConversion}
                  />
                )}
                {meetings.length > 0 && (
                  <Button
                    theme={buttonTheme.primary}
                    onClick={handleClickConvertToReviewMeeting}
                    text={`Convert To ${label("review", {
                      titleCase: true,
                    })} Meeting`}
                    disabled={!selectedMeeting || loadingConversion}
                  />
                )}
              </div>
            )}
          </div>
        </Modal>
      )}
      {isMeetingCreationDialogOpen && (
        <MeetingDialog
          onClose={handleCloseDialog}
          enforceMeetingDate
          formOptions={{
            templateId: requiredTopicTemplateId,
            isFormalOneonone: true,
            facilitatorId: facilitator.id,
            attendees: uniqBy(
              compact([
                {
                  ...facilitator,
                  email: facilitator.email || "",
                  role: AttendeeRole.Required,
                  participantStatus: AttendeeStatus.NotResponded,
                },
                target && {
                  ...target,
                  role: AttendeeRole.Required,
                  participantStatus: AttendeeStatus.NotResponded,
                  email: target.email || "",
                },
              ]),
              "id"
            ),
          }}
        />
      )}
      <Button
        {...buttonProps}
        onClick={
          !!matchingOneonone
            ? undefined
            : () => setIsMeetingSelectorDialogOpen(true)
        }
        to={
          !!matchingOneonone
            ? getUrl({
                meetingId: matchingOneonone.id,
                meetingGroupId: matchingOneonone.meetingGroupId,
              })
            : undefined
        }
      />
    </>
  );
};

export default SelectOrCreate1on1Button;

import { gql } from "@apollo/client";

export default gql`
  query getUserRolesQuery($userIds: [Int!]!) {
    users(ids: $userIds) {
      edges {
        node {
          id
          roles {
            id
            title
          }
        }
      }
    }
  }
`;

import { useMutation } from "@apollo/client";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { ClickEvent, MenuDivider, MenuItem } from "@szhsin/react-menu";
import { compact } from "lodash";
import { useState } from "react";
import { FiCornerDownRight, FiCornerUpRight } from "react-icons/fi";
import { TbListTree, TbPlaylistAdd, TbPlaylistX } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import {
  AlignGoalMutationMutation,
  AlignGoalMutationMutationVariables,
  ArtifactType,
  CreatedArtifactFragmentFragment,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import alignGoalMutation from "@apps/artifact-sidebar/graphql/align-goal-mutation";
import getArtifactSidebarQuery from "@apps/artifact-sidebar/graphql/get-artifact-sidebar-query";
import GoalAlignmentPickerDialogWithExistingGoal from "@apps/goal-alignment-dialog-picker/goal-alignment-dialog-picker-with-existing-goal";
import { refreshAlignmentOfGoalIds } from "@apps/goal-alignment/helpers";
import useLabel from "@apps/use-label/use-label";
import GoalIcon from "@components/goal-icon/goal-icon";
import { useLink } from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { graphqlNone } from "@helpers/constants";
import { getUrl, isGoalArtifactNode, toWithBackground } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import { ArtifactDropdownArtifactType } from "./artifact-dropdown-menu";

export const ArtifactDropdownAlignmentSubMenu = ({
  artifact,
  buttonRef,
}: {
  artifact: ArtifactDropdownArtifactType;
  buttonRef: HTMLElement | null;
}) => {
  const label = useLabel();
  const link = useLink();
  const location = useLocation<TFLocationState>();
  const artifactUrl = getUrl({
    artifactType: artifact.artifactType,
    artifactId: artifact.id,
  });
  const isEditableGoalArtifact =
    artifact.id &&
    artifact.artifactType === ArtifactType.Goal &&
    artifact.canUpdate?.permission;

  const [isOpeningCreateSubGoalDialog, setIsOpeningCreateSubGoalDialog] =
    useState(false);
  const [isOpenGoalAlignmentDialog, setIsOpenGoalAlignmentDialog] = useState<
    "child" | "parent" | undefined
  >(undefined);

  const handleRevealChildGoalAlignmentPopover = () => {
    setIsOpenGoalAlignmentDialog("child");
  };

  const handleRevealParentGoalAlignmentPopover = () => {
    setIsOpenGoalAlignmentDialog("parent");
  };

  const handleCloseGoalAlignmentDialog = () => {
    setIsOpenGoalAlignmentDialog(undefined);
  };

  const handleCloseCreateArtifactDialog = (
    createdArtifact?: CreatedArtifactFragmentFragment
  ) => {
    setIsOpeningCreateSubGoalDialog(false);
    if (createdArtifact && isGoalArtifactNode(createdArtifact)) {
      refreshAlignmentOfGoalIds([
        artifact.id,
        createdArtifact?.parentGoal?.id,
        createdArtifact?.id,
      ]);
    }
  };

  const {
    confirm: confirmClearParentAlignment,
    ConfirmationDialog: ConfirmationDialogClearParentAlignment,
  } = useConfirm("Are you sure you want to remove this alignment?", "");
  const [updateGoal] = useMutation<
    AlignGoalMutationMutation,
    AlignGoalMutationMutationVariables
  >(alignGoalMutation);

  const handleClearParentAlignment = async () => {
    const oldParentGoalId = artifact.parentGoalId;
    const confirmed = await confirmClearParentAlignment();
    if (confirmed) {
      updateGoal({
        variables: {
          goalId: artifact.id,
          parentGoalId: graphqlNone,
        },
        refetchQueries: [getArtifactSidebarQuery],
        onError: onNotificationErrorHandler(),
        onCompleted: () => {
          refreshAlignmentOfGoalIds(compact([artifact.id, oldParentGoalId]));
        },
      });
    }
  };

  const handleClickLink = () => {
    link.redirect(
      toWithBackground({
        pathname: artifactUrl,
        location,
      })
    );
  };

  const fullAlignmentUrl = `/goal-alignment?goalId=${artifact.id}`;
  const handleClickViewFullAlignment = (e: ClickEvent) => {
    e.syntheticEvent.preventDefault();
    link.redirect(fullAlignmentUrl);
  };

  return (
    <>
      {isEditableGoalArtifact &&
        isOpenGoalAlignmentDialog === "child" &&
        buttonRef && (
          <GoalAlignmentPickerDialogWithExistingGoal
            alignmentType="child"
            artifactId={artifact.id}
            externalReferenceElement={buttonRef}
            onClose={handleCloseGoalAlignmentDialog}
          />
        )}
      {isEditableGoalArtifact &&
        isOpenGoalAlignmentDialog === "parent" &&
        buttonRef && (
          <GoalAlignmentPickerDialogWithExistingGoal
            alignmentType="parent"
            artifactId={artifact.id}
            externalReferenceElement={buttonRef}
            onClose={handleCloseGoalAlignmentDialog}
          />
        )}
      {isOpeningCreateSubGoalDialog && artifact.id && artifact.title && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.Goal,
            parentGoal: {
              value: artifact.id,
              label: artifact.title,
              htmlLabel: (
                <div className="flex items-center gap-1">
                  <GoalIcon scope={artifact.scope} state={artifact.state} />{" "}
                  {artifact.title}
                </div>
              ),
            },
          }}
          onClose={handleCloseCreateArtifactDialog}
        />
      )}
      <ConfirmationDialogClearParentAlignment />

      <MenuItem
        contentEditable={false}
        onClick={handleRevealParentGoalAlignmentPopover}
      >
        <FiCornerUpRight className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
        Align This {label("goal", { titleCase: true })} to a Parent
      </MenuItem>
      <MenuItem
        contentEditable={false}
        onClick={handleRevealChildGoalAlignmentPopover}
      >
        <FiCornerDownRight className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
        Align a Sub {label("goal", { titleCase: true })} to This
      </MenuItem>

      <MenuDivider />
      <MenuItem
        contentEditable={false}
        onClick={() => setIsOpeningCreateSubGoalDialog(true)}
      >
        <TbPlaylistAdd className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
        Create a Sub {label("goal", { titleCase: true })}
      </MenuItem>
      <MenuDivider />

      {isGoalArtifactNode(artifact) && artifact.parentGoalId && (
        <>
          <MenuItem
            contentEditable={false}
            onClick={handleClearParentAlignment}
          >
            <TbPlaylistX className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
            Unalign From Parent
          </MenuItem>
          <MenuDivider />
        </>
      )}

      <MenuItem contentEditable={false} onClick={handleClickLink}>
        <ExternalLinkIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
        Edit Alignment
      </MenuItem>
      <MenuDivider />

      <MenuItem
        href={fullAlignmentUrl}
        contentEditable={false}
        onClick={handleClickViewFullAlignment}
      >
        <TbListTree className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
        View Full Alignment for This {label("goal", { titleCase: true })}
      </MenuItem>
    </>
  );
};

export default ArtifactDropdownAlignmentSubMenu;

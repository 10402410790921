import { gql } from "@apollo/client";

const getActiveComplianceProgramWithUnmetTopicTemplatesQuery = gql`
  query getActiveComplianceProgramWithUnmetTopicTemplates($meetingId: Int!) {
    meeting(meetingId: $meetingId) {
      id
      activeComplianceProgramsWithUnmetTopicTemplates {
        edges {
          node {
            id
            title
            requiredTopicTemplates(first: 1) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getActiveComplianceProgramWithUnmetTopicTemplatesQuery;

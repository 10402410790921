import { useQuery } from "@apollo/client";
import moment from "moment";
import { useState } from "react";
import {
  AssessmentType,
  GetMeetingRecentlyCompletedAssessmentDeliveriesNewPageQueryQuery,
  GetMeetingRecentlyCompletedAssessmentDeliveriesNewPageQueryQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
  RecentlyCompletedAssessmentDeliveryNewPageFragment,
} from "types/graphql-schema";

import AssessmentDeliverySidebar from "@apps/assessments/components/delivery/assessment-delivery-sidebar";
import getMeetingRecentlyCompletedAssessmentDeliveriesQuery from "@apps/meeting-new/graphql/get-meeting-recently-completed-assessment-deliveries-query";
import useLabel from "@apps/use-label/use-label";
import { UiPreferenceCache } from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Avatar from "@components/avatar/avatar";
import Layout from "@components/layout/layout";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { batchClient } from "@graphql/client";
import { getAssessmentTypeLabel } from "@helpers/constants";
import { assertEdgesNonNull } from "@helpers/helpers";

const RecentlyCompletedAssessmentDeliveryItem = ({
  assessmentDelivery,
}: {
  assessmentDelivery: RecentlyCompletedAssessmentDeliveryNewPageFragment;
}) => {
  const label = useLabel();
  const [showSidebar, setShowSidebar] = useState(false);

  const handleClickDeliveryLink = () => {
    setShowSidebar(true);
  };

  return (
    <Layout.SidebarSubSectionListItem>
      <>
        {showSidebar && (
          <AssessmentDeliverySidebar
            assessmentDeliveryId={assessmentDelivery.id}
            onClose={() => setShowSidebar(false)}
          />
        )}
        <div className="flex items-center gap-1.5 text-sm">
          <Avatar user={assessmentDelivery.target} size={4} />
          <button
            type="button"
            className="text-blue-link hover:underline"
            onClick={handleClickDeliveryLink}
          >
            {assessmentDelivery.target?.name}:{" "}
            {getAssessmentTypeLabel(
              assessmentDelivery.template.assessmentType
            ).toLowerCase()}{" "}
            {label("review")}
          </button>
        </div>
        <div className="text-xs flex justify-between mt-1 text-gray-500 ml-5 pl-0.5">
          <span>{assessmentDelivery.complianceProgram?.title}</span>
          {assessmentDelivery.deliveryDatetime ? (
            <span className="text-gray-400">
              {moment(assessmentDelivery.deliveryDatetime).format("LLL")}
            </span>
          ) : assessmentDelivery.lastSubmissionDatetime ? (
            <span className="text-gray-400">
              {moment(assessmentDelivery.lastSubmissionDatetime).format("LLL")}
            </span>
          ) : null}
        </div>
      </>
    </Layout.SidebarSubSectionListItem>
  );
};

const RecentlyCompletedAssessmentDeliveryCollapsibleContainer = ({
  meeting,
  assessmentType,
  collapseKey,
  title,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  assessmentType: AssessmentType;
  collapseKey: keyof UiPreferenceCache;
  title: string;
}) => {
  const { data, loading, fetchMore } = useQuery<
    GetMeetingRecentlyCompletedAssessmentDeliveriesNewPageQueryQuery,
    GetMeetingRecentlyCompletedAssessmentDeliveriesNewPageQueryQueryVariables
  >(getMeetingRecentlyCompletedAssessmentDeliveriesQuery, {
    variables: { meetingId: meeting.id, assessmentType, limit: 2 },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    client: batchClient,
    onError: onNotificationErrorHandler(),
  });
  const assessmentDeliveries = data?.meeting
    ?.recentlyCompletedAssessmentDeliveries
    ? assertEdgesNonNull(data.meeting.recentlyCompletedAssessmentDeliveries)
    : [];

  const handleClickMore = () => {
    if (
      data?.meeting?.recentlyCompletedAssessmentDeliveries.pageInfo.hasNextPage
    ) {
      fetchMore({
        variables: {
          limit: 5,
          after:
            data.meeting?.recentlyCompletedAssessmentDeliveries.pageInfo
              .endCursor,
          merge: true,
        },
      });
    }
  };

  return (
    <Layout.SidebarSubSection
      expandedUiPreferenceKey={collapseKey}
      title={title}
      loading={assessmentDeliveries.length === 0 && loading}
      count={data?.meeting?.recentlyCompletedAssessmentDeliveries.totalCount}
    >
      <Layout.SidebarSubSectionList
        loading={loading}
        hasNextPage={
          !!data?.meeting?.recentlyCompletedAssessmentDeliveries.pageInfo
            .hasNextPage
        }
        onClickMore={handleClickMore}
      >
        {assessmentDeliveries.map((assessmentDelivery) => (
          <RecentlyCompletedAssessmentDeliveryItem
            assessmentDelivery={assessmentDelivery}
            key={assessmentDelivery.id}
          />
        ))}
      </Layout.SidebarSubSectionList>
    </Layout.SidebarSubSection>
  );
};

const RecentlyCompletedAssessmentDeliveries = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const assessmentGroups: {
    title: string;
    collapseKey: keyof UiPreferenceCache;
    assessmentType: AssessmentType;
  }[] = [
    {
      title: getAssessmentTypeLabel(AssessmentType.Performance),
      assessmentType: AssessmentType.Performance,
      collapseKey: "oneononePerformanceAssessments",
    },
    {
      title: getAssessmentTypeLabel(AssessmentType.Manager),
      assessmentType: AssessmentType.Manager,
      collapseKey: "oneononeManagerAssessments",
    },
    {
      title: getAssessmentTypeLabel(AssessmentType.Peer),
      assessmentType: AssessmentType.Peer,
      collapseKey: "oneononePeerAssessments",
    },
  ];

  return (
    <Layout.SidebarSection
      title="Assessments recently delivered"
      expandedUiPreferenceKey="meetingSidebarRecentlyCompletedAssessmentsContainerExpanded"
    >
      {assessmentGroups.map((assessmentGroup, i) => (
        <RecentlyCompletedAssessmentDeliveryCollapsibleContainer
          meeting={meeting}
          collapseKey={assessmentGroup.collapseKey}
          title={assessmentGroup.title}
          assessmentType={assessmentGroup.assessmentType}
          key={assessmentGroup.title}
        />
      ))}
    </Layout.SidebarSection>
  );
};
export default RecentlyCompletedAssessmentDeliveries;

import { useQuery } from "@apollo/client";
import moment from "moment";
import { useMemo } from "react";
import { TbInputCheck } from "react-icons/tb";
import {
  GetDeliveredEngagementSurveysQuery,
  GetDeliveredEngagementSurveysQueryVariables,
} from "types/graphql-schema";

import { currentOrganizationVar } from "@cache/cache";
import Button from "@components/button/button";
import Layout from "@components/layout/layout";
import AppLink from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getDeliveredEngagementSurveysQuery from "../graphql/get-delivered-engagement-surveys-query";

const EngagementSurveyHomepageDeliveries = () => {
  const currentOrganization = currentOrganizationVar();
  const { data, loading, fetchMore } = useQuery<
    GetDeliveredEngagementSurveysQuery,
    GetDeliveredEngagementSurveysQueryVariables
  >(getDeliveredEngagementSurveysQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      organizationId: currentOrganization.id,
      after: null,
      orderBy: "-submitted_datetime",
    },
    onError: onNotificationErrorHandler(),
  });
  const deliveries = useMemo(() => {
    return data?.engagementSurveyDeliveries
      ? assertEdgesNonNull(data?.engagementSurveyDeliveries)
      : [];
  }, [data]);

  const handleClickLoadMore = () => {
    if (data?.engagementSurveyDeliveries?.pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          after: data?.engagementSurveyDeliveries?.pageInfo?.endCursor,
          merge: true,
        },
      });
    }
  };

  return (
    <Layout.MainSubSection
      className="mt-8"
      title=""
      loading={loading}
      emptyPlaceholder={
        deliveries.length === 0
          ? `There are no delivered surveys for you`
          : null
      }
    >
      <Layout.MainSubSectionList>
        {deliveries.map((delivery) => (
          <Layout.MainSubSectionListItem key={delivery.id}>
            <div className="flex items-center justify-between text-sm gap-4">
              <div className="flex items-center gap-3">
                <TbInputCheck className="shrink-0 w-5 h-5" />
                <div className="flex flex-col gap-0.5">
                  <div className="font-medium">
                    {delivery.complianceProgram?.title}
                  </div>
                  {delivery.complianceProgram && (
                    <AppLink
                      to={`/survey/delivery/${delivery.id}`}
                      className="text-gray-500 text-xs font-medium hover:underline"
                    >
                      {delivery.complianceProgram?.title}
                    </AppLink>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="text-gray-400">
                  {delivery.lastSubmissionDatetime
                    ? `Last submission on ${moment(
                        delivery.lastSubmissionDatetime
                      ).format("MMM Do, YYYY")}`
                    : ""}
                </div>
                <Button text="View" to={`/survey/delivery/${delivery.id}`} />
              </div>
            </div>
          </Layout.MainSubSectionListItem>
        ))}
        {data?.engagementSurveyDeliveries?.pageInfo.hasNextPage && (
          <Layout.MainSubSectionListItem className="flex justify-center">
            <Button text="Load more" onClick={handleClickLoadMore} />
          </Layout.MainSubSectionListItem>
        )}
      </Layout.MainSubSectionList>
    </Layout.MainSubSection>
  );
};

export default EngagementSurveyHomepageDeliveries;

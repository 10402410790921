import { useMutation } from "@apollo/client";
import { PlusIcon, SparklesIcon, TrashIcon } from "@heroicons/react/outline";
import { useState } from "react";
import {
  ArtifactType,
  CreatedArtifactFragmentFragment,
} from "types/graphql-schema";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import getMeetingRecognitionsQuery from "@apps/meeting-new/graphql/get-meeting-recognitions-query";
import hideSuggestedArtifactMutation from "@apps/meeting-transcription-dialog/graphql/hide-suggested-artifact-mutation";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { graphqlNone } from "@helpers/constants";

const MeetingSummaryGroupTranscriptionSuggestedArtifact = ({
  meeting,
  suggestedArtifact,
}: {
  meeting: any;
  suggestedArtifact: any;
}) => {
  const [showArtifactDialog, setShowArtifactDialog] = useState(false);
  const currentOrganization = currentOrganizationVar();
  const label = useLabel();
  const emptyAssignee = {
    id: graphqlNone,
    name: "Unassigned",
  };

  const assignee = suggestedArtifact.suggestedAssignee || emptyAssignee;
  const [hideSuggestedArtifact, { loading: loadingHide }] = useMutation(
    hideSuggestedArtifactMutation
  );
  const artifactLabel = label(suggestedArtifact.artifactType);

  const handleClickHideSuggestedArtifact = () => {
    hideSuggestedArtifact({
      variables: { suggestedArtifactId: suggestedArtifact.id },
      optimisticResponse: {
        updateSuggestedArtifact: {
          suggestedArtifact: {
            ...suggestedArtifact,
            hidden: true,
          },
        },
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleCloseDialog = (
    createdArtifact?: CreatedArtifactFragmentFragment
  ) => {
    setShowArtifactDialog(false);
    if (createdArtifact) {
      handleClickHideSuggestedArtifact();
    }
  };

  return (
    <div key={suggestedArtifact.id} className="flex items-start gap-1.5 group">
      {showArtifactDialog && (
        <ArtifactCreationDialog
          formOptions={{
            title: suggestedArtifact.title,
            feedbackMessage:
              suggestedArtifact.artifactType === ArtifactType.Feedback
                ? JSON.stringify({
                    type: "doc",
                    content: [
                      {
                        type: "paragraph",
                        content: [
                          { type: "text", text: suggestedArtifact.description },
                        ],
                      },
                    ],
                  })
                : undefined,
            artifactType: suggestedArtifact.artifactType,
            organizationId: meeting.organization?.id || currentOrganization.id,
            meetingId: meeting.id,
            meetingGroupId: meeting.meetingGroup.id,
            assignee:
              suggestedArtifact.artifactType === ArtifactType.ActionItem &&
              assignee.id !== graphqlNone
                ? assignee
                : undefined,
            recognitionRecipients:
              suggestedArtifact.artifactType === ArtifactType.Recognition &&
              assignee.id !== graphqlNone
                ? [assignee]
                : [],
            feedbackRecipients:
              suggestedArtifact.artifactType === ArtifactType.Feedback &&
              assignee.id !== graphqlNone
                ? [assignee]
                : [],
          }}
          onClose={handleCloseDialog}
          refetchQueries={[getMeetingRecognitionsQuery]}
        />
      )}

      <div className="shrink-0">
        <SparklesIcon className="h-5 w-5 text-purple-500" />
      </div>
      <button
        className="flex-1 text-left text-gray-700 hover:underline"
        onClick={() => setShowArtifactDialog(true)}
      >
        Suggestion:{" "}
        <strong className="text-gray-900 font-medium">
          {suggestedArtifact.title}
        </strong>
      </button>
      <div className="flex items-center gap-2">
        <div className="p-0.5">
          <Tooltip text={`Create ${artifactLabel}`}>
            <button
              className="p-1 rounded-full hover:opacity-90 text-white bg-blue-link"
              onClick={() => setShowArtifactDialog(true)}
              disabled={loadingHide}
            >
              <PlusIcon className="h-3 w-3" />
            </button>
          </Tooltip>
        </div>
        <Tooltip text={`Delete suggestion`}>
          <button
            className="p-1 rounded-md hover:bg-gray-100 text-gray-400"
            onClick={handleClickHideSuggestedArtifact}
            disabled={loadingHide}
          >
            <TrashIcon className="h-4 w-4" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default MeetingSummaryGroupTranscriptionSuggestedArtifact;

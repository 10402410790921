import { useQuery } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import { sortBy } from "lodash";
import { useMemo } from "react";
import { PiStarFourFill } from "react-icons/pi";
import {
  GetDashboardInsightsQuery,
  GetDashboardInsightsQueryVariables,
  InsightType,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import getDashboardInsightsQuery from "../graphql/get-dashboard-insights-query";
import Insight from "./insight";

const requestTypes = [InsightType.FeedbackRequest];

const Insights = ({ selectedUser }: { selectedUser: BasicUser }) => {
  const { uiPreferenceCache } = useUiPreferenceCache();
  const { data, loading } = useQuery<
    GetDashboardInsightsQuery,
    GetDashboardInsightsQueryVariables
  >(getDashboardInsightsQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    skip: !uiPreferenceCache.dashboardRequirementsContainerExpanded,
    variables: { personId: selectedUser.id },
    onError: onNotificationErrorHandler(),
  });
  const allInsights = useMemo(() => {
    const userInsights = data?.user?.insights
      ? assertEdgesNonNull(data.user.insights)
      : [];
    return sortBy(userInsights, "type");
  }, [data]);

  const requirementInsights = useMemo(() => {
    return allInsights.filter(
      (insight) =>
        !!insight.complianceProgram?.id && !requestTypes.includes(insight.type)
    );
  }, [allInsights]);
  const insights = useMemo(() => {
    return allInsights.filter(
      (insight) =>
        !insight.complianceProgram && !requestTypes.includes(insight.type)
    );
  }, [allInsights]);
  const requests = useMemo(() => {
    return allInsights.filter((insight) => requestTypes.includes(insight.type));
  }, [allInsights]);

  const loadingNoData = !data && loading;

  const countClassName =
    "px-1.5 py-0.5 bg-gray-100 rounded-md font-semibold text-xs text-gray-900 flex items-center gap-0.5";
  const aiClassName = classNames(
    countClassName,
    "bg-purple-100 text-purple-900"
  );
  return !data && loading ? (
    <div>
      <Layout.MainSection
        expandedUiPreferenceKey="dashboardRequirementsExpanded"
        title="Requirements"
      >
        <Loading />
      </Layout.MainSection>
    </div>
  ) : (
    <>
      {requirementInsights.length > 0 && (
        <Layout.MainSection
          expandedUiPreferenceKey="dashboardRequirementsExpanded"
          title={
            <div className="flex items-center gap-2">
              Requirements
              <span className={countClassName}>
                {requirementInsights.length}
              </span>
            </div>
          }
          loading={loadingNoData}
        >
          <Layout.MainSubSectionList>
            {requirementInsights.map((requirementInsight) => (
              <Layout.MainSubSectionListItem key={requirementInsight.id}>
                <Insight
                  insight={requirementInsight}
                  selectedUser={selectedUser}
                />
              </Layout.MainSubSectionListItem>
            ))}
          </Layout.MainSubSectionList>
        </Layout.MainSection>
      )}
      {insights.length > 0 && (
        <Layout.MainSection
          expandedUiPreferenceKey="dashboardInsightsExpanded"
          title={
            <div className="flex items-center gap-2">
              Insights
              <span className={aiClassName}>
                <PiStarFourFill className="w-3 h-3" />
                {insights.length}
              </span>
            </div>
          }
          loading={loadingNoData}
        >
          <Layout.MainSubSectionList>
            {insights.map((insight) => (
              <Layout.MainSubSectionListItem key={insight.id}>
                <Insight insight={insight} selectedUser={selectedUser} />
              </Layout.MainSubSectionListItem>
            ))}
          </Layout.MainSubSectionList>
        </Layout.MainSection>
      )}
      {requests.length > 0 && (
        <Layout.MainSection
          expandedUiPreferenceKey="dashboardRequestsExpanded"
          title={
            <div className="flex items-center gap-2">
              Requests
              <span className={countClassName}>{requests.length}</span>
            </div>
          }
          loading={loadingNoData}
        >
          <Layout.MainSubSectionList>
            {requests.map((request) => (
              <Layout.MainSubSectionListItem key={request.id}>
                <Insight insight={request} selectedUser={selectedUser} />
              </Layout.MainSubSectionListItem>
            ))}
          </Layout.MainSubSectionList>
        </Layout.MainSection>
      )}
    </>
  );
};

export default withErrorBoundary(Insights, {
  fallback: <Error description={"The insights could not be rendered."} />,
});

import { useQuery } from "@apollo/client";
import { capitalize } from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ArtifactType,
  CommonFeedbackArtifactFragmentFragment,
  FeedbackState,
  GetFeedbackPageQueryQuery,
  GetFeedbackPageQueryQueryVariables,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Avatars from "@components/avatar/avatars";
import Button, { buttonTheme } from "@components/button/button";
import FeedbackStateLabel from "@components/draft-label/feedback-state-label";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import AppPopover from "@components/popover/app-popover";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  assertEdgesNonNull,
  getFeedbackSentToString,
  getUrl,
  isBackgroundLocation,
  toWithBackground,
} from "@helpers/helpers";

import getFeedbackPageQuery from "./graphql/get-feedback-page-query";

enum FeedbackFilterEnum {
  received = "received",
  given = "given",
  requests = "requests",
  requested = "requested",
  draft = "draft",
}

enum ForFilterEnum {
  me = "For me",
  reports = "For my reports",
}

const FeedbackPage = () => {
  const location = useLocation<TFLocationState>();
  const label = useLabel();
  const link = useLink();
  const [tabFilter, setTabFilter] = useState(
    location.pathname === `/feedbacks/given`
      ? FeedbackFilterEnum.given
      : location.pathname === `/feedbacks/received`
      ? FeedbackFilterEnum.received
      : location.pathname === `/feedbacks/requests`
      ? FeedbackFilterEnum.requests
      : location.pathname === `/feedbacks/requested`
      ? FeedbackFilterEnum.requested
      : location.pathname === `/feedbacks/draft`
      ? FeedbackFilterEnum.draft
      : FeedbackFilterEnum.received
  );
  const isShowingPendingRequests = tabFilter === FeedbackFilterEnum.requested;
  const [forFilter, setForFilter] = useState(ForFilterEnum.me);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const currentOrganization = currentOrganizationVar();
  const currentUser = currentUserVar();
  const reportIds = assertEdgesNonNull(currentUser.directReports).map(
    (report) => report.id
  );

  const handleCloseArtifactCreationDialog = useCallback(() => {
    setShowFeedbackDialog(false);
  }, []);

  const { data, loading, fetchMore } = useQuery<
    GetFeedbackPageQueryQuery,
    GetFeedbackPageQueryQueryVariables
  >(getFeedbackPageQuery, {
    variables: {
      fetchPendingRequest: isShowingPendingRequests,
      pendingRequestRecipientId:
        forFilter === ForFilterEnum.me && isShowingPendingRequests
          ? currentUser.id
          : undefined,
      pendingRequestRecipientsOfManagerId:
        forFilter === ForFilterEnum.reports && isShowingPendingRequests
          ? currentUser.id
          : undefined,
      organizationId: currentOrganization.id,
      feedbackSender:
        tabFilter === FeedbackFilterEnum.given ||
        tabFilter === FeedbackFilterEnum.requests ||
        tabFilter === FeedbackFilterEnum.draft
          ? currentUser.id
          : undefined,
      feedbackRecipient:
        (tabFilter === FeedbackFilterEnum.received ||
          tabFilter === FeedbackFilterEnum.requested) &&
        forFilter === ForFilterEnum.me
          ? currentUser.id
          : undefined,
      feedbackRecipients:
        (tabFilter === FeedbackFilterEnum.received ||
          tabFilter === FeedbackFilterEnum.requested) &&
        forFilter === ForFilterEnum.reports
          ? reportIds
          : undefined,
      feedbackState:
        tabFilter === FeedbackFilterEnum.requests ||
        tabFilter === FeedbackFilterEnum.requested
          ? FeedbackState.Requested
          : tabFilter === FeedbackFilterEnum.draft
          ? FeedbackState.Draft
          : FeedbackState.Sent,
      limit: 20,
    },
    onError: onNotificationErrorHandler(),
  });

  const handleClickMore = () => {
    fetchMore({
      variables: {
        merge: true,
        after: data?.artifacts?.pageInfo.endCursor,
        first: 20,
      },
    });
  };

  const handleToggleForFilter = (
    option: ToggleButtonGroupType<ForFilterEnum>
  ) => {
    setForFilter(option?.value || ForFilterEnum.me);
  };

  useEffect(() => {
    if (isBackgroundLocation(location)) {
      link.replace(`/feedbacks/${tabFilter}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabFilter]);

  const artifacts = data?.artifacts ? assertEdgesNonNull(data.artifacts) : [];
  const feedbacks = artifacts.filter(
    (artifact) => artifact.__typename === "FeedbackArtifactNode"
  ) as CommonFeedbackArtifactFragmentFragment[];
  const pendingRequests = data?.pendingFeedbackRequests
    ? assertEdgesNonNull(data.pendingFeedbackRequests)
    : [];

  // Render
  return (
    <div aria-label="Feedback page" className="flex flex-col flex-1">
      <div className="flex flex-wrap items-center justify-between h-14 px-4 sm:px-6 border-b bg-gray-50">
        <AppLink
          className="mb-2 sm:mb-0 text-xl font-medium mr-8 flex items-center"
          to="/feedback"
        >
          {label("feedback", { capitalize: true })}
        </AppLink>

        <div className="flex items-center gap-4">
          <Button
            theme={buttonTheme.primary}
            onClick={() => setShowFeedbackDialog(true)}
            text={`Give ${label("feedback")}`}
          />
        </div>
      </div>
      {showFeedbackDialog && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.Feedback,
          }}
          onClose={handleCloseArtifactCreationDialog}
          hideTypeSelector
          refetchQueries={[getFeedbackPageQuery]}
        />
      )}
      <div className="p-6">
        <div className="bg-white shadow rounded-lg p-6 flex flex-col gap-6">
          <div
            className="flex gap-3 items-center"
            aria-label="Feedback page filters"
          >
            <ToggleButtonGroup<FeedbackFilterEnum>
              theme={ToggleButtonGroupTheme.mergedLightBlueButtons}
              buttons={[
                {
                  active: tabFilter === FeedbackFilterEnum.received,
                  title: capitalize(FeedbackFilterEnum.received),
                  value: FeedbackFilterEnum.received,
                },
                {
                  active: tabFilter === FeedbackFilterEnum.given,
                  title: capitalize(FeedbackFilterEnum.given),
                  value: FeedbackFilterEnum.given,
                },
              ]}
              onClick={(option: ToggleButtonGroupType<FeedbackFilterEnum>) =>
                setTabFilter(option.value || FeedbackFilterEnum.given)
              }
            />
            <ToggleButtonGroup<FeedbackFilterEnum>
              theme={ToggleButtonGroupTheme.mergedLightBlueButtons}
              buttons={[
                {
                  active: tabFilter === FeedbackFilterEnum.requests,
                  title: capitalize(FeedbackFilterEnum.requests),
                  value: FeedbackFilterEnum.requests,
                },
                {
                  active: tabFilter === FeedbackFilterEnum.requested,
                  title: capitalize(FeedbackFilterEnum.requested),
                  value: FeedbackFilterEnum.requested,
                },
              ]}
              onClick={(option: ToggleButtonGroupType<FeedbackFilterEnum>) =>
                setTabFilter(option.value || FeedbackFilterEnum.given)
              }
            />
            <ToggleButtonGroup<FeedbackFilterEnum>
              theme={ToggleButtonGroupTheme.mergedLightBlueButtons}
              buttons={[
                {
                  active: tabFilter === FeedbackFilterEnum.draft,
                  title: capitalize(FeedbackFilterEnum.draft),
                  value: FeedbackFilterEnum.draft,
                },
              ]}
              onClick={(option: ToggleButtonGroupType<FeedbackFilterEnum>) =>
                setTabFilter(option.value || FeedbackFilterEnum.given)
              }
            />
            {data && loading && <Loading mini size={4} />}
          </div>
          {reportIds.length > 0 &&
            (FeedbackFilterEnum.requested === tabFilter ||
              FeedbackFilterEnum.received === tabFilter) && (
              <div aria-label="Feedback request filter" className="flex">
                <ToggleButtonGroup
                  theme={ToggleButtonGroupTheme.roundedCompact}
                  buttons={[
                    {
                      value: ForFilterEnum.me,
                      title: ForFilterEnum.me,
                      active: forFilter === ForFilterEnum.me,
                    },
                    {
                      value: ForFilterEnum.reports,
                      title: ForFilterEnum.reports,
                      active: forFilter === ForFilterEnum.reports,
                    },
                  ]}
                  onClick={handleToggleForFilter}
                />
              </div>
            )}
          <div aria-label="Feedback table">
            <TableContainer>
              <Table>
                {isShowingPendingRequests ? (
                  <TableHeadRow>
                    <TableHeadCell className="min-w-72">Title</TableHeadCell>
                    <TableHeadCell width="36">State</TableHeadCell>
                    <TableHeadCell width="48">Created by</TableHeadCell>
                    <TableHeadCell width="48">Subject</TableHeadCell>
                    <TableHeadCell width="48">Requested from</TableHeadCell>
                    <TableHeadCell width="48">Requested on</TableHeadCell>
                  </TableHeadRow>
                ) : (
                  <TableHeadRow>
                    <TableHeadCell className="min-w-72">Title</TableHeadCell>
                    <TableHeadCell width="36">State</TableHeadCell>
                    <TableHeadCell width="48">Subjects</TableHeadCell>
                    <TableHeadCell width="48">
                      {tabFilter.includes("request")
                        ? "Requested from"
                        : "Sent by"}
                    </TableHeadCell>
                    <TableHeadCell width="48">Visibility</TableHeadCell>
                    <TableHeadCell width="48">Sent on</TableHeadCell>
                  </TableHeadRow>
                )}
                <TableBody>
                  {loading && !data && (
                    <TableBodyRow>
                      <TableBodyCell colSpan={4}>
                        <Loading mini size={5} />
                      </TableBodyCell>
                    </TableBodyRow>
                  )}
                  {!loading &&
                    feedbacks.length === 0 &&
                    pendingRequests.length === 0 && (
                      <TableBodyRow>
                        <TableBodyCell colSpan={4}>
                          <div className="text-sm text-gray-500">
                            No feedback.
                          </div>
                        </TableBodyCell>
                      </TableBodyRow>
                    )}
                  {feedbacks.map((feedback) => (
                    <TableBodyRow key={feedback.id}>
                      <TableBodyCell>
                        <div className="line-clamp-3">
                          <Tooltip text={feedback.title}>
                            <span>
                              <AppLink
                                to={toWithBackground({
                                  pathname: getUrl({
                                    artifactId: feedback.id,
                                    artifactType: feedback.artifactType,
                                  }),
                                  location,
                                })}
                                className="text-blue-link hover:underline"
                              >
                                {feedback.title}
                              </AppLink>
                            </span>
                          </Tooltip>
                        </div>
                      </TableBodyCell>
                      <TableBodyCell>
                        <FeedbackStateLabel
                          feedbackState={feedback.feedbackState}
                        />
                      </TableBodyCell>
                      <TableBodyCell>
                        <AppPopover
                          content={
                            <div className="p-4 flex flex-col gap-2 items-start justify-start text-sm">
                              {assertEdgesNonNull(
                                feedback.feedbackRecipients
                              ).map((recipient) => (
                                <AppLink
                                  key={recipient.id}
                                  to={`/dashboard/user/${recipient.id}`}
                                  className="flex items-center justify-start gap-1.5 hover:underline text-gray-800"
                                >
                                  <Avatar user={recipient} size={5} />
                                  {recipient.name}
                                </AppLink>
                              ))}
                            </div>
                          }
                        >
                          <AppPopover.Button className="active:outline-none hover:underline">
                            <div className="flex items-center gap-1.5">
                              <Avatars
                                users={assertEdgesNonNull(
                                  feedback.feedbackRecipients
                                )}
                                max={5}
                                size={5}
                                modalTitle="Recipients"
                              />
                              <div>
                                {feedback.feedbackRecipients.totalCount > 1
                                  ? `${
                                      feedback.feedbackRecipients.totalCount
                                    } ${pluralize(
                                      "recipient",
                                      feedback.feedbackRecipients.totalCount
                                    )}`
                                  : feedback.feedbackRecipients.edges[0]?.node
                                      ?.name}
                              </div>
                            </div>
                          </AppPopover.Button>
                        </AppPopover>
                      </TableBodyCell>
                      <TableBodyCell>
                        <div className="flex items-center gap-1.5">
                          <AppLink
                            to={`/dashboard/user/${feedback.sender.id}`}
                            className="flex items-center justify-start gap-1.5 hover:underline text-gray-800"
                          >
                            <Avatar user={feedback.sender} size={5} />
                            {feedback.sender.name}
                          </AppLink>
                        </div>
                      </TableBodyCell>
                      <TableBodyCell className="text-sm capitalize">
                        {getFeedbackSentToString(feedback)}
                      </TableBodyCell>
                      <TableBodyCell className="text-sm">
                        {moment(feedback.created).format("LLL")}
                      </TableBodyCell>
                    </TableBodyRow>
                  ))}
                  {pendingRequests.map((request) => (
                    <TableBodyRow key={request.id}>
                      <TableBodyCell>
                        <div className="line-clamp-3">
                          <Tooltip text={request.title}>
                            <span className="text-gray-500">
                              {request.title}
                            </span>
                          </Tooltip>
                        </div>
                      </TableBodyCell>
                      <TableBodyCell>
                        <FeedbackStateLabel
                          feedbackState={request.feedbackState}
                        />
                      </TableBodyCell>
                      <TableBodyCell className="text-sm capitalize">
                        {request.creator?.name || "Unknown"}
                      </TableBodyCell>
                      <TableBodyCell className="text-sm capitalize">
                        {assertEdgesNonNull(request.recipients)
                          .map((recipient) => recipient.name)
                          .join(", ")}
                      </TableBodyCell>
                      <TableBodyCell className="text-sm capitalize">
                        {request.sender?.name || "Unknown"}
                      </TableBodyCell>
                      <TableBodyCell className="text-sm">
                        {moment(request.created).format("LLL")}
                      </TableBodyCell>
                    </TableBodyRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {data?.artifacts?.pageInfo.hasNextPage && (
              <TableFooter className="flex justify-center items-center gap-2">
                <Button
                  theme={buttonTheme.text}
                  text="View more"
                  onClick={handleClickMore}
                  disabled={loading}
                />
                {loading && <Loading size="5" mini />}
              </TableFooter>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackPage;

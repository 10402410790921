import { range } from "lodash";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";

import { getRatingScaleClassName } from "@apps/ratings/helpers";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

type Props = {
  answer?: number | null;
  startValue: number;
  endValue: number;
  disabled?: boolean;
  labels: ReadonlyArray<string | null>;
  labelDescriptions: ReadonlyArray<string | null>;
  onClickAnswer?: (answer: number) => void;
};
const AssessmentQuestionRangeOptions: React.FC<Props> = ({
  answer,
  startValue,
  endValue,
  disabled,
  labels,
  labelDescriptions,
  onClickAnswer,
}) => {
  return (
    <div
      className={classNames(
        `gap-2 grid`,
        getRatingScaleClassName(startValue, endValue)
      )}
    >
      {range(startValue, endValue + 1).map((value, index) => (
        <Tooltip
          key={value}
          text={
            labelDescriptions[index]
              ? `<div>${labelDescriptions[index]
                  ?.split("\n")
                  .join("<br/>")}</div>`
              : undefined
          }
        >
          <button
            contentEditable={false}
            className={classNames(
              "flex flex-col gap-1.5 items-center cursor-default rounded p-3",
              answer !== value && "border border-gray-100 bg-gray-50",
              answer === value && "bg-blue-100 border-blue-200",
              !disabled && "hover:bg-gray-200 cursor-pointer",
              !disabled && answer === value && "hover:bg-blue-100"
            )}
            onClick={(evt) => {
              evt.preventDefault();
              if (onClickAnswer) {
                onClickAnswer(value);
              }
            }}
            disabled={disabled}
            aria-label="Range assessment quesiton option"
          >
            <div
              className={classNames(
                "text-sm font-semibold w-full text-center tracking-tighter leading-tight flex-1",
                answer !== value && "text-gray-500",
                answer === value && "text-gray-700"
              )}
            >
              {labels[index] || value}{" "}
            </div>
            <div className="flex w-full justify-center">
              {answer === value ? (
                <MdRadioButtonChecked className="h-4 w-4 text-blue-600" />
              ) : (
                <MdRadioButtonUnchecked className="h-4 w-4 text-gray-500" />
              )}
            </div>
          </button>
        </Tooltip>
      ))}
    </div>
  );
};

export default AssessmentQuestionRangeOptions;

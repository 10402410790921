import { BasicUser } from "types/topicflow";

import Avatar from "@components/avatar/avatar";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";

const AssessmentQuestionStackedTextAnswers = ({
  answers,
}: {
  answers: { responder?: BasicUser | null; text?: string | null }[];
}) => {
  return (
    <div className="flex flex-col gap-6">
      {answers
        .filter((answer) => answer.text)
        .map((answer, index) => (
          <div key={index} className="flex items-start text-sm">
            <div className="mt-1 w-48 flex items-center mr-4">
              <Avatar className="mr-2" user={answer.responder} size="5" />
              <div className="text-ellipsis overflow-hidden text-nowrap">
                {answer.responder ? answer.responder.name : "Anonymous"}
              </div>
            </div>
            <div className="flex-1">
              <TextareaWysiwyg
                editable={false}
                className="bg-white"
                value={answer.text}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default AssessmentQuestionStackedTextAnswers;

import { useQuery } from "@apollo/client";
import { ExclamationIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ComplianceProgramState,
  GetComplianceProgramQuery,
  GetComplianceProgramQueryVariables,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import ComplianceProgramUserChangesDialog from "@apps/programs/components/compliance-program-user-changes-dialog";
import { errorNotificationVar } from "@cache/cache";
import Button from "@components/button/button";
import AppLabel from "@components/draft-label/app-label";
import DraftLabel from "@components/draft-label/draft-label";
import Layout from "@components/layout/layout";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import Tabs from "@components/tabs/tabs";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import usePkParams from "@helpers/hooks/use-pk-params";

import getComplianceProgramQuery from "../graphql/get-compliance-program-query";
import ComplianceProgramActionDropdown from "./compliance-program-action-dropdown";
import ComplianceProgramActions from "./compliance-program-actions";
import ComplianceProgramDetails from "./compliance-program-details";
import ComplianceProgramProgress from "./compliance-program-progress";

enum ComplianceProgramReportingTab {
  Actions = "My Actions",
  Progress = "Progress",
}

export enum ProgramActionToDoStateFilter {
  All = "All Actions",
  ToDo = "To Do",
}

export const complianceProgramReportingTableColsClassName =
  "w-64 max-w-64 shrink-0";
export const complianceProgramReportingTableColNameClassName = classNames(
  complianceProgramReportingTableColsClassName,
  "truncate"
);

const ComplianceProgram = () => {
  const location = useLocation<TFLocationState>();
  const complianceProgramId = usePkParams("complianceProgramId");
  const url = `/programs/${complianceProgramId}/progress`;
  const [selectedTab, setSelectedTab] = useState<ComplianceProgramReportingTab>(
    location.pathname.endsWith("/actions")
      ? ComplianceProgramReportingTab.Actions
      : ComplianceProgramReportingTab.Progress
  );
  const link = useLink();
  const [
    isShowingProgramUserChangesDialog,
    setIsShowingProgramUserChangesDialog,
  ] = useState(false);

  const { data: complianceProgramData, loading: isLoadingComplianceProgram } =
    useQuery<GetComplianceProgramQuery, GetComplianceProgramQueryVariables>(
      getComplianceProgramQuery,
      {
        fetchPolicy: "network-only",
        variables: { complianceProgramId },
        onError: onNotificationErrorHandler(),
      }
    );

  const complianceProgram = useMemo(
    () => complianceProgramData?.complianceProgram,
    [complianceProgramData]
  );

  useDocumentTitle(complianceProgram?.title || "Program Reporting");

  const handleChangeTab = (option: string) => {
    if (option === ComplianceProgramReportingTab.Actions) {
      link.redirect(`/programs/${complianceProgramId}/actions`);
    } else {
      link.redirect(`/programs/${complianceProgramId}/progress`);
    }
  };

  useEffect(() => {
    if (
      location.pathname.endsWith("progress") &&
      selectedTab === ComplianceProgramReportingTab.Actions
    ) {
      setSelectedTab(ComplianceProgramReportingTab.Progress);
    } else if (
      location.pathname.endsWith("actions") &&
      selectedTab === ComplianceProgramReportingTab.Progress
    ) {
      setSelectedTab(ComplianceProgramReportingTab.Actions);
    }
  }, [selectedTab, location.pathname]);

  useEffect(() => {
    if (
      complianceProgram?.canReadDetails &&
      !complianceProgram?.canReadDetails.permission
    ) {
      errorNotificationVar({
        title: "You do not have permission to view this program.",
        timeout: 10000,
      });
      link.redirect(`/programs`);
    }
  }, [complianceProgram?.canReadDetails]);

  return (
    <Layout>
      <Layout.Header
        breadcrumbs={compact([
          { title: "Programs", url: "/programs" },
          complianceProgram && {
            title: complianceProgram.title,
            url: url,
          },
        ])}
      />
      <Layout.Container>
        {isLoadingComplianceProgram ? (
          <Layout.Main fullWidth>
            <Loading className="mt-12">Loading program...</Loading>
          </Layout.Main>
        ) : complianceProgram && complianceProgram.canReadDetails.permission ? (
          <Layout.Main fullWidth>
            <Layout.MainSection
              title={
                <div className="flex items-center gap-3">
                  <AppLink className="hover:underline" to={url}>
                    {complianceProgram.title}
                  </AppLink>
                  <div>
                    {complianceProgram.state ===
                      ComplianceProgramState.Draft && <DraftLabel />}
                    {complianceProgram.state ===
                      ComplianceProgramState.Paused && (
                      <AppLabel
                        label="Paused"
                        className="text-amber-900 bg-amber-200"
                      />
                    )}
                  </div>
                </div>
              }
              rightSide={
                <div className="flex items-center gap-4">
                  {complianceProgram.canUpdate?.permission && (
                    <Button
                      text="Edit"
                      to={`/programs/${complianceProgram.id}/edit`}
                    />
                  )}
                  <ComplianceProgramActionDropdown
                    complianceProgram={complianceProgram}
                  />
                </div>
              }
            >
              {complianceProgram.availableAppliesToUpdates?.hasUpdates && (
                <div className="flex text-sm items-center justify-between p-4 bg-amber-100 text-yellow-900 rounded-lg">
                  <div className="flex items-start flex-grow space-x-3">
                    <span className="text-amber-800 mt-0.5">
                      <ExclamationIcon className="h-5 w-5" />
                    </span>
                    <div>
                      <p className="text-gray-800 font-semibold">
                        It looks like there’s been some changes to the
                        organization
                      </p>
                      <p className="text-sm">
                        To keep this program up to date, confirm the changes to
                        reflect them in your program.
                      </p>
                    </div>
                  </div>
                  <button
                    className="text-yellow-700 font-medium text-sm hover:underline pr-7"
                    onClick={() => setIsShowingProgramUserChangesDialog(true)}
                  >
                    View Changes
                  </button>
                </div>
              )}
              {isShowingProgramUserChangesDialog && (
                <ComplianceProgramUserChangesDialog
                  onClose={() => setIsShowingProgramUserChangesDialog(false)}
                  complianceProgram={complianceProgram}
                />
              )}
              <Layout.MainSubSection
                title="Program Details"
                expandedUiPreferenceKey="programReportingDetailsExpanded"
              >
                <ComplianceProgramDetails
                  complianceProgram={complianceProgram}
                />
              </Layout.MainSubSection>

              {complianceProgram.state === ComplianceProgramState.Published && (
                <>
                  <Tabs
                    className="mb-4"
                    options={compact([
                      {
                        value: ComplianceProgramReportingTab.Progress,
                        label: ComplianceProgramReportingTab.Progress,
                      },
                      {
                        value: ComplianceProgramReportingTab.Actions,
                        label: ComplianceProgramReportingTab.Actions,
                      },
                    ])}
                    selectedValue={selectedTab}
                    onClickOptionValue={handleChangeTab}
                  />

                  {selectedTab === ComplianceProgramReportingTab.Actions && (
                    <ComplianceProgramActions
                      complianceProgramId={complianceProgram.id}
                    />
                  )}
                  {selectedTab === ComplianceProgramReportingTab.Progress && (
                    <ComplianceProgramProgress
                      complianceProgramId={complianceProgram.id}
                    />
                  )}
                </>
              )}
            </Layout.MainSection>
          </Layout.Main>
        ) : (
          <Layout.Main fullWidth>
            <div className="p-6">Program not found.</div>
          </Layout.Main>
        )}
      </Layout.Container>
    </Layout>
  );
};

export default ComplianceProgram;

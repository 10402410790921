import { gql } from "@apollo/client";

const getUpcoming1on1sQuery = gql`
  fragment Upcoming1on1Meeting on MeetingNode {
    id
    id
    title
    startDatetime
    endDatetime
    meetingGroupId
    draft
    isLocked
    isFinalized
  }
  query getUpcoming1on1s($startDatetime: DateTime!, $participants: [Int!]!) {
    scheduledMeetings: meetings(
      isFormalOneonone: true
      startDatetime_Gte: $startDatetime
      participants: $participants
      orderBy: "start_datetime"
      usingRequiredTemplateOfValidPrograms: false
      isFinalized: false
      first: 8
    ) {
      edges {
        node {
          ...Upcoming1on1Meeting
        }
      }
    }
  }
`;

export default getUpcoming1on1sQuery;

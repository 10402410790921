import { range } from "lodash";
import { MdRadioButtonUnchecked } from "react-icons/md";

import { getRatingScaleClassName } from "@apps/ratings/helpers";
import Avatars from "@components/avatar/avatars";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

import { AssessmentAnswerWithResponderAndId } from "./questions/types";

const AssessmentQuestionRangeOptionsCombined = ({
  answers,
  startValue,
  endValue,
  labels,
  labelDescriptions,
  onClickAnswer,
}: {
  answers: AssessmentAnswerWithResponderAndId[];
  startValue: number;
  endValue: number;
  labels: ReadonlyArray<string | null>;
  labelDescriptions: ReadonlyArray<string | null>;
  onClickAnswer?: (answer: number) => void;
}) => {
  return (
    <div
      className={classNames(
        `gap-2 grid`,
        getRatingScaleClassName(startValue, endValue)
      )}
    >
      {range(startValue, endValue + 1).map((value, index) => {
        const itemAnswers = answers.filter(
          (answer) => answer.integerAnswer === value
        );
        return (
          <Tooltip
            key={value}
            text={
              labelDescriptions[index]
                ? `<div>${labelDescriptions[index]
                    ?.split("\n")
                    .join("<br/>")}</div>`
                : undefined
            }
          >
            <button
              contentEditable={false}
              className={classNames(
                "flex flex-col gap-1.5 items-center cursor-default rounded p-3",
                itemAnswers.length === 0 && "border border-gray-100 bg-gray-50",
                itemAnswers.length > 0 && "bg-blue-100"
              )}
              onClick={(evt) => {
                evt.preventDefault();
                if (onClickAnswer) {
                  onClickAnswer(value);
                }
              }}
            >
              <div
                className={classNames(
                  "text-sm font-semibold w-full text-center tracking-tighter leading-tight flex-1",
                  itemAnswers.length === 0 && "text-gray-500",
                  itemAnswers.length > 0 && "text-gray-700"
                )}
              >
                {labels[index] || value}{" "}
              </div>
              <div>
                {itemAnswers.length > 0 ? (
                  <Avatars
                    showNullAsAnonymous
                    users={itemAnswers.map((answer) => answer.responder)}
                    size={5}
                    max={3}
                    modalTitle="Responders"
                  />
                ) : (
                  <MdRadioButtonUnchecked className="h-4 w-4 text-gray-500" />
                )}
              </div>
            </button>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default AssessmentQuestionRangeOptionsCombined;

import { flatMap, noop } from "lodash";
import { useMemo } from "react";
import { EngagementSurveyDeliveryFragment } from "types/graphql-schema";

import AssessmentDeliveryQuestion from "@apps/assessments/components/delivery/assessment-delivery-question";
import AssessmentLayout from "@apps/assessments/components/layouts/assessment-layout";
import { AssessmentSection } from "@apps/assessments/components/questions/types";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

const EngagementSurveyDeliveryContent = ({
  assessmentDeliveryData,
}: {
  assessmentDeliveryData?: EngagementSurveyDeliveryFragment | null;
}) => {
  const sectionNodes = useMemo(() => {
    if (!assessmentDeliveryData) {
      return [];
    }
    const assessmentTemplate = assertNonNull(assessmentDeliveryData.template);
    const questionSets = assertEdgesNonNull(assessmentTemplate.questionSets);
    return flatMap(questionSets, (set) => {
      const newSections: AssessmentSection[] = assertEdgesNonNull(
        set.sections
      ).map((section) => {
        const questions = assertEdgesNonNull(section.questions);
        return {
          id: section.id,
          title: section.title,
          description: section.description,
          questions,
        };
      });
      return newSections;
    });
  }, [assessmentDeliveryData]);

  const allAnswers = useMemo(() => {
    if (!assessmentDeliveryData) {
      return [];
    }
    const allAnswers = assertEdgesNonNull(assessmentDeliveryData.answers);
    return allAnswers;
  }, [assessmentDeliveryData]);

  return (
    <div>
      {sectionNodes.map((section) => {
        const sectionQuestions = section.questions;
        const hasVisibleQuestions = sectionQuestions.some((question) => {
          const answers = allAnswers.filter(
            (answer) => answer.questionId === question.question.id
          );
          return answers.length > 0;
        });
        if (!hasVisibleQuestions) {
          return null;
        }
        const heading = section.title ? (
          <AssessmentLayout.SectionHeading>
            <AssessmentLayout.SectionHeadingTitle>
              {section.title}
            </AssessmentLayout.SectionHeadingTitle>
            {section.description && !isEmptyValue(section.description) && (
              <AssessmentLayout.SectionHeadingDescription>
                <TextareaWysiwyg
                  editable={false}
                  className="bg-white"
                  value={section.description}
                />
              </AssessmentLayout.SectionHeadingDescription>
            )}
          </AssessmentLayout.SectionHeading>
        ) : null;

        if (sectionQuestions.length === 0) {
          return null;
        }

        return (
          <AssessmentLayout.SectionContainer key={section.id}>
            {heading}
            {sectionQuestions.length !== 0 && (
              <AssessmentLayout.SectionRegularQuestions>
                {sectionQuestions.map((node) => {
                  const { question } = node;
                  const answers = allAnswers.filter(
                    (answer) => answer.questionId === question.id
                  );
                  return (
                    <AssessmentDeliveryQuestion
                      key={question.id}
                      canUpdate={false}
                      excludedAnswerIds={[]}
                      onUpdateExcludedAnswers={noop}
                      answers={answers}
                      question={question}
                    />
                  );
                })}
              </AssessmentLayout.SectionRegularQuestions>
            )}
          </AssessmentLayout.SectionContainer>
        );
      })}
    </div>
  );
};

export default EngagementSurveyDeliveryContent;

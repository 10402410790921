import { useQuery } from "@apollo/client";
import {
  GetMeetingNewPageQueryQuery,
  GetMeetingNewPageQueryQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import getMeetingQuery from "@apps/meeting-new/graphql/get-meeting-query";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import MeetingSummary from "./summary";

const PreviousMeetingSummary = ({
  meeting,
  meetingGroupId,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  meetingGroupId: number;
}) => {
  const previousMeetingId = meeting.previousMeeting?.id;

  const { data, loading } = useQuery<
    GetMeetingNewPageQueryQuery,
    GetMeetingNewPageQueryQueryVariables
  >(getMeetingQuery, {
    variables: { meetingId: previousMeetingId || -1 },
    skip: !previousMeetingId,
    onError: onNotificationErrorHandler(),
  });

  const previousMeeting = data?.meeting;

  if (!previousMeetingId || (!loading && !previousMeeting)) {
    return null;
  }

  if (loading) {
    return <Loading>Loading last meeting recap...</Loading>;
  }

  return previousMeeting ? (
    <MeetingSummary meeting={previousMeeting} meetingGroupId={meetingGroupId} />
  ) : null;
};

export default PreviousMeetingSummary;

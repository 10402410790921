import { ChevronRightIcon, SelectorIcon } from "@heroicons/react/outline";
import { truncate } from "lodash";
import moment from "moment";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import useUiPreferenceCache, {
  MeetingsViewEnum,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import AppLink from "@components/link/link";
import AppPopover from "@components/popover/app-popover";
import {
  formatMeetingTimes,
  getUrl,
  removeCurrentYear,
} from "@helpers/helpers";

import MeetingDraftPopoverPanel from "./draft-popover-panel";
import PreviousMeetingsPopoverPanel from "./previous-meetings-popover-panel";

const MeetingBreadcrumb = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const meetingDate = meeting.startDatetime ? (
    <span>
      {meeting.draft ? "Draft, " : ""}
      <span className="inline">
        {removeCurrentYear(moment(meeting.startDatetime).format("ll"))}
      </span>
      <span className="hidden @7xl/layout-header:inline">
        , {formatMeetingTimes(meeting)}
      </span>
    </span>
  ) : meeting.draft ? (
    "Draft, unscheduled"
  ) : (
    "Unscheduled"
  );

  const { uiPreferenceCache } = useUiPreferenceCache();

  return (
    <div className="flex gap-2 items-center text-sm text-gray-600">
      <AppLink
        className="hover:underline"
        to={
          uiPreferenceCache.meetingsView === MeetingsViewEnum.calendar
            ? "/calendar"
            : "/meetings/group"
        }
      >
        Meetings
      </AppLink>
      <ChevronRightIcon className="h-3 w-3 text-gray-500" />
      <AppLink
        className="hover:underline"
        to={getUrl({
          meetingGroupId: meeting.meetingGroup?.id,
          meetingId: meeting.id,
        })}
      >
        {truncate(meeting.title || meeting.meetingGroup?.title || "", {
          length: 48,
          separator: " ",
        })}
      </AppLink>
      {meeting.isRecurring && (
        <>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <AppPopover
            content={({ open }) => (
              <>
                {meeting.draft ? (
                  <MeetingDraftPopoverPanel meeting={meeting} />
                ) : (
                  <PreviousMeetingsPopoverPanel open={open} meeting={meeting} />
                )}
              </>
            )}
          >
            <AppPopover.Button className="flex items-center gap-1.5 hover:underline">
              <span>{meetingDate}</span>
              <SelectorIcon className="h-4 w-4 text-gray-500" />
            </AppPopover.Button>
          </AppPopover>
        </>
      )}
    </div>
  );
};

export default MeetingBreadcrumb;

import aroundcoLogo from "@static/img/aroundco.png";
import googleMeetLogo from "@static/img/meet-64.png";
import teamsLogo from "@static/img/teams.svg";
import wherebyLogo from "@static/img/whereby.png";
import zoomLogo from "@static/img/zoom-64.png";
import moment from "moment";
import { OnlineVideoConference } from "types/graphql-schema";

import Button from "@components/button/button";
import { buttonTheme } from "@components/button/button";
import { onlineVideoConferenceType } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getAssetUrl } from "@helpers/helpers";

export const MeetingVideoConferenceImage = ({
  meeting,
  className,
}: {
  meeting: {
    videoConferenceType?: OnlineVideoConference | null;
  };
  className?: string;
}) => {
  return meeting.videoConferenceType === onlineVideoConferenceType.google ? (
    <img
      src={getAssetUrl(googleMeetLogo)}
      className={classNames("h-4 w-4 shrunk-0", className)}
      alt="Google Meet"
    />
  ) : meeting.videoConferenceType === onlineVideoConferenceType.zoom ? (
    <img
      src={getAssetUrl(zoomLogo)}
      className={classNames("h-4 w-4 shrunk-0", className)}
      alt="Zoom"
    />
  ) : meeting.videoConferenceType === onlineVideoConferenceType.teams ? (
    <img
      src={getAssetUrl(teamsLogo)}
      className={classNames("h-4 w-4 shrunk-0", className)}
      alt="Microsoft Teams"
    />
  ) : meeting.videoConferenceType === onlineVideoConferenceType.whereby ? (
    <img
      src={getAssetUrl(wherebyLogo)}
      className={classNames("h-4 w-4 shrunk-0", className)}
      alt="Whereby"
    />
  ) : meeting.videoConferenceType === onlineVideoConferenceType.aroundco ? (
    <img
      src={getAssetUrl(aroundcoLogo)}
      className={classNames("h-4 w-4 shrunk-0", className)}
      alt="Around"
    />
  ) : null;
};

export const getMeetingVideoConferenceName = ({
  meeting,
  compact,
}: {
  meeting: {
    videoConferenceType?: OnlineVideoConference | null;
  };
  compact?: boolean;
}) => {
  return meeting.videoConferenceType === onlineVideoConferenceType.google
    ? compact
      ? "Meet"
      : "Google Meet"
    : meeting.videoConferenceType === onlineVideoConferenceType.zoom
    ? "Zoom"
    : meeting.videoConferenceType === onlineVideoConferenceType.teams
    ? compact
      ? "Teams"
      : "Microsoft Teams"
    : meeting.videoConferenceType === onlineVideoConferenceType.whereby
    ? "Whereby"
    : meeting.videoConferenceType === onlineVideoConferenceType.aroundco
    ? "Around"
    : "Video Conference";
};

const MeetingVideoConferenceButton = ({
  meeting,
  className = "",
  textClassName = "",
  iconClassName = "",
  compact = false,
}: {
  meeting: {
    startDatetime?: string | null;
    videoConferenceType?: OnlineVideoConference | null;
    videoConferenceUrl?: string | null;
  };
  className?: string;
  textClassName?: string;
  iconClassName?: string;
  compact?: boolean;
}) => {
  const isCompleted = moment(meeting.startDatetime).isBefore(moment(), "day");
  const label = getMeetingVideoConferenceName({
    meeting,
    compact,
  });

  return meeting.videoConferenceUrl && !isCompleted ? (
    <Button
      tooltip={`Open ${label}`}
      theme={buttonTheme.iconGray}
      className={classNames(className, compact ? "w-[28px] px-0" : "")}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (meeting.videoConferenceUrl) {
          window.open(meeting.videoConferenceUrl, "_blank");
        }
      }}
    >
      <MeetingVideoConferenceImage
        meeting={meeting}
        className={iconClassName}
      />
      <span className={classNames(textClassName)}>{label}</span>
    </Button>
  ) : null;
};

export default MeetingVideoConferenceButton;

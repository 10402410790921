import { CalendarIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import { useCallback, useState } from "react";
import {
  MeetingDialogFragmentFragment,
  MeetingEditTypeEnum,
} from "types/graphql-schema";

import useTrashMeeting from "@apps/meeting-dialog/hooks/use-trash-meeting-button";
import MeetingDialog, {
  meetingDialogAction,
} from "@apps/meeting-dialog/meeting-dialog";
import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Avatars from "@components/avatar/avatars";
import Button, { buttonTheme } from "@components/button/button";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { assertEdgesNonNullWithStringId, getUrl } from "@helpers/helpers";

const UnscheduledMeeting = ({
  meeting,
  onMeetingUpdated,
  onMeetingDeleted,
}: {
  meeting: MeetingDialogFragmentFragment;
  onMeetingUpdated: (data: { meeting: MeetingDialogFragmentFragment }) => void;
  onMeetingDeleted: (meetingId: number) => void;
}) => {
  const [isShowingMeetingDialog, setIsShowingMeetingDialog] = useState(false);
  const currentUser = currentUserVar();
  const participants = assertEdgesNonNullWithStringId(meeting.participants);
  const otherParticipant = participants.find(
    (participant) => participant.user && participant.user.id !== currentUser.id
  );

  const handleCloseModal = useCallback(() => {
    setIsShowingMeetingDialog(false);
  }, []);

  const {
    ConfirmationCancelDialog,
    ConfirmationDeleteDialog,
    isTrashingMeeting,
    handleTrashMeeting,
  } = useTrashMeeting({
    draft: meeting.draft,
    meetingId: meeting.id,
    onCompleted: () => onMeetingDeleted(meeting.id),
  });

  return (
    <>
      <ConfirmationCancelDialog />
      <ConfirmationDeleteDialog />
      {isShowingMeetingDialog && (
        <MeetingDialog
          meetingId={meeting.id}
          meetingGroupId={meeting.meetingGroup?.id}
          onClose={handleCloseModal}
          defaultAction={meetingDialogAction.edit}
          onSaved={onMeetingUpdated}
        />
      )}
      <div className="text-sm border rounded-lg p-2 flex items-center justify-between">
        <div className="flex-1 flex items-center gap-2 min-w-0">
          <CalendarIcon className="h-4 w-4 text-gray-400 hidden @2xl/overview-meetings:block shrink-0" />
          <AppLink
            className="flex-1 hover:underline truncate pr-4"
            key={meeting.id}
            to={getUrl({
              meetingGroupId: meeting.meetingGroupId,
              meetingId: meeting.id,
            })}
            disabled={isTrashingMeeting}
          >
            {meeting.title}
          </AppLink>
        </div>
        <div className="items-center gap-2 hidden @2xl/overview-meetings:flex">
          {meeting.meetingGroup?.isFormalOneonone && otherParticipant ? (
            <Avatar user={otherParticipant.user} size={4} />
          ) : (
            <Avatars
              max={3}
              users={compact(participants.map(({ user }) => user))}
              size={5}
              modalTitle="Participants"
            />
          )}
          <Button
            theme={buttonTheme.iconGray}
            icon={TrashIcon}
            onClick={() => handleTrashMeeting(MeetingEditTypeEnum.SingleEvent)}
            disabled={isTrashingMeeting}
          />
          <Button
            theme={buttonTheme.iconGray}
            icon={PencilIcon}
            onClick={() => setIsShowingMeetingDialog(true)}
            disabled={isTrashingMeeting}
          />
          {isTrashingMeeting && <Loading size="4" mini />}
        </div>
      </div>
    </>
  );
};

export default UnscheduledMeeting;

import { Placement } from "@floating-ui/react";
import { Popover, PopoverPanelProps, Portal } from "@headlessui/react";
import {
  Children,
  ReactNode,
  cloneElement,
  isValidElement,
  useState,
} from "react";
import { usePopper } from "react-popper";

import { classNames } from "@helpers/css";

export const AppPopoverClassName =
  "z-modal min-w-64 max-w-96 bg-white border rounded-lg shadow-lg";

const AppPopover = ({
  children,
  className,
  options,
  content,
  placement = "top-start",
}: {
  children: ReactNode;
  className?: string;
  options?: PopoverPanelProps<"div">;
  content?:
    | ReactNode
    | (({ open, close }: { open: boolean; close: () => void }) => ReactNode);
  placement?: Placement;
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: "offset", options: { offset: [0, 10] } },
      { name: "arrow", options: { element: arrowElement } },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          padding: 16,
        },
      },
    ],
  });

  const extendedChildren = Children.map(children, (child) => {
    // Check if the child is a valid React element before cloning
    if (isValidElement(child)) {
      return cloneElement(child, {
        ref: setReferenceElement,
      } as any);
    }
    return child; // If not a React element, return as is
  });
  return (
    <Popover className={classNames(className)}>
      {({ open, close }) => (
        <>
          {extendedChildren}

          {content && (
            <Portal>
              <Popover.Panel
                {...options}
                className={
                  options?.className ? options?.className : AppPopoverClassName
                }
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                <div ref={setArrowElement} style={styles.arrow} id="arrow" />
                <div className="bg-white rounded-lg">
                  {typeof content === "function"
                    ? content({ open, close })
                    : content}
                </div>
              </Popover.Panel>
            </Portal>
          )}
        </>
      )}
    </Popover>
  );
};

AppPopover.Button = Popover.Button;

AppPopover.className = AppPopoverClassName;

export default AppPopover;

import { useQuery } from "@apollo/client";
import { compact } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  AssessmentType,
  GetAssessmentQuery,
  GetAssessmentQueryVariables,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import AskAI from "@apps/ask-ai/ask-ai";
import RelatedRequirements from "@apps/assessments/components/assessment/related-requirements";
import Feedbacks from "@apps/dashboard-new/components/feedbacks";
import Recognitions from "@apps/dashboard-new/components/recognitions";
import useLabel from "@apps/use-label/use-label";
import WorkHistorySidebar from "@apps/work-history/components/work-history-sidebar";
import { currentOrganizationVar } from "@cache/cache";
import Layout from "@components/layout/layout";
import { ToggleButtonGroupType } from "@components/toggle-button-group/toggle-button-group";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getAssessmentTypeLabel } from "@helpers/constants";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import getAssessmentQuery from "../../graphql/get-assessment-query";
import { AssessmentAnswer } from "../questions/types";
import AssessmentContent from "./assessment-content";
import AssessmentGoalSidebar from "./assessment-goal-sidebar";

export enum AssessmentSidebarTab {
  artifacts = "Items",
  workHistory = "Work history",
  askAI = "Ask AI",
}

const getAnswersFromAssessment = (
  assessment: GetAssessmentQuery["assessment"]
) => {
  const answersEdges = assertEdgesNonNull(assertNonNull(assessment).answers);
  return answersEdges.map((a) => ({
    questionId: a.questionId,
    integerAnswer:
      a.__typename === "RangeAssessmentAnswerNode" ||
      a.__typename === "NpsAssessmentAnswerNode" ||
      a.__typename === "ResponsibilityAssessmentAnswerNode" ||
      a.__typename === "CompetencyAssessmentAnswerNode" ||
      a.__typename === "IndividualGoalAssessmentAnswerNode" ||
      a.__typename === "OverallGoalAssessmentAnswerNode" ||
      a.__typename === "CompetencyCriteriaAssessmentAnswerNode"
        ? a.integerAnswer
        : null,
    textAnswer:
      a.__typename === "TextAssessmentAnswerNode" ? a.textAnswer : null,
    choices:
      a.__typename === "MultiChoiceAssessmentAnswerNode"
        ? [...a.choices]
        : null,
    comment: a.comment,
  }));
};

const Assessment = () => {
  const location = useLocation<TFLocationState>();
  const [chatSessionId, setChatSessionId] = useState<null | number>(null);
  const [activeTab, setActiveTab] = useState(AssessmentSidebarTab.artifacts);
  const [proposedAnswers, setProposedAnswers] = useState<
    AssessmentAnswer[] | null
  >(null);
  const { assessmentId: assessmentIdParam } = useParams<{
    assessmentId: string;
  }>();
  const assessmentId = parseInt(assessmentIdParam);
  const organization = currentOrganizationVar();
  const label = useLabel();

  const { data: myAssessmentsData, loading: isLoadingAssessment } = useQuery<
    GetAssessmentQuery,
    GetAssessmentQueryVariables
  >(getAssessmentQuery, {
    fetchPolicy: "no-cache",
    variables: {
      assessmentId,
      organizationId: organization?.id,
    },
    onCompleted: (response) => {
      setProposedAnswers(getAnswersFromAssessment(response.assessment));
    },
    onError: onNotificationErrorHandler(),
  });

  const assessment = useMemo(
    () => (myAssessmentsData ? myAssessmentsData.assessment : null),
    [myAssessmentsData]
  );

  const complianceProgramPeriod = useMemo(
    () =>
      assessment?.periodStartDate && assessment?.periodEndDate
        ? [assessment.periodStartDate, assessment.periodEndDate]
        : undefined,
    [assessment]
  );

  const isEngagementSurvey = useMemo(
    () =>
      assessment?.template.assessmentType === AssessmentType.EngagementSurvey,
    [assessment]
  );

  const target = useMemo(
    () =>
      !isEngagementSurvey && assessment
        ? assertNonNull(assessment.target)
        : null,
    [assessment, isEngagementSurvey]
  );

  const relatedMeetings = assessment?.relatedMeetings
    ? assertEdgesNonNull(assessment?.relatedMeetings)
    : [];

  const handleChangeTab = (
    tab: ToggleButtonGroupType<AssessmentSidebarTab>
  ) => {
    if (tab.value !== undefined) setActiveTab(tab.value);
  };

  useEffect(() => {
    // reset chat session when changing assessment id
    setChatSessionId(null);
    setProposedAnswers(null);
  }, [assessmentId]);

  const isSelfAssessment = useMemo(
    () => !!assessment && assessment?.target?.id === assessment?.responder?.id,
    [assessment]
  );

  const path = useMemo(() => {
    if (isEngagementSurvey || location.pathname.includes("/survey")) {
      return `/survey`;
    }
    return `/assessments}`;
  }, [location.pathname, isEngagementSurvey]);

  const assessmentLabel = useMemo(() => {
    if (isEngagementSurvey) {
      return "Survey";
    }
    return "review";
  }, [isEngagementSurvey]);

  useDocumentTitle(
    label(assessmentLabel, { capitalize: true, pluralize: true })
  );

  return (
    <Layout className="bg-white">
      <Layout.Header
        breadcrumbs={compact([
          {
            title: label(assessmentLabel, { titleCase: true, pluralize: true }),
            url: path,
          },
          assessment && {
            title: `${getAssessmentTypeLabel(
              assertNonNull(assessment.template.assessmentType),
              isSelfAssessment
            )} ${
              isEngagementSurvey
                ? ""
                : label(assessmentLabel, { titleCase: true })
            }: ${assessment.complianceProgram?.title || "Untitled"}`,
            url: `${path}/assessment/${assessmentId}`,
          },
        ])}
        sidebarExpandedUiPreferenceKey={
          isEngagementSurvey ? undefined : "assessmentSidebarExpanded"
        }
        className="bg-white"
      />
      <Layout.Container loading={isLoadingAssessment}>
        <Layout.Main
          fullWidth
          sidebarExpandedUiPreferenceKey={
            isEngagementSurvey ? undefined : "assessmentSidebarExpanded"
          }
        >
          {assessment && proposedAnswers !== null && (
            <div
              className="mt-8"
              aria-label="Assessments > performance assessment form"
            >
              <AssessmentContent
                myAssessmentsData={myAssessmentsData}
                isLoadingAssessment={isLoadingAssessment}
                proposedAnswers={proposedAnswers}
                onChangeProposedAnswers={setProposedAnswers}
              />
            </div>
          )}
          {!assessment && (
            <div className="mt-8 flex justify-center">
              {label(assessmentLabel, { capitalize: true })} not found
            </div>
          )}
        </Layout.Main>

        {!isEngagementSurvey && (
          <Layout.Sidebar<AssessmentSidebarTab>
            sidebarExpandedUiPreferenceKey={
              isEngagementSurvey ? undefined : "assessmentSidebarExpanded"
            }
            className="bg-white"
            onChangeTab={handleChangeTab}
            tabs={compact([
              {
                title: AssessmentSidebarTab.artifacts,
                value: AssessmentSidebarTab.artifacts,
                active: activeTab === AssessmentSidebarTab.artifacts,
              },
              organization.featureFlags.workHistory && {
                title: AssessmentSidebarTab.workHistory,
                value: AssessmentSidebarTab.workHistory,
                active: activeTab === AssessmentSidebarTab.workHistory,
              },
              {
                title: AssessmentSidebarTab.askAI,
                value: AssessmentSidebarTab.askAI,
                active: activeTab === AssessmentSidebarTab.askAI,
              },
            ])}
            fullHeight={activeTab === AssessmentSidebarTab.askAI}
          >
            {activeTab === AssessmentSidebarTab.askAI ? (
              <AskAI
                chatSessionId={chatSessionId}
                onChangeChatSessionId={setChatSessionId}
              />
            ) : activeTab === AssessmentSidebarTab.workHistory ? (
              <WorkHistorySidebar
                userId={assertNonNull(assessment?.target).id}
                startDate={
                  assessment?.periodStartDate ||
                  moment().subtract(7, "days").format("YYYY-MM-DD")
                }
                endDate={
                  assessment?.periodEndDate || moment().format("YYYY-MM-DD")
                }
              />
            ) : activeTab === AssessmentSidebarTab.artifacts ? (
              <>
                {relatedMeetings.length === 0 &&
                  !organization.featureFlags.goals &&
                  !organization.featureFlags.recognitions &&
                  !organization.featureFlags.feedbacks && (
                    <Layout.SidebarSection>
                      <Layout.SidebarSubSectionEmpty>
                        No related data.
                      </Layout.SidebarSubSectionEmpty>
                    </Layout.SidebarSection>
                  )}
                <RelatedRequirements relatedMeetings={relatedMeetings} />

                {organization.featureFlags.goals && target && (
                  <AssessmentGoalSidebar
                    selectedUser={target}
                    goalDueBetweenDates={complianceProgramPeriod}
                  />
                )}

                {organization.featureFlags.recognitions && target && (
                  <Recognitions
                    selectedUser={target}
                    createdBetweenDates={complianceProgramPeriod}
                  />
                )}
                {organization.featureFlags.feedbacks && target && (
                  <Feedbacks
                    selectedUser={target}
                    createdBetweenDates={complianceProgramPeriod}
                  />
                )}
              </>
            ) : null}
          </Layout.Sidebar>
        )}
      </Layout.Container>
    </Layout>
  );
};

export default Assessment;

import { gql } from "@apollo/client";

export default gql`
  mutation syncComplianceProgramUsersMutation($complianceProgramId: Int!) {
    syncComplianceProgramUsers(complianceProgramId: $complianceProgramId) {
      complianceProgram {
        id
      }
    }
  }
`;

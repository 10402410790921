import { createPortal } from "react-dom";
import { SaveOrgWideCompetencyMutationVariables } from "types/graphql-schema";

import Modal, { ModalWidth } from "@components/modal/modal";

import CompetencyForm from "./competency-form";

const CompetencyFormModal = ({
  organizationId,
  competencyId,
  startingData,
  onClose,
  onCompetencySaved,
}: {
  organizationId: number;
  competencyId?: number;
  startingData?: Partial<
    Omit<SaveOrgWideCompetencyMutationVariables, "criteria">
  >;
  onClose: () => void;
  onCompetencySaved: (competencyId: number) => void;
}) => {
  return createPortal(
    <Modal width={ModalWidth.full} open onClose={onClose} alignedTop>
      <div className="p-6 flex flex-col">
        <CompetencyForm
          organizationId={organizationId}
          competencyId={competencyId}
          startingData={startingData}
          onCancel={onClose}
          onCompetencySaved={(id) => {
            onCompetencySaved(id);
            onClose();
          }}
          isInModal
        />
      </div>
    </Modal>,
    document.body
  );
};

export default CompetencyFormModal;

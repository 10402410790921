import { gql } from "@apollo/client";

export default gql`
  mutation exportPerformanceAssessmentCsv(
    $complianceProgramId: Int!
    $userIds: [Int]
    $reportToIds: [Int]
    $teamIds: [Int]
    $roleIds: [Int]
    $roleLevels: [Int]
    $careerTrackIds: [Int]
    $answerFilters: [PerformanceAssessmentAnswerFilter]
  ) {
    exportComplianceProgramPerformanceAssessmentToCsv(
      complianceProgramId: $complianceProgramId
      userIds: $userIds
      reportToIds: $reportToIds
      teamIds: $teamIds
      roleIds: $roleIds
      roleLevels: $roleLevels
      careerTrackIds: $careerTrackIds
      answerFilters: $answerFilters
    ) {
      complianceProgram {
        id
      }
    }
  }
`;

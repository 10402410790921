import { useQuery } from "@apollo/client";
import { CgArrowsExpandRight } from "react-icons/cg";
import { TbArrowBarRight } from "react-icons/tb";
import {
  GetMeetingNewPageQueryQuery,
  GetMeetingNewPageQueryQueryVariables,
} from "types/graphql-schema";

import MeetingDropdownMenu from "@apps/meeting-dropdown-menu/meeting-dropdown-menu";
import { MeetingPageContent } from "@apps/meeting-new/components/meeting-page";
import { AIAssistMeetingBotPopover } from "@apps/meeting-new/components/meeting-sidebar/ai-assist-bot";
import MeetingVideoConferenceButton from "@apps/meeting-new/components/meeting/video-conference-button";
import getMeetingQuery from "@apps/meeting-new/graphql/get-meeting-query";
import Button, { buttonTheme } from "@components/button/button";
import AppError from "@components/error/error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { getUrl } from "@helpers/helpers";

const MeetingsOverviewSidebar = ({
  meetingId,
  onCloseSidebar,
}: {
  meetingId: number;
  onCloseSidebar: () => void;
}) => {
  const { loading, data } = useQuery<
    GetMeetingNewPageQueryQuery,
    GetMeetingNewPageQueryQueryVariables
  >(getMeetingQuery, {
    variables: { meetingId },
    onError: onNotificationErrorHandler(),
  });
  const sidebarMeeting = data?.meeting;
  const sidebarMeetingGroup = sidebarMeeting?.meetingGroup;

  return (
    <div className="">
      {loading ? (
        <Loading size={5} className="p-6 text-sm">
          Loading meeting...
        </Loading>
      ) : sidebarMeetingGroup && sidebarMeeting ? (
        <div className="p-6">
          <div className="flex items-center justify-between mb-4">
            <Button
              theme={buttonTheme.iconGray}
              icon={TbArrowBarRight}
              onClick={onCloseSidebar}
            />
            <div className="flex items-center gap-4">
              <AIAssistMeetingBotPopover meeting={sidebarMeeting} />
              <MeetingVideoConferenceButton
                meeting={sidebarMeeting}
                textClassName="hidden"
                iconClassName="h-5 w-5"
                compact={true}
              />
              <Button
                theme={buttonTheme.iconGray}
                icon={CgArrowsExpandRight}
                onClick={onCloseSidebar}
                to={getUrl({
                  meetingId: sidebarMeeting.id,
                  meetingGroupId: sidebarMeetingGroup.id,
                })}
              />
              <MeetingDropdownMenu
                meeting={sidebarMeeting}
                meetingGroup={sidebarMeetingGroup}
                size={5}
              />
            </div>
          </div>
          <MeetingPageContent
            meetingGroupId={sidebarMeetingGroup.id}
            meeting={sidebarMeeting}
            isInSidebar={true}
          />
        </div>
      ) : (
        <AppError title="Meeting not found" />
      )}
    </div>
  );
};

export default MeetingsOverviewSidebar;

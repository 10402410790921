import { gql } from "@apollo/client";

import CommonGoalArtifactFragment from "@graphql/common-goal-artifact-fragment";

export default gql`
  ${CommonGoalArtifactFragment}
  fragment AssessmentDeliveryAssessmentGroupSection on AssessmentSectionInterface {
    id
    title
    description
    questions {
      edges {
        node {
          id
          question {
            ... on RangeAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
            }
            ... on IndividualGoalAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
              goal {
                ...CommonGoalArtifactFragment
              }
            }
            ... on OverallGoalAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
            }
            ... on CompetencyAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
              role {
                id
                title
                level
              }
              competency {
                id
                criteria {
                  edges {
                    node {
                      id
                      content
                    }
                  }
                }
              }
            }
            ... on CompetencyCriteriaAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
              role {
                id
                title
                level
              }
              competencyCriteria {
                id
                content
                competency {
                  id
                  title
                  description
                }
              }
            }
            ... on ResponsibilityAssessmentQuestionNode {
              id
              title
              description
              startValue
              endValue
              labels
              labelDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
              responsibility {
                id
                content
                role {
                  id
                  title
                  level
                }
              }
            }
            ... on MultiChoiceAssessmentQuestionNode {
              id
              title
              description
              options
              optionDescriptions
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
            }
            ... on TextAssessmentQuestionNode {
              id
              title
              description
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
            }
            ... on NpsAssessmentQuestionNode {
              id
              title
              description
              isCommentMandatory
              isResponseMandatory
              responses
              questionType
              responseVisibility
            }
          }
        }
      }
    }
  }

  fragment AssessmentDelivery on AssessmentDeliveryNode {
    id
    state
    summary
    calibrationAssessmentId
    excludedAnswerIds
    deliveryDatetime
    dueDate
    periodStartDate
    periodEndDate
    targetCurrentRoleIds
    canUpdate {
      permission
      reason
    }
    creator {
      id
      name
    }
    target {
      id
      name
      email
      firstName
      avatar
      organizationSpecificInfo(organizationId: $organizationId) {
        position
        jobLevel
      }
      managers {
        edges {
          node {
            id
            name
            email
            firstName
            avatar
          }
        }
      }
    }
    relatedMeetings {
      edges {
        node {
          id
          title
          meetingGroupId
          startDatetime
          endDatetime
          draft
          isFinalized
          canUpdate {
            permission
          }
        }
      }
    }
    complianceProgram {
      id
      title
      periodStartDate
      periodEndDate
      canReadDetails {
        permission
        reason
      }
      performanceAssessmentTemplate {
        id
        delivery
      }
      managerAssessmentTemplate {
        id
        delivery
      }
      peerAssessmentTemplate {
        id
        delivery
      }
      requiredTopicTemplates(first: 1) {
        edges {
          node {
            id
          }
        }
      }
    }
    template {
      id
      assessmentType
      anonymity
      delivery
      hasSelfAssessment
      questionSets {
        edges {
          node {
            id
            title
            sections {
              edges {
                node {
                  ...AssessmentDeliveryAssessmentGroupSection
                }
              }
            }
          }
        }
      }
    }
    additionalQuestionSets {
      edges {
        node {
          id
          title
          sections {
            edges {
              node {
                ...AssessmentDeliveryAssessmentGroupSection
              }
            }
          }
        }
      }
    }
    answers {
      edges {
        node {
          ... on RangeAssessmentAnswerNode {
            id
            responder {
              id
              name
              email
              firstName
              avatar
            }
            questionId
            integerAnswer
            comment
          }
          ... on IndividualGoalAssessmentAnswerNode {
            id
            responder {
              id
              name
              email
              firstName
              avatar
            }
            questionId
            integerAnswer
            comment
          }
          ... on OverallGoalAssessmentAnswerNode {
            id
            responder {
              id
              name
              email
              firstName
              avatar
            }
            questionId
            integerAnswer
            comment
          }
          ... on ResponsibilityAssessmentAnswerNode {
            id
            responder {
              id
              name
              email
              firstName
              avatar
            }
            questionId
            integerAnswer
            comment
          }
          ... on CompetencyAssessmentAnswerNode {
            id
            responder {
              id
              name
              email
              firstName
              avatar
            }
            questionId
            integerAnswer
            comment
          }
          ... on CompetencyCriteriaAssessmentAnswerNode {
            id
            responder {
              id
              name
              email
              firstName
              avatar
            }
            questionId
            integerAnswer
            comment
          }
          ... on MultiChoiceAssessmentAnswerNode {
            id
            responder {
              id
              name
              email
              firstName
              avatar
            }
            questionId
            choices
            comment
          }
          ... on TextAssessmentAnswerNode {
            id
            responder {
              id
              name
              email
              firstName
              avatar
            }
            questionId
            textAnswer
            comment
          }
          ... on NpsAssessmentAnswerNode {
            id
            responder {
              id
              name
              email
              firstName
              avatar
            }
            questionId
            integerAnswer
            comment
          }
        }
      }
    }
  }

  query getAssessmentDelivery(
    $assessmentDeliveryId: IntOrString!
    $organizationId: Int!
  ) {
    assessmentDelivery(id: $assessmentDeliveryId) {
      ...AssessmentDelivery
      undeliveredPeerAssessmentDeliveryId
      relatedAssessmentDeliveries(state: delivered) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

import { AssessmentQuestionResponseVisibility } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";

import AssessmentQuestionHeading from "../questions/assessment-question-heading";
import { getQuestionClass } from "../questions/question";
import {
  AssessmentAnswerWithResponderAndId,
  AssessmentQuestion,
} from "../questions/types";
import AssessmentDeliveryResponderCheckbox from "./assessment-delivery-responder-checkbox";

const AssessmentDeliveryQuestion = ({
  canUpdate,
  excludedAnswerIds,
  answers,
  question,
  target,
  onUpdateExcludedAnswers,
}: {
  canUpdate: boolean;
  excludedAnswerIds: number[];
  answers: AssessmentAnswerWithResponderAndId[];
  question: AssessmentQuestion;
  target?: BasicUser | null;
  onUpdateExcludedAnswers: (excludedAnswerIds: number[]) => void;
}) => {
  const label = useLabel();
  const QuestionClass = getQuestionClass(question, { label });
  if (!canUpdate && answers.length === 0) {
    return null;
  }

  const hiddenFromSubject =
    question.responseVisibility ===
    AssessmentQuestionResponseVisibility.HiddenFromSubject;

  return (
    <div
      key={question.id}
      className="p-6"
      aria-label={`Assessment question: ${question.title}`}
    >
      <AssessmentQuestionHeading question={question} />

      <div className="flex flex-col gap-4 mt-4">
        {canUpdate ? (
          answers?.map((answer) => {
            const isExcluded = excludedAnswerIds.includes(answer.id);

            const responder = (
              <AssessmentDeliveryResponderCheckbox
                hiddenFromSubject={hiddenFromSubject}
                isExcluded={isExcluded}
                canUpdate={canUpdate}
                answer={answer}
                target={target}
                excludedAnswerIds={excludedAnswerIds}
                onUpdateExcludedAnswers={onUpdateExcludedAnswers}
              />
            );

            if (isExcluded) {
              return <div key={answer.id}>{responder}</div>;
            }

            return (
              <div key={answer.id}>
                {responder}
                {QuestionClass.getDelivery({ answer })}
              </div>
            );
          })
        ) : (
          <div>{QuestionClass.getDeliveryReadonly({ answers })}</div>
        )}
      </div>
    </div>
  );
};

export default AssessmentDeliveryQuestion;

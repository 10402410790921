import { Popover } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/outline";
import { useState } from "react";

import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";

const GoalParticipantsUserPicker = ({
  goal,
  onSelectUser,
  canChange = true,
  buttonText = "Add participant",
  excludeIds = [],
}: {
  goal: {
    id: number;
  };
  onSelectUser: (user: null | UserComboboxOption) => void;
  canChange: boolean;
  buttonText?: string;
  excludeIds?: number[];
}) => {
  const [canLoadData, setCanLoadData] = useState(false);
  const { loading, options, query, setQuery } = useUserComboboxQuery({
    queryOptions: {
      skip: !canLoadData,
    },
    excludeUserIds: excludeIds,
  });

  const handleChangeAssignee = (option: UserComboboxOption) => {
    onSelectUser(option);
  };

  const handleClearAssignee = () => {
    onSelectUser(null);
  };

  const handlePreloadData = () => {
    setCanLoadData(true);
  };

  const selectedClassName = classNames(
    "text-base flex items-center gap-1",
    "text-gray-400 hover:text-gray-600",
    "font-semibold text-gray-800"
  );

  const filteredOptions = options.filter((option) => {
    return !excludeIds.includes(option.id);
  });

  return canChange ? (
    <UserCombobox
      aria-label="Goal participants people dropdown"
      loading={loading}
      width="full"
      query={query}
      value={null}
      options={filteredOptions}
      onChangeValue={handleChangeAssignee}
      onChangeQuery={setQuery}
      onClickButton={handlePreloadData}
      onClearValue={handleClearAssignee}
    >
      {({ setReferenceElement, onClickButton }) => (
        <div className={"flex justify-between bg-white rounded-md"}>
          <Popover.Button
            className="flex items-center text-sm gap-2 text-gray-500 hover:text-gray-800 hover:underline"
            onClick={onClickButton}
            ref={setReferenceElement}
          >
            <PlusIcon className="h-5 w-5 text-gray-700 p-0.5 border-2 border-gray-400 rounded-full shrink-0" />
            {buttonText}
          </Popover.Button>
        </div>
      )}
    </UserCombobox>
  ) : (
    <span className={classNames(selectedClassName, "hover:bg-white")}>
      Add an owner
    </span>
  );
};

export default GoalParticipantsUserPicker;

import { compact } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { currentUserVar, isAdminVar } from "@cache/cache";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertNonNull,
  getUrlParam,
} from "@helpers/helpers";

import { bgClassName } from "../helpers";
import MyAssessmentsNominations from "./my-assessments-tabs/nominations";
import MyAssessmentsReceived from "./my-assessments-tabs/received";
import MyAssessmentsSubmitted from "./my-assessments-tabs/submitted";
import MyAssessmentsToAnswer from "./my-assessments-tabs/to-answer";
import MyAssessmentsToDeliver from "./my-assessments-tabs/to-deliver";

enum MyAssessmentTab {
  toAnswer = "toAnswer",
  toDeliver = "toDeliver",
  received = "received",
  submitted = "submitted",
  nominations = "nominations",
}

const MyAssessments = () => {
  const currentUser = assertNonNull(currentUserVar());
  const reports = assertEdgesNonNull(currentUser.directReports);
  const hasReports = reports.length > 0;
  const isAdmin = isAdminVar();

  const location = useLocation();
  const history = useHistory();
  const userIdFromUrl = getUrlParam(location, "userId");
  const tabFromUrl = getUrlParam(location, "tab");
  const [assessmentUserFilter, setAssessmentUserFilter] = useState<
    number | null
  >(userIdFromUrl ? parseInt(userIdFromUrl) : null);
  const [tab, setTab] = useState(tabFromUrl ?? MyAssessmentTab.toAnswer);

  const {
    options: assessmentUserFilterOptions,
    query,
    setQuery,
  } = useUserComboboxQuery({});

  const handleChangeAssessmentUserFilter = useCallback(
    (option?: UserComboboxOption) => {
      setAssessmentUserFilter(option ? option.id : null);

      const params = new URLSearchParams(location.search);
      if (option) {
        params.set("userId", `${option.id}`);
      } else {
        params.delete("userId");
      }
      const url = `${location.pathname}?${params}`;
      history.push(url);
    },
    [location, history]
  );

  const handleChangeTab = useCallback(
    (tab: MyAssessmentTab) => {
      setTab(tab);
      const params = new URLSearchParams(location.search);
      params.set("tab", tab);
      const url = `${location.pathname}?${params}`;
      history.push(url);
    },
    [location, history]
  );

  const tabOptions = useMemo(
    () =>
      compact([
        {
          active: tab === MyAssessmentTab.nominations,
          title: "Nominations",
          value: MyAssessmentTab.nominations,
        },
        {
          active: tab === MyAssessmentTab.toAnswer,
          title: "To answer",
          value: MyAssessmentTab.toAnswer,
        },
        hasReports && {
          active: tab === MyAssessmentTab.toDeliver,
          title: "To deliver",
          value: MyAssessmentTab.toDeliver,
        },
        {
          active: tab === MyAssessmentTab.submitted,
          title: "Submitted",
          value: MyAssessmentTab.submitted,
        },
        {
          active: tab === MyAssessmentTab.received,
          title: "Completed",
          value: MyAssessmentTab.received,
        },
      ]),
    [hasReports, tab]
  );

  const userComboboxValue =
    assessmentUserFilterOptions.find(({ id }) => id === assessmentUserFilter) ||
    null;

  return (
    <div
      className={classNames(bgClassName, "p-6 w-full")}
      aria-label="My assessments"
    >
      <div className="mb-4 flex items-center gap-4">
        <ToggleButtonGroup<MyAssessmentTab>
          theme={ToggleButtonGroupTheme.mergedLightBlueButtons}
          buttons={tabOptions}
          onClick={(option: ToggleButtonGroupType<MyAssessmentTab>) =>
            handleChangeTab(option.value ?? MyAssessmentTab.toAnswer)
          }
        />
        {(hasReports || isAdmin) && (
          <div className="w-64">
            <UserCombobox
              onChangeValue={handleChangeAssessmentUserFilter}
              options={assessmentUserFilterOptions}
              value={userComboboxValue}
              placeholder="Filter by subject"
              clearable={assessmentUserFilter !== null}
              query={query}
              onChangeQuery={setQuery}
              onClearValue={handleChangeAssessmentUserFilter}
            />
          </div>
        )}
      </div>

      {tab === MyAssessmentTab.toAnswer ? (
        <MyAssessmentsToAnswer userFilter={assessmentUserFilter} />
      ) : tab === MyAssessmentTab.toDeliver ? (
        <MyAssessmentsToDeliver userFilter={assessmentUserFilter} />
      ) : tab === MyAssessmentTab.received ? (
        <MyAssessmentsReceived userFilter={assessmentUserFilter} />
      ) : tab === MyAssessmentTab.nominations ? (
        <MyAssessmentsNominations userFilter={assessmentUserFilter} />
      ) : (
        <MyAssessmentsSubmitted userFilter={assessmentUserFilter} />
      )}
    </div>
  );
};

export default MyAssessments;

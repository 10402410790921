import { gql } from "@apollo/client";

import { AssessmentQuestionFragment } from "./get-questions-query";

export const AssessmentGroupFragment = gql`
  ${AssessmentQuestionFragment}
  fragment AssessmentGroupFragment on AssessmentGroupNode {
    id
    title
    created
    archived
    isOnlySelfAssessment
    hasNpsQuestions
    creator {
      id
      name
    }
    canUpdate {
      permission
      reason
    }
    canDelete {
      permission
      reason
    }
    sections {
      edges {
        node {
          id
          title
          description
          questions {
            edges {
              node {
                id
                question {
                  ...AssessmentQuestionFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default gql`
  ${AssessmentGroupFragment}
  query getAssessmentGroups($organizationId: Int!, $archived: Boolean) {
    assessmentGroups(organizationId: $organizationId, archived: $archived) {
      edges {
        node {
          ...AssessmentGroupFragment
        }
      }
    }
  }
`;

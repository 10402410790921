import { CalendarIcon, InformationCircleIcon } from "@heroicons/react/outline";
import { compact, sortBy } from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import { MdGridView } from "react-icons/md";
import {
  AssessmentDeliveryState,
  AssessmentType,
  AttendeeRole,
  AttendeeStatus,
  GetAssessmentDeliveryQuery,
} from "types/graphql-schema";

import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import SelectOrCreate1on1Button from "@apps/meeting-dialog/select-or-create-1-on-1-button";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import CloseButton from "@components/close-button/close-button";
import AppLabel from "@components/draft-label/app-label";
import DraftLabel from "@components/draft-label/draft-label";
import Layout from "@components/layout/layout";
import AppLink from "@components/link/link";
import ToggleSwitch from "@components/toggle-switch/toggle-switch";
import InfoTooltip from "@components/tooltip/info-tooltip";
import { getAssessmentTypeLabel } from "@helpers/constants";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertNonNull,
  formatMeetingTimes,
  getUrl,
  removeCurrentYear,
} from "@helpers/helpers";

import RelatedSubmissions from "../assessment/related-submissions";

const AssessmentDeliveryHeader = ({
  assessmentDeliveryData,
  isDelivered,
  onToggleShowHiddenQuestions,
  isShowingHiddenQuestions,
  hiddenQuestionCount,
  onClose,
}: {
  assessmentDeliveryData?: GetAssessmentDeliveryQuery;
  isDelivered?: boolean;
  onToggleShowHiddenQuestions: (isShowingHiddenQuestions: boolean) => void;
  isShowingHiddenQuestions: boolean;
  hiddenQuestionCount: number;
  onClose?: () => void;
}) => {
  const currentUser = currentUserVar();
  const label = useLabel();
  const [isOpenMeetingDialog, setIsOpenMeetingDialog] = useState(false);

  const assessmentDelivery = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery
        : null,
    [assessmentDeliveryData]
  );
  const program = useMemo(
    () => (assessmentDelivery ? assessmentDelivery.complianceProgram : null),
    [assessmentDelivery]
  );
  const canReadProgramDetails = useMemo(
    () => program?.canReadDetails.permission,
    [program]
  );
  const template = useMemo(
    () => (assessmentDelivery ? assessmentDelivery.template : null),
    [assessmentDelivery]
  );

  const target = useMemo(
    () =>
      assessmentDelivery &&
      assessmentDelivery.template.assessmentType !==
        AssessmentType.EngagementSurvey
        ? assertNonNull(assessmentDelivery.target)
        : null,
    [assessmentDelivery]
  );

  const relatedMeetings = useMemo(() => {
    return sortBy(
      assessmentDelivery?.relatedMeetings
        ? assertEdgesNonNull(assessmentDelivery.relatedMeetings)
        : [],
      "draft"
    );
  }, [assessmentDelivery]);
  const firstRelatedMeeting = useMemo(() => {
    return relatedMeetings[0];
  }, [relatedMeetings]);
  const firstRelatedMeetingTitle =
    firstRelatedMeeting &&
    firstRelatedMeeting.startDatetime &&
    firstRelatedMeeting.endDatetime
      ? `${removeCurrentYear(
          moment(firstRelatedMeeting.startDatetime).format("MMMM D, YYYY")
        )}, ${formatMeetingTimes(firstRelatedMeeting)}`
      : firstRelatedMeeting
      ? firstRelatedMeeting.title
      : `Draft Meeting`;

  if (!assessmentDeliveryData) {
    return null;
  }

  const leftCellClassName = "py-2 font-medium w-[200px]";
  const rightCellClassName = "py-2";
  const isDraft =
    assessmentDelivery?.state !== AssessmentDeliveryState.Delivered;

  const handleToggleShowHiddenQuestions = () => {
    onToggleShowHiddenQuestions(!isShowingHiddenQuestions);
  };

  const requiredTopicTemplates = assessmentDelivery?.complianceProgram
    ?.requiredTopicTemplates
    ? assertEdgesNonNull(
        assessmentDelivery.complianceProgram.requiredTopicTemplates
      )
    : [];
  const requiredTopicTemplate = requiredTopicTemplates[0];

  const managers = assessmentDelivery?.target?.managers
    ? assertEdgesNonNull(assessmentDelivery.target.managers)
    : [];
  const targetManager = managers[0];
  const currentUserIsManager = managers.some(
    (manager) => currentUser?.id === manager?.id
  );
  const canEditMeeting =
    currentUser?.id === assessmentDelivery?.target?.id || currentUserIsManager;

  return (
    <div className="text-sm p-6 mb-12 border-b">
      <div className="flex flex-col gap-6 w-full mx-auto max-w-screen-lg">
        <div className="flex items-center gap-2 justify-between">
          <div className="flex items-center gap-2">
            <Avatar user={target} size={8} />
            <AppLink
              to={`/assessments/assessment/delivery/${assessmentDelivery?.id}`}
              className="text-2xl font-bold text-gray-800 hover:underline"
            >{`${target?.name}: ${label("review", {
              capitalize: true,
            })}`}</AppLink>
          </div>
          {onClose && <CloseButton onClick={onClose} />}
        </div>

        {hiddenQuestionCount > 0 && (
          <div
            className="flex items-center justify-between w-full gap-4 bg-blue-50 rounded-md p-4"
            aria-label="Show subject perspective"
          >
            <div className="text-sm flex items-center gap-2">
              <span>
                Show {target?.firstName || target?.name}'s perspective of this{" "}
                {label("review")}
              </span>
              <InfoTooltip
                text={`Use this setting to hide/show responses to questions that are not visible to the subject in this ${label(
                  "review"
                )}.`}
              />
            </div>
            <div>
              <ToggleSwitch
                checked={!isShowingHiddenQuestions}
                onChange={handleToggleShowHiddenQuestions}
              />
            </div>
          </div>
        )}

        <div>
          <Layout.MainSubSection
            title={`${label("review", { capitalize: true })} Details`}
            collapsible
            defaultIsExpanded
          >
            <table className="text-gray-600 w-full">
              <tbody>
                <tr>
                  <td className={leftCellClassName}>Program</td>
                  {canReadProgramDetails ? (
                    <td className={rightCellClassName}>
                      <Button
                        to={`/programs/${program?.id}`}
                        theme={buttonTheme.text}
                        leftNegativeMargin
                      >
                        <MdGridView className="shrink-0 w-4 h-4" />
                        {program?.title}
                      </Button>
                    </td>
                  ) : (
                    <td
                      className={classNames(
                        rightCellClassName,
                        "flex items-center gap-1"
                      )}
                    >
                      <MdGridView className="shrink-0 w-4 h-4 text-gray-400" />
                      {program?.title}
                    </td>
                  )}
                </tr>
                {!isDelivered && (
                  <tr>
                    <td className={leftCellClassName}>{`${label("review", {
                      capitalize: true,
                    })} type`}</td>
                    <td className={rightCellClassName}>
                      {getAssessmentTypeLabel(
                        assertNonNull(template?.assessmentType)
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={leftCellClassName}>Subject</td>
                  <td className={rightCellClassName}>
                    <div className="flex items-center">
                      <Avatar className="mr-1" user={target} size="5" />{" "}
                      {target?.name}
                    </div>
                  </td>
                </tr>
                {target?.organizationSpecificInfo?.position && (
                  <tr>
                    <td className={leftCellClassName}>Position</td>
                    <td className={rightCellClassName}>
                      {target?.organizationSpecificInfo?.position}
                    </td>
                  </tr>
                )}
                {target?.organizationSpecificInfo?.jobLevel && (
                  <tr>
                    <td className={leftCellClassName}>Job level</td>
                    <td className={rightCellClassName}>
                      {target?.organizationSpecificInfo?.jobLevel}
                    </td>
                  </tr>
                )}

                <tr>
                  <td className={leftCellClassName}>Program period</td>
                  <td className={rightCellClassName}>
                    {program?.periodStartDate && program?.periodEndDate
                      ? `${moment(program.periodStartDate).format(
                          "MMM D, YYYY"
                        )} - ${moment(program.periodEndDate).format(
                          "MMM D, YYYY"
                        )}`
                      : "Not set"}
                  </td>
                </tr>
                <tr>
                  <td className={leftCellClassName}>Due date</td>
                  <td className={rightCellClassName}>
                    {moment(assessmentDelivery?.dueDate).format("MMM D, YYYY")}
                  </td>
                </tr>

                {!isDraft && (
                  <tr>
                    <td className={leftCellClassName}>Delivery</td>
                    <td className={rightCellClassName}>
                      <div>
                        {assessmentDelivery.deliveryDatetime
                          ? `Delivered by ${
                              assessmentDelivery.creator?.name
                            } on ${moment(
                              assessmentDelivery.deliveryDatetime
                            ).format("MMM D, YYYY @ h:mma")}`
                          : "Immediate access"}
                      </div>
                    </td>
                  </tr>
                )}

                {requiredTopicTemplate && (
                  <tr>
                    <td className={leftCellClassName}>
                      {label("review", { capitalize: true })} Meeting
                    </td>
                    <td className={rightCellClassName}>
                      {firstRelatedMeeting &&
                      firstRelatedMeeting.startDatetime &&
                      !firstRelatedMeeting.draft ? (
                        <Button
                          theme={buttonTheme.text}
                          leftNegativeMargin
                          to={getUrl({
                            meetingId: firstRelatedMeeting.id,
                            meetingGroupId: firstRelatedMeeting.meetingGroupId,
                          })}
                        >
                          <CalendarIcon className="w-4 h-4 text-gray-400 shrink-0" />
                          {firstRelatedMeetingTitle}
                          {firstRelatedMeeting.isFinalized ? (
                            <AppLabel
                              className="ml-1 bg-emerald-100 text-emerald-600"
                              label="Finalized"
                            />
                          ) : (
                            <AppLabel className="ml-1" label="Not Finalized" />
                          )}
                        </Button>
                      ) : firstRelatedMeeting?.draft ? (
                        <div className="flex items-center gap-2">
                          <Button
                            theme={buttonTheme.text}
                            leftNegativeMargin
                            to={getUrl({
                              meetingId: firstRelatedMeeting.id,
                              meetingGroupId:
                                firstRelatedMeeting.meetingGroupId,
                            })}
                          >
                            <CalendarIcon className="w-4 h-4 text-gray-400 shrink-0" />
                            {firstRelatedMeetingTitle}
                          </Button>
                          <button
                            className="flex hover:opacity-90"
                            onClick={() => setIsOpenMeetingDialog(true)}
                          >
                            <DraftLabel />
                          </button>
                        </div>
                      ) : canEditMeeting ? (
                        <>
                          <Button
                            theme={buttonTheme.text}
                            leftNegativeMargin
                            onClick={() => setIsOpenMeetingDialog(true)}
                          >
                            <CalendarIcon className="w-4 h-4 text-gray-400 shrink-0" />
                            Unscheduled
                          </Button>
                        </>
                      ) : (
                        <div className="flex items-center gap-1">
                          <CalendarIcon className="w-4 h-4 text-gray-400 shrink-0" />
                          Unscheduled
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Layout.MainSubSection>

          {isOpenMeetingDialog && (
            <MeetingDialog
              onClose={() => setIsOpenMeetingDialog(false)}
              refetchQueries={["getAssessmentDelivery"]}
              meetingGroupId={
                firstRelatedMeeting
                  ? firstRelatedMeeting.meetingGroupId
                  : undefined
              }
              meetingId={
                firstRelatedMeeting ? firstRelatedMeeting.id : undefined
              }
              formOptions={
                firstRelatedMeeting
                  ? {}
                  : {
                      title: `${label("review", {
                        capitalize: true,
                      })} Meeting`,
                      templateId: requiredTopicTemplate?.id,
                      isFormalOneonone: true,
                      facilitatorId: targetManager?.id,
                      attendees: compact([
                        assessmentDelivery?.target && {
                          ...assessmentDelivery.target,
                          role: AttendeeRole.Required,
                          participantStatus: AttendeeStatus.NotResponded,
                        },
                        targetManager && {
                          ...targetManager,
                          role: AttendeeRole.Required,
                          participantStatus: AttendeeStatus.NotResponded,
                        },
                      ]),
                    }
              }
            />
          )}

          {waffle.flag_is_active("review-submissions") &&
            assessmentDelivery &&
            target && (
              <RelatedSubmissions
                target={target}
                assessmentDeliveryId={assessmentDelivery.id}
                showButtonToScroll={isDelivered} // is delivered means that we will show combined deliveries
              />
            )}

          {canEditMeeting &&
            requiredTopicTemplate &&
            !firstRelatedMeeting?.id &&
            targetManager &&
            target && (
              <div className="mt-4 flex items-center gap-6 justify-between pl-4 pr-6 py-4 bg-blue-50 rounded-lg">
                <div className="flex items-start gap-2">
                  <InformationCircleIcon className="w-5 h-5 text-blue-400 shrink-0" />
                  <div>
                    <div className="text-sm font-medium text-gray-800">
                      Schedule a meeting for {target?.name}'s{" "}
                      {label("review", { capitalize: true })}!
                    </div>
                    <div className="mt-1 text-sm text-gray-800">
                      Prepare to go over {target?.name}'s {label("review")} by
                      creating a new {label("1-on-1")} meeting.
                    </div>
                  </div>
                </div>
                <div>
                  <SelectOrCreate1on1Button
                    buttonProps={{
                      className: "shrink-0 whitespace-nowrap",
                      theme: buttonTheme.lightBlue,
                      text: `Schedule ${label("review", {
                        titleCase: true,
                      })} Meeting`,
                      onClick: () => setIsOpenMeetingDialog(true),
                    }}
                    matchingOneonone={firstRelatedMeeting}
                    target={target}
                    requiredTopicTemplateId={requiredTopicTemplate.id}
                    facilitator={targetManager}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentDeliveryHeader;

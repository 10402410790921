import React from "react";

export type Field = {
  title: string;
  render: () => React.ReactNode;
};

const FormFields = ({
  fields,
  width = 300,
}: {
  fields: Field[];
  width?: number;
}) => {
  return (
    <div className="flex flex-col gap-4">
      {fields.map((field) => (
        <div key={field.title} className="flex items-baseline">
          <div
            className="text-gray-600 text-sm shrink-0"
            style={{ width: `${width}px` }}
          >
            {field.title}
          </div>
          <div className="flex-1">{field.render()}</div>
        </div>
      ))}
    </div>
  );
};

export default FormFields;

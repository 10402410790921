import { gql } from "@apollo/client";

export default gql`
  fragment EngagementSurveyProgramAssessmentTemplate on AssessmentTemplateNode {
    id
    assessmentType
  }
  fragment EngagementSurveyComplianceProgram on ComplianceProgramNode {
    id
    title
    dueDate
    canReadDetails {
      permission
      reason
    }
    usersMissingAssessment {
      edges {
        node {
          target {
            id
            name
            avatar
          }
          responder {
            id
            name
            avatar
          }
          assessmentTemplateId
          dueDate
        }
      }
    }
    engagementSurveyTemplate {
      ...EngagementSurveyProgramAssessmentTemplate
    }
    assessments(responderId: $currentUserId) {
      edges {
        node {
          id
          state
          target {
            id
            name
            avatar
          }
          responder {
            id
            name
            avatar
          }
          template {
            id
          }
          submittedDatetime
        }
      }
    }
  }
  query getActiveEngagementSurveyActions(
    $organizationId: Int!
    $currentUserId: Int!
  ) {
    compliancePrograms(
      onlyActive: true
      assessmentType: engagement_survey
      organizationId: $organizationId
      orderBy: "-due_date"
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...EngagementSurveyComplianceProgram
        }
      }
    }
  }
`;

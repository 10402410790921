import { useMutation } from "@apollo/client";
import { Placement } from "@floating-ui/react";
import { ReactNode } from "react";
import { GetMeetingNewPageQueryQuery } from "types/graphql-schema";

import updateMeetingGroupMutation from "@apps/meeting-new/graphql/update-meeting-group-mutation";
import { MeetingOrTemplateVisibility } from "@apps/meeting-new/helpers";
import MeetingContentAccess from "@components/meeting-content-access/meeting-content-access";
import AppPopover from "@components/popover/app-popover";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertNonNull } from "@helpers/helpers";

const MeetingContentAccessDropdown = ({
  meetingGroup,
  children,
  placement = "top-end",
}: {
  children?: ReactNode;
  meetingGroup: NonNullable<
    NonNullable<GetMeetingNewPageQueryQuery["meeting"]>["meetingGroup"]
  >;
  placement?: Placement;
}) => {
  const title = meetingGroup?.title || "";
  useDocumentTitle(title);

  const [updateMeetingGroup] = useMutation(updateMeetingGroupMutation);

  const handleSaveVisibility = ({
    allowAdminVisibility,
    allowOrgVisibility,
    allowManagementTreeVisibility,
  }: MeetingOrTemplateVisibility) => {
    updateMeetingGroup({
      variables: {
        meetingGroupId: assertNonNull(meetingGroup).id,
        allowAdminVisibility,
        allowOrgVisibility,
        allowManagementTreeVisibility,
      },
      optimisticResponse: {
        updateMeetingGroup: {
          meetingGroup: {
            ...meetingGroup,
            allowManagementTreeVisibility,
            allowAdminVisibility,
            allowOrgVisibility,
          },
        },
      },
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <AppPopover
      className="flex-1 min-w-0 flex"
      placement={placement}
      content={
        <div className="p-6 pt-4 text-sm">
          <div className="text-base font-medium mb-2">
            Change meeting content access
          </div>
          <MeetingContentAccess
            allowManagementTreeVisibility={
              meetingGroup.allowManagementTreeVisibility
            }
            allowAdminVisibility={meetingGroup.allowAdminVisibility}
            allowOrgVisibility={meetingGroup.allowOrgVisibility}
            isFormalOneonone={meetingGroup.isFormalOneonone}
            onChange={handleSaveVisibility}
          />
        </div>
      }
    >
      {children}
    </AppPopover>
  );
};

export default MeetingContentAccessDropdown;

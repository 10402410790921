import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { createPortal } from "react-dom";
import {
  AssessmentTemplateComplianceRequirement,
  CompetencyAssessmentType,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import FormFields from "@components/form/form-fields";
import Input from "@components/input/input";
import Modal, { ModalWidth } from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { assertNonNull } from "@helpers/helpers";

import { AssessmentTemplateExtraSettings } from "../compliance-program-form";

const competencyAssessmentOptions = [
  {
    value: CompetencyAssessmentType.CriteriaCombined,
    label: "Assess competency as a whole",
  },
  {
    value: CompetencyAssessmentType.IndividualCriteria,
    label: "Assess each competency individually",
  },
];

enum NextRoleAssessmentConfig {
  NO = "NO",
  ONLY_MANAGER = "ONLY_MANAGER",
  ONLY_SUBJECT = "ONLY_SUBJECT",
  BOTH = "BOTH",
}

const rateNextRoleOptions = [
  {
    value: NextRoleAssessmentConfig.NO,
    label: "No",
  },
  {
    value: NextRoleAssessmentConfig.ONLY_MANAGER,
    label: "Only manager",
  },
  {
    value: NextRoleAssessmentConfig.ONLY_SUBJECT,
    label: "Only employee",
  },
  {
    value: NextRoleAssessmentConfig.BOTH,
    label: "Both",
  },
];

const nextRoleAssessmentConfigToProgramSettings = (
  opt: ComboboxGenericOption<NextRoleAssessmentConfig>
) => {
  if (opt.value === NextRoleAssessmentConfig.ONLY_MANAGER) {
    return {
      roleBasedAssessmentsSelfAssessNextRole: false,
      roleBasedAssessmentsAssessNextRole: true,
    };
  }
  if (opt.value === NextRoleAssessmentConfig.ONLY_SUBJECT) {
    return {
      roleBasedAssessmentsSelfAssessNextRole: true,
      roleBasedAssessmentsAssessNextRole: false,
    };
  }
  if (opt.value === NextRoleAssessmentConfig.BOTH) {
    return {
      roleBasedAssessmentsSelfAssessNextRole: true,
      roleBasedAssessmentsAssessNextRole: true,
    };
  }
  return {
    roleBasedAssessmentsSelfAssessNextRole: false,
    roleBasedAssessmentsAssessNextRole: false,
  };
};

const programSettingsToNextRoleAssessmentConfig = (
  programSettings: AssessmentTemplateComplianceRequirement
) => {
  if (
    programSettings.roleBasedAssessmentsAssessNextRole &&
    programSettings.roleBasedAssessmentsSelfAssessNextRole
  ) {
    return NextRoleAssessmentConfig.BOTH;
  }
  if (programSettings.roleBasedAssessmentsAssessNextRole) {
    return NextRoleAssessmentConfig.ONLY_MANAGER;
  }
  if (programSettings.roleBasedAssessmentsSelfAssessNextRole) {
    return NextRoleAssessmentConfig.ONLY_SUBJECT;
  }
  return NextRoleAssessmentConfig.NO;
};

const scaleSizeOptions = (startsAt: number) => [
  {
    value: 2,
    label: `${1 + startsAt}`,
  },
  {
    value: 3,
    label: `${2 + startsAt}`,
  },
  {
    value: 4,
    label: `${3 + startsAt}`,
  },
  {
    value: 5,
    label: `${4 + startsAt}`,
  },
];

const scaleStartsAtOptions = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
];

const RoleBasedAssessmentSettingsModal = ({
  onClose,
  canUpdate,
  selectedPerformanceAssessmentGroup,
  onChangeAssessmentTemplateSettings,
}: {
  selectedPerformanceAssessmentGroup: AssessmentTemplateComplianceRequirement;
  canUpdate: boolean;
  onClose: () => void;
  onChangeAssessmentTemplateSettings: (
    assessmentTemplateSettings: AssessmentTemplateExtraSettings
  ) => void;
}) => {
  const label = useLabel();

  return createPortal(
    <Modal
      open
      width={ModalWidth.medium}
      onClose={onClose}
      aria-label="Role based assessment settings modal"
    >
      <div className="p-6 flex flex-col">
        <ModalTitle>{`Role-based ${label("review", {
          pluralize: true,
          capitalize: true,
        })}`}</ModalTitle>

        <div className="mt-6">
          <div className="font-bold mb-6 text-sm">Advanced settings</div>
          <FormFields
            width={400}
            fields={[
              {
                title: "Competency assessment configuration",
                render: () => (
                  <ComboboxGeneric
                    options={competencyAssessmentOptions}
                    disabled={!canUpdate}
                    onChangeValue={(opt) =>
                      onChangeAssessmentTemplateSettings({
                        competencyAssessmentType: opt.value,
                      })
                    }
                    value={assertNonNull(
                      competencyAssessmentOptions.find(
                        ({ value }) =>
                          value ===
                          selectedPerformanceAssessmentGroup.competencyAssessmentType
                      )
                    )}
                  />
                ),
              },
              {
                title: "Next role competency assessment configuration",
                render: () => (
                  <ComboboxGeneric
                    options={competencyAssessmentOptions}
                    disabled={
                      !canUpdate ||
                      !(
                        selectedPerformanceAssessmentGroup.roleBasedAssessmentsAssessNextRole ||
                        selectedPerformanceAssessmentGroup.roleBasedAssessmentsSelfAssessNextRole
                      )
                    }
                    onChangeValue={(opt) =>
                      onChangeAssessmentTemplateSettings({
                        nextRoleCompetencyAssessmentType: opt.value,
                      })
                    }
                    value={assertNonNull(
                      competencyAssessmentOptions.find(
                        ({ value }) =>
                          value ===
                          selectedPerformanceAssessmentGroup.nextRoleCompetencyAssessmentType
                      )
                    )}
                  />
                ),
              },
              {
                title: "Assess employee's next role (if available)",
                render: () => (
                  <ComboboxGeneric
                    options={rateNextRoleOptions}
                    disabled={!canUpdate}
                    onChangeValue={(opt) =>
                      onChangeAssessmentTemplateSettings(
                        nextRoleAssessmentConfigToProgramSettings(opt)
                      )
                    }
                    value={assertNonNull(
                      rateNextRoleOptions.find(
                        ({ value }) =>
                          value ===
                          programSettingsToNextRoleAssessmentConfig(
                            selectedPerformanceAssessmentGroup
                          )
                      )
                    )}
                  />
                ),
              },
            ]}
          />
        </div>

        <div className="mt-6 pt-6 border-t">
          <div className="font-bold mb-6 text-sm">Input</div>
          <FormFields
            width={400}
            fields={[
              {
                title: "Scale",
                render: () => (
                  <div className="flex gap-2 items-center">
                    <ComboboxGeneric
                      className="w-24"
                      options={scaleStartsAtOptions}
                      disabled={!canUpdate}
                      onChangeValue={(opt) =>
                        onChangeAssessmentTemplateSettings({
                          roleBasedAssessmentScaleStartsAt: opt.value,
                        })
                      }
                      value={assertNonNull(
                        scaleStartsAtOptions.find(
                          ({ value }) =>
                            value ===
                            selectedPerformanceAssessmentGroup.roleBasedAssessmentScaleStartsAt
                        )
                      )}
                    />
                    <ArrowSmRightIcon className="text-gray-500 w-4 h-4 shrink-0" />
                    <ComboboxGeneric
                      className="w-24"
                      options={scaleSizeOptions(
                        assertNonNull(
                          selectedPerformanceAssessmentGroup.roleBasedAssessmentScaleStartsAt
                        )
                      )}
                      disabled={!canUpdate}
                      onChangeValue={(opt) =>
                        onChangeAssessmentTemplateSettings({
                          roleBasedAssessmentScale: opt.value,
                        })
                      }
                      value={assertNonNull(
                        scaleSizeOptions(
                          assertNonNull(
                            selectedPerformanceAssessmentGroup.roleBasedAssessmentScaleStartsAt
                          )
                        ).find(
                          ({ value }) =>
                            value ===
                            selectedPerformanceAssessmentGroup.roleBasedAssessmentScale
                        )
                      )}
                    />
                  </div>
                ),
              },
              ...assertNonNull(
                selectedPerformanceAssessmentGroup.roleBasedAssessmentScaleLabels
              ).map((label, index) => {
                return {
                  title: `Label ${
                    index +
                    assertNonNull(
                      selectedPerformanceAssessmentGroup.roleBasedAssessmentScaleStartsAt
                    )
                  }`,
                  render: () => (
                    <Input
                      disabled={!canUpdate}
                      placeholder="Label (optional)"
                      value={label ?? ""}
                      onChange={(e) => {
                        const newLabels = [
                          ...assertNonNull(
                            selectedPerformanceAssessmentGroup.roleBasedAssessmentScaleLabels
                          ),
                        ];
                        newLabels[index] = e.target.value;
                        onChangeAssessmentTemplateSettings({
                          roleBasedAssessmentScaleLabels: newLabels,
                        });
                      }}
                    />
                  ),
                };
              }),
            ]}
          />
        </div>

        <div className="mt-6 flex items-center justify-end gap-2">
          <Button onClick={onClose} theme={buttonTheme.text} text="Close" />
        </div>
      </div>
    </Modal>,
    document.body
  );
};

export default RoleBasedAssessmentSettingsModal;

import { useQuery } from "@apollo/client";
import { compact, sortBy } from "lodash";
import { useMemo, useState } from "react";
import {
  GetUserRolesQueryQuery,
  GetUserRolesQueryQueryVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import Avatar, { AvatarProps } from "@components/avatar/avatar";
import AppLink from "@components/link/link";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import getUserRolesQuery from "./graphql/get-user-roles-query.ts/get-user-roles-query";

type AvatarsUser =
  | (AvatarProps["user"] & {
      disabled?: boolean;
      tooltipSuffix?: string;
    })
  | null
  | undefined;

type Props = Omit<AvatarProps, "user"> & {
  users: AvatarsUser[];
  size?: number;
  max: number;
  className?: string;
  avatarClassName?: string;
  extraClassName?: string;
  modalTitle?: string;
  showNullAsAnonymous?: boolean;
};

const typeGuardUserWithId = (
  user: AvatarsUser
): user is BasicUser & { id: number } => {
  return !!user && "id" in user;
};

const Avatars: React.FC<Props> = ({
  users,
  className,
  avatarClassName,
  extraClassName = "",
  max,
  showNullAsAnonymous,
  modalTitle = "People",
  size = 5,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  // First users with name
  // Then users with email
  // Then null users (unknown/anonymous)
  const sortedUsers = useMemo(() => {
    return sortBy(users, (user) => {
      if (typeGuardUserWithId(user) && user.name) {
        return `1-${user.name}`;
      }
      if (user?.email) {
        return `2-${user.email}`;
      }
      return "3";
    });
  }, [users]);

  const nonNullUsers = compact(sortedUsers);
  const maxWithExtraPill = Math.max(max - 1, 1);

  const extraUserNames = useMemo(
    () =>
      nonNullUsers
        .slice(max - 1)
        .map((user) => user.name)
        .join(", "),
    [nonNullUsers, max]
  );
  const extraUserNamesWithAnon = useMemo(
    () =>
      sortedUsers
        .slice(max - 1)
        .map((user) => (user ? user.name : "Anonymous"))
        .join(", "),
    [sortedUsers, max]
  );

  const userIds = useMemo(
    () =>
      compact(
        sortedUsers.map((user) => (typeGuardUserWithId(user) ? user.id : null))
      ),
    [sortedUsers]
  );
  const { data } = useQuery<
    GetUserRolesQueryQuery,
    GetUserRolesQueryQueryVariables
  >(getUserRolesQuery, {
    skip: !open || userIds.length === 0,
    variables: { userIds },
    onError: onNotificationErrorHandler(),
  });

  const userRolesByUserId = useMemo(() => {
    const usersWithRoles = data?.users ? assertEdgesNonNull(data.users) : [];
    return usersWithRoles.reduce((memo, user) => {
      const roleStr = user.roles
        ? user.roles.map((role) => role.title).join(", ")
        : "";
      return {
        ...memo,
        [user.id]: roleStr,
      };
    }, {} as { [key: number]: string });
  }, [data]);

  const usersToShow = showNullAsAnonymous ? sortedUsers : nonNullUsers;
  const sizePx = size * 4;
  // used to overlap the avatars
  const negativeLeftMargin = -sizePx * (sizePx > 25 ? 0.45 : 0.3);

  return (
    <>
      <button
        className={classNames("flex items-center shrink-0", className)}
        style={{ minWidth: sizePx, paddingLeft: -negativeLeftMargin }}
        aria-label="Avatars"
        onClick={() => setOpen(true)}
      >
        {usersToShow.slice(0, maxWithExtraPill).map((user, index) => (
          <div
            key={user?.name || index}
            className={classNames(
              "rounded-full ring-1 ring-white shrink-0",
              `h-${size}`,
              avatarClassName
            )}
            style={{
              marginLeft: negativeLeftMargin,
            }}
          >
            <Avatar
              user={user}
              disabled={user?.disabled}
              tooltipSuffix={user?.tooltipSuffix}
              unassignedTooltipText={
                showNullAsAnonymous ? "Anonymous" : undefined
              }
              className={
                showNullAsAnonymous
                  ? classNames(user ? "bg-gray-300" : "bg-white")
                  : undefined
              }
              size={size}
              {...props}
            />
          </div>
        ))}
        {usersToShow.length > maxWithExtraPill && (
          <span
            className={classNames(
              `text-gray-500 text-sm tracking-tight shrunk-0 select-none`,
              "rounded-full text-2xs flex items-center justify-center z-1 bg-gray-200 text-gray-800",
              extraClassName
            )}
            style={{
              height: sizePx,
              width: sizePx,
              marginLeft: negativeLeftMargin,
            }}
          >
            <Tooltip
              text={
                showNullAsAnonymous ? extraUserNamesWithAnon : extraUserNames
              }
            >
              <span>+{sortedUsers.length - maxWithExtraPill}</span>
            </Tooltip>
          </span>
        )}
      </button>
      {open && (
        <Modal alignedTop onClose={() => setOpen(false)}>
          <div className="flex flex-col">
            <ModalTitle
              onClose={() => setOpen(false)}
              className="py-4 px-6 border-b"
            >
              {modalTitle} ({sortedUsers.length})
            </ModalTitle>
            <div className="flex flex-col gap-4 p-6">
              {sortedUsers.map((user, index) => (
                <div
                  key={user?.name || index}
                  className="flex justify-between gap-2"
                >
                  {typeGuardUserWithId(user) ? (
                    <AppLink
                      to={`/dashboard/user/${user.id}`}
                      className="flex items-center gap-2 text-sm hover:underline"
                    >
                      <Avatar user={user} size={5} />
                      {user?.name}
                    </AppLink>
                  ) : (
                    <div className="flex items-center gap-2 text-sm">
                      <Avatar size={5} />
                      {showNullAsAnonymous ? "Anonymous" : "Unknown"}
                    </div>
                  )}
                  {typeGuardUserWithId(user) && userRolesByUserId[user.id] && (
                    <div className="text-xs text-gray-400 tracking-tight text-right">
                      {userRolesByUserId[user.id]}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Avatars;

import {
  AlignmentChildGoalIdsByParentIdType,
  AlignmentGoalsByIdType,
} from "./goal-alignment";
import GoalAlignmentTreeItem from "./goal-alignment-tree-item";
import { AlignedGoalType } from "./use-fetch-alignment-goals";

const GoalAlignmentTree = ({
  goals,
  goalsById,
  childGoalIdsByParentId,
  indent,
  isShowingKeyResults,
  highlightedGoalIds,
  orderPriorityGoalIds,
  expandedGoalIds,
  onToggleExpandedGoal,
}: {
  indent: number;
  goals: AlignedGoalType[];
  goalsById: AlignmentGoalsByIdType;
  childGoalIdsByParentId: AlignmentChildGoalIdsByParentIdType;
  isShowingKeyResults: boolean;
  highlightedGoalIds?: number[];
  orderPriorityGoalIds?: number[];
  expandedGoalIds?: number[];
  onToggleExpandedGoal: (goalIds: number[], toExpand: boolean) => void;
}) => {
  const filteredGoals = goals;
  return (
    <div className="">
      {filteredGoals.map((goal) => (
        <GoalAlignmentTreeItem
          key={goal.id}
          goal={goal}
          goalsById={goalsById}
          childGoalIdsByParentId={childGoalIdsByParentId}
          isShowingKeyResults={isShowingKeyResults}
          indent={indent}
          highlightedGoalIds={highlightedGoalIds}
          orderPriorityGoalIds={orderPriorityGoalIds}
          expandedGoalIds={expandedGoalIds}
          onToggleExpandedGoal={onToggleExpandedGoal}
        />
      ))}
    </div>
  );
};

export default GoalAlignmentTree;

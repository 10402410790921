import { useMutation } from "@apollo/client";
import { compact, flatMap } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AssessmentQuestionResponses,
  AssessmentQuestionType,
  AssessmentState,
  AssessmentType,
  DeleteAssessmentAnswerMutationMutation,
  DeleteAssessmentAnswerMutationMutationVariables,
  GetAssessmentQuery,
  SaveAssessmentMutation,
  SaveAssessmentMutationVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import {
  currentOrganizationVar,
  errorNotificationVar,
  successNotificationVar,
} from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import useDebounce from "@components/use-debounce/use-debounce";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { getAssessmentTypeLabel } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import createOrUpdateAssessmentMutation from "../../graphql/create-or-update-assessment-mutation";
import deleteAssessmentAnswerMutation from "../../graphql/delete-assessment-answer-mutation";
import getMyAssessmentsQuery from "../../graphql/get-my-assessments-query";
import getPreviousAssessmentsQuery from "../../graphql/get-previous-assessments-query";
import { assessmentTypeSectionHeadings, bgClassName } from "../../helpers";
import AssessmentLayout from "../layouts/assessment-layout";
import AssessmentQuestionItem from "../questions/assessment-question-item";
import GoalBasedQuestions from "../questions/goal-based-questions";
import {
  isGoalBasedQuestionType,
  isRoleBasedQuestionType,
} from "../questions/helpers";
import { getQuestionClass } from "../questions/question";
import { AssessmentAnswer, AssessmentSection } from "../questions/types";
import AssessmentHeader from "./assessment-header";
import AssessmentRoleBasedSection from "./assessment-role-based-section";

const AssessmentContent = ({
  myAssessmentsData,
  isLoadingAssessment,
  proposedAnswers,
  onChangeProposedAnswers,
}: {
  proposedAnswers: AssessmentAnswer[];
  onChangeProposedAnswers: (answers: AssessmentAnswer[]) => void;
  myAssessmentsData?: GetAssessmentQuery;
  isLoadingAssessment: boolean;
}) => {
  const debouncedProposedAnswers = useDebounce(proposedAnswers, 200);
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);
  const { assessmentId: assessmentIdParam } = useParams<{
    assessmentId: string;
  }>();
  const assessmentId = parseInt(assessmentIdParam);
  const organization = currentOrganizationVar();
  const { onError } = useNotificationError();
  const link = useLink();
  const label = useLabel();
  const [showErrors, setShowErrors] = useState(false);

  const assessment = useMemo(
    () => (myAssessmentsData ? myAssessmentsData.assessment : null),
    [myAssessmentsData]
  );
  const assessmentTemplateId = useMemo(
    () => (assessment ? assessment.template.id : null),
    [assessment]
  );
  const complianceProgram = useMemo(
    () => (assessment ? assertNonNull(assessment.complianceProgram) : null),
    [assessment]
  );

  const isEngagementSurvey = useMemo(
    () =>
      assessment?.template.assessmentType === AssessmentType.EngagementSurvey,
    [assessment]
  );
  const target = useMemo(
    () =>
      !isEngagementSurvey && assessment
        ? assertNonNull(assessment.target)
        : null,
    [assessment, isEngagementSurvey]
  );
  const responder = useMemo(() => assessment?.responder ?? null, [assessment]);
  const isSelfAssessment = useMemo(
    () => target?.id === responder?.id,
    [responder, target]
  );

  const assessmentLabel = useMemo(() => {
    if (isEngagementSurvey) {
      return "Survey";
    }
    return "review";
  }, [isEngagementSurvey]);
  const assessmentPath = useMemo(() => {
    if (isEngagementSurvey) {
      return "/survey";
    }
    return "/assessments";
  }, [isEngagementSurvey]);

  const sectionNodes = useMemo(() => {
    if (!assessment) {
      return [];
    }
    const additionQuestionSets = assertEdgesNonNull(
      assessment.additionalQuestionSets
    );
    const addtionalSections = flatMap(additionQuestionSets, (set) => {
      const newSections: AssessmentSection[] = assertEdgesNonNull(
        set.sections
      ).map((section) => {
        const questions = assertEdgesNonNull(section.questions);
        return {
          id: section.id,
          title: section.title,
          description: section.description,
          questions,
        };
      });
      return newSections;
    });
    const questionSets = assertEdgesNonNull(assessment.template.questionSets);
    const regularSections = flatMap(questionSets, (set) => {
      const newSections: AssessmentSection[] = assertEdgesNonNull(
        set.sections
      ).map((section) => {
        const questions = assertEdgesNonNull(section.questions);
        return {
          id: section.id,
          title: section.title,
          description: section.description,
          questions,
        };
      });
      return newSections;
    });
    return [...addtionalSections, ...regularSections];
  }, [assessment]);

  const questionNodes = useMemo(() => {
    if (!assessment) {
      return [];
    }
    const allQuestions = flatMap(sectionNodes, (section) => section.questions);
    return allQuestions.filter((node) => {
      const { question } = node;
      return (
        question.responses === AssessmentQuestionResponses.Both ||
        (question.responses ===
          AssessmentQuestionResponses.ExcludeSelfAssessment &&
          !isSelfAssessment) ||
        (question.responses ===
          AssessmentQuestionResponses.SelfAssessmentOnly &&
          isSelfAssessment)
      );
    });
  }, [assessment, isSelfAssessment, sectionNodes]);

  const [deleteAnswers] = useMutation<
    DeleteAssessmentAnswerMutationMutation,
    DeleteAssessmentAnswerMutationMutationVariables
  >(deleteAssessmentAnswerMutation);

  const [saveAssessment, { loading: isSavingAssessment }] = useMutation<
    SaveAssessmentMutation,
    SaveAssessmentMutationVariables
  >(createOrUpdateAssessmentMutation);

  useEffect(() => {
    if (
      !assessment ||
      debouncedProposedAnswers.length === 0 ||
      isFirstPageLoad ||
      assessment.state === AssessmentState.Submitted
    ) {
      if (isFirstPageLoad) {
        setIsFirstPageLoad(false);
      }
      return;
    }
    saveAssessment({
      variables: {
        assessmentId: assertNonNull(assessmentId),
        organizationId: organization.id,
        targetId: isEngagementSurvey ? undefined : assertNonNull(target).id,
        assessmentTemplateId: assertNonNull(assessmentTemplateId),
        complianceProgramId: assertNonNull(complianceProgram).id,
        answers: debouncedProposedAnswers,
      },
      onError,
    });
    // autosave, only fire to backend when debounced answers changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedProposedAnswers]);

  const handleUpdateAnswer = useCallback(
    (newAnswer: AssessmentAnswer) => {
      const newAnswers = [
        ...proposedAnswers.filter(
          (answer) => newAnswer.questionId !== answer.questionId
        ),
        newAnswer,
      ];
      onChangeProposedAnswers(newAnswers);
    },
    [onChangeProposedAnswers, proposedAnswers]
  );

  const handleDeleteAnswers = useCallback(
    (questionIds: number[]) => {
      if (assessmentId) {
        const newAnswers = proposedAnswers.filter(
          (answer) => !questionIds.includes(answer.questionId)
        );
        onChangeProposedAnswers(newAnswers);
        deleteAnswers({
          variables: {
            questionIds,
            assessmentId: assessmentId,
          },
          onError: onNotificationErrorHandler(),
        });
      }
    },
    [assessmentId, deleteAnswers, onChangeProposedAnswers, proposedAnswers]
  );

  const areAllQuestionsAnswered = useMemo(() => {
    const individualGoalBasedQuestions = questionNodes.filter(
      (node) =>
        node.question.__typename === "IndividualGoalAssessmentQuestionNode"
    );

    return questionNodes.every((node) => {
      const QuestionClass = getQuestionClass(node.question, { label });
      const answer = proposedAnswers.find(
        (answer) => answer.questionId === node.question.id
      );
      // If there are no individual goal based questions, then the overall goal question can be considered answered
      if (
        node.question.questionType === AssessmentQuestionType.OverallGoal &&
        individualGoalBasedQuestions.length === 0
      ) {
        return true;
      }
      return QuestionClass.isQuestionAnswered(answer);
    });
  }, [proposedAnswers, questionNodes, label]);

  const areAllCommentsEntered = useMemo(() => {
    return questionNodes.every((node) => {
      const QuestionClass = getQuestionClass(node.question, { label });
      const answer = proposedAnswers.find(
        (answer) => answer.questionId === node.question.id
      );
      return QuestionClass.hasEnteredComment(answer);
    });
  }, [proposedAnswers, questionNodes, label]);

  const handleSubmitAssessment = useCallback(() => {
    if (!areAllCommentsEntered || !areAllQuestionsAnswered) {
      errorNotificationVar({
        title: "Please answer all questions and comments",
      });
      setShowErrors(true);
      return;
    }
    saveAssessment({
      variables: {
        assessmentId: assertNonNull(assessmentId),
        organizationId: organization.id,
        targetId: isEngagementSurvey ? undefined : assertNonNull(target).id,
        assessmentTemplateId: assertNonNull(assessmentTemplateId),
        complianceProgramId: assertNonNull(complianceProgram).id,
        answers: proposedAnswers,
        state: AssessmentState.Submitted,
      },
      refetchQueries: [getMyAssessmentsQuery, getPreviousAssessmentsQuery],
      onError,
      onCompleted: () => {
        const assessmentType = assertNonNull(
          assessment?.template.assessmentType
        );
        const title =
          assessmentType === AssessmentType.EngagementSurvey
            ? `${label(assessmentLabel)} submitted`
            : `${getAssessmentTypeLabel(
                assessmentType,
                isSelfAssessment
              )} ${label(assessmentLabel)} submitted`;
        successNotificationVar({ title });
        link.redirect(assessmentPath);
      },
    });
  }, [
    isEngagementSurvey,
    assessmentLabel,
    assessmentPath,
    saveAssessment,
    assessmentId,
    isSelfAssessment,
    organization,
    target,
    assessmentTemplateId,
    complianceProgram,
    proposedAnswers,
    onError,
    assessment,
    link,
    areAllCommentsEntered,
    areAllQuestionsAnswered,
    label,
  ]);

  if (isLoadingAssessment) {
    return (
      <Loading className={classNames(bgClassName, "p-6 w-full mx-auto")}>
        Loading
      </Loading>
    );
  }

  if (!assessment) {
    return (
      <div
        className={classNames(bgClassName, "flex-1 flex justify-center p-10")}
      >
        {label(assessmentLabel, { capitalize: true })} not found
      </div>
    );
  }

  const canUpdate = assessment.canUpdate?.permission;
  const formDisabled =
    assessment.state === AssessmentState.Submitted || !canUpdate;

  return (
    <div>
      <AssessmentHeader myAssessmentsData={myAssessmentsData} />

      <div className="px-6">
        <AssessmentLayout.AssessmentTypeSection>
          <AssessmentLayout.AssessmentTypeHeader>
            {
              assessmentTypeSectionHeadings(label)[
                assessment.template.assessmentType
              ]
            }
          </AssessmentLayout.AssessmentTypeHeader>
          <div className="mt-12">
            {sectionNodes.map((section) => {
              const sectionQuestions = section.questions.filter(
                ({ question }) =>
                  questionNodes.map((q) => q.question.id).includes(question.id)
              );
              const hasVisibleQuestions = sectionQuestions.length > 0;
              if (!hasVisibleQuestions) {
                return null;
              }
              const heading =
                String(section.id).endsWith("-goals") ||
                String(section.id).endsWith("-role") ? null : section.title ? (
                  <AssessmentLayout.SectionHeading>
                    <AssessmentLayout.SectionHeadingTitle>
                      {section.title}
                    </AssessmentLayout.SectionHeadingTitle>
                    {section.description && !isEmptyValue(section.description) && (
                      <AssessmentLayout.SectionHeadingDescription>
                        <TextareaWysiwyg
                          editable={false}
                          className="bg-white"
                          value={section.description}
                        />
                      </AssessmentLayout.SectionHeadingDescription>
                    )}
                  </AssessmentLayout.SectionHeading>
                ) : null;

              const regularQuestions = sectionQuestions.filter(
                ({ question }) =>
                  !isRoleBasedQuestionType(question.questionType) &&
                  !isGoalBasedQuestionType(question.questionType)
              );

              const goalBasedQuestions = sectionQuestions.filter(
                ({ question }) => isGoalBasedQuestionType(question.questionType)
              );
              const overallGoalBasedQuestion = goalBasedQuestions.find(
                ({ question }) =>
                  question.questionType === AssessmentQuestionType.OverallGoal
              );
              const individualGoalBasedQuestions = goalBasedQuestions.filter(
                ({ question }) =>
                  question.questionType ===
                  AssessmentQuestionType.IndividualGoal
              );

              return (
                <AssessmentLayout.SectionContainer key={section.id}>
                  {heading}
                  {regularQuestions.length !== 0 && (
                    <AssessmentLayout.SectionRegularQuestions>
                      {regularQuestions.map((questionWithWeight) => {
                        const { question } = questionWithWeight;
                        if (
                          question.responses ===
                            AssessmentQuestionResponses.ExcludeSelfAssessment &&
                          isSelfAssessment
                        ) {
                          return null;
                        }
                        if (
                          question.responses ===
                            AssessmentQuestionResponses.SelfAssessmentOnly &&
                          !isSelfAssessment
                        ) {
                          return null;
                        }
                        const answer = proposedAnswers.find(
                          (answer) => answer.questionId === question.id
                        );
                        return (
                          <AssessmentQuestionItem
                            key={question.id}
                            answer={answer}
                            question={question}
                            onUpdateAnswer={handleUpdateAnswer}
                            formDisabled={formDisabled}
                            showError={showErrors}
                          />
                        );
                      })}
                    </AssessmentLayout.SectionRegularQuestions>
                  )}

                  <AssessmentRoleBasedSection
                    sectionQuestions={sectionQuestions}
                    targetCurrentRoleIds={compact(
                      assessment.targetCurrentRoleIds
                    )}
                    onUpdateAnswer={handleUpdateAnswer}
                    onDeleteAnswers={handleDeleteAnswers}
                    formDisabled={formDisabled}
                    showErrors={showErrors}
                    allAnswers={proposedAnswers}
                    nextRoleAssessmentEnabled={
                      (!isSelfAssessment &&
                        !!myAssessmentsData?.assessment?.template
                          .roleBasedAssessmentsAssessNextRole) ||
                      (isSelfAssessment &&
                        !!myAssessmentsData?.assessment?.template
                          .roleBasedAssessmentsSelfAssessNextRole)
                    }
                    isSelfAssessment={isSelfAssessment}
                  />

                  {String(section.id).endsWith("-goals") && (
                    <GoalBasedQuestions
                      overallGoalBasedQuestion={overallGoalBasedQuestion}
                      individualGoalBasedQuestions={
                        individualGoalBasedQuestions
                      }
                      isSelfAssessment={isSelfAssessment}
                      proposedAnswers={proposedAnswers}
                      formDisabled={formDisabled}
                      showErrors={showErrors}
                      onUpdateAnswer={handleUpdateAnswer}
                    />
                  )}
                </AssessmentLayout.SectionContainer>
              );
            })}

            <div className="flex items-center justify-end mt-4 pb-6">
              {!formDisabled && (
                <div className="flex items-center gap-2">
                  {isSavingAssessment && <Loading mini size="4" />}
                  <Button
                    onClick={() => {
                      const title =
                        assessment.template.assessmentType ===
                        AssessmentType.EngagementSurvey
                          ? `${label(assessmentLabel)} saved for later`
                          : `${getAssessmentTypeLabel(
                              assessment.template.assessmentType,
                              isSelfAssessment
                            )} ${label(assessmentLabel)} saved for later`;
                      successNotificationVar({ title });
                      link.redirect(assessmentPath);
                    }}
                    theme={buttonTheme.text}
                    disabled={isSavingAssessment}
                    text="Save as draft"
                  />
                  <Button
                    disabled={isSavingAssessment}
                    type="button"
                    theme={buttonTheme.primary}
                    onClick={handleSubmitAssessment}
                    text="Submit"
                  />
                </div>
              )}
            </div>
          </div>
        </AssessmentLayout.AssessmentTypeSection>
      </div>
    </div>
  );
};

export default AssessmentContent;

import React from "react";
import { IconType } from "react-icons";

import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";

interface HeroButton {
  title: string;
  subtitle: string;
  selected?: boolean;
  disabled?: boolean;
  tooltip?: string | null;
  icon: IconType;
  iconColor?: string;
  onClick: () => void;
}

const HeroButtons = ({ buttons }: { buttons: HeroButton[] }) => {
  return (
    <div className="flex flex-row items-center gap-8 h-auto w-full">
      {buttons.map((button) => (
        <Tooltip key={button.title} text={button.tooltip}>
          <button
            disabled={button.disabled}
            onClick={() => {
              if (!button.disabled) {
                button.onClick();
              }
            }}
            className={classNames(
              "flex-1 p-4 rounded border disabled:cursor-not-allowed disabled:opacity-50",
              button.selected && "bg-gray-50",
              !button.disabled && "hover:bg-gray-50"
            )}
          >
            <div className="flex flex-col items-center gap-2">
              <button.icon
                className={classNames(
                  "text-xl",
                  button.iconColor ?? "text-gray-800"
                )}
              />
              <div className="text-sm font-bold text-gray-800">
                {button.title}
              </div>
              <div className="text-sm text-gray-600">{button.subtitle}</div>
            </div>
          </button>
        </Tooltip>
      ))}
    </div>
  );
};

export default HeroButtons;

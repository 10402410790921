import { compact } from "lodash";
import { useMemo, useState } from "react";
import {
  AssessmentGroupAnonymity,
  AssessmentGroupDelivery,
  AssessmentGroupFragmentFragment,
  AssessmentGroupProviders,
  AssessmentTemplateComplianceRequirement,
  AssessmentType,
  Maybe,
  SaveComplianceProgramMutationVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { ComboboxGenericOption } from "@components/combobox/generic-combobox";
import FormFields from "@components/form/form-fields";
import Select from "@components/select/select";
import {
  assessmentGroupAnonymityOptions,
  assessmentGroupDeliveryOptions,
  assessmentGroupProviderOptions,
} from "@helpers/constants";
import { assertNonNull } from "@helpers/helpers";

import AssessmentGroupSidebar from "../assessment-group-sidebar/assessment-group-sidebar";
import { AssessmentTemplateExtraSettings } from "../compliance-program-form";
import { ComplianceProgramStep } from "../compliance-program-form-steps";
import ComplianceProgramFormStep from "./compliance-program-form-step";
import QuestionSetSelector from "./question-set-selector";

const PeerAssessmentStep = ({
  proposedComplianceProgram,
  canUpdate,
  complianceProgramSteps,
  onUpdateComplianceProgramSteps,
  onUpdateAssessmentTemplateSettings,
  onUpdateAssessmentTemplate,
  availableAssessmentGroups,
  onRemoveAssessmentTemplate,
  onAssessmentGroupCreated,
}: {
  proposedComplianceProgram: SaveComplianceProgramMutationVariables;
  canUpdate: boolean;
  complianceProgramSteps: ComplianceProgramStep[];
  onUpdateComplianceProgramSteps: (steps: ComplianceProgramStep[]) => void;
  onUpdateAssessmentTemplateSettings: (
    assessmentType: AssessmentType
  ) => (settings: AssessmentTemplateExtraSettings) => void;
  onUpdateAssessmentTemplate: (
    assessmentType: AssessmentType
  ) => (opt: ComboboxGenericOption<number> | null) => void;
  availableAssessmentGroups: AssessmentGroupFragmentFragment[];
  onRemoveAssessmentTemplate: (assessmentType: AssessmentType) => void;
  onAssessmentGroupCreated: (
    newAssessmentGroup: AssessmentGroupFragmentFragment
  ) => void;
}) => {
  const label = useLabel();

  const [isAssessmentGroupSidebarOpen, setIsAssessmentGroupSidebarOpen] =
    useState(false);
  const [assessmentGroupToDuplicate, setAssessmentGroupToDuplicate] =
    useState<AssessmentGroupFragmentFragment | null>(null);

  const availableAssessmentGroupSelectOptions = useMemo(() => {
    return availableAssessmentGroups
      .filter(
        (assessmentGroup) =>
          !assessmentGroup.isOnlySelfAssessment &&
          !assessmentGroup.hasNpsQuestions
      )
      .map(({ id, title }) => ({
        value: id,
        label: title,
      }));
  }, [availableAssessmentGroups]);

  const selectedAssessmentTemplates = useMemo(
    () =>
      proposedComplianceProgram.assessmentTemplates
        ? compact(
            proposedComplianceProgram.assessmentTemplates as Maybe<AssessmentTemplateComplianceRequirement>[]
          )
        : [],
    [proposedComplianceProgram.assessmentTemplates]
  );

  const selectedPeerAssessmentTemplate = useMemo(() => {
    return selectedAssessmentTemplates.find(
      (template) => template.assessmentType === AssessmentType.Peer
    );
  }, [selectedAssessmentTemplates]);

  const selectedAssessmentGroup = useMemo(() => {
    return selectedPeerAssessmentTemplate &&
      selectedPeerAssessmentTemplate.questionSetIds.length > 0
      ? availableAssessmentGroups.find(
          ({ id }) => id === selectedPeerAssessmentTemplate.questionSetIds[0]
        ) ?? null
      : null;
  }, [availableAssessmentGroups, selectedPeerAssessmentTemplate]);

  const peerDeliveryOptions = useMemo(
    () => assessmentGroupDeliveryOptions(label, AssessmentType.Peer),
    [label]
  );

  return (
    <>
      {isAssessmentGroupSidebarOpen && (
        <AssessmentGroupSidebar
          assessmentGroup={selectedAssessmentGroup}
          assessmentGroupToDuplicate={assessmentGroupToDuplicate}
          onClose={() => {
            setAssessmentGroupToDuplicate(null);
            setIsAssessmentGroupSidebarOpen(false);
          }}
          onAssessmentGroupCreated={(assessmentGroup) => {
            onAssessmentGroupCreated(assessmentGroup);
            onUpdateAssessmentTemplate(AssessmentType.Peer)({
              value: assessmentGroup.id,
              label: assessmentGroup.title,
            });
          }}
        />
      )}
      <ComplianceProgramFormStep
        canDelete={canUpdate}
        title={`Peer ${label("review", { capitalize: true })}`}
        onDelete={() => {
          onRemoveAssessmentTemplate(AssessmentType.Peer);
          onUpdateComplianceProgramSteps(
            complianceProgramSteps.filter(
              (s) => s !== ComplianceProgramStep.PEER_REVIEW
            )
          );
        }}
      >
        <div className="p-6 flex flex-col gap-3">
          <div className="text-sm text-gray-600 font-medium mb-4">
            Sections (For Form Input)
          </div>
          <div className="flex items-center gap-3 p-4 bg-white border rounded">
            <QuestionSetSelector
              assessmentType={AssessmentType.Peer}
              availableAssessmentGroupSelectOptions={
                availableAssessmentGroupSelectOptions
              }
              canUpdate={canUpdate}
              onUpdateAssessmentTemplate={onUpdateAssessmentTemplate}
              onDuplicateAssessmentGroup={setAssessmentGroupToDuplicate}
              onOpenAssessmentGroupSidebar={() =>
                setIsAssessmentGroupSidebarOpen(true)
              }
              selectedAssessmentGroup={selectedAssessmentGroup}
            />
          </div>
        </div>

        <div className="p-6 border-t">
          <div className="text-sm text-gray-600 font-medium mb-4">Settings</div>
          <FormFields
            fields={[
              {
                title: "Providers",
                render: () =>
                  selectedPeerAssessmentTemplate ? (
                    <Select<AssessmentGroupProviders>
                      disabled={!canUpdate}
                      value={assertNonNull(
                        selectedPeerAssessmentTemplate.providers
                      )}
                      options={assessmentGroupProviderOptions(
                        AssessmentType.Peer
                      )}
                      onChange={(opt) =>
                        onUpdateAssessmentTemplateSettings(AssessmentType.Peer)(
                          {
                            providers: opt.value,
                          }
                        )
                      }
                      aria-label="Assessment providers select"
                    />
                  ) : (
                    <div className="text-sm h-10 flex items-center">-</div>
                  ),
              },
              {
                title: "Anonymity",
                render: () =>
                  selectedPeerAssessmentTemplate ? (
                    <Select<AssessmentGroupAnonymity>
                      disabled={!canUpdate}
                      value={assertNonNull(
                        selectedPeerAssessmentTemplate.anonymity
                      )}
                      options={assessmentGroupAnonymityOptions(
                        AssessmentType.Peer
                      )}
                      onChange={(opt) =>
                        onUpdateAssessmentTemplateSettings(AssessmentType.Peer)(
                          {
                            anonymity: opt.value,
                          }
                        )
                      }
                    />
                  ) : (
                    <div className="text-sm h-10 flex items-center">-</div>
                  ),
              },
              {
                title: "Delivery",
                render: () =>
                  selectedPeerAssessmentTemplate ? (
                    <Select<AssessmentGroupDelivery>
                      disabled={!canUpdate}
                      value={assertNonNull(
                        selectedPeerAssessmentTemplate.delivery
                      )}
                      options={peerDeliveryOptions}
                      onChange={(opt) =>
                        onUpdateAssessmentTemplateSettings(AssessmentType.Peer)(
                          {
                            delivery: opt.value,
                          }
                        )
                      }
                    />
                  ) : (
                    <div className="text-sm h-10 flex items-center">-</div>
                  ),
              },
            ]}
          />
        </div>
      </ComplianceProgramFormStep>
    </>
  );
};
export default PeerAssessmentStep;

import { useQuery } from "@apollo/client";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { PiWaveformBold } from "react-icons/pi";
import { useLocation, useParams } from "react-router-dom";
import {
  GetMeetingForExtensionQueryQuery,
  GetMeetingForExtensionQueryQueryVariables,
} from "types/graphql-schema";

import { MeetingPageContent } from "@apps/meeting-new/components/meeting-page";
import AIAssistMeetingBot from "@apps/meeting-new/components/meeting-sidebar/ai-assist-bot";
import Container from "@components/container/container";
import Error from "@components/error/error";
import GraphqlError from "@components/error/graphql-error";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import AppPopover from "@components/popover/app-popover";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";

import getMeetingForExtensionQuery from "../graphql/get-meeting-for-extension-query";

const ExtensionMeetingContainer = ({
  meetingCode,
}: {
  meetingCode?: string;
}) => {
  const params = useParams<{ meetingGroupId: string; meetingId: string }>();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const queryParamGoogleMeetUrl = urlParams.get("googleMeetUrl");
  const googleMeetUrl =
    queryParamGoogleMeetUrl !== null
      ? queryParamGoogleMeetUrl
      : meetingCode !== undefined
      ? `https://meet.google.com/${meetingCode}`
      : "";
  const meetingId = params.meetingId ? Number(params.meetingId) : undefined;

  const { loading, error, data } = useQuery<
    GetMeetingForExtensionQueryQuery,
    GetMeetingForExtensionQueryQueryVariables
  >(getMeetingForExtensionQuery, {
    skip: !googleMeetUrl && !meetingId,
    fetchPolicy: "network-only",
    variables: { googleMeetUrl, meetingId },
    onError: onNotificationErrorHandler(),
  });
  const meeting = data?.meeting;
  const meetingGroup = data?.meeting?.meetingGroup;

  // Renders
  if (error) {
    return (
      <Container>
        <div className="mx-4">
          <GraphqlError
            error={error}
            whatDidNotWork="The meeting could not be loaded."
          />
        </div>
      </Container>
    );
  }
  if (!data && loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (
    (!googleMeetUrl && !meetingId) ||
    ((googleMeetUrl || meetingId) &&
      !loading &&
      (!meeting || !meetingGroup?.id)) ||
    !data ||
    !meeting ||
    !meetingGroup?.id
  ) {
    return (
      <Container>
        <div className="mx-4">
          <Error title="No meetings associated with this Google Meet." />
        </div>
        <div className="mt-6 mx-4 flex justify-center">
          <AppLink
            to="/extension/meetings"
            className="text-blue-800 hover:underline"
          >
            View today's meetings
          </AppLink>
        </div>
      </Container>
    );
  }

  return (
    <div>
      <div className="">
        <AppPopover
          options={{
            className: classNames(
              AppPopover.className,
              "min-w-[300px] max-w-[320px]"
            ),
          }}
          content={<AIAssistMeetingBot meeting={meeting} />}
        >
          <AppPopover.Button
            className="flex w-full justify-center items-center p-3 gap-1.5 text-gray-800 focus:outline-none bg-gray-50 border-b hover:bg-gray-100"
            disabled={!meeting.canUpdate?.permission}
          >
            {meeting.botIsRecording ? (
              <PiWaveformBold className="h-5 w-5 text-red-600" />
            ) : (
              <PiWaveformBold className="h-5 w-5 text-gray-400" />
            )}
            {meeting.botIsRecording
              ? "Transcribing"
              : meeting.hasBotInMeeting
              ? "Notetaker Paused"
              : meeting.botIsWaitingToJoinMeeting
              ? "Notetaker Waiting to Join"
              : "Not Transcribing"}
            {!!meeting.canUpdate?.permission && (
              <ChevronDownIcon className="h-4 w-4 text-gray-600" />
            )}
          </AppPopover.Button>
        </AppPopover>
      </div>
      <div className="bg-white p-4">
        <MeetingPageContent
          meetingGroupId={meetingGroup.id}
          meeting={meeting}
          isInExtension={true}
        />
      </div>
    </div>
  );
};

export default ExtensionMeetingContainer;

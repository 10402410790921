import { isInteger } from "lodash";
import { ReactNode } from "react";
import {
  AssessmentQuestionType,
  GoalState,
  GoalVisibility,
} from "types/graphql-schema";

import ArtifactDueDate from "@apps/artifact/components/artifact-due-date";
import { UseLabelType } from "@apps/use-label/use-label";
import DraftLabel from "@components/draft-label/draft-label";
import GoalIcon from "@components/goal-icon/goal-icon";
import GoalProgressPill from "@components/goal-progress-pill/goal-progress-pill";
import GoalVisibilityIcon from "@components/goal-visibility-icon/goal-visibility-icon";
import AppLink from "@components/link/link";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { getUrl } from "@helpers/helpers";

import AssessmentQuestionMultiSelectOptions from "../assessment-question-multi-select-options";
import AssessmentQuestionMultiSelectOptionsCombined from "../assessment-question-multi-select-options-combined";
import AssessmentQuestionRangeOptionsCombined from "../assessment-question-range-options-combined";
import AssessmentQuestionStackedTextAnswers from "../assessment-question-stacked-text-answers";
import IndividualGoalAssessmentQuestionContent from "./individual-goal-assessment-question-content";
import NpsAssessmentQuestionContent from "./nps-assessment-question-content";
import OverallGoalAssessmentQuestionContent from "./overall-goal-assessment-question-content";
import OverallGoalAssessmentQuestionDescription from "./overall-goal-assessment-question-description";
import RangeAssessmentQuestionContent from "./range-assessment-question-content";
import TextAssessmentQuestionContent from "./text-assessment-question-content";
import {
  AssessmentAnswer,
  AssessmentAnswerWithResponderAndId,
  AssessmentQuestion,
  CompetencyAssessmentQuestion,
  IndividualGoalAssessmentQuestion,
  MultiChoiceAssessmentQuestion,
  NpsAssessmentQuestion,
  OverallGoalAssessmentQuestion,
  RangeAssessmentQuestion,
  ResponsibilityAssessmentQuestion,
} from "./types";

type QuestionInterfaceOpts = {
  label: UseLabelType;
};

type QuestionFormProps = {
  formDisabled: boolean;
  answer?: AssessmentAnswer;
  onUpdateAnswer: (newAnswer: AssessmentAnswer) => void;
};

type QuestionDeliveryProps = {
  answer: AssessmentAnswerWithResponderAndId;
};

type QuestionDeliveryReadonlyProps = {
  answers: AssessmentAnswerWithResponderAndId[];
};

interface AssessmentQuestionInterface {
  getTitle(): ReactNode;
  getDescription(): ReactNode;
  getForm(opts: QuestionFormProps): ReactNode;
  getDelivery(opts: QuestionDeliveryProps): ReactNode;
  getDeliveryReadonly(opts: QuestionDeliveryReadonlyProps): ReactNode;
  isQuestionAnswered(answer?: AssessmentAnswer | null): boolean;
  hasEnteredComment(answer?: AssessmentAnswer | null): boolean;
}

interface QuestionConstructor {
  new (
    data: AssessmentQuestion,
    opts: QuestionInterfaceOpts
  ): AssessmentQuestionInterface;
}

class BaseQuestion implements AssessmentQuestionInterface {
  constructor(public data: AssessmentQuestion) {}

  getTitle(): ReactNode {
    return <div className="font-medium text-sm">{this.data.title}</div>;
  }

  getDescription(): ReactNode {
    return (
      !isEmptyValue(this.data.description) && (
        <TextareaWysiwyg
          className="max-w-max text-gray-400"
          value={this.data.description}
        />
      )
    );
  }

  getForm(opts: QuestionFormProps): ReactNode {
    throw new Error("Method not implemented.");
  }

  getDelivery(opts: QuestionDeliveryProps): ReactNode {
    throw new Error("Method not implemented.");
  }

  getDeliveryReadonly(opts: QuestionDeliveryReadonlyProps): ReactNode {
    throw new Error("Method not implemented.");
  }

  isQuestionAnswered(): boolean {
    throw new Error("Method not implemented.");
  }

  hasEnteredComment(answer?: AssessmentAnswer | null): boolean {
    return (
      !this.data.isCommentMandatory || !isEmptyValue(answer?.comment ?? "{}")
    );
  }
}

class RangeQuestion extends BaseQuestion {
  data: RangeAssessmentQuestion;

  constructor(data: AssessmentQuestion) {
    super(data);
    if (data.__typename !== "RangeAssessmentQuestionNode") {
      throw new Error("Invalid question type in RangeQuestion constructor");
    }
    this.data = data;
  }

  getForm({
    answer,
    onUpdateAnswer,
    formDisabled,
  }: QuestionFormProps): ReactNode {
    return (
      <RangeAssessmentQuestionContent
        disabled={formDisabled}
        answer={answer}
        question={this.data}
        onChangeAnswer={(newAnswer) => {
          onUpdateAnswer({
            questionId: this.data.id,
            ...answer,
            ...newAnswer,
            textAnswer: newAnswer.textAnswer ?? answer?.textAnswer ?? "{}",
            comment: newAnswer.comment ?? answer?.comment ?? "{}",
          });
        }}
      />
    );
  }

  getDelivery({ answer }: QuestionDeliveryProps): ReactNode {
    return (
      <RangeAssessmentQuestionContent
        disabled
        answer={answer}
        question={this.data}
      />
    );
  }

  getDeliveryReadonly({ answers }: QuestionDeliveryReadonlyProps): ReactNode {
    const commentItems = answers
      .filter((answer) => !isEmptyValue(answer.comment))
      .map((answer) => ({
        responder: answer.responder,
        text: answer.comment,
      }));
    return (
      <>
        <AssessmentQuestionRangeOptionsCombined
          answers={answers}
          startValue={this.data.startValue}
          endValue={this.data.endValue}
          labels={this.data.labels}
          labelDescriptions={this.data.labelDescriptions}
        />
        {commentItems.length > 0 && (
          <div className="mt-6">
            <AssessmentQuestionStackedTextAnswers answers={commentItems} />
          </div>
        )}
      </>
    );
  }

  isQuestionAnswered(answer?: AssessmentAnswer | null): boolean {
    return !this.data.isResponseMandatory || isInteger(answer?.integerAnswer);
  }
}
class NpsQuestion extends BaseQuestion {
  data: NpsAssessmentQuestion;

  constructor(data: AssessmentQuestion) {
    super(data);
    if (data.__typename !== "NpsAssessmentQuestionNode") {
      throw new Error("Invalid question type in NpsQuestion constructor");
    }
    this.data = data;
  }

  getForm({
    answer,
    onUpdateAnswer,
    formDisabled,
  }: QuestionFormProps): ReactNode {
    return (
      <NpsAssessmentQuestionContent
        disabled={formDisabled}
        answer={answer}
        question={this.data}
        onChangeAnswer={(newAnswer) => {
          onUpdateAnswer({
            questionId: this.data.id,
            ...answer,
            ...newAnswer,
            textAnswer: newAnswer.textAnswer ?? answer?.textAnswer ?? "{}",
            comment: newAnswer.comment ?? answer?.comment ?? "{}",
          });
        }}
      />
    );
  }

  getDelivery({ answer }: QuestionDeliveryProps): ReactNode {
    return (
      <NpsAssessmentQuestionContent
        disabled
        answer={answer}
        question={this.data}
      />
    );
  }

  getDeliveryReadonly({ answers }: QuestionDeliveryReadonlyProps): ReactNode {
    const commentItems = answers
      .filter((answer) => !isEmptyValue(answer.comment))
      .map((answer) => ({
        responder: answer.responder,
        text: answer.comment,
      }));
    return (
      <>
        <AssessmentQuestionRangeOptionsCombined
          answers={answers}
          startValue={0}
          endValue={10}
          labels={["", "", "", "", "", "", "", "", "", ""]}
          labelDescriptions={["", "", "", "", "", "", "", "", "", ""]}
        />
        {commentItems.length > 0 && (
          <div className="mt-6">
            <AssessmentQuestionStackedTextAnswers answers={commentItems} />
          </div>
        )}
      </>
    );
  }

  isQuestionAnswered(answer?: AssessmentAnswer | null): boolean {
    return !this.data.isResponseMandatory || isInteger(answer?.integerAnswer);
  }
}

class MultichoiceQuestion extends BaseQuestion {
  data: MultiChoiceAssessmentQuestion;

  constructor(data: AssessmentQuestion) {
    super(data);
    if (data.__typename !== "MultiChoiceAssessmentQuestionNode") {
      throw new Error(
        "Invalid question type in MultichoiceQuestion constructor"
      );
    }
    this.data = data;
  }

  getForm({
    answer,
    onUpdateAnswer,
    formDisabled,
  }: QuestionFormProps): ReactNode {
    return (
      <>
        <AssessmentQuestionMultiSelectOptions
          choices={answer?.choices}
          disabled={formDisabled}
          options={this.data.options}
          optionDescriptions={this.data.optionDescriptions}
          onClickAnswer={(newChoices) =>
            onUpdateAnswer({
              questionId: this.data.id,
              integerAnswer: null,
              textAnswer: answer?.textAnswer ?? "{}",
              choices: newChoices,
              comment: answer?.comment ?? "{}",
            })
          }
        />
        {answer && (!formDisabled || !isEmptyValue(answer.comment)) && (
          <div className="mt-2 flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Comment {this.data.isCommentMandatory ? "*" : ""}
              </div>
            </div>
            <TextareaWysiwyg
              editable={!formDisabled}
              className="mt-1 bg-white"
              value={answer.comment ?? "{}"}
              onChangeValue={(comment) =>
                onUpdateAnswer({
                  questionId: this.data.id,
                  integerAnswer: null,
                  textAnswer: answer.textAnswer ?? "{}",
                  choices: answer.choices,
                  comment,
                })
              }
            />
          </div>
        )}
      </>
    );
  }

  getDelivery({ answer }: QuestionDeliveryProps): ReactNode {
    return (
      <>
        <AssessmentQuestionMultiSelectOptions
          choices={answer.choices}
          disabled
          options={this.data.options}
          optionDescriptions={this.data.optionDescriptions}
        />
        {answer && !isEmptyValue(answer.comment) && (
          <div className="mt-2 flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Comment
              </div>
            </div>
            <TextareaWysiwyg
              editable={false}
              className="mt-1 bg-white"
              value={answer.comment ?? "{}"}
            />
          </div>
        )}
      </>
    );
  }

  getDeliveryReadonly({ answers }: QuestionDeliveryReadonlyProps): ReactNode {
    const commentItems = answers
      .filter((answer) => !isEmptyValue(answer.comment))
      .map((answer) => ({
        responder: answer.responder,
        text: answer.comment,
      }));
    return (
      <>
        <AssessmentQuestionMultiSelectOptionsCombined
          answers={answers}
          options={this.data.options}
          optionDescriptions={this.data.optionDescriptions}
        />
        {commentItems.length > 0 && (
          <div className="mt-6">
            <AssessmentQuestionStackedTextAnswers answers={commentItems} />
          </div>
        )}
      </>
    );
  }

  isQuestionAnswered(answer?: AssessmentAnswer | null): boolean {
    return (
      !this.data.isResponseMandatory ||
      (!!answer?.choices && answer.choices.length > 0)
    );
  }
}

class TextQuestion extends BaseQuestion {
  getForm({
    answer,
    onUpdateAnswer,
    formDisabled,
  }: QuestionFormProps): ReactNode {
    return (
      <TextAssessmentQuestionContent
        disabled={formDisabled}
        answer={answer}
        onChangeAnswer={(newAnswer) =>
          onUpdateAnswer({
            questionId: this.data.id,
            integerAnswer: null,
            choices: answer?.choices,
            textAnswer: null,
            comment: null,
            ...newAnswer,
          })
        }
      />
    );
  }

  getDelivery({ answer }: QuestionDeliveryProps): ReactNode {
    return (
      <TextareaWysiwyg
        key={answer.id}
        editable={false}
        className="bg-white"
        value={answer.textAnswer ?? "{}"}
        deps={[answer.id]}
      />
    );
  }

  getDeliveryReadonly({ answers }: QuestionDeliveryReadonlyProps): ReactNode {
    const responseItems = answers.map((answer) => ({
      responder: answer.responder,
      text: answer.textAnswer,
    }));
    return <AssessmentQuestionStackedTextAnswers answers={responseItems} />;
  }

  isQuestionAnswered(answer?: AssessmentAnswer | null): boolean {
    return (
      !this.data.isResponseMandatory ||
      !isEmptyValue(answer?.textAnswer ?? "{}")
    );
  }
}

class CompetencyQuestion extends BaseQuestion {
  data: CompetencyAssessmentQuestion;

  constructor(data: AssessmentQuestion) {
    super(data);
    if (data.__typename !== "CompetencyAssessmentQuestionNode") {
      throw new Error(
        "Invalid question type in CompetencyQuestion constructor"
      );
    }
    this.data = data;
  }

  isQuestionAnswered(answer?: AssessmentAnswer | null): boolean {
    return !this.data.isResponseMandatory || isInteger(answer?.integerAnswer);
  }
}

class CompetencyCriteriaQuestion extends BaseQuestion {
  isQuestionAnswered(answer?: AssessmentAnswer | null): boolean {
    return !this.data.isResponseMandatory || isInteger(answer?.integerAnswer);
  }
}

class ResponsibilityQuestion extends BaseQuestion {
  data: ResponsibilityAssessmentQuestion;

  constructor(data: AssessmentQuestion) {
    super(data);
    if (data.__typename !== "ResponsibilityAssessmentQuestionNode") {
      throw new Error(
        "Invalid question type in ResponsibilityQuestion constructor"
      );
    }
    this.data = data;
  }

  isQuestionAnswered(answer?: AssessmentAnswer | null): boolean {
    return !this.data.isResponseMandatory || isInteger(answer?.integerAnswer);
  }
}

class IndividualGoalQuestion extends BaseQuestion {
  data: IndividualGoalAssessmentQuestion;

  constructor(data: AssessmentQuestion) {
    super(data);
    if (data.__typename !== "IndividualGoalAssessmentQuestionNode") {
      throw new Error(
        "Invalid question type in IndividualGoalQuestion constructor"
      );
    }
    this.data = data;
  }

  getTitle(): ReactNode {
    if (!this.data.goal) {
      return null;
    }
    return (
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-1">
          <GoalIcon
            scope={this.data.goal.scope}
            size={5}
            state={this.data.goal.state}
          />
          <AppLink
            to={getUrl({
              artifactId: this.data.goal.id,
              artifactType: this.data.goal.artifactType,
            })}
            target="_blank"
            className="font-medium text-sm hover:underline"
          >
            {this.data.goal.title}{" "}
            {this.data.goal.goalVisibility === GoalVisibility.Private && (
              <GoalVisibilityIcon
                className="inline"
                goalVisibility={this.data.goal.goalVisibility}
                size="4"
              />
            )}{" "}
            {this.data.goal.state === GoalState.Draft && <DraftLabel />}
          </AppLink>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-xs text-gray-500">
            <ArtifactDueDate
              labelName="Due date"
              artifact={this.data.goal}
              canEdit={false}
            />
          </div>
          <GoalProgressPill
            progress={this.data.goal.progress}
            status={this.data.goal.status}
            state={this.data.goal.state}
            isStale={this.data.goal.isStale}
          />
        </div>
      </div>
    );
  }

  getDescription(): ReactNode {
    return null;
  }

  getForm({
    answer,
    onUpdateAnswer,
    formDisabled,
  }: QuestionFormProps): ReactNode {
    return (
      <IndividualGoalAssessmentQuestionContent
        disabled={formDisabled}
        answer={answer}
        question={this.data}
        onChangeAnswer={(newAnswer) => {
          onUpdateAnswer({
            questionId: this.data.id,
            ...answer,
            ...newAnswer,
            textAnswer: newAnswer.textAnswer ?? answer?.textAnswer ?? "{}",
            comment: newAnswer.comment ?? answer?.comment ?? "{}",
          });
        }}
      />
    );
  }

  getDelivery({ answer }: QuestionDeliveryProps): ReactNode {
    return (
      <RangeAssessmentQuestionContent
        disabled
        answer={answer}
        question={this.data}
      />
    );
  }

  getDeliveryReadonly({ answers }: QuestionDeliveryReadonlyProps): ReactNode {
    const commentItems = answers
      .filter((answer) => !isEmptyValue(answer.comment))
      .map((answer) => ({
        responder: answer.responder,
        text: answer.comment,
      }));
    return (
      <>
        <AssessmentQuestionRangeOptionsCombined
          answers={answers}
          startValue={this.data.startValue}
          endValue={this.data.endValue}
          labels={this.data.labels}
          labelDescriptions={this.data.labelDescriptions}
        />
        {commentItems.length > 0 && (
          <div className="mt-6">
            <AssessmentQuestionStackedTextAnswers answers={commentItems} />
          </div>
        )}
      </>
    );
  }

  isQuestionAnswered(answer?: AssessmentAnswer | null): boolean {
    return !this.data.isResponseMandatory || isInteger(answer?.integerAnswer);
  }
}

class OverallGoalQuestion extends BaseQuestion {
  data: OverallGoalAssessmentQuestion;
  label: UseLabelType;

  constructor(data: AssessmentQuestion, opts: QuestionInterfaceOpts) {
    super(data);
    if (data.__typename !== "OverallGoalAssessmentQuestionNode") {
      throw new Error(
        "Invalid question type in OverallGoalQuestion constructor"
      );
    }
    this.data = data;
    this.label = opts.label;
  }

  getTitle(): ReactNode {
    return (
      <div className="font-medium text-sm">
        Provide an overall assessment on{" "}
        {this.label("goal", { pluralize: true })}
      </div>
    );
  }

  getDescription(): ReactNode {
    return <OverallGoalAssessmentQuestionDescription />;
  }

  getForm({
    answer,
    onUpdateAnswer,
    formDisabled,
  }: QuestionFormProps): ReactNode {
    return (
      <OverallGoalAssessmentQuestionContent
        disabled={formDisabled}
        answer={answer}
        question={this.data}
        onChangeAnswer={(newAnswer) => {
          onUpdateAnswer({
            questionId: this.data.id,
            ...answer,
            ...newAnswer,
            textAnswer: newAnswer.textAnswer ?? answer?.textAnswer ?? "{}",
            comment: newAnswer.comment ?? answer?.comment ?? "{}",
          });
        }}
      />
    );
  }

  getDelivery({ answer }: QuestionDeliveryProps): ReactNode {
    return (
      <RangeAssessmentQuestionContent
        disabled
        answer={answer}
        question={this.data}
      />
    );
  }

  getDeliveryReadonly({ answers }: QuestionDeliveryReadonlyProps): ReactNode {
    const commentItems = answers
      .filter((answer) => !isEmptyValue(answer.comment))
      .map((answer) => ({
        responder: answer.responder,
        text: answer.comment,
      }));
    return (
      <>
        <AssessmentQuestionRangeOptionsCombined
          answers={answers}
          startValue={this.data.startValue}
          endValue={this.data.endValue}
          labels={this.data.labels}
          labelDescriptions={this.data.labelDescriptions}
        />
        {commentItems.length > 0 && (
          <div className="mt-6">
            <AssessmentQuestionStackedTextAnswers answers={commentItems} />
          </div>
        )}
      </>
    );
  }

  isQuestionAnswered(answer?: AssessmentAnswer | null): boolean {
    return !this.data.isResponseMandatory || isInteger(answer?.integerAnswer);
  }
}

const questionClasses: Record<AssessmentQuestionType, QuestionConstructor> = {
  [AssessmentQuestionType.Range]: RangeQuestion,
  [AssessmentQuestionType.Multichoice]: MultichoiceQuestion,
  [AssessmentQuestionType.Text]: TextQuestion,
  [AssessmentQuestionType.Competency]: CompetencyQuestion,
  [AssessmentQuestionType.CompetencyCriteria]: CompetencyCriteriaQuestion,
  [AssessmentQuestionType.Responsibility]: ResponsibilityQuestion,
  [AssessmentQuestionType.IndividualGoal]: IndividualGoalQuestion,
  [AssessmentQuestionType.OverallGoal]: OverallGoalQuestion,
  [AssessmentQuestionType.Nps]: NpsQuestion,
};

const questionTypenameToEnum: Record<
  AssessmentQuestion["__typename"],
  AssessmentQuestionType
> = {
  RangeAssessmentQuestionNode: AssessmentQuestionType.Range,
  MultiChoiceAssessmentQuestionNode: AssessmentQuestionType.Multichoice,
  TextAssessmentQuestionNode: AssessmentQuestionType.Text,
  CompetencyAssessmentQuestionNode: AssessmentQuestionType.Competency,
  CompetencyCriteriaAssessmentQuestionNode:
    AssessmentQuestionType.CompetencyCriteria,
  ResponsibilityAssessmentQuestionNode: AssessmentQuestionType.Responsibility,
  IndividualGoalAssessmentQuestionNode: AssessmentQuestionType.IndividualGoal,
  OverallGoalAssessmentQuestionNode: AssessmentQuestionType.OverallGoal,
  NpsAssessmentQuestionNode: AssessmentQuestionType.Nps,
};

export const getQuestionClass = (
  question: AssessmentQuestion,
  opts: QuestionInterfaceOpts
) => {
  return new questionClasses[questionTypenameToEnum[question.__typename]](
    question,
    opts
  );
};

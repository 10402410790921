import { useLazyQuery, useMutation } from "@apollo/client";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useState } from "react";
import {
  DeleteOrgWideCareerTrackMutation,
  DeleteOrgWideCareerTrackMutationVariables,
  GetOrganizationCareerTracksQuery,
  GetOrganizationCareerTracksQueryVariables,
} from "types/graphql-schema";

import deleteOrgWideCareerTrackMutation from "@apps/org-settings/graphql/delete-org-wide-career-track-mutation";
import getOrganizationCareerTracksQuery from "@apps/org-settings/graphql/get-organization-career-tracks-query";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { useNotificationError } from "@components/use-error/use-error";
import useUserComboboxQuery, {
  getCacheTeamOption,
} from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxTeamOption,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";
import useUrlQueryParams from "@helpers/hooks/use-url-query-params";

import { roleLevelIterator } from "./helpers";
import StickyColumnShadow from "./sticky-column-shadow";

const stickyColWidth = "w-80 min-w-80 max-w-80";
const roleColWidth = "w-56 max-w-56 min-w-56";
const careerTrackPageSize = 20;

const editCareerTrackUrl = (organizationId: number, competencyId: number) =>
  `/settings/organization/${organizationId}/career-track/edit/${competencyId}`;

const JobLadder = ({ organizationId }: { organizationId: number }) => {
  const label = useLabel();
  const link = useLink();
  const { onError } = useNotificationError();
  const organization = currentOrganizationVar();
  const params = useUrlQueryParams({ team: null });
  const paramTeamId = params.team ? Number(params.team) : null;
  const initialTeam = paramTeamId ? getCacheTeamOption(paramTeamId) : null;

  const [deletedTrackIds, setDeletedTrackIds] = useState<number[]>([]);

  const [selectedTeam, setSelectedTeam] = useState<UserComboboxOption | null>(
    initialTeam
      ? {
          id: initialTeam.id,
          title: initialTeam.title,
          type: UserComboboxOptionType.TEAM,
        }
      : null
  );
  const [levelCount, setLevelCount] = useState(0);

  const {
    confirm: confirmDeleteCareerTrack,
    ConfirmationDialog: ConfirmationDialogDeleteCareerTrack,
  } = useConfirm(
    `Are you sure?`,
    `Are you sure you want to remove this Career Track?`
  );

  const [fetchCareerTracks, { data, loading, fetchMore }] = useLazyQuery<
    GetOrganizationCareerTracksQuery,
    GetOrganizationCareerTracksQueryVariables
  >(getOrganizationCareerTracksQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError,
    onCompleted: (data) => {
      const tracks = assertEdgesNonNull(data.careerTracks);

      const maxLevel = tracks.reduce((max, track) => {
        const roles = assertEdgesNonNull(track.roles);
        const level = roles.reduce((roleMax, role) => {
          return Math.max(roleMax, role.level);
        }, 0);
        return Math.max(max, level);
      }, 0);
      setLevelCount(maxLevel);
    },
  });

  useEffect(() => {
    // get new career tracks if selected team changes
    fetchCareerTracks({
      variables: {
        first: careerTrackPageSize,
        organizationId,
        teamId: selectedTeam ? selectedTeam.id : null,
      },
    });
  }, [fetchCareerTracks, organizationId, selectedTeam]);

  const [deleteCareerTrack, { loading: isDeleting }] = useMutation<
    DeleteOrgWideCareerTrackMutation,
    DeleteOrgWideCareerTrackMutationVariables
  >(deleteOrgWideCareerTrackMutation);

  const handleDeleteCareerTrack = useCallback(
    async (careerTrackId: number) => {
      const confirmed = await confirmDeleteCareerTrack();
      if (confirmed) {
        deleteCareerTrack({
          variables: {
            careerTrackId,
          },
          onError,
          onCompleted: () => {
            setDeletedTrackIds((existingIds) => [
              ...existingIds,
              careerTrackId,
            ]);
            successNotificationVar({
              title: `Career Track deleted`,
            });
          },
        });
      }
    },
    [confirmDeleteCareerTrack, deleteCareerTrack, onError]
  );

  const handleViewMoreTracks = useCallback(() => {
    fetchMore({
      variables: {
        merge: true,
        first: careerTrackPageSize,
        after: data?.careerTracks.pageInfo.endCursor,
      },
    });
  }, [data, fetchMore]);

  const { options, setQuery, query } = useUserComboboxQuery({
    types: [UserComboboxOptionType.TEAM],
    selected: selectedTeam ? selectedTeam : undefined,
  });

  const careerTracks = data?.careerTracks
    ? assertEdgesNonNull(data.careerTracks).filter(
        (track) => !deletedTrackIds.includes(track.id)
      )
    : [];

  useEffect(() => {
    if (selectedTeam) {
      link.replace(
        `/settings/organization/${organization.id}/competencies?team=${selectedTeam.id}`
      );
    } else {
      link.replace(`/settings/organization/${organization.id}/competencies`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam, organization]);

  return (
    <div className="mb-6">
      <ConfirmationDialogDeleteCareerTrack />

      <div className="flex justify-between items-center mb-6">
        <div className="font-bold">Job Ladders and Roles</div>
        <div className="flex items-center gap-2">
          <div className="w-56">
            <UserCombobox
              className="flex-1"
              options={options}
              value={selectedTeam}
              onChangeValue={setSelectedTeam}
              clearable
              onClearValue={() => setSelectedTeam(null)}
              query={query}
              onChangeQuery={setQuery}
              placeholder={`All ${label("team", {
                capitalize: true,
                pluralize: true,
              })}`}
              searchPlaceholder={`Search ${label("team", {
                pluralize: true,
              })}...`}
            />
          </div>
          <Button
            text="Add Career track"
            to={`/settings/organization/${organizationId}/career-track/new${
              selectedTeam
                ? `?teamStr=${selectedTeam?.id}:${
                    (selectedTeam as UserComboboxTeamOption)?.title
                  }`
                : ""
            }`}
          />
        </div>
      </div>

      {loading && careerTracks.length === 0 && (
        <div className="flex justify-center mt-8">
          <Loading />
        </div>
      )}
      {!loading && careerTracks.length === 0 && (
        <div className="flex justify-center mt-8">
          <div className="text-gray-500">No Career Tracks yet</div>
        </div>
      )}

      {careerTracks.length > 0 && (
        <>
          <div className="m-auto w-full">
            <div className="relative overflow-x-auto">
              <table className="text-sm table-fixed">
                <thead>
                  <tr>
                    <th
                      className={classNames(
                        stickyColWidth,
                        "p-3 border border-l-0 sticky left-0 bg-white text-left"
                      )}
                    >
                      Track
                      <StickyColumnShadow />
                    </th>
                    {roleLevelIterator(levelCount).map((level) => {
                      return (
                        <th key={level} className="p-3 border text-left">
                          Level {level}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {careerTracks.map((careerTrack) => {
                    return (
                      <tr key={careerTrack.id}>
                        <td
                          className={classNames(
                            stickyColWidth,
                            "p-3 border border-l-0 font-medium sticky left-0 bg-white"
                          )}
                        >
                          <div className="flex justify-between items-center">
                            <div>
                              <AppLink
                                to={editCareerTrackUrl(
                                  organizationId,
                                  careerTrack.id
                                )}
                                className="hover:underline"
                              >
                                {careerTrack.title}
                              </AppLink>
                            </div>
                            <div className="flex items-center">
                              <Button
                                theme={buttonTheme.iconGray}
                                icon={PencilIcon}
                                size={ButtonSize.small}
                                to={editCareerTrackUrl(
                                  organizationId,
                                  careerTrack.id
                                )}
                                disabled={isDeleting}
                              />
                              <Button
                                theme={buttonTheme.iconGray}
                                icon={TrashIcon}
                                size={ButtonSize.small}
                                onClick={() => {
                                  handleDeleteCareerTrack(careerTrack.id);
                                }}
                                disabled={isDeleting}
                              />
                            </div>
                          </div>
                          <StickyColumnShadow />
                        </td>
                        {roleLevelIterator(levelCount).map((level) => {
                          const role = assertEdgesNonNull(
                            careerTrack.roles
                          ).find((role) => role.level === level);
                          return (
                            <td
                              className={classNames(roleColWidth, "p-3 border")}
                              key={level}
                            >
                              {role ? role.title : "-"}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {data?.careerTracks.pageInfo.hasNextPage && (
            <div className="flex justify-center mt-2">
              {loading ? (
                <Loading mini size="5" />
              ) : (
                <Button onClick={handleViewMoreTracks} text="View more" />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default JobLadder;

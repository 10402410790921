import moment from "moment";
import { useMemo } from "react";
import {
  AssessmentGroupAnonymity,
  AssessmentGroupDelivery,
  AssessmentGroupProviders,
  AssessmentType,
  ComplianceProgramAppliesTo,
  ComplianceProgramRecurrence,
  GetComplianceProgramQuery,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { getAssessmentTypeLabel } from "@helpers/constants";
import { assertEdgesNonNull } from "@helpers/helpers";

import ComplianceProgramRecurrenceTooltip from "./compliance-program-recurrence-tooltip";

const appliesToLabels = {
  [ComplianceProgramAppliesTo.Organization]: "Entire organization",
  [ComplianceProgramAppliesTo.Departments]: "Departments",
  [ComplianceProgramAppliesTo.Managers]: "Managers",
  [ComplianceProgramAppliesTo.Users]: "Users",
};

const ComplianceProgramReportingDetailsLabel = ({
  children,
}: {
  children: string;
}) => <div className="w-56 shrink-0 font-medium text-gray-500">{children}</div>;

const ComplianceProgramReportingAssessmentTypeDetails = ({
  assessmentTemplate,
}: {
  assessmentTemplate:
    | NonNullable<
        GetComplianceProgramQuery["complianceProgram"]
      >["peerAssessmentTemplate"]
    | NonNullable<
        GetComplianceProgramQuery["complianceProgram"]
      >["managerAssessmentTemplate"]
    | NonNullable<
        GetComplianceProgramQuery["complianceProgram"]
      >["performanceAssessmentTemplate"]
    | NonNullable<
        GetComplianceProgramQuery["complianceProgram"]
      >["engagementSurveyTemplate"];
}) => {
  if (!assessmentTemplate) return <div>N/A</div>;
  const labels = [];
  if (assessmentTemplate.anonymity === AssessmentGroupAnonymity.Anonymous) {
    labels.push("Anonymous");
  }
  if (assessmentTemplate.anonymity === AssessmentGroupAnonymity.NotAnonymous) {
    labels.push("Not anonymous");
  }
  if (assessmentTemplate.anonymity === AssessmentGroupAnonymity.SemiAnonymous) {
    labels.push("Semi-anonymous");
  }
  if (assessmentTemplate.assessmentType !== AssessmentType.EngagementSurvey) {
    if (assessmentTemplate.delivery === AssessmentGroupDelivery.AdminApproval) {
      labels.push("Admin approval");
    }
    if (assessmentTemplate.delivery === AssessmentGroupDelivery.None) {
      labels.push("No delivery");
    }
    if (assessmentTemplate.delivery === AssessmentGroupDelivery.Full) {
      labels.push("Upon submission");
    }
    if (assessmentTemplate.delivery === AssessmentGroupDelivery.Partial) {
      labels.push("Manager approval");
    }
    if (
      assessmentTemplate.providers === AssessmentGroupProviders.ManagerSelect
    ) {
      labels.push("Manager selects nominees");
    }
    if (
      assessmentTemplate.providers === AssessmentGroupProviders.SubjectSelect
    ) {
      labels.push("Subject selects nominees");
    }
  }
  return <div>{`Enabled: ${labels.join(", ")}`}</div>;
};

const ComplianceProgramReportingDetails = ({
  complianceProgram,
}: {
  complianceProgram: NonNullable<
    GetComplianceProgramQuery["complianceProgram"]
  >;
}) => {
  const label = useLabel();
  const performanceAssessmentTemplate = useMemo(
    () =>
      complianceProgram?.performanceAssessmentTemplate
        ? complianceProgram.performanceAssessmentTemplate
        : null,
    [complianceProgram]
  );
  const managerAssessmentTemplate = useMemo(
    () =>
      complianceProgram?.managerAssessmentTemplate
        ? complianceProgram.managerAssessmentTemplate
        : null,
    [complianceProgram]
  );
  const peerAssessmentTemplate = useMemo(
    () =>
      complianceProgram?.peerAssessmentTemplate
        ? complianceProgram.peerAssessmentTemplate
        : null,
    [complianceProgram]
  );
  const engagementSurveyTemplate = useMemo(
    () =>
      complianceProgram?.engagementSurveyTemplate
        ? complianceProgram.engagementSurveyTemplate
        : null,
    [complianceProgram]
  );
  const topicTemplate = useMemo(
    () =>
      complianceProgram?.requiredTopicTemplates
        ? assertEdgesNonNull(complianceProgram.requiredTopicTemplates)[0]
        : null,
    [complianceProgram]
  );

  const excludedUsers = assertEdgesNonNull(complianceProgram.excludedUsers);

  return (
    <div className="flex flex-col text-sm gap-2 text-gray-700">
      {!complianceProgram.ongoing && (
        <>
          <div className="flex items-center gap-2">
            <ComplianceProgramReportingDetailsLabel>
              Start date:
            </ComplianceProgramReportingDetailsLabel>
            <div>
              {moment(complianceProgram.startDate).format("MMM D, YYYY")}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <ComplianceProgramReportingDetailsLabel>
              Due date:
            </ComplianceProgramReportingDetailsLabel>
            <div className="flex items-center gap-2">
              {moment(complianceProgram.dueDate).format("MMM D, YYYY")}
              {complianceProgram.recurrence !==
                ComplianceProgramRecurrence.None && (
                <ComplianceProgramRecurrenceTooltip
                  recurrence={complianceProgram.recurrence}
                />
              )}
            </div>
          </div>
          {!engagementSurveyTemplate && (
            <div className="flex items-center gap-2">
              <ComplianceProgramReportingDetailsLabel>
                Program period:
              </ComplianceProgramReportingDetailsLabel>
              <div className="flex items-center gap-2">
                {complianceProgram.periodStartDate &&
                complianceProgram.periodEndDate
                  ? `${moment(complianceProgram.periodStartDate).format(
                      "MMM D, YYYY"
                    )} - ${moment(complianceProgram.periodEndDate).format(
                      "MMM D, YYYY"
                    )}`
                  : "Not set"}
              </div>
            </div>
          )}
        </>
      )}
      {complianceProgram.ongoing && (
        <>
          <div className="flex items-center gap-2">
            <ComplianceProgramReportingDetailsLabel>
              Starts (days after start date):
            </ComplianceProgramReportingDetailsLabel>
            <div>{complianceProgram.startDateOffset}</div>
          </div>
          <div className="flex items-center gap-2">
            <ComplianceProgramReportingDetailsLabel>
              Due (days after start date):
            </ComplianceProgramReportingDetailsLabel>
            <div>{complianceProgram.startDateDueDateOffset}</div>
          </div>
          {!engagementSurveyTemplate && (
            <div className="flex items-center gap-2">
              <ComplianceProgramReportingDetailsLabel>
                Program period:
              </ComplianceProgramReportingDetailsLabel>
              <div>
                {complianceProgram.startDatePeriodStartOffset} -{" "}
                {complianceProgram.startDatePeriodEndOffset} days after start
                date
              </div>
            </div>
          )}
        </>
      )}

      {!engagementSurveyTemplate && (
        <>
          <div className="flex items-center gap-2">
            <ComplianceProgramReportingDetailsLabel>
              Meeting template:
            </ComplianceProgramReportingDetailsLabel>
            <div>{topicTemplate ? topicTemplate.title : "Not set"}</div>
          </div>
          <div className="flex items-center gap-2">
            <ComplianceProgramReportingDetailsLabel>
              {`${getAssessmentTypeLabel(AssessmentType.Performance)} ${label(
                "review",
                { capitalize: true }
              )}: `}
            </ComplianceProgramReportingDetailsLabel>
            <ComplianceProgramReportingAssessmentTypeDetails
              assessmentTemplate={performanceAssessmentTemplate}
            />
          </div>
          <div className="flex items-center gap-2">
            <ComplianceProgramReportingDetailsLabel>
              {`${getAssessmentTypeLabel(AssessmentType.Manager)} ${label(
                "review",
                {
                  capitalize: true,
                }
              )}: `}
            </ComplianceProgramReportingDetailsLabel>

            <ComplianceProgramReportingAssessmentTypeDetails
              assessmentTemplate={managerAssessmentTemplate}
            />
          </div>
          <div className="flex items-center gap-2">
            <ComplianceProgramReportingDetailsLabel>
              {`${getAssessmentTypeLabel(AssessmentType.Peer)} ${label(
                "review",
                {
                  capitalize: true,
                }
              )}: `}
            </ComplianceProgramReportingDetailsLabel>
            <ComplianceProgramReportingAssessmentTypeDetails
              assessmentTemplate={peerAssessmentTemplate}
            />
          </div>
        </>
      )}

      {engagementSurveyTemplate && (
        <div className="flex items-center gap-2">
          <ComplianceProgramReportingDetailsLabel>
            {`${getAssessmentTypeLabel(AssessmentType.EngagementSurvey)}:`}
          </ComplianceProgramReportingDetailsLabel>
          <ComplianceProgramReportingAssessmentTypeDetails
            assessmentTemplate={engagementSurveyTemplate}
          />
        </div>
      )}

      <div className="flex items-center gap-2">
        <ComplianceProgramReportingDetailsLabel>
          Applies to:
        </ComplianceProgramReportingDetailsLabel>
        <div>
          {appliesToLabels[complianceProgram.appliesTo]}
          {complianceProgram.appliesTo ===
            ComplianceProgramAppliesTo.Managers && (
            <span>
              {" "}
              (
              {complianceProgram.appliesToManagers.edges
                .map((edge) => edge?.node?.name)
                .join(", ")}
              )
            </span>
          )}
          {complianceProgram.appliesTo === ComplianceProgramAppliesTo.Users && (
            <span>
              {" "}
              (
              {complianceProgram.appliesToUsers.edges
                .map((edge) => edge?.node?.name)
                .join(", ")}
              )
            </span>
          )}
          {complianceProgram.appliesTo ===
            ComplianceProgramAppliesTo.Departments && (
            <span>
              {" "}
              (
              {complianceProgram.appliesToTeams.edges
                .map((edge) => edge?.node?.title)
                .join(", ")}
              )
            </span>
          )}
        </div>
      </div>
      {(excludedUsers.length > 0 ||
        complianceProgram.excludedHireDateAfter) && (
        <div className="flex items-center gap-2">
          <ComplianceProgramReportingDetailsLabel>
            Excluded:
          </ComplianceProgramReportingDetailsLabel>
          <div>
            {`${excludedUsers.map((user) => user.name).join(", ")}${
              complianceProgram.excludedHireDateAfter &&
              excludedUsers.length > 0
                ? ", "
                : ""
            }`}
            {complianceProgram.excludedHireDateAfter
              ? `Users hired after ${moment(
                  complianceProgram.excludedHireDateAfter
                ).format("MMM D, YYYY")}`
              : ""}
          </div>
        </div>
      )}
    </div>
  );
};

export default ComplianceProgramReportingDetails;

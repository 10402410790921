import { Draggable } from "@hello-pangea/dnd";
import {
  AdjustmentsIcon,
  LockClosedIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { useMemo, useState } from "react";
import { MdOutlineDragIndicator } from "react-icons/md";
import {
  AssessmentQuestionFragmentFragment,
  AssessmentQuestionResponseVisibility,
  AssessmentQuestionResponses,
  Maybe,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Button, { buttonTheme } from "@components/button/button";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { assertEdgesNonNull } from "@helpers/helpers";
import { capitalize, titleCase } from "@helpers/string";

import AssessmentQuestionMultiSelectOptions from "./assessment-question-multi-select-options";
import AssessmentQuestionRangeOptions from "./assessment-question-range-options";
import NewAssessmentQuestionModal from "./new-assessment-question-modal";

type Props = {
  canUpdate: boolean;
  questionId: Maybe<number>;
  index: number;
  availableQuestions: AssessmentQuestionFragmentFragment[];
  selectedQuestionIds: Maybe<number>[];
  onChangeQuestionId: (questionId: number) => void;
  onDelete: () => void;
  onNewQuestionCreated: (question: AssessmentQuestionFragmentFragment) => void;
};
const AssessmentGroupQuestionItem: React.FC<Props> = ({
  canUpdate,
  questionId,
  index,
  availableQuestions,
  selectedQuestionIds,
  onChangeQuestionId,
  onDelete,
  onNewQuestionCreated,
}) => {
  const label = useLabel();
  const [isHover, setIsHover] = useState(false);
  const [isAddingNewQuestion, setIsAddingNewQuestion] = useState(false);

  const availableQuestionOptions: ComboboxGenericOption<number>[] = useMemo(
    () =>
      availableQuestions
        .map(({ id, title, questionType, categories }) => {
          const categoryNames = assertEdgesNonNull(categories)
            .map(({ title }) => title)
            .join(", ");
          return {
            value: id,
            label: title,
            description: `${titleCase(questionType.replace(/_*/, " "))}${
              categoryNames ? ` - ${categoryNames}` : ""
            }`,
          };
        })
        .filter((opt) => {
          return (
            questionId === opt.value || !selectedQuestionIds.includes(opt.value)
          );
        }),
    [availableQuestions, questionId, selectedQuestionIds]
  );

  const selectedQuestion = useMemo(
    () => availableQuestions.find(({ id }) => id === questionId) ?? null,
    [availableQuestions, questionId]
  );

  return (
    <Draggable
      draggableId={`${questionId}`}
      index={index}
      isDragDisabled={!canUpdate}
    >
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="px-2 py-4 flex gap-2 relative bg-white border-b last:border-none"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {isAddingNewQuestion && (
            <NewAssessmentQuestionModal
              onClose={() => setIsAddingNewQuestion(false)}
              onNewQuestionCreated={onNewQuestionCreated}
            />
          )}
          <div className="flex-1">
            <div
              className="flex gap-2 items-center"
              {...provided.dragHandleProps}
            >
              {isHover && canUpdate && (
                <div className="cursor-grab absolute top-6 -left-4">
                  <MdOutlineDragIndicator className="h-5 w-5 text-gray-400" />
                </div>
              )}
              <AdjustmentsIcon className="h-5 w-5 text-gray-500" />
              <div className="flex gap-4 items-center">
                <ComboboxGeneric
                  className="w-48 sm:w-96"
                  placeholder="Select question"
                  disabled={!canUpdate}
                  options={availableQuestionOptions}
                  truncateOptionDescription
                  onChangeValue={({ value }) =>
                    value !== null && onChangeQuestionId(value)
                  }
                  value={
                    availableQuestionOptions.find(
                      ({ value }) => value === questionId
                    ) ?? null
                  }
                />
                {canUpdate && !selectedQuestion && (
                  <div>
                    or
                    <Button
                      className="ml-2"
                      theme={buttonTheme.text}
                      onClick={() => setIsAddingNewQuestion(true)}
                      text="Add new"
                    />
                  </div>
                )}
                {selectedQuestion &&
                  selectedQuestion.responses ===
                    AssessmentQuestionResponses.ExcludeSelfAssessment && (
                    <div className="bg-gray-200 rounded-lg px-3 py-2 text-xs text-gray-500">
                      Subject does not answer
                    </div>
                  )}
                {selectedQuestion &&
                  selectedQuestion.responses ===
                    AssessmentQuestionResponses.SelfAssessmentOnly && (
                    <div className="bg-gray-200 rounded-lg px-3 py-2 text-xs text-gray-500">
                      Self {label("review")} only
                    </div>
                  )}
                {selectedQuestion &&
                  selectedQuestion.responseVisibility ===
                    AssessmentQuestionResponseVisibility.HiddenFromSubject && (
                    <div className="bg-gray-200 rounded-lg px-3 py-2 text-xs text-gray-500 flex items-center gap-1.5">
                      <LockClosedIcon className="h-4 w-4" />
                      Responses not visible to subject
                    </div>
                  )}
              </div>
            </div>
            {selectedQuestion && (
              <div className="flex flex-col gap-2 pl-8 mt-4">
                {!isEmptyValue(selectedQuestion.description) && (
                  <TextareaWysiwyg
                    editable={false}
                    value={selectedQuestion.description}
                  />
                )}
                <div className="text-gray-500 text-xs uppercase font-semibold mb-2">
                  {`${capitalize(selectedQuestion.questionType)} question`}
                </div>
                {selectedQuestion.__typename ===
                  "RangeAssessmentQuestionNode" && (
                  <AssessmentQuestionRangeOptions
                    labels={selectedQuestion.labels}
                    labelDescriptions={selectedQuestion.labelDescriptions}
                    startValue={selectedQuestion.startValue}
                    endValue={selectedQuestion.endValue}
                    disabled
                  />
                )}
                {selectedQuestion.__typename ===
                  "MultiChoiceAssessmentQuestionNode" && (
                  <AssessmentQuestionMultiSelectOptions
                    options={selectedQuestion.options}
                    optionDescriptions={selectedQuestion.optionDescriptions}
                    disabled
                  />
                )}
              </div>
            )}
          </div>
          {canUpdate && (
            <div>
              <button
                className="text-gray-400 px-2 hover:text-rose-600"
                onClick={onDelete}
                aria-label="Delete template topic"
              >
                <TrashIcon className="w-4 h-4" />
              </button>
            </div>
          )}
        </li>
      )}
    </Draggable>
  );
};

export default AssessmentGroupQuestionItem;

import { useMutation } from "@apollo/client";
import { LightBulbIcon } from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";
import { Editor } from "@tiptap/react";
import { uniqueId } from "lodash";
import uniqBy from "lodash/uniqBy";
import moment from "moment";
import {
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  useRef,
  useState,
} from "react";
import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import MeetingEmptyPlaceholder from "@apps/meeting-new/components/meeting/empty-placeholder";
import SuggestedTopicsSidebar, {
  suggestedTopicsTabIds,
} from "@apps/suggested-topics-sidebar/suggested-topics-sidebar";
import { currentUserVar } from "@cache/cache";
import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import GraphqlError from "@components/error/graphql-error";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useMountedState from "@components/use-mounted-state/use-mounted-state";
import { delay, tempCacheIdPrefix, topicState } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { isEnterEvent } from "@helpers/helpers";

import createOrUpdateTopicMutation from "../graphql/create-or-update-topic-mutation";
import TopicNodeFragment from "../graphql/topic-node-fragment";

const TopicCreateForm = ({
  meetingGroup,
  meeting,
  afterTopicId,
  beforeTopicId,
  onCreated,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  meetingGroup: MeetingViewMeetingNodeNewPageFragmentFragment["meetingGroup"];
  afterTopicId?: number;
  beforeTopicId?: number;
  onCreated?: () => void;
}) => {
  const [showSuggestedTopicSidebar, setShowSuggestedTopicSidebar] =
    useState<suggestedTopicsTabIds | null>(null);

  const currentUser = currentUserVar();
  const isMounted = useMountedState();
  const [inputValue, setInputValue] = useState("");
  const trimmedInputValue = inputValue.trim();
  const createTopicInputRef = useRef<HTMLInputElement | null>(null);
  const [createTopic, { error, loading: createTopicLoading }] = useMutation(
    createOrUpdateTopicMutation
  );

  const focusOnInput = () => {
    if (createTopicInputRef?.current) {
      createTopicInputRef.current.focus();
    }
  };

  const handleSubmitForm = (e: FormEvent) => {
    e?.preventDefault();
  };

  const handleClickAddTopic = (e: MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    if (trimmedInputValue.length === 0) {
      focusOnInput();
      return;
    }
    handleCreateTopic(inputValue);
    setInputValue("");
  };

  const handleCreateTopic = (
    topicName: string,
    fieldToFocusOn = ".js-topic-discussion-notes-input"
  ) => {
    if (createTopicLoading) {
      return;
    }
    const tempId = uniqueId(`${tempCacheIdPrefix}-topic`);
    const topicData = {
      id: tempId,
      title: topicName.trim(),
      description: "",
      includesIndividualNotes: false,
      includesIndividualNotesForCurrentUser: false,
      includesSharedNotes: true,
      discussionNotes: null,
      isMandatory: false,
      canUpdate: {
        __typename: "PermissionNode",
        permission: true,
      },
      canDelete: {
        __typename: "PermissionNode",
        permission: true,
      },
      created: moment().format(),
      state: topicState.open,
      eventChannelName: `topic-${tempId}`,
      creator: currentUser,
      assignee: currentUser,
      previousTopic: null,
      linkedTemplateTopic: null,
      notesRequirement: null,
      comments: {
        totalCount: 0,
        __typename: "CommentNodeConnection",
      },
      individualNotes: {
        edges: [],
        __typename: "IndividualNoteNodeConnection",
      },
      copiedFrom: null,
      __typename: "TopicNode",
    };
    return createTopic({
      variables: {
        meetingId: meeting.id,
        meetingGroupId: meetingGroup?.id,
        title: topicName.trim(),
        afterTopicId,
        beforeTopicId,
      },
      optimisticResponse: {
        createOrUpdateTopic: {
          topic: topicData,
          __typename: "CreateOrUpdateTopicMutation",
        },
      },
      onError: onNotificationErrorHandler(),
      onCompleted: ({ createOrUpdateTopic }) => {
        if (isMounted()) {
          setInputValue("");
        }

        // focus on text editor
        if (!fieldToFocusOn) return;
        setTimeout(() => {
          const fieldToFocusOnEl = document.querySelector(
            `#meeting-${meeting.id}-topic-${createOrUpdateTopic.topic.id} ${fieldToFocusOn}`
          ) as Element & { editor?: Editor };
          if (fieldToFocusOnEl?.editor) {
            fieldToFocusOnEl.editor.chain().focus("all").run();
          }
        }, delay.waitTillFocus);
      },
      update(cache, response) {
        const { topic } = response.data.createOrUpdateTopic;
        // Cache topic node
        const createdTopicCacheNode = cache.writeFragment({
          data: topic,
          fragment: TopicNodeFragment,
          fragmentName: "TopicNodeNewPageFragment",
          variables: { meetingGroupId: meetingGroup?.id },
        });
        // Add topic to the list
        const meetingCacheId = cache.identify(meeting);
        cache.modify({
          id: meetingCacheId,
          fields: {
            [`topics`](cachedTopics) {
              const afterTopicRef = cache.identify({
                __typename: "TopicNode",
                id: afterTopicId,
              });
              const beforeTopicRef = cache.identify({
                __typename: "TopicNode",
                id: beforeTopicId,
              });
              const mergedEdges = afterTopicId
                ? cachedTopics.edges.reduce((memo: any[], cachedTopic: any) => {
                    if (cachedTopic.node.__ref === afterTopicRef) {
                      return [
                        ...memo,
                        cachedTopic,
                        {
                          node: createdTopicCacheNode,
                          __typename: "TopicNodeEdge",
                        },
                      ];
                    }
                    return [...memo, cachedTopic];
                  }, [])
                : beforeTopicId
                ? cachedTopics.edges.reduce((memo: any[], cachedTopic: any) => {
                    if (cachedTopic.node.__ref === beforeTopicRef) {
                      return [
                        ...memo,
                        {
                          node: createdTopicCacheNode,
                          __typename: "TopicNodeEdge",
                        },
                        cachedTopic,
                      ];
                    }
                    return [...memo, cachedTopic];
                  }, [])
                : [
                    ...cachedTopics.edges,
                    {
                      node: createdTopicCacheNode,
                      __typename: "TopicNodeEdge",
                    },
                  ];
              const uniqueEdges = uniqBy(mergedEdges, ({ node }) => node.__ref);
              return { edges: uniqueEdges };
            },
          },
        });

        if (onCreated) onCreated();
      },
    });
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyDownInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (isEnterEvent(event)) {
      handleCreateTopic(inputValue);
    }
  };

  // if no topic on meeting, then focus on create input
  const meetingHasNoTopics = (meeting?.topics?.edges || []).length === 0;

  // We prevent user from adding new topics when meeting is finalized
  if (meeting.isFinalized) {
    return null;
  }

  return (
    <div className="flex flex-col">
      {meetingHasNoTopics && <MeetingEmptyPlaceholder />}
      <div className={classNames("border-b")}>
        {showSuggestedTopicSidebar !== null && (
          <SuggestedTopicsSidebar
            meeting={meeting}
            defaultTabId={showSuggestedTopicSidebar}
            onClose={() => setShowSuggestedTopicSidebar(null)}
          />
        )}
        {error ? (
          <GraphqlError
            error={error}
            whatDidNotWork="The topic could not be created."
          />
        ) : (
          <form
            onSubmit={handleSubmitForm}
            className={classNames(
              "z-dropdown flex group items-center @container/topic-create-form py-4 gap-1.5"
            )}
            data-testid={`meeting-${meeting.id}`}
          >
            <div className="flex-1 flex items-center text-base text-left justify-between relative">
              <input
                ref={createTopicInputRef}
                className={classNames(
                  "w-full border-2 px-3 py-1 relative rounded-lg tracking-tight text-gray-800 text-lg placeholder:text-slate-400"
                )}
                autoComplete="off"
                placeholder="Type new topic..."
                aria-label="Create topic input"
                value={inputValue}
                disabled={createTopicLoading}
                onChange={handleChangeInput}
                onKeyDown={handleKeyDownInput}
              />
            </div>
            <>
              <Button
                theme={buttonTheme.primary}
                className="gap-1 tracking-tight"
                onClick={handleClickAddTopic}
                type="button"
                size={ButtonSize.large}
                aria-label="Add topic submit button"
              >
                <PlusIcon className="w-4 h-4" />
                <span className="hidden @xl/topic-create-form:inline">
                  Add Topic
                </span>
              </Button>
              <Button
                size={ButtonSize.large}
                className="gap-1 tracking-tight"
                type="button"
                onClick={() =>
                  setShowSuggestedTopicSidebar(suggestedTopicsTabIds.pastTopics)
                }
              >
                <LightBulbIcon className="w-4 h-4" />
                <span className="hidden @xl/topic-create-form:inline">
                  Suggestions
                </span>
              </Button>
            </>
          </form>
        )}
      </div>
    </div>
  );
};

export default TopicCreateForm;

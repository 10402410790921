import { gql } from "@apollo/client";

import { AssessmentGroupFragment } from "@apps/assessments/graphql/get-assessment-groups-query";

export default gql`
  ${AssessmentGroupFragment}
  mutation saveFullAssessmentGroup(
    $title: String
    $assessmentGroupId: Int
    $organizationId: Int
    $sections: [FullAssessmentGroupSectionInput]!
  ) {
    createOrUpdateFullAssessmentGroup(
      organizationId: $organizationId
      assessmentGroupId: $assessmentGroupId
      title: $title
      sections: $sections
    ) {
      assessmentGroup {
        ...AssessmentGroupFragment
      }
    }
  }
`;

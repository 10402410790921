import { useQuery } from "@apollo/client";
import a from "indefinite";
import { compact } from "lodash";
import { useState } from "react";
import {
  GoalScope,
  SearchGoalsToAlignQueryQuery,
  SearchGoalsToAlignQueryQueryVariables,
} from "types/graphql-schema";

import GoalAlignmentPickerDialogWithExistingGoal from "@apps/goal-alignment-dialog-picker/goal-alignment-dialog-picker-with-existing-goal";
import useLabel from "@apps/use-label/use-label";
import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import GoalIcon from "@components/goal-icon/goal-icon";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import searchGoalsToAlignQuery from "./graphql/search-goals-to-align-query";

const indexedGoalScopes = [
  GoalScope.Organization,
  GoalScope.Team,
  GoalScope.Personal,
];

const ArtifactSidebarGoalPickerCombobox = ({
  alignmentType = "parent",
  goalId,
  goalScope,
  parentGoalId,
  childGoalIds,
  onSelectGoal,
}: {
  goalId?: number;
  goalScope: GoalScope;
  alignmentType: "child" | "parent";
  onSelectGoal: (goal: ComboboxGenericOption<number>) => void;
  parentGoalId?: number;
  childGoalIds: number[];
}) => {
  const label = useLabel();
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [query, setQuery] = useState("");
  const search = useDebounce(query, 300);

  const relatedGoalIds = compact([parentGoalId].concat(childGoalIds));
  const indexOfCurrentGoal = indexedGoalScopes.findIndex(
    (scope) => scope === goalScope
  );
  const goalScopes =
    alignmentType === "parent"
      ? indexedGoalScopes.slice(0, indexOfCurrentGoal + 1)
      : indexedGoalScopes.slice(indexOfCurrentGoal);
  const { data, loading } = useQuery<
    SearchGoalsToAlignQueryQuery,
    SearchGoalsToAlignQueryQueryVariables
  >(searchGoalsToAlignQuery, {
    variables: {
      goalScopes: goalScopes,
      excludeIds: compact([goalId, ...relatedGoalIds]),
      goalParentIds: alignmentType === "child" ? [] : undefined,
      search: search,
    },
    onError: onNotificationErrorHandler(),
  });
  const goals = data?.artifacts ? assertEdgesNonNull(data.artifacts) : [];
  const goalOptions = goals.map((goal) => {
    if (goal.__typename === "GoalArtifactNode" && goal.title) {
      return {
        value: goal.id,
        label: goal.title,
        htmlLabel: (
          <div className="flex items-center gap-1">
            <GoalIcon scope={goal.scope} state={goal.state} /> {goal.title}
          </div>
        ),
      };
    }
    return null;
  });
  const options = compact(goalOptions);

  const hasAlreadyAParent = alignmentType === "parent" && !!parentGoalId;
  return waffle.flag_is_active("new-goal-alignment-picker") && goalId ? (
    <>
      {isPickerOpen && referenceElement && (
        <GoalAlignmentPickerDialogWithExistingGoal
          artifactId={goalId}
          alignmentType={alignmentType}
          externalReferenceElement={referenceElement}
          onClose={() => setIsPickerOpen(false)}
        />
      )}
      <Button
        ref={setReferenceElement}
        theme={buttonTheme.lightBlue}
        size={ButtonSize.small}
        disabled={hasAlreadyAParent}
        text={`Select ${a(label("goal"))}`}
      />
    </>
  ) : (
    <ComboboxGeneric<number>
      buttonClassName="px-2 py-1"
      loading={loading}
      options={options}
      value={null}
      placeholder={`Select ${a(label("goal"))}...`}
      onChangeValue={onSelectGoal}
      query={query}
      setQuery={setQuery}
      disabled={hasAlreadyAParent}
    />
  );
};

export default ArtifactSidebarGoalPickerCombobox;

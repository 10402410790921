import moment from "moment";
import { useMemo } from "react";
import { MdGridView } from "react-icons/md";
import { TbInputCheck } from "react-icons/tb";
import { GetDeliveredEngagementSurveyQuery } from "types/graphql-schema";

import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import AppLink from "@components/link/link";
import { classNames } from "@helpers/css";

const EngagementSurveyDeliveryHeader = ({
  assessmentDeliveryData,
}: {
  assessmentDeliveryData?: GetDeliveredEngagementSurveyQuery;
}) => {
  const assessmentDelivery = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery
        : null,
    [assessmentDeliveryData]
  );
  const program = useMemo(
    () => (assessmentDelivery ? assessmentDelivery.complianceProgram : null),
    [assessmentDelivery]
  );
  const canReadProgramDetails = useMemo(
    () => program?.canReadDetails.permission,
    [program]
  );

  if (!assessmentDeliveryData) {
    return null;
  }

  const leftCellClassName = "py-2 font-medium w-[200px]";
  const rightCellClassName = "py-2";

  return (
    <div className="text-sm p-6 mb-12 border-b">
      <div className="flex flex-col gap-6 w-full mx-auto max-w-screen-lg">
        <div className="flex items-center gap-2 justify-between">
          <div className="flex items-center gap-2">
            <TbInputCheck className="shrink-0 w-8 h-8" />
            <AppLink
              to={`/survey/delivery/${assessmentDelivery?.id}`}
              className="text-2xl font-bold text-gray-800 hover:underline"
            >{`Survey: ${program?.title}`}</AppLink>
          </div>
        </div>

        <div>
          <Layout.MainSubSection
            title="Survey Details"
            collapsible
            defaultIsExpanded
          >
            <table className="text-gray-600 w-full">
              <tbody>
                <tr>
                  <td className={leftCellClassName}>Program</td>
                  {canReadProgramDetails ? (
                    <td className={rightCellClassName}>
                      <Button
                        to={`/programs/${program?.id}`}
                        theme={buttonTheme.text}
                        leftNegativeMargin
                      >
                        <MdGridView className="shrink-0 w-4 h-4" />
                        {program?.title}
                      </Button>
                    </td>
                  ) : (
                    <td
                      className={classNames(
                        rightCellClassName,
                        "flex items-center gap-1"
                      )}
                    >
                      <MdGridView className="shrink-0 w-4 h-4 text-gray-400" />
                      {program?.title}
                    </td>
                  )}
                </tr>

                <tr>
                  <td className={leftCellClassName}>Due date</td>
                  <td className={rightCellClassName}>
                    {moment(assessmentDelivery?.dueDate).format("MMM D, YYYY")}
                  </td>
                </tr>
              </tbody>
            </table>
          </Layout.MainSubSection>
        </div>
      </div>
    </div>
  );
};

export default EngagementSurveyDeliveryHeader;

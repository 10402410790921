import { gql } from "@apollo/client";

const CommonGoalArtifactFragment = gql`
  fragment CommonGoalArtifactFragment on GoalArtifactNode {
    id
    artifactType
    startValue
    currentValue
    targetValue
    progress
    progressType
    dueDate
    startDate
    state
    status
    scope
    goalVisibility
    created
    parentGoalId
    firstOwner {
      id
      avatar
      name
    }
    ownerCount
    contributorCount
    canDelete {
      permission
    }
    canUpdate {
      permission
    }
    canRead {
      permission
    }
    isStale
    title

    updated
    creator {
      id
      name
      avatar
    }
  }
`;

export default CommonGoalArtifactFragment;

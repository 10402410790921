import { ReactNode } from "react";

import SelectOrCreate1on1Button from "@apps/meeting-dialog/select-or-create-1-on-1-button";
import { currentUserVar } from "@cache/cache";

import {
  ComplianceProgramActionOneononeItemProps,
  UserRelationEnum,
} from "../helpers";
import ComplianceProgramActionItem from "./compliance-program-action-item";

const ComplianceProgramActionItemFor1on1 = ({
  item,
  children,
}: {
  item: ComplianceProgramActionOneononeItemProps;
  children?: ReactNode | null;
}) => {
  const { key, buttonProps, ...extendedItem } = item;

  const currentUser = currentUserVar();
  const facilitator =
    item.userRelation === UserRelationEnum.manager && item.user?.id
      ? item.user
      : currentUser;

  const button = (
    <SelectOrCreate1on1Button
      {...extendedItem}
      target={item.user}
      buttonProps={buttonProps}
      facilitator={facilitator}
    />
  );

  return (
    <>
      <ComplianceProgramActionItem {...extendedItem} button={button}>
        {children}
      </ComplianceProgramActionItem>
    </>
  );
};

export default ComplianceProgramActionItemFor1on1;

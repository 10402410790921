import { InformationCircleIcon, PencilIcon } from "@heroicons/react/outline";
import { useState } from "react";
import {
  CareerTrackRoleType,
  CompetencyAppliesTo,
  CompetencyCriteriaUniqueness,
  CompetencyNode,
  OrganizationCompetencyCriteriaFragment,
} from "types/graphql-schema";

import { competencyInfo } from "@apps/org-settings/constants";
import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import CompetencyFormModal from "../competency-form-modal";
import CriteriaEditor from "../criteria-editor";
import { roleColWidth, roleLevelIterator, stickyColWidth } from "../helpers";
import StickyColumnShadow from "../sticky-column-shadow";
import { CareerTrackFormRole } from "./career-track-form";
import EmptyCriteriaCell from "./empty-criteria-cell";

const CareerTrackCompetencyRow = ({
  organizationId,
  competency,
  levelCount,
  roles,
  onDeleteCriteria,
  onCriteriaChange,
  onCompetenciesUpdated,
}: {
  organizationId: number;
  competency: Pick<
    CompetencyNode,
    | "id"
    | "title"
    | "criteriaUniqueness"
    | "appliesTo"
    | "appliesToIcRoles"
    | "appliesToManagementRoles"
  > &
    OrganizationCompetencyCriteriaFragment;
  levelCount: number;
  roles: CareerTrackFormRole[];
  onDeleteCriteria: (roleUuid: string, criteriaUuid: string) => void;
  onCriteriaChange: (
    roleUuid: string,
    level: number,
    items: ReadonlyArray<{
      uuid?: string;
      content?: string | null;
    }>
  ) => void;
  onCompetenciesUpdated: () => void;
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  return (
    <>
      {isEditModalOpen && (
        <CompetencyFormModal
          organizationId={organizationId}
          competencyId={competency.id}
          onClose={() => setIsEditModalOpen(false)}
          onCompetencySaved={onCompetenciesUpdated}
        />
      )}
      <tr key={competency.id} className="h-52 align-top">
        <td
          className={classNames(
            stickyColWidth,
            "p-4 border border-l-0 sticky left-0 bg-white z-10"
          )}
        >
          <div className="flex justify-between items-start">
            <div className="font-medium mt-1">{competency.title}</div>
            <Button
              theme={buttonTheme.iconGray}
              icon={PencilIcon}
              onClick={() => setIsEditModalOpen(true)}
              size={ButtonSize.small}
            />
          </div>
          <div className="flex justify-between gap-2 bg-gray-100 mt-2 p-2 text-xs rounded-md">
            <div className="w-5/6">{competencyInfo(competency)}</div>
            <InformationCircleIcon className="text-gray-400 h-4 w-4" />
          </div>

          <StickyColumnShadow />
        </td>
        {roleLevelIterator(levelCount).map((level) => {
          const role = roles.find((role) => role.level === level);

          if (!role) {
            return <EmptyCriteriaCell key={level} />;
          }

          const matchesRoleType =
            competency.appliesTo === CompetencyAppliesTo.AllRoles ||
            (competency.appliesToIcRoles &&
              role.roleType === CareerTrackRoleType.Ic) ||
            (competency.appliesToManagementRoles &&
              role.roleType === CareerTrackRoleType.Management);
          const criteria = role
            ? competency.criteriaUniqueness ===
                CompetencyCriteriaUniqueness.SameAcrossRoles && matchesRoleType
              ? assertEdgesNonNull(competency.criteria).filter(
                  (criteria) => criteria.level === level
                )
              : role.criteriaByCompetencyId[competency.id] ?? []
            : [];

          return (
            <td className={classNames(roleColWidth, "p-4 border")} key={level}>
              <CriteriaEditor
                criteria={criteria}
                matchesRoleType={matchesRoleType}
                editable={
                  competency.criteriaUniqueness ===
                    CompetencyCriteriaUniqueness.DifferentForEachRole &&
                  matchesRoleType
                }
                onAddCriteria={() =>
                  onCriteriaChange(role.uuid, level, [{ content: "" }])
                }
                onPasteMultipleCriteria={(items) => {
                  onCriteriaChange(role.uuid, level, items);
                }}
                onDeleteCriteria={(uuid) => onDeleteCriteria(role.uuid, uuid)}
                onCriteriaChange={(uuid, content) => {
                  onCriteriaChange(role.uuid, level, [{ uuid, content }]);
                }}
              />
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default CareerTrackCompetencyRow;

import { TrashIcon } from "@heroicons/react/outline";
import { PropsWithChildren } from "react";

import Button, { buttonTheme } from "@components/button/button";

const ComplianceProgramFormStep = ({
  title,
  canDelete,
  onDelete,
  children,
}: PropsWithChildren<{
  title: string;
  canDelete: boolean;
  onDelete: () => void;
}>) => {
  return (
    <div className="border rounded bg-gray-50">
      <div className="py-4 px-6 border-b flex items-center justify-between">
        <div className="text-lg font-semibold">{title}</div>
        <Button
          disabled={!canDelete}
          icon={TrashIcon}
          theme={buttonTheme.iconGray}
          onClick={onDelete}
        />
      </div>
      {children}
    </div>
  );
};

export default ComplianceProgramFormStep;

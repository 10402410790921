import { LockClosedIcon } from "@heroicons/react/outline";
import { TbTarget, TbTargetArrow } from "react-icons/tb";
import { GoalScope, GoalState } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Tooltip from "@components/tooltip/tooltip";
import { classNames, getGoalTextColorClassName } from "@helpers/css";

type GoalIconProps = {
  state?: GoalState;
  scope?: GoalScope;
  size?: string | number;
  className?: string;
  isBasicPrivateGoal?: boolean;
};

const GoalIcon = ({
  size = "5",
  state = GoalState.Draft,
  scope = GoalScope.Personal,
  className = "",
  isBasicPrivateGoal = false,
}: GoalIconProps) => {
  const label = useLabel();
  let suffix = "";
  if (scope === GoalScope.Personal) {
    suffix = "Individual ";
  } else if (scope === GoalScope.Team) {
    suffix = `${label("team", { capitalize: true })} `;
  } else if (scope === GoalScope.Organization) {
    suffix = `${label("organization", { capitalize: true })} `;
  } else if (scope === GoalScope.Career) {
    suffix = `Career `;
  }
  return (
    <Tooltip text={`${suffix}${label("goal")}`}>
      <span className="relative">
        {state === GoalState.Closed ? (
          <TbTargetArrow
            className={classNames(
              `w-${size} h-${size} ${getGoalTextColorClassName(scope)}`,
              isBasicPrivateGoal && "text-gray-400",
              className
            )}
          />
        ) : (
          <TbTarget
            className={classNames(
              `w-${size} h-${size} ${getGoalTextColorClassName(scope)}`,
              isBasicPrivateGoal && "text-gray-400",
              className
            )}
          />
        )}
        {isBasicPrivateGoal && (
          <LockClosedIcon className="rounded-full bg-white text-gray-500 absolute -bottom-0.5 -right-0.5 w-3 h-3 p-px shrink-0 " />
        )}
      </span>
    </Tooltip>
  );
};

export default GoalIcon;

import { MeetingViewMeetingNodeNewPageFragmentFragment } from "types/graphql-schema";

import { classNames } from "@helpers/css";

import MeetingSidebarAIContainer from "../meeting-sidebar/ai-container";
import MeetingSummaryGroupTranscription from "./summary-group-transcription";

const MeetingSummary = ({
  meeting,
  meetingGroupId,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  meetingGroupId: number;
}) => {
  return (
    <div className="mt-4 border-t pt-6">
      <MeetingSidebarAIContainer meeting={meeting}>
        {({ meeting: transcriptMeeting, refetch, loading }) => (
          <div className={classNames("text-sm flex flex-col gap-6")}>
            <MeetingSummaryGroupTranscription
              meeting={transcriptMeeting}
              meetingGroupId={meetingGroupId}
              refetch={refetch}
              loading={loading}
            />
          </div>
        )}
      </MeetingSidebarAIContainer>
    </div>
  );
};

export default MeetingSummary;

import moment from "moment";
import { useMemo } from "react";
import { OnlineVideoConference } from "types/graphql-schema";

import MeetingVideoConferenceButton from "@apps/meeting-new/components/meeting/video-conference-button";
import Link from "@components/link/link";
import { classNames } from "@helpers/css";
import { getUrl } from "@helpers/helpers";

const SidebarMeeting = ({
  meetingId,
  meetingGroupId,
  title,
  draft,
  videoConferenceUrl,
  videoConferenceType,
  startDatetime,
  endDatetime,
  now,
}: {
  title?: string | null;
  meetingId: number;
  meetingGroupId?: number;
  startDatetime?: string | null;
  endDatetime?: string | null;
  draft?: boolean;
  videoConferenceType?: OnlineVideoConference | null;
  videoConferenceUrl?: string | null;
  now?: number;
}) => {
  const meetingPath = useMemo(
    () => getUrl({ meetingGroupId, meetingId }),
    [meetingGroupId, meetingId]
  );
  const isActive = window.location.href.includes(meetingPath);
  const tsStartDatetime = useMemo(
    () => (startDatetime ? new Date(startDatetime).getTime() : null),
    [startDatetime]
  );
  const tsEndDatetime = useMemo(
    () => (endDatetime ? new Date(endDatetime).getTime() : null),
    [endDatetime]
  );
  const isInProgress = useMemo(
    () =>
      now && tsStartDatetime && tsEndDatetime
        ? now > tsStartDatetime && now < tsEndDatetime
        : false,
    [tsStartDatetime, tsEndDatetime, now]
  );
  const isAboutToStartProgress = useMemo(
    () =>
      now && tsStartDatetime && tsEndDatetime
        ? now > tsStartDatetime - 6 * 60 * 1000 && now < tsEndDatetime
        : false,
    [tsStartDatetime, tsEndDatetime, now]
  );
  const isPast = useMemo(
    () => (now && !!tsEndDatetime ? tsEndDatetime < now : false),
    [tsEndDatetime, now]
  );
  const timeLabel = useMemo(
    () => moment(startDatetime).format("h:mma").replace(":00", ""),
    [startDatetime]
  );

  if (!meetingGroupId) {
    return null;
  }

  return (
    <Link
      key={meetingId}
      to={meetingPath}
      className={classNames(
        "block rounded-lg py-1.5 px-2.5 mb-1 border bg-white  border-transparent border-gray-200 hover:border-gray-300",
        draft &&
          "bg-amber-50 hover:border-amber-400 border-dashed border-amber-300",
        isActive ? "border-blue-500 hover:border-blue-500" : "",
        isPast && !isActive && "opacity-50"
      )}
    >
      <div className="flex items-start gap-3">
        <div
          className={classNames(
            "flex gap-2 flex-1 text-sm text-gray-800 font-medium",
            "min-w-0 break-words", // to break words that are too long
            isPast && "line-through",
            draft && "text-amber-800",
            isActive && "text-blue-800"
          )}
        >
          <div
            className={classNames(
              "relative mt-1 w-3 h-3 rounded-full shrink-0",
              "bg-gray-300",
              draft && "bg-amber-300",
              isInProgress && "bg-emerald-500",
              isActive && "bg-blue-500"
            )}
          >
            {isInProgress && !isActive && (
              <div className="absolute top-0 w-3 h-3 rounded-full bg-emerald-500 animate-ping" />
            )}
          </div>
          {/* flex-1 & min-w-0 to break words that are too long */}
          <div className="flex-1 min-w-0 line-clamp-2">{title}</div>
        </div>
        {startDatetime && (
          <div
            className={classNames(
              "px-1 py-0.5 text-xs rounded-full",
              draft
                ? "text-amber-800"
                : isInProgress
                ? "bg-emerald-600 text-white font-medium"
                : "text-gray-500",
              isActive && !isInProgress && "text-blue-800"
            )}
          >
            {timeLabel}
          </div>
        )}
      </div>
      {videoConferenceUrl && isAboutToStartProgress && (
        <div className="mt-1.5 text-sm text-gray-700 -ml-1 flex">
          <div>
            <MeetingVideoConferenceButton
              meeting={{
                startDatetime,
                videoConferenceType,
                videoConferenceUrl,
              }}
              className="hover:text-gray-800 hover:bg-black/5 text-xs px-1 h-[22px] rounded text-gray-600 gap-1.5"
            />
          </div>
        </div>
      )}
    </Link>
  );
};

export default SidebarMeeting;

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { ReactElement, ReactNode, useState } from "react";

const PrepGroup = ({
  title,
  collapsible = false,
  isCollapsedByDefault = false,
  children,
}: {
  title: string | ReactNode;
  collapsible?: boolean;
  isCollapsedByDefault?: boolean;
  children: ReactElement | ReactElement[];
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedByDefault);

  const handleClick = () => {
    if (collapsible) {
      setIsCollapsed(!isCollapsed);
    }
  };

  return (
    <div className="border rounded-md">
      <div
        className="text-sm font-semibold flex justify-between items-center gap-2 px-6 py-4 group"
        onClick={handleClick}
        role={collapsible ? "button" : undefined}
      >
        <div className="text-gray-900 flex-1">{title}</div>
        {collapsible && (
          <span className="rounded-md p-1 shrink-0 group-hover:bg-gray-100">
            {isCollapsed ? (
              <ChevronDownIcon className="w-4 h-4 text-gray-500" />
            ) : (
              <ChevronUpIcon className="w-4 h-4 text-gray-500" />
            )}
          </span>
        )}
      </div>
      {(!collapsible || !isCollapsed) && (
        <div className="border-t">{children}</div>
      )}
    </div>
  );
};

export default PrepGroup;

import { ExternalLinkIcon } from "@heroicons/react/outline";
import { useMemo } from "react";
import {
  AssessmentQuestionType,
  GetComplianceProgramForReportQuery,
  RangeAssessmentQuestionNode,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import {
  getManagerEffectivenessAnswerPercentage,
  matchesReportsToFilter,
  matchesTeamsFilter,
  matchesUserFilter,
} from "@apps/reporting/helpers";
import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { assertNonNull } from "@helpers/helpers";

import { TeamPickerTeam } from "../team-picker";

const ManagerEffectivenessTable = ({
  questions,
  complianceProgram,
  userFilterList,
  reportsToFilterList,
  teamFilterList,
}: {
  complianceProgram: NonNullable<
    GetComplianceProgramForReportQuery["complianceProgram"]
  >;
  questions: Pick<
    RangeAssessmentQuestionNode,
    "id" | "title" | "startValue" | "endValue" | "labels" | "questionType"
  >[];
  userFilterList: BasicUser[];
  reportsToFilterList: BasicUser[];
  teamFilterList: TeamPickerTeam[];
}) => {
  const label = useLabel();
  const filteredQuestions = useMemo(
    () =>
      questions.filter((q) => q.questionType === AssessmentQuestionType.Range),
    [questions]
  );
  if (filteredQuestions.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-end mb-4 items-center gap-4">
        <Button
          to={`/programs/${complianceProgram.id}/progress`}
          text="Program Progress"
        />
      </div>
      <TableContainer scroll>
        <Table>
          <TableHeadRow>
            <TableHeadCell cellClassName="w-56">Name</TableHeadCell>
            <TableHeadCell>Responses/Reports</TableHeadCell>
            {filteredQuestions.map((question) => {
              return (
                <TableHeadCell key={question.id}>
                  {question.title}
                </TableHeadCell>
              );
            })}
          </TableHeadRow>
          <TableBody>
            {assertNonNull(
              complianceProgram.managerEffectivenessReport
            ).edges.map((rowEdge) => {
              const row = assertNonNull(rowEdge?.node);
              if (!matchesUserFilter(userFilterList, row.manager)) {
                return null;
              }
              if (!matchesReportsToFilter(reportsToFilterList, row.manager)) {
                return null;
              }
              if (!matchesTeamsFilter(teamFilterList, row.manager)) {
                return null;
              }
              return (
                <TableBodyRow key={row.manager.id}>
                  <TableBodyCell>
                    <div className="flex items-center justify-between min-w-0 w-56">
                      <div className="flex items-center min-w-0 gap-2 truncate">
                        <Avatar className="" size="6" user={row.manager} />
                        <div className="truncate flex-1">
                          {row.manager.name}
                        </div>
                      </div>
                      <Button
                        tooltip={
                          row.assessmentDelivery?.id
                            ? `View ${label("review")} package`
                            : `No ${label("review")} package available`
                        }
                        icon={ExternalLinkIcon}
                        disabled={!row.assessmentDelivery?.id}
                        to={
                          row.assessmentDelivery?.id
                            ? `/assessments/assessment/delivery/${row.assessmentDelivery.id}`
                            : ""
                        }
                        theme={buttonTheme.iconGray}
                      />
                    </div>
                  </TableBodyCell>
                  <TableBodyCell>
                    {row.assessments.totalCount}/
                    {row.manager.directReports.totalCount}
                  </TableBodyCell>
                  {filteredQuestions.map((question) => {
                    const questionData = row.questions.edges.find(
                      (q) => q?.node?.question.id === question.id
                    )?.node;

                    if (!questionData) {
                      return (
                        <TableBodyCell key={question.id}>N/A</TableBodyCell>
                      );
                    }
                    return (
                      <TableBodyCell key={question.id}>
                        {Math.round(
                          getManagerEffectivenessAnswerPercentage(
                            question,
                            questionData.avgAnswer
                          )
                        )}
                        %
                      </TableBodyCell>
                    );
                  })}
                </TableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ManagerEffectivenessTable;

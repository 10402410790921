import { ArrowSmRightIcon, CalendarIcon } from "@heroicons/react/outline";
import moment from "moment";
import { ChangeEventHandler, useCallback } from "react";
import DatePicker from "react-datepicker";
import {
  ComplianceProgramRecurrence,
  SaveComplianceProgramMutationVariables,
} from "types/graphql-schema";

import Input from "@components/input/input";
import Select, { SelectOption } from "@components/select/select";
import { assertNonNull } from "@helpers/helpers";

const ComplianceProgramFormStartDueDates = ({
  proposedComplianceProgram,
  onUpdateProposedComplianceProgram,
}: {
  proposedComplianceProgram: SaveComplianceProgramMutationVariables;
  onUpdateProposedComplianceProgram: (
    data: SaveComplianceProgramMutationVariables
  ) => void;
}) => {
  const handleEnableRecurrence: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (evt) => {
        onUpdateProposedComplianceProgram({
          ...proposedComplianceProgram,
          recurrence: evt.target.checked
            ? ComplianceProgramRecurrence.Monthly
            : ComplianceProgramRecurrence.None,
        });
      },
      [onUpdateProposedComplianceProgram, proposedComplianceProgram]
    );

  const handleChangeRecurrence = useCallback(
    (opt: SelectOption<ComplianceProgramRecurrence>) => {
      onUpdateProposedComplianceProgram({
        ...proposedComplianceProgram,
        recurrence: opt.value,
      });
    },
    [onUpdateProposedComplianceProgram, proposedComplianceProgram]
  );
  return proposedComplianceProgram.ongoing ? (
    <div className="flex gap-2 items-center justify-between">
      <div className="text-sm">Starts</div>
      <Input
        className="w-24"
        type="number"
        min={0}
        step={1}
        aria-label="Compliance program start day offset"
        value={proposedComplianceProgram.startDateOffset ?? ""}
        onChange={(e) =>
          onUpdateProposedComplianceProgram({
            ...proposedComplianceProgram,
            startDateOffset: Math.trunc(Number(e.target.value)),
          })
        }
      />
      <div className="text-sm">days after start date</div>
      <ArrowSmRightIcon className="text-gray-500 w-4 h-4 shrink-0" />
      <div className="text-sm">Ends</div>
      <Input
        className="w-24"
        type="number"
        min={0}
        step={1}
        aria-label="Compliance program due date offset"
        value={proposedComplianceProgram.startDateDueDateOffset ?? ""}
        onChange={(e) =>
          onUpdateProposedComplianceProgram({
            ...proposedComplianceProgram,
            startDateDueDateOffset: Math.trunc(Number(e.target.value)),
          })
        }
      />
      <div className="text-sm">days after start date</div>
    </div>
  ) : (
    <div>
      <div className="mb-2 flex gap-2 items-center">
        <DatePicker
          showIcon
          wrapperClassName="flex-1"
          icon={<CalendarIcon className="pr-4 text-gray-600" />}
          placeholderText="Start date"
          selected={
            proposedComplianceProgram.startDate
              ? moment(proposedComplianceProgram.startDate).toDate()
              : null
          }
          onChange={(date) =>
            onUpdateProposedComplianceProgram({
              ...proposedComplianceProgram,
              startDate: date ? moment(date).format("YYYY-MM-DD") : null,
            })
          }
          dateFormat="MMM d, yyyy"
          ariaLabelledBy="Compliance program start date"
          className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        />
        <ArrowSmRightIcon className="text-gray-500 w-4 h-4" />
        <DatePicker
          showIcon
          wrapperClassName="flex-1"
          icon={<CalendarIcon className="pr-4 text-gray-600" />}
          placeholderText="Due date"
          selected={
            proposedComplianceProgram.dueDate
              ? moment(proposedComplianceProgram.dueDate).toDate()
              : null
          }
          onChange={(date) =>
            onUpdateProposedComplianceProgram({
              ...proposedComplianceProgram,
              dueDate: date ? moment(date).format("YYYY-MM-DD") : null,
            })
          }
          dateFormat="MMM d, yyyy"
          ariaLabelledBy="Compliance program due date"
          className="px-4 py-2 block w-full sm:text-sm shadow-inner border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      <div className="w-full flex items-center gap-4">
        <div className="h-12 flex items-center gap-2 text-sm">
          <input
            type="checkbox"
            checked={
              proposedComplianceProgram.recurrence !==
              ComplianceProgramRecurrence.None
            }
            onChange={handleEnableRecurrence}
          />{" "}
          Repeat this program
        </div>
        {proposedComplianceProgram.recurrence !==
          ComplianceProgramRecurrence.None && (
          <Select
            containerClassName="flex-1"
            value={assertNonNull(proposedComplianceProgram.recurrence)}
            options={[
              {
                value: ComplianceProgramRecurrence.Monthly,
                label: "Monthly",
              },
              {
                value: ComplianceProgramRecurrence.Quarterly,
                label: "Quarterly",
              },
              {
                value: ComplianceProgramRecurrence.Yearly,
                label: "Yearly",
              },
            ]}
            onChange={handleChangeRecurrence}
            aria-label="Compliance program recurrence select"
          />
        )}
      </div>
    </div>
  );
};

export default ComplianceProgramFormStartDueDates;

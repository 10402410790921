import { compact, max, sortBy, uniqBy } from "lodash";
import moment from "moment";
import {
  ActiveReviewsComplianceProgramFragment,
  ActiveReviewsProgramAssessmentTemplateFragment,
  AssessmentDeliveryState,
  AssessmentNominationFragment,
  AssessmentState,
  AssessmentType,
  ComplianceProgramActionAssessmentDeliveryFragment,
  EngagementSurveyComplianceProgramFragment,
  GetComplianceProgramActionsQuery,
  UnmetComplainceProgramAssessmentTemplateFragment,
  UnmetComplianceProgramFragment,
  UserActivityComplianceGroupAssessmentTemplateFragment,
  UserActivityComplianceProgramMeetingFragment,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import { getLabel } from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import { ButtonProps, buttonTheme } from "@components/button/button";
import { getAssessmentTypeLabel } from "@helpers/constants";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
  assertNonNull,
  removeCurrentYear,
} from "@helpers/helpers";
import { pluralize } from "@helpers/string";

export enum UserRelationEnum {
  manager,
  report,
}

export const getReportsAndManagersWithMatchingOneonones = (
  complianceProgram:
    | NonNullable<GetComplianceProgramActionsQuery["complianceProgram"]>
    | NonNullable<ActiveReviewsComplianceProgramFragment>
) => {
  const currentUser = currentUserVar();

  const matchingOneonones = assertEdgesNonNull(
    complianceProgram.matchingOneonones
  );
  const reports = assertEdgesNonNull(complianceProgram.applicableReports).map(
    (user) => ({
      ...user,
      type: UserRelationEnum.report,
    })
  );
  const managers = assertEdgesNonNull(complianceProgram.applicableManagers).map(
    (user) => ({
      ...user,
      type: UserRelationEnum.manager,
    })
  );
  const reportsAndManagers = [...reports, ...managers];
  const items = reportsAndManagers.map((user) => {
    const userIds = sortBy([currentUser.id, user.id]);
    const matchingOneonone = matchingOneonones.find((oneonone) => {
      const participantIds = sortBy(
        assertEdgesNonNullWithStringId(oneonone.participants).map(
          (participant) => participant.user?.id
        )
      );
      return userIds.every((userId) => participantIds.includes(userId));
    });
    return { user, matchingOneonone };
  });
  return sortBy(items, (item) =>
    !item.matchingOneonone ? 0 : item.matchingOneonone.isFinalized ? 2 : 1
  );
};

export type ComplianceProgramActionBasicItemProps = {
  key: number | string;
  id?: null | number | string;
  user?: BasicUser | null;
  label?: string | null;
  buttonProps: ButtonProps;
  statusText?: string | null;
  statusCompleted?: boolean | null;
};

export type ComplianceProgramActionNominationItemProps =
  ComplianceProgramActionBasicItemProps & {
    assessmentOpenForNomination: AssessmentNominationFragment;
    complianceProgram?: AssessmentNominationFragment["complianceProgram"];
  };

export const mapAssessmentForNominationToItem = (
  currentUserId: number,
  assessmentOpenForNomination: AssessmentNominationFragment
): ComplianceProgramActionNominationItemProps => ({
  key: assessmentOpenForNomination.id,
  id: assessmentOpenForNomination.id,
  complianceProgram: assessmentOpenForNomination.complianceProgram,
  user: assessmentOpenForNomination.targetUser,
  label:
    currentUserId === assessmentOpenForNomination.targetUser?.id
      ? "Peer Nominations"
      : null,
  buttonProps: {
    text: "Nominate peers",
    theme: buttonTheme.lightBlue,
  },
  statusText: `${
    assessmentOpenForNomination.nominations.totalCount
  } ${pluralize(
    "nomination",
    assessmentOpenForNomination.nominations.totalCount
  )}`,
  statusCompleted: assessmentOpenForNomination.nominations.totalCount > 0,
  assessmentOpenForNomination: assessmentOpenForNomination,
});

export const mapAssessmentDeliveryToItem = (
  currentUserId: number,
  delivery: ComplianceProgramActionAssessmentDeliveryFragment
) => {
  const canDeliver =
    delivery.state === AssessmentDeliveryState.Draft &&
    delivery.canUpdate?.permission;
  const total =
    max([delivery.respondersCount, delivery.nominees.totalCount]) || 0;
  const label = getLabel();
  return {
    key: delivery.id,
    id: delivery.id,
    user: delivery.target,
    complianceProgram: delivery.complianceProgram,
    label:
      currentUserId === delivery.target?.id
        ? `${getAssessmentTypeLabel(delivery.template.assessmentType)} ${label(
            "review"
          )} package`
        : null,
    buttonProps: {
      text: canDeliver ? "Deliver" : `View`,
      to: `/assessments/assessment/delivery/${delivery.id}`,
      theme: canDeliver ? buttonTheme.lightBlue : undefined,
    },
    statusText:
      delivery.state === AssessmentDeliveryState.Draft &&
      delivery.template.assessmentType === AssessmentType.Peer
        ? `${delivery.respondersCount}/${total} ${pluralize(
            "peer",
            total
          )} submitted`
        : delivery.state !== AssessmentDeliveryState.Draft
        ? `Delivered ${
            delivery.deliveryDatetime
              ? removeCurrentYear(
                  moment(delivery.deliveryDatetime).format("MMM DD, YYYY")
                )
              : ""
          }`
        : "",
    statusCompleted: delivery.state === AssessmentDeliveryState.Delivered,
  };
};

const getAssessmentStateList = (
  complianceProgram:
    | NonNullable<GetComplianceProgramActionsQuery["complianceProgram"]>
    | UnmetComplianceProgramFragment,
  assessmentTemplate:
    | UserActivityComplianceGroupAssessmentTemplateFragment
    | UnmetComplainceProgramAssessmentTemplateFragment
    | ActiveReviewsProgramAssessmentTemplateFragment,
  currentUserId: number
) => {
  const usersMissingAssessments = complianceProgram?.usersMissingAssessment
    ? complianceProgram.usersMissingAssessment.edges.map((edge) =>
        assertNonNull(edge?.node)
      )
    : [];
  const complianceGroupAssessments = complianceProgram?.assessments
    ? assertEdgesNonNull(complianceProgram.assessments)
    : [];
  const matchingUserMissingAssessments = usersMissingAssessments
    .filter((missingAssessment) => {
      return (
        missingAssessment.responder.id === currentUserId &&
        missingAssessment.assessmentTemplateId === assessmentTemplate.id
      );
    })
    .map((missingAssessment) => ({
      id: null,
      responder: missingAssessment.responder,
      target: missingAssessment.target,
      state: null,
      submittedDatetime: null,
    }));
  const createdAssessments = complianceGroupAssessments
    .filter(
      (createdAssessment) =>
        createdAssessment.template.id === assessmentTemplate.id
    )
    .filter((createdAssessment) => {
      // even if they've started an assessment (it's still in draft) we only want to show those that are missing
      return matchingUserMissingAssessments.some(
        (missingAssessment) =>
          missingAssessment.target?.id === createdAssessment.target?.id
      );
    })
    .map((createdAssessment) => ({
      id: createdAssessment.id,
      target: createdAssessment.target,
      responder: createdAssessment.responder,
      state: createdAssessment.state,
      submittedDatetime: createdAssessment.submittedDatetime,
    }));
  const allAssessments = uniqBy(
    [...createdAssessments, ...matchingUserMissingAssessments],
    (assessment) => assessment.target?.id
  );
  return sortBy(allAssessments, (assessment) =>
    assessment.state === null
      ? 1
      : assessment.state === AssessmentState.Draft
      ? 2
      : 3
  );
};

export type ComplianceProgramActionAssessmentItemProps =
  ComplianceProgramActionBasicItemProps & {
    assessmentTemplateId: number;
    assessmentType: AssessmentType;
    complianceProgram:
      | NonNullable<GetComplianceProgramActionsQuery["complianceProgram"]>
      | UnmetComplianceProgramFragment
      | EngagementSurveyComplianceProgramFragment;
  };

export const getAssessmentItemsForTemplate = (
  complianceProgram:
    | NonNullable<GetComplianceProgramActionsQuery["complianceProgram"]>
    | UnmetComplianceProgramFragment,
  assessmentTemplate:
    | UserActivityComplianceGroupAssessmentTemplateFragment
    | UnmetComplainceProgramAssessmentTemplateFragment
    | ActiveReviewsProgramAssessmentTemplateFragment,
  currentUserId: number
): ComplianceProgramActionAssessmentItemProps[] => {
  const label = getLabel();
  const isEngagementSurvey =
    assessmentTemplate.assessmentType === AssessmentType.EngagementSurvey;
  const path = isEngagementSurvey ? `/survey` : `/assessments`;
  return compact(
    getAssessmentStateList(
      complianceProgram,
      assessmentTemplate,
      currentUserId
    ).map((assessmentState) => {
      if (!assessmentState.target && !isEngagementSurvey) {
        return null;
      }
      const isCurrentUser = assessmentState.target?.id === currentUserId;
      return {
        id: assessmentState.id,
        key: `${assessmentState.id}-${assessmentState.target?.id}-${assessmentTemplate.id}`,
        assessmentTemplateId: assessmentTemplate.id,
        assessmentType: assessmentTemplate.assessmentType,
        complianceProgram: complianceProgram,
        user: isEngagementSurvey
          ? assessmentState.responder
          : assessmentState.target,
        label: isCurrentUser
          ? isEngagementSurvey
            ? "Survey"
            : `Self ${label("review")}${
                assessmentTemplate.assessmentType === AssessmentType.Performance
                  ? ` for performance`
                  : assessmentTemplate.assessmentType === AssessmentType.Manager
                  ? ` for upward feedback`
                  : ""
              }`
          : null,
        buttonProps:
          assessmentState.state === AssessmentState.Draft ||
          assessmentState.state === null
            ? {
                text: `${
                  assessmentState.state === AssessmentState.Draft
                    ? "Continue"
                    : `Start`
                } ${label(isEngagementSurvey ? "survey" : "review")}`,
                to: `${path}/assessments/${assessmentState.id}`,
                theme: buttonTheme.lightBlue,
              }
            : {
                // assumed it is submitted
                text: `View`,
                to: `${path}/assessments/${assessmentState.id}`,
              },
        statusText:
          assessmentState.state === AssessmentState.Submitted &&
          assessmentState.submittedDatetime
            ? `Submitted ${removeCurrentYear(
                moment(assessmentState.submittedDatetime).format("MMM DD, YYYY")
              )}`
            : complianceProgram?.dueDate
            ? `Due ${removeCurrentYear(
                moment(complianceProgram?.dueDate).format("MMM DD, YYYY")
              )}`
            : null,
        statusCompleted:
          assessmentState.state === AssessmentState.Submitted &&
          !!assessmentState.submittedDatetime,
      };
    })
  );
};

export type ComplianceProgramActionOneononeItemProps =
  ComplianceProgramActionBasicItemProps & {
    user: BasicUser;
    userRelation: number;
    requiredTopicTemplateId: number;
    matchingOneonone?: UserActivityComplianceProgramMeetingFragment;
    complianceProgram:
      | NonNullable<GetComplianceProgramActionsQuery["complianceProgram"]>
      | NonNullable<ActiveReviewsComplianceProgramFragment>;
  };

export const getOneononeItemsFromComplianceProgram = (
  complianceProgram:
    | NonNullable<GetComplianceProgramActionsQuery["complianceProgram"]>
    | NonNullable<ActiveReviewsComplianceProgramFragment>,
  currentUserId: number
): ComplianceProgramActionOneononeItemProps[] => {
  const label = getLabel();
  const requiredTopicTemplates = complianceProgram?.requiredTopicTemplates
    ? assertEdgesNonNull(complianceProgram.requiredTopicTemplates)
    : [];
  const requiredTopicTemplate = requiredTopicTemplates[0];
  const reportsAndManagersWithMatchingOneonone = requiredTopicTemplate
    ? getReportsAndManagersWithMatchingOneonones(complianceProgram)
    : [];
  return reportsAndManagersWithMatchingOneonone.map(
    (reportAndManagerWithMatchingOneonone) => {
      return {
        key: reportAndManagerWithMatchingOneonone.user.id,
        id: reportAndManagerWithMatchingOneonone.user.id,
        user: reportAndManagerWithMatchingOneonone.user,
        requiredTopicTemplateId: requiredTopicTemplate.id,
        matchingOneonone: reportAndManagerWithMatchingOneonone.matchingOneonone,
        complianceProgram: complianceProgram,
        label:
          currentUserId === reportAndManagerWithMatchingOneonone.user.id &&
          reportAndManagerWithMatchingOneonone.user.type ===
            UserRelationEnum.report
            ? `${label("1-on-1", { pluralize: true })} Meetings`
            : null,
        buttonProps: reportAndManagerWithMatchingOneonone.matchingOneonone
          ? {
              text: "View Meeting",
            }
          : {
              text: `Schedule ${label("review")} Meeting`,
              theme: buttonTheme.lightBlue,
            },
        statusText: reportAndManagerWithMatchingOneonone.matchingOneonone
          ?.isFinalized
          ? "Finalized"
          : reportAndManagerWithMatchingOneonone.matchingOneonone?.draft
          ? "Draft"
          : reportAndManagerWithMatchingOneonone.matchingOneonone?.startDatetime
          ? "Scheduled"
          : null,
        statusCompleted:
          reportAndManagerWithMatchingOneonone.matchingOneonone?.isFinalized,
        userRelation: reportAndManagerWithMatchingOneonone.user.type,
      };
    }
  );
};
